import * as React from 'react';
import useUser from '~/src/hooks/useUser';
import { InnerUIFlagsProvider } from './InnerUIFlagsProvider';

export const UIFlagsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const user = useUser();
  return (
    <InnerUIFlagsProvider membershipId={user.currentOrganization?.id}>
      {children}
    </InnerUIFlagsProvider>
  );
};
