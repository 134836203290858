import _ from 'lodash';
import { types } from 'mobx-state-tree';
import { stateCodeToStateName } from '~/src/containers/CourtFormsLandingContainer';

export const UserFlagStore = types
  .model({
    featureFlags: types.optional(types.array(types.string), []),
  })
  .actions((self) => {
    const setFeatureFlags = (flags = []) => {
      // @ts-ignore
      self.featureFlags = flags;
    };

    return {
      setFeatureFlags,
    };
  })
  .views((self) => {
    const isV2Subscriptions = () => {
      return self.featureFlags.indexOf('SUBSCRIPTIONS_V2') > -1;
    };
    const isUnifiedPlatform = () => {
      return self.featureFlags.indexOf('TAGS_V2') > -1;
    };

    const doesFeatureFlagForThisStateCodeExist = (stateCode: string) => {
      return self.featureFlags.indexOf(`${stateCode}_JC_FORMS`) > -1;
    };

    const isClioFreePlan = () => {
      return self.featureFlags.indexOf('CLIO_FREE') > -1;
    };

    const isQuestionnairesEnabled = () => {
      return self.featureFlags.indexOf('INTAKE_FORMS') > -1;
    };

    const isClioQuestionnaireEnabled = () => {
      return self.featureFlags.indexOf('QUESTIONNAIRES') > -1;
    };

    const isWordDocumentAutomationEnabled = () => {
      return self.featureFlags.indexOf('WORD_DOCUMENT_AUTOMATION') > -1;
    };

    const isFederalCourtFormsEnabled = () => {
      return self.featureFlags.indexOf('FEDERAL_FORMS') > -1;
    };

    const isCourtFormsEnabled = () => {
      // whether court forms of FEDERAL is enabled
      if (self.featureFlags.indexOf('FEDERAL_FORMS') > -1) {
        return true;
      }

      // whether court forms of any state is enabled
      let anyStateIsEnabled = false;
      _.forEach(stateCodeToStateName, (_, stateCode) => {
        if (self.featureFlags.indexOf(`${stateCode}_JC_FORMS`) > -1) {
          anyStateIsEnabled = true;
        }
      });

      return anyStateIsEnabled;
    };

    const isAddinLongDocumentsEnabled = () =>
      self.featureFlags.indexOf('ADDIN_LONG_DOCUMENTS') > -1;

    return {
      isV2Subscriptions,
      isUnifiedPlatform,
      doesFeatureFlagForThisStateCodeExist,
      isClioFreePlan,
      isQuestionnairesEnabled,
      isClioQuestionnaireEnabled,
      isWordDocumentAutomationEnabled,
      isFederalCourtFormsEnabled,
      isCourtFormsEnabled,
      isAddinLongDocumentsEnabled,
    };
  });
