/* Libraries */
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import qs from 'qs';

/* Containers */
// import QuestionnaireResponseContainer from '~/src/containers/QuestionnaireResponse';

import QuestionnaireResponsePageContainer from '~/src/containers/pages/questionnaires/QuestionnaireResponsePage';
import EditQuestionnairePageContainer from '~/src/containers/pages/questionnaires/EditQuestionnairePage';
import QuestionnaireTablePageContainer from '~/src/containers/pages/questionnaires/QuestionnaireTablePage';
import CreateNewQuestionnaireOptionsPageContainer from '~/src/containers/pages/questionnaires/CreateNewQuestionnaireOptionsPage';
import CreateNewQuestionnaireMapTemplatesPageContainer from '~/src/containers/pages/questionnaires/CreateNewQuestionnaireMapTemplatesPage';
import CreateNewQuestionnairePageContainer from '~/src/containers/pages/questionnaires/CreateNewQuestionnairePage';

/* Components */
import QuestionnairesGated from '~/src/components/QuestionnairesGated';

/* Hooks */
import useFeatureFlags from '~/src/hooks/useFeatureFlags';
import { useLayoutContext } from '~/src/contexts/Layout';

const QuestionnaireRoutes = () => {
  const { setTitle } = useLayoutContext();

  return (
    <Switch>
      <Redirect exact from="/questionnaire" to="/questionnaire/all" />
      <Route
        exact
        path="/questionnaire/all"
        render={() => {
          setTitle('Questionnaires');

          return <QuestionnaireTablePageContainer />;
        }}
      />
      <Route
        exact
        path="/questionnaire/new"
        render={() => {
          setTitle('Create questionnaire');

          return <CreateNewQuestionnaireOptionsPageContainer />;
        }}
      />
      <Route
        exact
        path="/questionnaire/new/map"
        render={() => {
          setTitle('Create questionnaire');

          return <CreateNewQuestionnaireMapTemplatesPageContainer />;
        }}
      />
      <Route
        exact
        path="/questionnaire/new/create"
        render={({ location }) => {
          setTitle('Create questionnaire');
          const queryDict = qs.parse(location.search, {
            ignoreQueryPrefix: true,
          });
          const templateSetIds = queryDict.templateSetId
            ? [queryDict.templateSetId]
            : [];
          const templateIds = queryDict.templates || [];

          return (
            <CreateNewQuestionnairePageContainer
              templateSetIds={templateSetIds}
              templateIds={templateIds}
            />
          );
        }}
      />
      <Route
        exact
        path="/questionnaire/edit/:id"
        render={({ match: { params } }) => {
          const { id } = params;

          return <EditQuestionnairePageContainer id={id} setTitle={setTitle} />;
        }}
      />
      <Route
        exact
        path="/questionnaire/response/:id/view"
        render={({ history, match: { params } }) => {
          const { id } = params;

          if (!id) {
            history.go(-1);
          }

          return <QuestionnaireResponsePageContainer id={id} />;
        }}
      />
      <Route
        exact
        path="/questionnaire/response/:id/edit"
        render={({ history, match: { params } }) => {
          const { id } = params;

          if (!id) {
            history.go(-1);
          }

          return <QuestionnaireResponsePageContainer edit id={id} />;
        }}
      />
    </Switch>
  );
};

const QuestionnaireRoutesContainer = () => {
  const { isClioFreePlan } = useFeatureFlags();

  if (isClioFreePlan) {
    return <QuestionnairesGated />;
  }

  return <QuestionnaireRoutes />;
};

export default QuestionnaireRoutesContainer;
