import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { Contact } from '~/src/entities/contact';
import { ProjectStackFieldGroup } from '~/src/entities/project';
import {
  useUpdateContactWithProjectData,
  StackSavedData,
} from '~/src/entities/project/hooks';
import { useDebouncedValue } from '~/src/hooks/useDebouncedValue';
import { flattenFn } from '../../utils';

type ContactUpdaterWithProjectDataProps = {
  contact: Contact;
  group: ProjectStackFieldGroup;
};

const ContactUpdaterWithProjectData = ({
  contact,
  group,
}: ContactUpdaterWithProjectDataProps) => {
  const { mutate } = useUpdateContactWithProjectData();

  const watchedValues = useWatch();
  const debouncedWatchedValues = useDebouncedValue(watchedValues, 1000);

  useEffect(() => {
    const stackSavedData: StackSavedData = flattenFn(debouncedWatchedValues);
    if (contact && stackSavedData) {
      const nextStackSavedData: StackSavedData = {};
      for (const key in stackSavedData) {
        if (group.fields.indexOf(key) > -1) {
          const arr = key.split('.'); // key == 'attorney.city'
          const partialKey = arr[arr.length - 1]; // partialKey == 'city'
          if (partialKey) {
            if (
              (partialKey === 'first_name' || partialKey === 'last_name') &&
              !stackSavedData[key]
            ) {
              continue; // should not update contact to have blank first_name, blank last_name
            }

            nextStackSavedData[partialKey] = stackSavedData[key] ?? ''; // Use a default value('') if stackSavedData[key] is undefined
          }
        }
      }
      if (Object.keys(nextStackSavedData).length > 0) {
        const originalData: StackSavedData = {
          ...contact.data,
          first_name: contact.first_name,
          last_name: contact.last_name,
        };

        let haveDifferentValues = false;
        for (const key in nextStackSavedData) {
          if (nextStackSavedData[key] != originalData[key]) {
            haveDifferentValues = true;
            break;
          }
        }
        if (haveDifferentValues) {
          const payload = {
            contactId: contact.id,
            stack_saved_data: nextStackSavedData,
          };
          mutate(payload);
        }
      }
    }
  }, [debouncedWatchedValues, mutate, contact?.id, contact, group]);

  return null;
};

export default ContactUpdaterWithProjectData;
