import React from 'react';
import PropTypes from 'prop-types';
import Button from '~/src/components/Button';

import { css } from 'aphrodite';
import ContactSlideInDetail from './ContactSlideInDetail';

import styles from './styles';

const ContactSlideIn = ({
  fieldValues,
  contact,
  edit,
  onEdit,
  onCancelEdit,
  onSave,
  onFormChange,
}) => {
  const editButton = <Button onClick={onEdit}>Edit</Button>;
  const cancelEdit = <Button onClick={onCancelEdit}>Cancel edit</Button>;

  const footer = (
    <footer className={css(styles.contactsSlideInFooter)}>
      <Button primary onClick={onSave}>
        Save
      </Button>
    </footer>
  );

  return (
    <aside className={css(styles.contactsSlideInContainer)}>
      <header className={css(styles.contactSlideInHeader)}>
        <h1 className={css(styles.contactSlideInHeaderTitle)}>
          {contact.fullName()}
        </h1>
        {!edit && editButton}
        {edit && cancelEdit}
      </header>
      <div
        className={css(
          styles.contactSlideInBody,
          edit && styles.contactSlideInBodyFooter,
        )}
      >
        <ContactSlideInDetail
          fieldValues={fieldValues}
          contact={contact}
          onFormChange={onFormChange}
          edit={edit}
        />
      </div>

      {edit && footer}
    </aside>
  );
};

ContactSlideIn.propTypes = {
  edit: PropTypes.bool,
  onFormChange: PropTypes.func,
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
};

ContactSlideIn.defaultProps = {
  fieldValues: {},
  edit: false,
  onFormChange: () => {},
  onEdit: () => {},
  onSave: () => {},
};

export default ContactSlideIn;
