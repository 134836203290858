import React, { useState } from 'react';
import PageLayout, { LoadingOverlay } from '~/src/components/PageLayout';
import { useSnackbar } from 'notistack';
import { history } from '../../../utils/history';
import { useProject, usePopulateProject } from '~/src/entities/project';
import { PopulatePageContent } from './PopulatePageContent';
import { PopulatePageFooter } from './PopulatePageContent/PopulatePageFooter';
import { useUIFlagEnabled } from '~/src/entities/uiFlags';

type PopulatePageV2Props = {
  projectId: number;
};

export const PopulatePageV2 = ({ projectId }: PopulatePageV2Props) => {
  const project = useProject(projectId, {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const isProgressTrackerEnabled = useUIFlagEnabled('progressTracker');
  const [isGenerating, setIsGenerating] = useState(false);
  const { mutateAsync } = usePopulateProject();
  const snackbar = useSnackbar();

  const isLoading = project.isLoading;

  const onBack = () => {
    history.goBack();
  };

  const onGenerate = async () => {
    try {
      setIsGenerating(true);
      await mutateAsync({ projectId });
      history.push(`/drafting/${projectId}`);
    } catch (error) {
      snackbar.enqueueSnackbar(
        'Something went wrong, please try again later.',
        {
          variant: 'error',
        },
      );
      setIsGenerating(false);
    }
  };

  const pageProps = {
    showNavigation: false,
    headerProps: {
      showCloseButton: true,
      showAvatar: false,
      onClose: onBack,
    },
  };

  if (project.data)
    return (
      <PageLayout
        title={project.data.title}
        disablePadding
        {...pageProps}
        footer={
          <PopulatePageFooter
            isGenerating={isGenerating}
            onCancel={onBack}
            onGenerate={onGenerate}
          />
        }
      >
        <PopulatePageContent
          project={project.data}
          isProgressTrackerEnabled={isProgressTrackerEnabled}
        />
      </PageLayout>
    );

  if (isLoading) {
    return (
      <PageLayout {...pageProps}>
        <LoadingOverlay title="Loading details" subtitle="Please wait" />
      </PageLayout>
    );
  }

  return <PageLayout {...pageProps}>Error</PageLayout>;
};
