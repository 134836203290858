import { types, flow, getParent, getRoot } from 'mobx-state-tree';
import moment from 'moment';

/* Services */
import templatesService from '~/src/services/templates';

/* Models */
import createPaginatedModel from '~/src/stores/composers/createPaginatedModel';

import { Tag } from '~/src/stores/tagsStore';

/* Utils */
import { getTemplateIdentifier } from '~/src/utils/dataTransformers';
import analyticsService from '../services/analytics';
import { getOrgFprintFromStoreNode, isUnifiedPlatform } from './utils';

const PreviewPage = types.model('PreviewPage', {
  backgroundUrl: types.string,
  page: types.integer,
  pageid: types.integer,
});

const TemplateVariable = types.model('TemplateVariable', {
  source: types.string,
  type: types.string,
  prompt: types.string,
  tag: types.string,
});

const IncludedTemplate = types.model('TemplateVariable', {
  templateId: types.number,
  templateName: types.string,
  guid: types.string,
  variables: types.array(types.map(types.frozen({}))),
});

const TemplateStore = types
  .model('Template', {
    loading: types.maybeNull(types.boolean),
    createdAt: types.maybeNull(types.string),
    id: types.string,
    identifier: types.identifier,
    docid: types.integer,
    documentType: types.integer,
    fprint: types.maybeNull(types.string),
    msWordTemplate: types.maybeNull(types.string),
    pdfBackendDocid: types.maybeNull(types.integer),
    previewPages: types.optional(types.array(PreviewPage), []),
    subtitle: types.string,
    templateDocid: types.maybeNull(types.integer),
    title: types.string,
    updatedAt: types.maybeNull(types.string), // NOTE: the attribute is "last_modified" on the backend
    selected: types.optional(types.boolean, false),
    documentCategoryId: types.maybeNull(types.number),
    // V2.0 Tags Specific
    tagIds: types.optional(types.array(types.reference(Tag)), []),
    // TODO: V1.0 Tags Specific, we will eventually deperate this in favor of tagId once all users have been migrated.
    tags: types.optional(types.array(TemplateVariable), []),
    isBaseTemplate: types.optional(types.boolean, false),
    includes: types.optional(types.array(IncludedTemplate), []),
  })
  .actions((self) => {
    const toggleSelected = () => {
      const currentStatus = self.selected;
      self.selected = !currentStatus;
      return self.selected;
    };

    const getUserStore = () => {
      const root = getRoot(self);
      return root.user;
    };

    const getTagsStore = () => {
      const rootStore = getRoot(self);
      return rootStore.tags;
    };

    const fetchTags = flow(function* fetchTags() {
      if (isUnifiedPlatform(self)) {
        const tagsStore = self.getTagsStore();
        const tagIds = yield tagsStore.fetchTagByTemplateId(self.id);
        self.tagIds = tagIds;
      } else {
        const tags = yield templatesService.getTemplateTags(
          getOrgFprintFromStoreNode(self),
          self.id,
        );
        const nextTags = tags.map((tag) => ({
          source: tag.attribute_source || 'unknown',
          type: tag.variable_type,
          prompt: tag.prompt,
          tag: tag.tag,
        }));

        self.tags.replace(nextTags);
      }
    });

    return {
      getTagsStore,
      getUserStore,
      fetchTags,
      toggleSelected,
    };
  })
  .views((self) => {
    const getTags = () => {
      if (isUnifiedPlatform(self)) {
        return self.tagIds;
      }
      return self.tags;
    };

    const formattedUpdatedAt = () => {
      return moment(self.updatedAt).format('M/D/YYYY');
    };

    return {
      getTags,
      formattedUpdatedAt,
    };
  });

const TemplatesStore = types
  .model('TemplatesStore', {
    childrenTemplatesLoadingState: types.optional(types.map(types.boolean), {}),
    error: types.optional(types.string, ''),
  })
  .actions((self) => {
    const setTemplate = (template) => {
      return self.addItem(template.identifier, template);
    };

    const setLoadingState = (id, isLoading) => {
      self.childrenTemplatesLoadingState.set(id, isLoading);
    };

    const fetchTemplate = flow(function* (templateId) {
      const res = yield templatesService.getTemplate(
        getOrgFprintFromStoreNode(self),
        templateId,
      );
      self.setTemplate(res);
      setLoadingState(templateId, false);
      return self.getTemplate(templateId);
    });

    const fetch = flow(function* (query = {}) {
      return yield self.paginate(query);
    });

    const updateTemplate = flow(function* updateTemplate(
      templateId,
      properties,
    ) {
      try {
        const updatedTemplate = yield templatesService.updateTemplate(
          getOrgFprintFromStoreNode(self),
          templateId,
          properties,
        );

        const template = self.getTemplate(templateId);
        analyticsService.track('Template Updated', {
          templateId,
          newName: properties.name,
          oldName: template.title,
        });

        return self.setTemplate(updatedTemplate);
      } catch (error) {
        console.error('Failed to update template', error);
        self.error = error;
      }
    });

    const deleteTemplate = flow(function* deleteTemplate(templateId) {
      try {
        yield templatesService.deleteTemplate(
          getOrgFprintFromStoreNode(self),
          templateId,
        );
        const parent = getParent(self);
        const template = self.getTemplate(templateId);

        parent.templateSets.removeTemplate(templateId);

        self.removeItem(template.identifier);
        analyticsService.track('Template Deleted', {
          templateId,
          template: template.toJSON(),
        });
        return true;
      } catch (error) {
        console.error('Failed to update template', error);
        self.error = error;
        return false;
      }
    });

    const selectTemplate = (templateId) => {
      const template = self.getItem(getTemplateIdentifier(templateId));

      if (template) {
        const status = template.toggleSelected();
        return status;
      }

      return false;
    };

    const addTemplateToSeletionSidebar = (template) => {
      const store = getRoot(self);
      store.sidebarItems.add({
        ...template,
      });
    };

    const fetchChildrenTemplates = flow(function* fetchChildrenTemplates(
      templateIds,
    ) {
      templateIds = templateIds.filter(
        (id) => !self.getItem(getTemplateIdentifier(id)),
      );
      yield Promise.all(
        templateIds.map((id) => {
          setLoadingState(id, true);
          return self.fetchTemplate(id);
        }),
      );
    });

    const includedInOtherTemplates = (templateId) => {
      return Array.from(self.dictionary.values()).some((template) => {
        if (template.includes) {
          return template.includes.some(
            (include) => include.templateId === parseInt(templateId),
          );
        }
        return false;
      });
    };

    return {
      fetchTemplate,
      fetch,
      deleteTemplate,
      setTemplate,
      updateTemplate,
      selectTemplate,
      addTemplateToSeletionSidebar,
      fetchChildrenTemplates,
      includedInOtherTemplates,
    };
  })
  .views((self) => {
    function getTemplate(templateId) {
      return self.dictionary.get(getTemplateIdentifier(templateId));
    }

    function getTemplateSet(templateIds) {
      return templateIds.map((id) => self.getItem(getTemplateIdentifier(id)));
    }

    function getSelectedTemplates() {
      return self.list.flat().filter((template) => template.selected);
    }

    function selectOptions() {
      return Array.from(self.dictionary.values()).map((template) => ({
        value: `${template.id}`,
        label: template.title,
        subtitle: template.subtitle,
        documentType: template.documentType,
      }));
    }

    return {
      getTemplate,
      getTemplateSet,
      getSelectedTemplates,
      selectOptions,
    };
  });

const PaginatedTemplatesStore = createPaginatedModel(
  'PaginatedTemplatesStore',
  TemplatesStore,
  TemplateStore,
  { paginate: templatesService.getTemplates },
);

export { PaginatedTemplatesStore as default, TemplateStore, TemplateVariable };
