import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  container: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    display: 'block',
    marginTop: theme.unit * 2,
    textAlign: 'center',
    fontSize: theme.fonts.size.regular,
    color: theme.colors.charcoal,
  },
  subtitle: {
    marginTop: theme.unit,
    textAlign: 'center',
    fontSize: theme.fonts.size.small,
    color: theme.colors.chrome,
  },
});
