import { QueryParams } from './types';

export const questionnaireQueryArgs = {
  all: ['clioQuestionnaires'] as const,
  lists: () => [...questionnaireQueryArgs.all, 'list'] as const,
  list: (filters: Partial<QueryParams>) =>
    [...questionnaireQueryArgs.lists(), { filters }] as const,
  details: () => [...questionnaireQueryArgs.all, 'detail'] as const,
  detail: (id: string) => [...questionnaireQueryArgs.details(), id] as const,
};

export const submissionQueryArgs = {
  all: ['questionnaireSubmissions'] as const,
  lists: () => [...submissionQueryArgs.all, 'list'] as const,
  list: (filters: Partial<QueryParams>) =>
    [...submissionQueryArgs.lists(), { filters }] as const,
  details: () => [...submissionQueryArgs.all, 'detail'] as const,
  detail: (id: string) => [...submissionQueryArgs.details(), id] as const,
};
