import React from 'react';

import Button from '~/src/components/Button';
import { Box } from '@mui/material';
import { SearchField } from '~/src/components/SearchField';

export const SearchCreateHeader: React.FC<{
  handleSearch: () => void;
  handleCreate: () => void;
}> = ({ handleSearch, handleCreate }) => {
  return (
    <Box pb={3} sx={{ display: 'flex' }}>
      <Box>
        <SearchField
          onChange={handleSearch}
          size="small"
          placeholder="Search Questionnaires"
        />
      </Box>
      <Box sx={{ position: 'absolute', right: '16px' }}>
        <Button primary onClick={handleCreate}>
          Create new questionnaire
        </Button>
      </Box>
    </Box>
  );
};
