import { SnapshotIn } from 'mobx-state-tree';
import { InputType } from '~/src/models/inputType';
import {
  ProjectCardFieldOption,
  ProjectCardField,
} from '~/src/stores/projectsStore/projectCardFieldStore';
import ProjectCard, {
  ProjectCardFormValues,
} from '../stores/projectsStore/projectCardStore';

export namespace Project {
  export const ComputedFieldType = {
    Undefined: 'undefined',
    Sum: 'sum',
    Subtract: 'subtract',
    Reference: 'reference',
    Compound: 'compound',
  } as const;

  export type ComputedFieldType =
    typeof ComputedFieldType[keyof typeof ComputedFieldType];

  export interface Project {
    id: number;
    identifier: string;
    name: string;
    matterId?: number | null;
    fprint: string;
    cards: Project.Card[];
    documents: Project.Document[];
    updatedAt: string | null;
    isDownloading?: boolean;
    stackSavedData: Record<string, string>;
  }

  export type Card = SnapshotIn<typeof ProjectCard>;

  export type CardFieldOption = SnapshotIn<typeof ProjectCardFieldOption>;

  export type CardField = SnapshotIn<typeof ProjectCardField>;
  export type CardFormValues = SnapshotIn<typeof ProjectCardFormValues>;

  export interface Document {
    id: number;
    identifier: string;
    docx: null | string;
    processedDocx: null | string;
    processedMsPdf: null | string;
    pdf: string;
    documentType: number;
    ms_pdf: null | string;
    templateId: null | number;
    defaultValueId: null | number;
    globalTemplateId: null | number;
    title: string;
    subtitle: string;
    pages: Project.Page[];
    processedPages: Project.Page[];
    allElements: Project.DocumentElement[];
  }

  export interface Page {
    id: number;
    page: number;
    backgroundUrl: string;
    isProcessed: boolean;
    elements: Project.PageElement[];
  }

  export interface BaseElement {
    id: number;
    top: number;
    left: number;
    width: number;
    height: number;
    inputType: InputType;
    value?: string | boolean | null;
    tag?: string | null;
    aliasTag?: string | null;
    lineHeight?: number | null;
  }

  export interface DocumentElement extends BaseElement {
    pageNumber?: number | null;
    computedFieldType?: ComputedFieldType | null;
    computedFieldTag?: string | null;
    computedTagDestination?: string | null;
  }

  export interface PageElement extends BaseElement {
    signaturePageId: number | null;
    imageHeight?: number | null;
    imageWidth?: number | null;
  }
}
