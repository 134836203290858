/* Libraries */
import React from 'react';
import { css } from 'aphrodite';
import { Edit3 } from 'react-feather';
import { useObserver } from 'mobx-react';

/* Components */
import Button from '~/src/components/Button';
import Dropdown from '~/src/components/Dropdown';
import Triangle from '~/src/components/Triangle';
import Checkbox from '~/src/components/Checkbox';

/* Styles */
import dropdownStyles from '~/src/components/Dropdown/styles';

import useMst from '~/src/hooks/useMst';
import styles from '../../components/Footers/styles';

export type DraftingFooterProps = {
  onCurrentDownload?: () => void;
  onEdit?: () => void;
  onCreateEsign: () => void;
  onSaveInClio: () => Promise<void>;
  onAllDownload: () => void;
  disableSaveInClio?: boolean;
  disableEdit?: boolean;
  disableEsign?: boolean;
  disableDownload?: boolean;
  showHighlights?: boolean;
  onToggleShowHighlights?: () => void;
  isHighlightingEnabled?: boolean;
};

export const DraftingFooter = ({
  onEdit,
  onCurrentDownload,
  onAllDownload,
  onCreateEsign,
  onSaveInClio,
  disableSaveInClio,
  disableEdit,
  disableEsign,
  disableDownload,
  showHighlights,
  onToggleShowHighlights,
  isHighlightingEnabled,
}: DraftingFooterProps) => {
  let toggleDropdownCallback: () => void;

  const { totalNumber, clioStore } = useMst((store) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useObserver(() => ({
      totalNumber: store.sidebarItems.items.length,
      clioStore: store.clio,
    })),
  );

  const handleCurrentDownload = () => {
    onCurrentDownload?.();
    toggleDropdownCallback();
  };

  const handleAlDownload = () => {
    onAllDownload?.();
    toggleDropdownCallback();
  };

  const renderTrigger = ({
    toggleDropdown,
  }: {
    toggleDropdown: () => void;
  }) => {
    toggleDropdownCallback = toggleDropdown;
    return (
      <span analyticsname="DownloadPdf">
        <Button disabled={disableDownload} onClick={toggleDropdown}>
          Download <Triangle primary orientation={'down'} />
        </Button>
      </span>
    );
  };

  return (
    <footer className={css(styles.documentPreviewFooter)}>
      {/* TODO - Update 'EDIT' text to perhaps something else? */}
      <Button disabled={disableEdit} Icon={Edit3} onClick={onEdit}>
        Back
      </Button>
      <div className={css(styles.rightSideButtons)}>
        {clioStore.courtFormClioMatterId && (
          <span
            analyticsname={`Save to Clio Button Clicked - ${
              clioStore.courtFormClioParentId ? 'Edit' : 'New'
            }`}
          >
            <Button
              className={css(styles.clioButton)}
              disabled={disableSaveInClio}
              id="clioButton"
              onClick={onSaveInClio}
            >
              Save to Clio
            </Button>
          </span>
        )}
        {isHighlightingEnabled && (
          <div
            className={css(styles.documentPreviewHighlightToggle)}
            analyticsname="Highlight Changes"
          >
            <Checkbox
              checked={showHighlights}
              size={2}
              onClick={onToggleShowHighlights}
              disabled={false}
            />
            <span className={css(styles.documentPreviewHighlightToggleText)}>
              Highlight Changes
            </span>
          </div>
        )}
        <Dropdown
          renderTrigger={renderTrigger}
          className={css(styles.eSignButton)}
        >
          <a
            onClick={handleCurrentDownload}
            className={css(dropdownStyles.dropdownActionItem)}
            analyticsname="Download Current Document"
          >
            Download current document
          </a>
          {totalNumber > 1 && (
            <a
              onClick={handleAlDownload}
              className={css(dropdownStyles.dropdownActionItem)}
              analyticsname="Download All (.zip)"
            >
              Download all (.zip)
            </a>
          )}
        </Dropdown>
        <Button primary disabled={disableEsign} onClick={onCreateEsign}>
          Esign
        </Button>
      </div>
    </footer>
  );
};

DraftingFooter.defaultProps = {
  onCurrentDownload: () => {},
  onAllDownload: () => {},
  onEdit: () => {},
  onCreateEsign: () => {},
  onSaveInClio: () => {},
  onToggleShowHighlights: () => {},
  showHighlights: false,
  isHighlightingEnabled: false,
};
