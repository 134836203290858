import React from 'react';
import { Box } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { FieldRenderProps } from '../PopulateCardField';
import { ProjectStackField, Project } from '~/src/entities/project';
import FieldHeader from '../FieldHeader';

export type DatePickerFieldProps = {
  field: FieldRenderProps;
  projectField: ProjectStackField;
  project: Project;
};

export const DatePickerField = ({
  field,
  projectField,
  project,
}: DatePickerFieldProps) => {
  const { value, onChange, ref, ...rest } = field;

  const parseDateStringToMoment = (dateString: string) => {
    const moment1 = moment(dateString, projectField.date_format);
    if (moment1.isValid()) {
      return moment1;
    }
    return null;
  };

  const handleChange = (momentObj: moment.Moment | null) => {
    if (momentObj && momentObj.isValid()) {
      onChange(momentObj.format(projectField.date_format));
    } else {
      onChange('');
    }
  };

  return (
    <Box>
      <Box>
        <FieldHeader projectField={projectField} project={project} />
      </Box>
      <Box>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            slotProps={{
              textField: {
                fullWidth: true,
                size: 'small',
                placeholder: projectField.date_format,
              },
              field: { shouldRespectLeadingZeros: true },
            }}
            inputRef={ref}
            format={projectField.date_format}
            value={parseDateStringToMoment(value)}
            onChange={handleChange}
            {...rest}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
};
