import React from 'react';
import { createTheme } from '@mui/material/styles';
import { ColorPartial } from '@mui/material/styles/createPalette';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subheading: true;
    label: true;
    smallLabel: true;
    smallCaption: true;
    captionAllCaps: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    subheading: React.CSSProperties;
    label: React.CSSProperties;
    smallLabel: React.CSSProperties;
    smallCaption: React.CSSProperties;
    captionAllCaps: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    subheading?: React.CSSProperties;
    label?: React.CSSProperties;
    smallLabel?: React.CSSProperties;
    smallCaption?: React.CSSProperties;
    captionAllCaps?: React.CSSProperties;
  }

  interface TypeBackground {
    default: string;
    paper: string;
    page: string;
  }

  interface PaletteOptions {
    blue: ColorPartial;
    green: ColorPartial;
    red: ColorPartial;
    yellow: ColorPartial;
    border: string;
    actions: {
      default: string;
      hover: string;
      destructive: {
        default: string;
        hover: string;
      };
    };
  }

  interface Palette {
    blue: ColorPartial;
    green: ColorPartial;
    red: ColorPartial;
    yellow: ColorPartial;
    border: string;
    actions: {
      default: string;
      hover: string;
      destructive: {
        default: string;
        hover: string;
      };
    };
  }

  interface TypeText {
    icon: string;
    hint: string;
    subdued: string;
  }
}

const blue = {
  50: '#eff6ff',
  100: '#dbeafe',
  200: '#bfdbfe',
  300: '#93c5fd',
  400: '#60a5fa',
  600: '#2563eb',
  700: '#1d4ed8',
} as const;

const grey = {
  50: '#1d4ed8',
  100: '#F3F4F6',
  200: '#E5E7EB',
  300: '#D1D5DB',
  400: '#9CA3AF',
  500: '#6b7280',
  600: '#4b5563',
  900: '#111827',
} as const;

const green = {
  50: '#ECFDF5',
  100: '#d1fae5',
  200: '#a7f3d0',
  300: '#6EE7B7',
  400: '#34D399',
  500: '#10B981',
  600: '#059669',
  700: '#047857',
} as const;

const red = {
  50: '#FEF2F2',
  300: '#fca5a5',
  400: '#f87171',
  600: '#dc2626',
  700: '#b91c1c',
} as const;

const yellow = {
  50: '#fffbeb',
  300: '#fcd34d',
  400: '#fbbf24',
  500: '#f59e0b',
  700: '#b45309',
} as const;

const actions = {
  default: blue[600],
  hover: blue[700],
  destructive: {
    default: red[600],
    hover: red[700],
  },
};

const spacing = 8;

export const muiTheme = createTheme({
  spacing,
  shadows: [
    'none',
    '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    '0px 1px 2px 0px rgba(16, 24, 40, 0.06),0px 1px 3px 0px rgba(16, 24, 40, 0.1)',
    '0px 2px 4px -2px rgba(16, 24, 40, 0.06),0px 4px 8px -2px rgba(16, 24, 40, 0.1)',
    '0px 4px 6px -2px rgba(16, 24, 40, 0.03),0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
    '0px 8px 8px -4px rgba(16, 24, 40, 0.03),0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
    '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
  ],
  typography: {
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", "Helvetica", sans-serif',
    // overline is deprecated - doesn't have a corresponding foundation
    overline: {
      textTransform: 'none',
      fontWeight: 'bold',
      fontSize: 16,
    },
    h1: {
      fontWeight: 600,
      lineHeight: '24px',
      fontSize: 20,
    },
    h2: {
      fontWeight: 600,
      lineHeight: '24px',
      fontSize: 18,
    },
    subheading: {
      fontWeight: 500,
      lineHeight: '24px',
      fontSize: 16,
    },
    body1: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
    },
    body2: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '18px',
    },
    button: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '20px',
      textTransform: 'none',
    },
    label: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '20px',
    },
    smallLabel: {
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '16px',
    },
    caption: {
      fontWeight: 400,
      lineHeight: '16px',
      fontSize: 12,
    },
    smallCaption: {
      fontWeight: 600,
      lineHeight: '16px',
      fontSize: 10,
    },
    captionAllCaps: {
      fontWeight: 600,
      fontSize: 10,
      lineHeight: '16px',
      textTransform: 'uppercase',
    },
  },
  palette: {
    border: '#D1D5DB',
    error: {
      main: red[700],
    },
    background: {
      default: grey[100],
      page: '#F3F4F6',
    },
    text: {
      primary: grey[900],
      secondary: grey[600],
      icon: grey[900],
      hint: grey[400],
      subdued: grey[600],
    },
    actions,
    blue,
    grey,
    green,
    red,
    yellow,
    primary: { main: '#2459ed' },
    info: { main: '#2563EB', light: '#EFF6FF' },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            boxShadow: 'none',
            marginBottom: theme.spacing(1),
            '&:before': {
              display: 'none',
            },
            '&.Mui-expanded': {
              margin: 0,
            },
          };
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: theme.spacing(4),
          borderRadius: theme.spacing(1),
          '&:hover': { background: theme.palette.background.page },
          '&.Mui-expanded': {
            minHeight: theme.spacing(4),
          },
        }),
        content: {
          margin: 0,
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        color: 'text.primary',
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(0.5),
          };
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: 'white',
          '&.Mui-error': {
            background: red[50],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: ({ theme }) => ({ borderColor: theme.palette.border }),
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        svg: {
          display: 'block !important',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'outlined',
        disableElevation: true,
      },
      styleOverrides: { root: { textTransform: 'inherit' } },
    },
    MuiTooltip: {
      styleOverrides: { tooltip: { background: 'black' } },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ theme }) => {
          return {
            color: theme.palette.grey[300],
            '&.Mui-focusVisible:not(.Mui-checked)': {
              color: theme.palette.grey[900],
            },
            '&:hover:not(.Mui-checked)': {
              color: theme.palette.grey[900],
            },
          };
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            '&:hover .MuiCheckbox-root:not(.Mui-disabled):not(.Mui-checked), &:hover .MuiRadio-root:not(.Mui-disabled):not(.Mui-checked)':
              {
                color: theme.palette.grey[900],
              },
          };
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            padding: 0,
            color: theme.palette.grey[300],
            '&.Mui-focusVisible:not(.Mui-checked)': {
              color: theme.palette.grey[900],
            },
            '&:hover:not(.Mui-checked)': {
              color: theme.palette.grey[900],
            },
          };
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: ({ theme }) => ({
          '&.Mui-focused': {
            background: `${theme.palette.blue[50]} !important`,
          },
        }),
      },
    },
    MuiMenu: {
      defaultProps: {
        PaperProps: {
          elevation: 0,
        },
      },
      styleOverrides: {
        paper: ({ theme }) => {
          return {
            borderRadius: theme.shape.borderRadius,
            border: `1px solid ${theme.palette.border}`,
          };
        },
      },
    },
  },
});
