import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  rowActionButton: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.unit * 1}px ${theme.unit * 2}px`,
    paddingRight: theme.unit,
  },
  actionButton: {
    marginRight: theme.unit * 2,
  },
  templatesPopulate: {
    marginBottom: theme.unit * 4,
  },
  populateSidebarContainer: {
    padding: theme.unit * 2,
  },
  populateSidebarItem: {
    display: 'flex',
    borderRadius: theme.borderRadius,
    fontSize: theme.fonts.size.small,
    padding: `${theme.unit}px ${theme.unit * 3}px`,
    marginBottom: theme.unit,
    ':hover': {
      cursor: 'pointer',
    },
  },
  populateSidebarItemSelected: {
    color: theme.colors.eggplant,
    background: theme.colors.paleEggplant,
  },
  populateSidebarItemIcon: {
    marginRight: theme.unit,
  },
  templatesDraftContainer: {
    maxWidth: theme.unit * 70,
    margin: '0 auto',
  },
  templatesDraftAction: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.unit * 2,
  },
  templatesDraftActionPrimary: {
    marginLeft: theme.unit * 2,
  },
  populateCardFooter: {
    display: 'flex',
    fontSize: '12px',
    justifyContent: 'space-between',
  },
  populateCardHeader: {
    display: 'flex',
    flexFlow: 'column',
  },
  /* Populate Contact Search Bar */
  contactSearchBarContainer: {
    marginTop: theme.unit * 2,
  },
  /* Populate Clear Contact Confirm */
  clearContactContainer: {
    fontSize: '13px',
    marginTop: theme.unit * 2,
    borderRadius: theme.borderRadius,
    padding: '10px 12px',
    background: '#FBF4F3',
    display: 'flex',
    justifyContent: 'space-between',
  },
  clearContactConfirm: {
    color: '#ED2F1D',
    marginRight: theme.unit * 2,
    cursor: 'pointer',
  },
  clearContactCancel: {
    cursor: 'pointer',
  },
  storyInputField: {
    marginBottom: theme.unit * 2,
  },
  storyCard: {
    marginBottom: theme.unit * 4,
  },
});
