/* Libraries */
import React from 'react';
import { inject, observer } from 'mobx-react';

/* Higher Order Components */
import WithLayoutProps from '~/src/hoc/WithLayoutProps';

/* Components */
import SettingsSubscription from '~/src/components/Settings/SettingsSubscription';
import SettingsSubscriptionV2 from '~/src/components/Settings/SettingsSubscriptionV2';
import SettingsSubscriptionV2FreeTrial from '~/src/components/Settings/SettingsSubscriptionV2freeTrial';

/* Configurations */
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';

class SettingsSubscriptionContainer extends React.Component {
  componentDidMount() {
    this.props.store.user.currentOrganization.fetchSubscription();
  }

  handleCreateSubscription = () => {
    this.props.showModal(LAYOUT_MODAL_TYPES.createSubscription);
  };

  handleRenewSubscription = async () => {
    const res =
      await this.props.store.user.currentOrganization.renewSubscription();

    if (res) {
      this.props.showToast(LAYOUT_TOAST_TYPES.success, {
        message: 'Subscription renewed!',
      });
    } else {
      this.props.showToast(LAYOUT_TOAST_TYPES.error);
    }
  };

  render() {
    const isClioFree = this.props.store.user.flags.isClioFreePlan();
    const { subscription } = this.props.store.user.currentOrganization;
    const loading = this.props.store.user.currentOrganization.isLoading();

    const isV2Subscriptions = this.props.store.user.flags.isV2Subscriptions();

    if (isV2Subscriptions) {
      const { subscription } = this.props.store.user.currentOrganization;

      if (subscription && subscription.freeTrialIsActive()) {
        return (
          <SettingsSubscriptionV2FreeTrial
            loading={loading}
            subscription={subscription}
            isV2Subscriptions={isV2Subscriptions}
            isClioFree={isClioFree}
            onCreateNewSubscription={this.handleCreateSubscription}
            onRenewSubscription={this.handleRenewSubscription}
          />
        );
      }

      return (
        <SettingsSubscriptionV2
          loading={loading}
          subscription={subscription}
          isV2Subscriptions={isV2Subscriptions}
          isClioFree={isClioFree}
          onCreateNewSubscription={this.handleCreateSubscription}
          onRenewSubscription={this.handleRenewSubscription}
        />
      );
    }
    return (
      <SettingsSubscription
        loading={loading}
        subscription={subscription}
        isV2Subscriptions={isV2Subscriptions}
        isClioFree={isClioFree}
        onCreateNewSubscription={this.handleCreateSubscription}
        onRenewSubscription={this.handleRenewSubscription}
      />
    );
  }
}

export default inject((store) => store)(
  WithLayoutProps(observer(SettingsSubscriptionContainer)),
);
