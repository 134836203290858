import { useMutation, useQueryClient } from 'react-query';
import { APIError } from '~/src/utils/error';
import { type Questionnaire } from '@clio/questionnaire-builder';
import { getQuestionnaireById } from '../urls';
import {
  getClioQuestionnaireAuthToken,
  questionnaireAuthorizationHeaders,
} from '../utils';
import { questionnaireQueryArgs } from '../queryArgs';

type UpdateClioQuestionnaire = {
  questionnaireId: string;
  clioQuestionnaire: Partial<Questionnaire>;
};

export const useUpdateClioQuestionnaire = (orgFprint: string) => {
  const queryClient = useQueryClient();
  return useMutation<Questionnaire, APIError, UpdateClioQuestionnaire>({
    onSuccess: () => {
      queryClient.invalidateQueries(questionnaireQueryArgs.all);
    },
    mutationFn: async ({ questionnaireId, clioQuestionnaire }) => {
      const token = await getClioQuestionnaireAuthToken(orgFprint);
      const response = await fetch(getQuestionnaireById(questionnaireId), {
        method: 'PUT',
        headers: {
          ...questionnaireAuthorizationHeaders(token),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(clioQuestionnaire),
      });

      if (!response.ok) {
        throw new APIError('Failed to update questionnaire', response);
      }

      return response.json();
    },
  });
};
