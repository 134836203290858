import { types } from 'mobx-state-tree';

import { camelCase } from 'lodash';
import { ProjectCardField } from './projectCardFieldStore';

export const ProjectCardFormValues = types.model({
  fields: types.map(types.model({ value: types.frozen() })),
});

const ProjectCard = types
  .model({
    id: types.string,
    name: types.string,
    relatedContactId: types.string,
    type: types.string,
    fields: types.optional(types.array(ProjectCardField), []),
    focus: types.optional(types.boolean, false),
    formValue: types.frozen(
      types.optional(ProjectCardFormValues, () => ({
        fields: {},
      })),
    ),
  })
  .actions((self) => {
    const setFocus = (focus: boolean, forceUpdate?: boolean) => {
      if (forceUpdate) {
        self.focus = !focus;
      }

      self.focus = focus;
    };

    const setRelatedContactId = (contactId: string | number) => {
      self.relatedContactId = `${contactId}`;
    };

    const setFormValue = (form: typeof self.formValue) => {
      self.formValue = form;
    };

    return {
      setRelatedContactId,
      setFormValue,
      setFocus,
    };
  })
  .views((self) => {
    const getFields = () => {
      if (!self.formValue || !self.formValue.fields) {
        return {};
      }

      return self.formValue.fields;
    };

    const getFieldValues = () => {
      if (!self.formValue || !self.formValue.fields) {
        return {};
      }

      const fieldValues: Record<string, unknown> = {};
      const { fields } = self.formValue;
      const fieldKeys = Object.keys(self.formValue.fields);

      for (let i = 0; i < fieldKeys.length; i++) {
        const key = fieldKeys[i]!;
        const field = fields[key];

        fieldValues[key] = field?.value;
      }

      return fieldValues;
    };

    const getFieldValuesByLabel = () => {
      const fieldValuesByLabel: Record<string, unknown> = {};
      self.fields.forEach((field) => {
        const value = self.formValue?.fields?.[field.id];
        const labelKey = camelCase(field.label);
        fieldValuesByLabel[labelKey] = value;
      });

      return fieldValuesByLabel;
    };

    const isComplete = () => {
      const fieldIds = self.fields.map((field) => field.id);

      if (!self.formValue || !self.formValue.fields) {
        return false;
      }

      const formFieldValues = self.formValue.fields;

      for (let i = 0; i < fieldIds.length; i++) {
        const fieldId = fieldIds[i]!;
        if (
          !formFieldValues[fieldId] ||
          !formFieldValues[fieldId]?.value ||
          formFieldValues[fieldId]?.value.length === 0
        ) {
          return false;
        }
      }

      return true;
    };

    return {
      isComplete,
      getFields,
      getFieldValues,
      getFieldValuesByLabel,
    };
  });

export default ProjectCard;
