import React, { useState, useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { css } from 'aphrodite';
import styles from './styles';

import useFetchProject from '~/src/hooks/useFetchProject';
import useMst, { useStore } from '~/src/hooks/useMst';
import PageLayout, { LoadingOverlay } from '~/src/components/PageLayout';

import { useLayoutContext } from '~/src/contexts/Layout';
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { NAVIGATION_WIDTH } from '~/src/components/PageLayout/styles';

import { useWebViewerContext } from '~/src/components/Webviewer';
import { Project } from '~/src/models';
import analytics from '~/src/services/analytics';
import clioService, {
  StartUploadDocument,
} from '../../services/api/clioService';
import { DraftingFooter } from './DraftingFooter';
import { history } from '../../utils/history';
import { AddDocumentButton } from './AddDocumentButton';
import { SelectionSidebarHeader } from '~/src/components/SelectionSidebar';
import PDFViewer from './PDFViewer';

export type DraftingModalProps = {
  title: string;
  messages: string[];
  onCancel: Function;
};

export type PdfBlob = {
  blobData: Blob;
  numberOfPages: number;
  documentId: number;
};

export const DraftingPage = ({ projectId }: { projectId: number }) => {
  const {
    signatures,
    addDocumentToSelectionSidebar,
    initialize,
    setDocIndex,
    setProjectid,
    setIdentifier,
    clioMatterId,
    clioParentId,
    orgFprint,
  } = useMst((store) => ({
    signatures: store.signatures,
    addDocumentToSelectionSidebar:
      store.documents.addDocumentToSelectionSidebar,
    initialize: store.sidebarItems.initialize,
    setDocIndex: store.sidebarItems.setIndex,
    setProjectid: store.projects.setProjectId,
    setIdentifier: store.sidebarItems.setIdentifier,
    clioMatterId: store.clio.courtFormClioMatterId,
    clioParentId: store.clio.courtFormClioParentId,
    orgFprint: store.user.currentOrganization?.fprint,
  }));

  const { sidebarItems } = useStore();
  const docIndex = useObserver(() => sidebarItems.currentIndex);

  const { setWebViewerPosition, hideWebViewer, setZIndexOfWebViewer } =
    useWebViewerContext();
  useEffect(() => {
    setWebViewerPosition({
      top: 69,
      left: NAVIGATION_WIDTH,
      right: 335,
      bottom: 72,
    });
    return () => {
      hideWebViewer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { project, loading }: { project: any; loading: boolean } =
    useFetchProject(projectId, false, true);

  const { showModal, hideModal } = useLayoutContext();

  const [fullLoaded, setFullLoaded] = useState(false);
  const [isHighlighted, setIsHighlighted] = useState(true);
  const [documents, setDocuments] = useState<Project.Document[]>([]);

  const currentDocument = documents[docIndex - 1];

  const isHighlightingEnabled = !!currentDocument?.docx;
  const [viewerIsLoading, setViewerIsLoading] = useState(false);
  const [requestedDownloadAllPdf, setRequestedDownloadAllPdf] = useState(false);
  const [requestedDownloadPdf, setRequestedDownloadPdf] = useState(false);
  const [allPdfBlobs, setAllPdfBlobs] = useState([]);
  const [createEsignPackage, setCreateEsignPackage] = useState(false);
  const [signaturePackageFilesData, setSignaturePackageFilesData] = useState(
    [],
  );
  const [reRouteToEsign, setReRouteToEsign] = useState(false);
  const [savingInClio, setSavingInClio] = useState(false);
  const [clioDocumentUploadURLs, setClioDocumentUploadURLs] = useState<
    Record<number, StartUploadDocument> | undefined
  >(undefined);
  const [currentPdfBlob, setCurrentPdfBlob] = useState<PdfBlob | undefined>(
    undefined,
  );
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>(undefined);

  const handleEdit = () => {
    history.replace(`/populate/${projectId}`);
    setZIndexOfWebViewer(1); // restore Webviewer zIndex to 1 since router URL changes
  };

  const handleAllDownload = () => {
    setZIndexOfWebViewer(0);
    setViewerIsLoading(true);
    setRequestedDownloadAllPdf(true);
  };
  const handleCurrentDownload = () => {
    setRequestedDownloadPdf(true);
  };

  const handleCreateEsign = () => {
    setZIndexOfWebViewer(0);
    setViewerIsLoading(true);
    setCreateEsignPackage(true);
  };

  const handleToggleHighlight = () => {
    setIsHighlighted((prevIsHighlighted) => !prevIsHighlighted);
  };

  const [hasPdfTronLoadedDocument, setHasPdfTronLoadedDocument] =
    useState(false);

  const setToFirstDocument = () => {
    setDocIndex(1);
  };

  const handleSaveInClioError = () => {
    analytics.track('Save to Clio failed', {
      success: false,
      flow: clioParentId ? 'edit' : 'create',
    });
    showModal(LAYOUT_MODAL_TYPES.error, {
      title: 'Error: failed to save',
      messages: [
        'We are unable to save these documents to Clio. Please check your internet connection and your matter permissions.',
        'To continue, you will need to download a copy from Lawyaw and return to Clio to manually upload them. We apologize for the inconvenience.',
      ],
      onCancel: () => {
        setToFirstDocument();
        setClioDocumentUploadURLs(undefined);
        setSavingInClio(false);
        setZIndexOfWebViewer(1);
        hideModal();
      },
    });
  };

  const handleSaveInClio = async () => {
    setSavingInClio(true);
    setZIndexOfWebViewer(-1);
    setToFirstDocument();
    const startUpload = clioParentId
      ? clioService.startEdit.bind(
          clioService.startEdit,
          orgFprint!,
          projectId,
          clioMatterId!,
          clioParentId,
        )
      : clioService.startUpload.bind(
          clioService.startUpload,
          orgFprint!,
          projectId,
          clioMatterId!,
        );

    try {
      const response = await startUpload();
      setRedirectUrl(response.redirect_url);
      const mappedUrls = response.documents.reduce(
        (obj, doc) => ({ ...obj, [doc.project_document_id]: doc }),
        {},
      );

      setClioDocumentUploadURLs(mappedUrls);
    } catch {
      handleSaveInClioError();
    }
  };

  const redirectToClio = () => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  const markFullyUploaded = () => {
    if (!clioDocumentUploadURLs) {
      return;
    }
    const payload = Object.values(clioDocumentUploadURLs).map((doc) => {
      return { id: doc.id, uuid: doc.latest_document_version.uuid };
    });
    clioService
      .markFullyUploaded(orgFprint!, payload)
      .then(redirectToClio)
      .catch(handleSaveInClioError);
  };

  const createSignaturePackage = async () => {
    const createdSignaturePackage = await signatures.createSignaturePackage(
      signaturePackageFilesData,
      project.id,
    );
    if (createdSignaturePackage) {
      setSignaturePackageFilesData([]);
      setZIndexOfWebViewer(1);
      setViewerIsLoading(false);
      setCreateEsignPackage(false);
      history.push(
        `/signature-wizard/${createdSignaturePackage.lawyawProjectId}/${createdSignaturePackage.id}/new`,
      );
    }
  };

  useEffect(() => {
    if (reRouteToEsign) {
      createSignaturePackage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reRouteToEsign]);

  useEffect(() => {
    setFullLoaded(false);
    if (!loading && !project) {
      history.goBack();
    }
    if (!loading && project) {
      setDocuments(project.documents);
      setIdentifier(project.documents[0].id);
      project.documents.forEach((document: any) => {
        addDocumentToSelectionSidebar(document);
      });
      setFullLoaded(true);
      setProjectid(projectId);
    }
    return () => {
      initialize();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  /**
   * The document save process is a little convoluted here. We let the PDFViewer load the document using
   * webviewer, and wait for it to update currentPdfBlob once async loading is complete. Then we load the
   * next document (letting PDFViewer manage loading it), and repeat until we've processed all documents
   * in the set.
   */
  useEffect(() => {
    if (clioDocumentUploadURLs && currentPdfBlob && redirectUrl) {
      const documentId = currentPdfBlob.documentId;
      const uploadInfo = clioDocumentUploadURLs[documentId];
      if (!uploadInfo) {
        handleSaveInClioError();
        return;
      }

      clioService
        .uploadToS3(uploadInfo.latest_document_version, currentPdfBlob.blobData)
        .then((response) => {
          if (response.status !== 200) {
            analytics.track('Save to Clio failed', {
              success: false,
              flow: clioParentId ? 'edit' : 'create',
              step: 'upload',
            });
            throw new Error('S3 upload was not successful.');
          }

          // since currentIndex of SidebarItemsStore is 1 - based instead of 0 - based
          // and project.documents array is 0 - based
          if (docIndex < project.documents.length) {
            setDocIndex(docIndex + 1);
          } else {
            markFullyUploaded();
            setClioDocumentUploadURLs(undefined);
          }
        })
        .catch(handleSaveInClioError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPdfBlob, clioDocumentUploadURLs, redirectUrl]);

  if (!fullLoaded) {
    return (
      <PageLayout title={project?.name || 'Loading...'}>
        <LoadingOverlay
          title="Loading details"
          subtitle="Please wait"
          relative={true}
        />
      </PageLayout>
    );
  }

  const footerButtonsDisabled = !hasPdfTronLoadedDocument || savingInClio;

  return (
    <PageLayout
      title={project?.name || 'Loading...'}
      showSelectionSidebar={true}
      selectionSidebarProps={{
        isDrafting: true,
        renderHeader: (items: any[]) => (
          <>
            <SelectionSidebarHeader title={'Documents'} count={items.length} />
            <AddDocumentButton projectId={project.id} />
          </>
        ),
      }}
      footer={
        project ? (
          <DraftingFooter
            onEdit={handleEdit}
            onCurrentDownload={handleCurrentDownload}
            onAllDownload={handleAllDownload}
            onCreateEsign={handleCreateEsign}
            onSaveInClio={handleSaveInClio}
            showHighlights={isHighlighted}
            onToggleShowHighlights={handleToggleHighlight}
            isHighlightingEnabled={isHighlightingEnabled}
            disableDownload={footerButtonsDisabled}
            disableEdit={footerButtonsDisabled}
            disableEsign={footerButtonsDisabled}
            disableSaveInClio={footerButtonsDisabled}
          />
        ) : null
      }
      disableHorizontalPadding={true}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          height: '100%',
          marginTop: '-15px',
        }}
      >
        <div className={css(styles.draftingEditContainer)}>
          <PDFViewer
            viewerIsLoading={viewerIsLoading || savingInClio}
            backendLoaded={hasPdfTronLoadedDocument}
            setBackendLoaded={setHasPdfTronLoadedDocument}
            setViewerIsLoading={setViewerIsLoading}
            requestedDownloadPdf={requestedDownloadPdf}
            setRequestedDownloadPdf={setRequestedDownloadPdf}
            allDocuments={documents}
            docIndex={docIndex}
            setDocIndex={setDocIndex}
            currentDocument={currentDocument}
            requestedDownloadAllPdf={requestedDownloadAllPdf}
            currentProject={project}
            allPdfBlobs={allPdfBlobs}
            setAllPdfBlobs={setAllPdfBlobs}
            setRequestedDownloadAllPdf={setRequestedDownloadAllPdf}
            setReRouteToEsign={setReRouteToEsign}
            createEsignPackage={createEsignPackage}
            signaturePackageFilesData={signaturePackageFilesData}
            setSignaturePackageFilesData={setSignaturePackageFilesData}
            setCurrentDocumentPdfBlob={setCurrentPdfBlob}
            showHighlighting={isHighlighted}
          />
          {viewerIsLoading || savingInClio ? (
            <LoadingOverlay
              className={css(styles.draftingLoadingOverlay)}
              title={
                savingInClio ? 'Saving your documents to Clio...' : 'Loading...'
              }
            />
          ) : null}
        </div>
      </div>
    </PageLayout>
  );
};
