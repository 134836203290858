import { http } from '~/src/services/requests';
import qs from 'qs';

function authService() {
  return {
    searchMatter: (orgFprint, { fields = [], ...params } = {}) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v2/org/${orgFprint}/clio/matters/?${qs.stringify(
            {
              fields: fields.join(','),
              ...params,
            },
          )}`,
          method: 'GET',
        },
        parse,
      );
    },
    clioIntegrationData: (orgFprint) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/clio/authorize/`,
          method: 'GET',
        },
        parse,
      );
    },
    deauthenticate: (orgFprint) => {
      const parse = false;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/clio/authorize/`,
          method: 'DELETE',
        },
        parse,
      );
    },
    authenticate: (orgFprint, code) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/clio/auth-pro/`,
          method: 'POST',
          body: {
            code,
          },
        },
        parse,
      );
    },
    sync: (orgFprint) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/clio/sync/`,
          method: 'POST',
        },
        parse,
      );
    },
    matterSync: (orgFprint, matterId) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/clio/sync/matter/`,
          method: 'POST',
          body: { external_id: matterId },
        },
        parse,
      );
    },
    bulkImport: (orgFprint) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/clio/bulk-import/`,
          method: 'POST',
        },
        parse,
      );
    },

    startEdit: (orgFprint, projectId, clioMatterId, clioParentFolderId) => {
      const body = {
        clio_matter_id: clioMatterId,
        clio_parent_id: clioParentFolderId,
        project_id: projectId,
      };
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/clio/edit/`,
          method: 'POST',
          body,
        },
        parse,
      );
    },

    startUpload: (orgFprint, projectId, clioMatterId) => {
      const body = {
        clio_matter_id: clioMatterId,
        project_id: projectId,
      };
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/clio/upload/`,
          method: 'POST',
          body,
        },
        parse,
      );
    },

    uploadToS3: (documentVersion, documentBlob) => {
      const headers = Object.assign(
        {},
        ...documentVersion.put_headers.map((header) => ({
          [header.name]: header.value,
        })),
      );
      headers['Content-Length'] = documentBlob.size;

      return fetch(documentVersion.put_url, {
        body: documentBlob,
        method: 'PUT',
        headers,
      });
    },

    markFullyUploaded: (orgFprint, documents) => {
      const body = { documents };
      const parse = false;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/clio/upload/`,
          method: 'PATCH',
          body,
        },
        parse,
      );
    },
  };
}

export default authService();
