import { http, getPaginationUrlQuery } from '~/src/services/requests';
import * as qs from 'querystring';

function templatesService() {
  return {
    fetchTags: async (orgFprint, { templateId }) => {
      const parse = true;
      const query = qs.stringify(
        { template_ids: [templateId] },
        { arrayFormat: 'brackets' },
      );

      return http.innerSendAuthenticated(
        {
          relativePath: `/intake_form/org/${orgFprint}/tag/?${query}`,
          method: 'GET',
        },
        parse,
      );
    },
    getTemplate: async (orgFprint, templateId) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/word-addin/api/v1/org/${orgFprint}/template/${templateId}/`,
          method: 'GET',
        },
        parse,
      );
    },
    getTemplatePreview: async (orgFprint, docId) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/pdf/api/v1/org/${orgFprint}/preview/${docId}/`,
          method: 'GET',
        },
        parse,
      );
    },
    getTemplates: async (
      orgFprint,
      { page, search, sortBy, order, limit },
      documentType = 'MS_WORD',
    ) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/clients/api/v1/org/${orgFprint}/template/${getPaginationUrlQuery(
            { page, search, sortBy, order, limit, documentType },
          )}`,
          method: 'GET',
        },
        parse,
      );
    },
    updateTemplate: async (orgFprint, templateId, body) => {
      const parse = true;
      return http.innerSendAuthenticated(
        {
          relativePath: `/word-addin/api/v1/org/${orgFprint}/template/${templateId}/`,
          method: 'PUT',
          body,
        },
        parse,
      );
    },
    deleteTemplate: async (orgFprint, templateId) => {
      const parse = false;
      return http.innerSendAuthenticated(
        {
          relativePath: `/word-addin/api/v1/org/${orgFprint}/template/${templateId}/`,
          method: 'DELETE',
        },
        parse,
      );
    },
  };
}

export default templatesService();
