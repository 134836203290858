import React from 'react';
import { Box, Typography } from '@mui/material';
import { ProjectStackField, Project } from '~/src/entities/project';
import { FieldHeaderCount } from './FieldHeaderCount';
import { FieldHeaderHint } from './FieldHeaderHint';

export type FieldHeaderProps = {
  projectField: ProjectStackField;
  project: Project;
};

export const FieldHeader = ({ projectField, project }: FieldHeaderProps) => {
  return (
    <Box pb={1}>
      <Box>
        <Box
          display="flex"
          alignItems={'center'}
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="label">{projectField.label}</Typography>
          </Box>
          <Box>
            <FieldHeaderCount projectField={projectField} project={project} />
          </Box>
        </Box>
        <Box>
          <FieldHeaderHint projectField={projectField} />
        </Box>
      </Box>
    </Box>
  );
};
