import React from 'react';
import {
  QuestionnaireBuilderSubmissionApp,
  type QuestionnaireSubmission,
} from '@clio/questionnaire-builder';
import { useOrgFprint } from '~/src/hooks/useOrgFprint';
import Page, { LoadingOverlay } from '~/src/components/PageLayout';
import { history } from '~/src/utils/history';
import {
  useClioQuestionnaireSubmissionById,
  useDraftDocumentsUsingQuestionnaire,
  useGetQuestionnaireTemplates,
  useClioQuestionnaireSubmissionApprove,
  useDeleteClioQuestionnaireSubmission,
  useGetClioQuestionnaireById,
} from '~/src/entities/Questionnaires';
import { useLayoutContext } from '~/src/contexts';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';

interface QuestionnaireSubmissionPageProps {
  questionnaireId: string;
  submissionId: string;
  onExit: () => void;
}

export const QuestionnaireSubmissionPage: React.FC<
  QuestionnaireSubmissionPageProps
> = ({ questionnaireId, submissionId, onExit }) => {
  const { showToast } = useLayoutContext();

  const orgFprint = useOrgFprint();

  const {
    data: submissionData,
    isError,
    isLoading,
  } = useClioQuestionnaireSubmissionById(orgFprint, submissionId);

  const {
    data: questionnaire,
    isError: isQuestionnaireError,
    isLoading: isQuestionnaireLoading,
  } = useGetClioQuestionnaireById(orgFprint, questionnaireId);

  const { matterData, isMatterError, updateMatterAndCreateDocSet } =
    useDraftDocumentsUsingQuestionnaire(orgFprint, submissionData);

  const { mutateAsync: approveSubmission, isLoading: isApproveLoading } =
    useClioQuestionnaireSubmissionApprove(orgFprint, submissionId);

  const { mutateAsync: deleteSubmission } =
    useDeleteClioQuestionnaireSubmission(orgFprint);

  const {
    data: docData,
    isError: isDocError,
    isLoading: isDocLoading,
  } = useGetQuestionnaireTemplates(orgFprint, questionnaireId);

  const handleApprove = async ({
    title,
    submission,
  }: {
    title: string;
    submission: QuestionnaireSubmission;
  }): Promise<void> => {
    // get a dict of external_field_identifier to response from the submission
    const getResponseDict = (submission: QuestionnaireSubmission) => {
      const responseDict: { [key: string]: string } = {};
      submission.section_submissions_attributes.forEach((sectionSubmission) => {
        sectionSubmission.question_submissions_attributes.forEach(
          (questionSubmission) => {
            questionSubmission.question.question_mappings.forEach((mapping) => {
              responseDict[mapping.external_field_identifier] =
                questionSubmission.response;
            });
          },
        );
      });

      return responseDict;
    };

    if (!docData) return;

    const templateIds: string[] = docData.map((doc) => `${doc.template_id}`);
    const entityAttributeIdResponseDict = getResponseDict(submission);

    try {
      const res = await updateMatterAndCreateDocSet(
        title,
        entityAttributeIdResponseDict,
        templateIds,
      );
      await approveSubmission();

      history.push(`/populate/${res.id}`);
    } catch (e) {
      showToast(LAYOUT_TOAST_TYPES.error, {
        message: 'Something went wrong, please try again later.',
      });
    }
  };

  const handleDelete = async ({
    submission,
  }: {
    submission: QuestionnaireSubmission;
  }): Promise<void> => {
    await deleteSubmission(
      { submissionId: `${submission.id}` },
      {
        onError: () => {
          showToast(LAYOUT_TOAST_TYPES.error, {
            message: 'Something went wrong, please try again later.',
          });
        },
        onSuccess: () => {
          showToast(LAYOUT_TOAST_TYPES.success, {
            message: 'Submission deleted',
          });
          onExit();
        },
      },
    );
  };

  if (isError || isDocError || isMatterError || isQuestionnaireError) {
    return <div>Error, Failed to load Questionnaire Submission.</div>;
  }

  if (
    isLoading ||
    isDocLoading ||
    isApproveLoading ||
    isQuestionnaireLoading ||
    !questionnaire ||
    !submissionData ||
    !matterData ||
    !docData
  ) {
    return (
      <LoadingOverlay
        title="Loading questionnaire submission..."
        relative={false}
      />
    );
  }

  return (
    <Page disablePadding={true} showNavigation={false} showHeader={false}>
      <QuestionnaireBuilderSubmissionApp
        matter={{
          id: matterData.id,
          title: matterData.title,
          onClick: () => {},
        }}
        documents={docData.map((doc) => ({
          id: doc.template_id,
          name: `${doc.template.title}`,
          subtitle: `${doc.template.subtitle}`,
        }))}
        submission={{ ...submissionData }}
        onExit={onExit}
        onApprove={handleApprove}
        onDelete={handleDelete}
        questionnaire={questionnaire}
      />
    </Page>
  );
};
