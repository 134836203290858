import React from 'react';
import { Route } from 'react-router-dom';
import { history } from '~/src/utils/history';

import Tabs from '~/src/components/PageLayout/Tabs';
import Page from '~/src/components/PageLayout';

import { QuestionnaireTable } from './QuestionnaireTable';
import { QuestionnaireSubmissionTable } from './QuestionnaireSubmissionTable';
import { QuestionnaireSubmission } from '@clio/questionnaire-builder';

const tabs = (
  <Tabs
    tabsMetadata={[
      {
        headerText: 'Questionnaires',
        toUrl: '/questionnaires/all',
      },
      {
        headerText: 'Pending',
        toUrl: '/questionnaires/pending',
      },
      {
        headerText: 'Submitted',
        toUrl: '/questionnaires/submitted',
      },
    ]}
    active={undefined}
    setActive={undefined}
  />
);

export const ClioQuestionnairePage: React.FC = () => {
  return (
    <Page title={'Questionnaires'} headerProps={{ tabs }}>
      <Route path="/questionnaires/all">
        <QuestionnaireTable />
      </Route>
      <Route path="/questionnaires/pending">
        <QuestionnaireSubmissionTable
          title={'Pending'}
          status={['pending', 'not_started']}
          onViewDetails={function (submission: QuestionnaireSubmission): void {
            history.push(
              `/questionnaires/${submission.questionnaire_id}/pending/${submission.id}`,
            );
          }}
        />
      </Route>
      <Route path="/questionnaires/submitted">
        <QuestionnaireSubmissionTable
          title={'Submitted'}
          status={['approved', 'needs_review']}
          onViewDetails={function (submission: QuestionnaireSubmission): void {
            history.push(
              `/questionnaires/${submission.questionnaire_id}/submitted/${submission.id}`,
            );
          }}
        />
      </Route>
    </Page>
  );
};
