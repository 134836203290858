import React from 'react';
import { css } from 'aphrodite';
import { AlertCircle } from 'react-feather';
import _, { isEqual, cloneDeep } from 'lodash';
import { inject, observer } from 'mobx-react';

/* Higher Order Components */
import WithLayoutProps from '~/src/hoc/WithLayoutProps';

/* Components */
import Card, { CardTitle, CardBreak } from '~/src/components/Card';
import Button from '~/src/components/Button';
import { Label } from '~/src/components/Inputs';
import Banner from '~/src/components/Banner';
import { Form, renderFormField } from '~/src/components/Forms';
import Spinner from '~/src/components/Spinner';

/* Styles */
import modalStyles from '~/src/components/Modal/styles';

/* Hooks */
import useUser from '~/src/hooks/useUser';

/* Constants */
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import {
  COURT_FORM_PLANS,
  COURT_FORM_ANNUAL_PLANS,
  COURT_FORM_MONTHLY_PLANS,
  DOCUMENT_AUTOMATION_PLANS,
  SEATS_MONTHLY_PRICE,
  SEATS_YEARLY_PRICE,
} from '~/src/utils/constants';
import styles from './styles';
import { useCurrentOrg } from '~/src/hooks/useCurrentOrg';
import { Typography } from '@mui/material';

const CreateNewSubscription = ({ onCreateNewSubscription }) => {
  return (
    <>
      <CardTitle>Create new subscription</CardTitle>
      <div className={css(styles.settingsRow)}>
        <Button onClick={onCreateNewSubscription}>Choose plan</Button>
      </div>
    </>
  );
};

const NoSubscriptionNeeded = () => {
  return (
    <>
      <CardTitle>No subscription</CardTitle>
      <div className={css(styles.settingsRow)}>
        This account does not require a subscription.
      </div>
    </>
  );
};

const ChoosePlanForm = ({
  onChange,
  cycle,
  courtFormPlans,
  documentAutomationPlans,
}) => {
  const fields = [
    {
      id: 'plan',
      label: '',
      type: 'planChoice',
      options: [
        {
          label: 'Fillable court forms',
          plans: COURT_FORM_PLANS[cycle],
          defaultValue: courtFormPlans,
        },
        {
          label: 'Word document automation',
          plans: [DOCUMENT_AUTOMATION_PLANS[cycle][0]],
          defaultValue: [documentAutomationPlans],
        },
      ],
      validation: () => true,
    },
    {
      id: 'cycle',
      label: 'Billing cycle',
      type: 'multipleChoice',
      defaultValue: cycle,
      value: cycle,
      options: [
        { label: 'Monthly', value: 'monthly' },
        { label: 'Annual', value: 'annual' },
      ],
      validation: () => true,
    },
  ];

  const renderFields = fields.map((field) => renderFormField(field));

  return (
    <div className={css(modalStyles.modalContainer)}>
      <div className={css(modalStyles.modalBody)}>
        <Form fields={renderFields} onChange={onChange} />
      </div>
    </div>
  );
};

const SubscriptionDetails = ({
  loading,
  subscription,
  onUpdateSeats,
  onUpdatePaymentMethod,
  onRenewSubscription,
  nextInvoice,
  cycle,
  handlePlanChange,
  courtFormPlans,
  documentAutomationPlans,
  pendingRequest,
}) => {
  const { quantity } = subscription;
  const seatLabel = quantity > 1 ? 'seats' : 'seat';
  const endDate = subscription.nextInvoiceEndFormatted();

  const { ccId } = subscription;

  const canceled = subscription.isCanceled();

  const handleUpdateSeats = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    if (onUpdateSeats) {
      onUpdateSeats();
    }
  };

  const handleRenewSubscription = () => {
    onRenewSubscription();
  };

  const renewLabel = loading ? 'Renewing...' : 'Renew subscription';

  const renderCanceledFragment = () => {
    let description = `Your subscription is set to cancel on ${endDate}`;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const org = useUser().currentOrganization;
    if (
      org.subscription.isCanceled() &&
      org.subscription.remainingDaysFromCanceledDate() < 0
    ) {
      description = `Your subscription was canceled on ${endDate}`;
    }

    return (
      <>
        <div className={css(styles.settingsRow)}>
          <Banner small alert Icon={AlertCircle}>
            {description}
          </Banner>
          <Button onClick={handleRenewSubscription} disabled={loading} primary>
            {renewLabel}
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className="relative">
      <CardTitle bold>Subscription</CardTitle>
      <div className={css(!canceled && styles.settingsRow)}>
        <Label className={css(styles.settingsLabel)} label={'Plan'}>
          {quantity} {seatLabel}
        </Label>
      </div>
      {!canceled && (
        <div className={css(styles.settingsRow)}>
          <Button primary onClick={handleUpdateSeats}>
            Update seats
          </Button>
        </div>
      )}
      {canceled && renderCanceledFragment()}
      <ChoosePlanForm
        cycle={cycle}
        onChange={handlePlanChange}
        courtFormPlans={[...courtFormPlans]}
        documentAutomationPlans={documentAutomationPlans}
      />
      <div className="h-10" />
      <div className={css(styles.settingsRow)}>
        <CardBreak />
      </div>
      {!canceled && (
        <div className={css(styles.settingsRow)}>
          <Label className={css(styles.settingsLabel)} label={'Next invoice'}>
            ${nextInvoice} on {endDate}
          </Label>
        </div>
      )}
      <div className={css(styles.settingsRow)}>
        <Label className={css(styles.settingsLabel)} label={'Payment method'}>
          Card ending in {ccId}
        </Label>
        <Button
          secondary
          cssStyle={styles.settingsSubscriptionLabelButton}
          onClick={onUpdatePaymentMethod}
        >
          Update payment method
        </Button>
      </div>

      {pendingRequest && (
        <div className="absolute flex items-center justify-center -inset-8 bg-opacity-50 bg-gray-100">
          <Spinner />
        </div>
      )}
    </div>
  );
};

const CancelCard = ({ onCancelSubscription }) => {
  const handleCancelSubscription = (event) => {
    if (event.preventDefault) {
      event.preventDefault();
    }
    if (onCancelSubscription) {
      onCancelSubscription();
    }
  };

  return (
    <Card elevate>
      <CardTitle bold>Cancel subscription</CardTitle>
      <p className={css(styles.settingsRow, styles.cardBody)}>
        Upon canceling, you and your team will lose access to your templates,
        drafted documents, contacts and matter information stored in Lawyaw
        after the current billing period.
      </p>
      <a
        role="button"
        tabIndex={0}
        href={'#'}
        onKeyPress={handleCancelSubscription}
        onClick={handleCancelSubscription}
        className={css(styles.buttonLink)}
      >
        Cancel subscription
      </a>
    </Card>
  );
};

class SettingsSubscription extends React.Component {
  constructor(props) {
    super(props);

    // Generate state.courtFormPlans based on props.subscription.plan.name
    let courtFormPlans = [];
    const {
      subscription: { plan, quantity, ccId },
    } = props;

    const WORD_DOCUMENT_AUTOMATION_NAME = 'WORD DOCUMENT AUTOMATION';

    for (let i = 0; i < COURT_FORM_MONTHLY_PLANS.length; i++) {
      courtFormPlans.push(plan.id.includes(COURT_FORM_MONTHLY_PLANS[i].id));
    }

    if (!courtFormPlans.includes(true)) {
      courtFormPlans = [];
      for (let i = 0; i < COURT_FORM_ANNUAL_PLANS.length; i++) {
        courtFormPlans.push(plan.id.includes(COURT_FORM_ANNUAL_PLANS[i].id));
      }
    }

    this.state = {
      nextInvoice: 'Calculating...',
      courtFormPlans,
      documentAutomationPlans: plan.name.includes(
        WORD_DOCUMENT_AUTOMATION_NAME,
      ),
      currentState: {
        courtFormPlans: [...courtFormPlans],
        documentAutomationPlans: plan.name.includes(
          WORD_DOCUMENT_AUTOMATION_NAME,
        ),
      },
      cycle: plan.interval === 'month' ? 'monthly' : 'annual',
      seats: quantity,
      cardToken: ccId,
      pendingRequest: false,
    };
  }

  async componentDidMount() {
    this.fetchInvoice();
  }

  fetchInvoice = async () => {
    const { store } = this.props;
    const { cycle, seats } = this.state;

    this.setState({ pendingRequest: true });
    const nextInvoice =
      await store.user.currentOrganization.fetchUpcomingInvoice(
        [],
        [],
        [],
        cycle,
        seats,
        '',
      );

    if (nextInvoice) {
      const { total } = nextInvoice;
      this.setState({ nextInvoice: total / 100 });
    }
    this.setState({ pendingRequest: false });
  };

  onBillingCycleChange = async () => {
    const { seats, cardToken, cycle } = this.state;
    const newCycle = cycle === 'monthly' ? 'annual' : 'monthly';

    this.props.hideModal();

    this.setState({ pendingRequest: true });
    const res =
      await this.props.store.user.currentOrganization.updateSubscription({
        add_plans: [],
        remove_plans: [],
        one_time_charges: [],
        'card-token': cardToken,
        seat: seats,
        coupon: '',
        trial_period_days: '',
        interval_change: true,
        duration: newCycle,
      });
    this.setState({ pendingRequest: false });

    if (res) {
      this.setState({ cycle: newCycle }, this.fetchInvoice);
    }
  };

  onBillingCycleCancel = () => {
    this.props.hideModal();
  };

  handlePlanChange = async (form) => {
    const selectedPlan =
      form && form.fields && form.fields.plan ? form.fields.plan.value : null;
    const newState = cloneDeep(this.state);

    const currentState = {
      courtFormPlans: this.state.courtFormPlans,
      documentAutomationPlans: this.state.documentAutomationPlans,
    };
    if (selectedPlan) {
      const { index, selected } = selectedPlan;
      if (index) {
        // Add to document automation plans
        newState.documentAutomationPlans = selected[0];
      } else {
        // Add to court form plans
        newState.courtFormPlans = selected;
      }
    }
    const cycle = form.fields.cycle.value;
    const { nextInvoice, seats, cardToken } = this.state;
    const { courtFormPlans, documentAutomationPlans } = newState;
    if (
      courtFormPlans.filter((item) => item).length === 0 &&
      !documentAutomationPlans
    ) {
      this.props.showToast(LAYOUT_TOAST_TYPES.error, {
        message: 'You should select at least 1 plan!',
      });
      return;
    }

    const courtFormPlanIds = COURT_FORM_PLANS[cycle]
      .filter((plan, id) => courtFormPlans[id])
      .map((plan) => plan.id);
    const documentAutomationPlanIds = documentAutomationPlans
      ? [DOCUMENT_AUTOMATION_PLANS[cycle][0].id]
      : [];

    const currentCourtFormPlanIds = COURT_FORM_PLANS[cycle]
      .filter((plan, id) => currentState.courtFormPlans[id])
      .map((plan) => plan.id);
    const currentDocumentAutomationPlanIds =
      currentState.documentAutomationPlans
        ? [DOCUMENT_AUTOMATION_PLANS[cycle][0].id]
        : [];
    newState.cycle = cycle;

    if (newState.cycle !== this.state.cycle) {
      let newPlanPrice = 0;
      // Calculate the next plan
      if (newState.cycle === 'annual') {
        const pricePlans =
          COURT_FORM_MONTHLY_PLANS.filter((plan, id) => courtFormPlans[id]).map(
            (plan) => plan.price,
          ) || [];
        const documentAutomationAnnualPlans = DOCUMENT_AUTOMATION_PLANS.annual;
        newPlanPrice = pricePlans.reduce((acc, item) => acc + item, 0) * 10;
        newPlanPrice += documentAutomationPlans
          ? documentAutomationAnnualPlans[0].price
          : 0;
        newPlanPrice += seats * SEATS_YEARLY_PRICE;
      } else {
        const pricePlans =
          COURT_FORM_ANNUAL_PLANS.filter((plan, id) => courtFormPlans[id]).map(
            (plan) => plan.price,
          ) || [];
        const documentAutomationMonthlyPlans =
          DOCUMENT_AUTOMATION_PLANS.monthly;
        newPlanPrice = pricePlans.reduce((acc, item) => acc + item, 0) / 10;
        newPlanPrice += documentAutomationPlans
          ? documentAutomationMonthlyPlans[0].price
          : 0;
        newPlanPrice += seats * SEATS_MONTHLY_PRICE;
      }
      // Show confirmation form to change billing cycle
      this.props.showModal(LAYOUT_MODAL_TYPES.confirm, {
        title: `Change billing cycle to ${cycle}`,
        message: (
          <div className="flex flex-col w-full">
            <div className="flex flex-row justify-between font-medium text-sm mb-2">
              <span>Current plan</span>
              <span>
                ${nextInvoice} / {cycle === 'monthly' ? 'year' : 'mo'}
              </span>
            </div>
            <div className="flex flex-row justify-between font-medium text-sm">
              <span>New plan</span>
              <span>
                ${newPlanPrice} / {cycle === 'monthly' ? 'mo' : 'year'}
              </span>
            </div>
          </div>
        ),
        onConfirm: this.onBillingCycleChange,
        onCancel: this.onBillingCycleCancel,
        primaryActionTitle: 'Confirm',
      });
      return;
    }

    if (!isEqual(newState, this.state)) {
      // Update court form plans or document automation plans
      this.setState({ pendingRequest: true });

      const res =
        await this.props.store.user.currentOrganization.updateSubscription({
          add_plans: _.concat(
            _.difference(courtFormPlanIds, currentCourtFormPlanIds),
            _.difference(
              documentAutomationPlanIds,
              currentDocumentAutomationPlanIds,
            ),
          ),
          remove_plans: _.concat(
            _.difference(currentCourtFormPlanIds, courtFormPlanIds),
            _.difference(
              currentDocumentAutomationPlanIds,
              documentAutomationPlanIds,
            ),
          ),
          one_time_charges: [],
          'card-token': cardToken,
          seat: seats,
          coupon: '',
          trial_period_days: '',
          interval_change: false,
          duration: cycle,
        });
      this.setState({ pendingRequest: false });

      if (res) {
        this.setState({ ...newState, seats, currentState }, this.fetchInvoice);
      }
    }
  };

  handleUpdateSeats = () => {
    this.props.showModal(LAYOUT_MODAL_TYPES.subscriptionUpdate);
  };

  handleUpdatePaymentMethod = () => {
    this.props.showModal(LAYOUT_MODAL_TYPES.updatePaymentMethod);
  };

  handleCancelSubscription = () => {
    this.props.showModal(LAYOUT_MODAL_TYPES.cancelSubscriptionConfirm);
  };

  handleRenewSubscription = () => {
    this.props.onRenewSubscription();
  };

  render() {
    const {
      onCreateNewSubscription,
      subscription,
      isNonProfit,
      isClioFree,
      loading,
    } = this.props;

    const {
      nextInvoice,
      courtFormPlans,
      documentAutomationPlans,
      cycle,
      pendingRequest,
    } = this.state;
    let cardContent = null;

    cardContent = (
      <CreateNewSubscription
        onCreateNewSubscription={onCreateNewSubscription}
      />
    );

    if (subscription) {
      cardContent = (
        <SubscriptionDetails
          pendingRequest={pendingRequest}
          loading={loading}
          subscription={subscription}
          onUpdateSeats={this.handleUpdateSeats}
          onUpdatePaymentMethod={this.handleUpdatePaymentMethod}
          onRenewSubscription={this.handleRenewSubscription}
          nextInvoice={nextInvoice}
          cycle={cycle}
          handlePlanChange={this.handlePlanChange}
          courtFormPlans={courtFormPlans}
          documentAutomationPlans={documentAutomationPlans}
        />
      );
    }

    if (isNonProfit || !subscription) {
      cardContent = <NoSubscriptionNeeded />;
    }

    if (isClioFree && !subscription) {
      cardContent = (
        <CreateNewSubscription
          onCreateNewSubscription={onCreateNewSubscription}
        />
      );
    }

    const cardActions =
      !isNonProfit && subscription && !subscription.canceledAt ? (
        <CancelCard onCancelSubscription={this.handleCancelSubscription} />
      ) : null;

    return (
      <div className={css(styles.settingsCardContainer)}>
        <Card elevate>{cardContent}</Card>
        <div className={css(styles.settingsOuterButtonContainer)}>
          {cardActions}
        </div>
      </div>
    );
  }
}

const ObservedSubscriptionSettings = observer(SettingsSubscription);

const SubscriptionWrapper = (props) => {
  const org = useCurrentOrg();
  if (!org.admin) {
    return (
      <div className={css(styles.settingsCardContainer)}>
        <Card elevate>
          <CardTitle bold>Subscription</CardTitle>
          <Typography>
            Please contact the organization&apos;s administrator to have them
            update the subscription information.
          </Typography>
        </Card>
      </div>
    );
  }

  return <ObservedSubscriptionSettings {...props} />;
};

export default inject((store) => store)(WithLayoutProps(SubscriptionWrapper));
