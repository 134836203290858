import * as React from 'react';

export const UIFlags = {
  cardLists: false,
  templateDraftingList: false,
  populateExperienceMilestone4: false,
  progressTracker: false,
};

export type UIFlag = keyof typeof UIFlags;

export const UI_FLAG_LABELS: Record<UIFlag, string> = {
  cardLists: 'Card Lists',
  templateDraftingList: 'Remove Template Drafting Select',
  populateExperienceMilestone4: 'Populate Experience Milestone 4',
  progressTracker: 'Progress Tracker Widget',
};

export type FlagSetter = (flag: UIFlag, enabled: boolean) => void;

type UIFlagsContextType = {
  flags: typeof UIFlags;
  setFlag: FlagSetter;
};

export const UIFlagsContext = React.createContext<
  UIFlagsContextType | undefined
>(undefined);

export const useUIFlagsContext = () =>
  React.useContext(UIFlagsContext) as UIFlagsContextType;

export const useUIFlags = () => useUIFlagsContext().flags;

export const useToggleUIFlag = () => {
  const { setFlag, flags } = useUIFlagsContext();
  return (flag: UIFlag) => setFlag(flag, !flags[flag]);
};

export const useUIFlagEnabled = (flag: UIFlag) => useUIFlags()[flag];

export const useCheckUIFlagEnabled = () => {
  const flags = useUIFlags();
  return (flag: UIFlag) => flags[flag];
};
