/* Libs */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { startCase } from 'lodash';
import { css } from 'aphrodite';

/* Containers */

import QuestionnaireResponsesContainer from '~/src/containers/questionnaires/QuestionnaireResponses';

/* Components */
import Card from '~/src/components/Card';
import { Form } from '~/src/components/Forms';
import { Small } from '~/src/components/Text';
import { Row } from '~/src/components/PageLayout/Layout';
import SingleMatterSync from '~/src/components/SingleMatterSync';
import Documents from '~/src/components/Documents';
import DocumentSets from '~/src/components/DocumentSets';
import {
  mapDocumentToTable,
  mapDocumentSetToTable,
} from '~/src/utils/dataTransformers';

/* Styles */
import styles from './styles';

const MatterDetail = ({
  summaryFields,
  documents,
  documentSets,
  intakes,
  relatedContactsFields,
  clioCustomFields,
  entityCardFields,
  isQuestionnairesEnabled,
  matter,
  onSynced,
}) => {
  return (
    <Row>
      <div className={css(styles.column)}>
        {matter.externalMatterId ? (
          <SingleMatterSync
            analyticsEventName="Matter Detail"
            onSynced={onSynced}
            matter={matter}
          />
        ) : null}

        <Card
          elevate
          dynamic
          cssStyle={styles.card}
          renderHeader={() => 'Summary'}
        >
          <Form fields={summaryFields} />
        </Card>

        {isQuestionnairesEnabled && (
          <Card
            evlevate
            dynamic
            noPadding={intakes.length > 0}
            cssStyle={styles.card}
            renderHeader={() => 'Submitted Questionnaires'}
          >
            {intakes.length > 0 ? (
              <QuestionnaireResponsesContainer
                intakes={intakes}
                columnWidths={['40%', '20%']}
                hideHeader
                noBorder
              />
            ) : (
              <Small>
                There are no questionnaire responses attached to this matter.
                Publish a questionnaire and assign it to this matter to view
                submitted questionnaires.
              </Small>
            )}
          </Card>
        )}

        <Card
          elevate
          dynamic
          noPadding={documents.length > 0}
          cssStyle={styles.card}
          renderHeader={() => 'Documents'}
        >
          {documents.length <= 0 && (
            <Small>
              There are no documents attached to this matter. Select a template
              for the <Link to="/templates">list of templates</Link> and choose
              this matter in the following screen.
            </Small>
          )}
          {documents.length > 0 && (
            <Documents
              documents={documents.map(mapDocumentToTable)}
              visibleItems={documents.length}
              columnWidths={['40%', '20%']}
              hideHeader
              noBorder
            />
          )}
        </Card>

        <Card
          elevate
          dynamic
          noPadding={documentSets.length > 0}
          cssStyle={styles.card}
          renderHeader={() => 'Document sets'}
        >
          {documentSets.length <= 0 && (
            <Small>
              There are no document sets attached to this matter. Select a
              template for the <Link to="/templates">list of templates</Link>{' '}
              and choose this matter in the following screen.
            </Small>
          )}
          {documentSets.length > 0 && (
            <DocumentSets
              documentSets={documentSets.map(mapDocumentSetToTable)}
              visibleItems={documentSets.length}
              columnWidths={['40%', '20%']}
              hideHeader
              noBorder
            />
          )}
        </Card>
      </div>
      <div className={css(styles.divider)} />
      <div className={css(styles.column)}>
        {relatedContactsFields.length > 0 && (
          <Card
            key="related-contacts"
            elevate
            dynamic
            cssStyle={styles.card}
            renderHeader={() => 'Related Contacts'}
          >
            <Form fields={relatedContactsFields} />
          </Card>
        )}
        {clioCustomFields.length > 0 && (
          <Card
            key="clio-fields"
            elevate
            dynamic
            cssStyle={styles.card}
            renderHeader={() => 'Clio Fields'}
          >
            <Form fields={clioCustomFields} />
          </Card>
        )}
        {Object.keys(entityCardFields).map((entity) => {
          const label = startCase(entity);

          const fields = entityCardFields[entity];

          return (
            <Card
              elevate
              key={label}
              dynamic
              cssStyle={styles.card}
              renderHeader={() => label}
            >
              <Form fields={fields} />
            </Card>
          );
        })}
      </div>
    </Row>
  );
};

MatterDetail.defaultProps = {
  intakes: [],
  documents: [],
};

MatterDetail.propTypes = {
  intakes: PropTypes.array,
  documentSets: PropTypes.array,
};

export default MatterDetail;
