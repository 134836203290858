import {
  Box,
  Card,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Edited } from '~/src/components/Inputs/Edit';
import { ProjectStackField, Project } from '~/src/entities/project';
import { FieldRenderProps } from '..';
import { FieldHeaderWithEdit } from '../FieldHeader/FieldHeaderWithEdit';

export type SingleSelectFieldProps = {
  field: FieldRenderProps;
  projectField: ProjectStackField;
  project: Project;
};

export const SingleSelectField = ({
  field,
  projectField,
  project,
}: SingleSelectFieldProps) => {
  const { value, onChange, ref } = field;
  const options = projectField.multiple_choice_variable!.options;

  const selectedOption = options.find((o) =>
    o.formatted ? o.option === value : o.value === value,
  );

  const isEdited = !!field.value && !selectedOption;

  const [isEditing, setIsEditing] = useState(isEdited);

  return (
    <Card
      sx={(theme) => ({
        border: `1px solid ${isEditing ? theme.palette.border : 'transparent'}`,
        marginBottom: theme.spacing(3),
      })}
      elevation={isEditing ? 3 : 0}
    >
      <Box p={isEditing ? 2 : 0}>
        <FieldHeaderWithEdit
          projectField={projectField}
          project={project}
          isEdited={isEdited}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />

        {projectField.single_select_display_type === 'Dropdown' ? (
          <Select
            inputRef={ref}
            inputProps={{ id: field.name, 'data-testid': 'select-input' }}
            id={field.name}
            data-testid="select"
            labelId={field.name}
            value={
              selectedOption?.formatted
                ? selectedOption?.option
                : selectedOption?.value
            }
            sx={{ display: 'block' }}
            size={'small'}
            onChange={(e) => onChange(e.target.value)}
          >
            {options.map(({ value, option, formatted }, _) => {
              return (
                <MenuItem key={value} value={formatted ? option : value}>
                  {option}
                </MenuItem>
              );
            })}
          </Select>
        ) : (
          <RadioGroup
            name={field.name}
            value={field.value}
            onChange={(_, v) => onChange(v)}
            id={field.name}
          >
            {options.map((field, i) => (
              <Box pb={1} key={field.value}>
                <FormControlLabel
                  style={{ display: 'flex' }}
                  slotProps={{ typography: { variant: 'body1' } }}
                  checked={
                    value === (field.formatted ? field.option : field.value)
                  }
                  control={
                    <Radio
                      inputRef={i === 0 ? ref : undefined}
                      sx={{ paddingTop: 0, paddingBottom: 0 }}
                    />
                  }
                  label={field.option}
                  value={field.formatted ? field.option : field.value}
                />
              </Box>
            ))}
          </RadioGroup>
        )}

        {isEditing ? (
          <Box pt={5}>
            <Box pb={1}>
              <Typography variant={'label'}>Text to Merge</Typography>
            </Box>
            <TextField
              inputProps={{ 'data-testid': 'text-to-merge-field' }}
              size={'small'}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              fullWidth
              multiline
            />
            <Box
              py={1}
              visibility={isEdited ? 'visible' : 'hidden'}
              display={'flex'}
              justifyContent={'flex-end'}
              alignItems={'center'}
            >
              <Edited />
            </Box>
          </Box>
        ) : null}
      </Box>
    </Card>
  );
};
