import React, { useState } from 'react';
import styles from '~/src/components/Modal/styles';

import Button from '~/src/components/Button';
import { SpinnerWithTitle } from '~/src/components/Spinner';
import { ReactComponent as IconError } from '~/src/static/icon-error.svg';
import { ModalContent } from '~/src/components/Modal/Generic';
import analyticsService from '~/src/services/analytics';
import { useUpdateUserPreferences } from '~/src/entities/user';

export type SyncSingleMatterModalContentProps = {
  onConfirm: () => void;
  error: boolean;
  onCancel: () => void;
  requiresConfirm: boolean;
};

const SyncSingleMatterModalContent = ({
  onConfirm,
  error,
  requiresConfirm,
  onCancel,
}: SyncSingleMatterModalContentProps) => {
  const { mutate } = useUpdateUserPreferences({
    onMutate: () => {
      onConfirm();
      analyticsService.track('Single Matter Sync - Do not prompt again');
    },
  });
  const [dontShowAgain, setDontShowAgain] = useState(false);
  if (requiresConfirm) {
    return (
      <ModalContent
        title={'Overwrite Lawyaw fields with Clio Manage content?'}
        size={'small'}
        actions={
          <>
            <Button
              cssStyle={styles.buttonAction}
              onClick={() => {
                analyticsService.track(
                  'Single Matter Sync - Canceled At Overwrite Prompt',
                );
                onCancel();
              }}
            >
              Close
            </Button>

            <Button
              cssStyle={styles.buttonAction}
              primary
              analyticsname="Single Matter Sync - Get Updates from Clio Manage"
              onClick={() => {
                if (dontShowAgain) {
                  mutate({ warn_sync_overwrite: false });
                } else {
                  onConfirm();
                }
              }}
            >
              Get Updates from Clio Manage
            </Button>
          </>
        }
      >
        <>
          <div style={{ marginBottom: 24 }}>
            All Lawyaw fields that have a different content in Clio Manage will
            be overwritten.
            <br />
            <br />
            <u>
              <a
                href="https://help.lawyaw.com/en/articles/6790521-getting-matter-updates-from-clio-manage"
                target={'_blank'}
                rel="noreferrer"
              >
                Learn More
              </a>
            </u>
          </div>
          <div>
            <label style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                style={{ marginRight: '9px' }}
                checked={dontShowAgain}
                onChange={() => setDontShowAgain(!dontShowAgain)}
                analyticsname="Single Matter Sync - Don't show this again"
              />
              Don&apos;t show this again
            </label>
          </div>
        </>
      </ModalContent>
    );
  }

  if (error) {
    return (
      <ModalContent
        title={'Unable to retrieve updates'}
        size={'small'}
        actions={
          <Button primary cssStyle={styles.buttonAction} onClick={onCancel}>
            Close
          </Button>
        }
        titleIcon={<IconError style={{ marginRight: '16px' }} />}
      >
        <>
          <div style={{ marginBottom: 24 }}>
            We were unable to retrieve the latest updates from Clio Manage.
            Please check your Clio matter permissions and try again.
          </div>
          <div>If the problem persists, reach out to customer support.</div>
        </>
      </ModalContent>
    );
  }

  return (
    <ModalContent size={'small'}>
      <SpinnerWithTitle title="Retrieving updates..." subtitle="Please Wait" />
    </ModalContent>
  );
};

export default SyncSingleMatterModalContent;
