import { useObserver } from 'mobx-react';
import { useCurrentOrg } from './useCurrentOrg';

export const useNeedsToCreateSubscription = () => {
  const org = useCurrentOrg()!;

  return useObserver(() => {
    if (org?.subscription?.freeTrialIsActive()) {
      return org?.subscription?.remainingDaysOfFreeTrial() <= 0;
    }

    if (org?.subscription || org?.subscriptionId) return false;

    if (org?.isNonProfit) return false;

    if (org?.featureFlags.includes('MANAGE_LEAD')) return false;

    return true;
  });
};
