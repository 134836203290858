import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '~/src/components/Button';
import Card from '~/src/components/Card';
import { Form, renderFormField } from '~/src/components/Forms';
import { VALIDATION_TYPES } from '~/src/utils/validation';

import { default as cardStyles } from '~/src/components/Card/styles';

import { PRACTICE_AREAS, US_AND_CANADA_STATES } from '~/src/utils/constants';

const getFormFields = (fieldsValid) => [
  // {
  //   label: "What best describes you?",
  //   id: "Occupation",
  //   type: "select",
  //   validation: VALIDATION_TYPES.present,
  //   options: OCCUPATION_LIST,
  // },
  {
    label: 'What is your primary practice area',
    id: 'Primary practice area',
    type: 'select',
    validation: VALIDATION_TYPES.present,
    options: PRACTICE_AREAS,
    required: true,
  },
  {
    label: 'What state do you practice in primarily?',
    id: 'State',
    type: 'select',
    validation: VALIDATION_TYPES.present,
    options: US_AND_CANADA_STATES,
    required: true,
  },
  {
    label: 'How many people are in your organization',
    id: 'Number of people in your organization',
    type: 'text',
    validation: VALIDATION_TYPES.present,
    error: fieldsValid['Number of people in your organization']
      ? null
      : 'Not a valid number of people',
    required: true,
  },
];

const WelcomeFreeTrialForm = ({ onSubmit, onChange }) => {
  const [fieldsValid, setFieldsValid] = useState({});
  const [formValid, setFormValid] = useState(false);

  const handleChange = (form) => {
    onChange(form);
    const nextFieldsValid = { ...fieldsValid };

    Object.keys(form.fields).forEach((fieldKey) => {
      const field = form.fields[fieldKey];
      nextFieldsValid[fieldKey] = !!field.value === false ? true : field.valid;
    });

    setFieldsValid(nextFieldsValid);
    setFormValid(form.valid);
  };

  const fields = getFormFields(fieldsValid);

  const fieldsProp = fields.map((field) =>
    renderFormField({ ...field, editable: true }),
  );

  const renderHeader = () => (
    <div>
      <div className="font-bold mb-2">Tell us a little bit about yourself</div>
      <div className="text-sm">
        This will be used to customize the product experience
      </div>
    </div>
  );

  const renderActions = () => (
    <div>
      <Button
        primary
        cssStyle={cardStyles.cardAction}
        onClick={onSubmit}
        disabled={!formValid}
      >
        Enter my account
      </Button>
    </div>
  );

  return (
    <div className="mt-32">
      <Card renderHeader={renderHeader} renderActions={renderActions}>
        <Form fields={fieldsProp} onChange={handleChange} />
      </Card>
    </div>
  );
};

WelcomeFreeTrialForm.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

WelcomeFreeTrialForm.defaultProps = {
  onChange: () => {},
  onSubmit: () => {},
};

export default WelcomeFreeTrialForm;
