import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

import Table from '~/src/containers/Table';
import Button from '~/src/components/Button';
import Triangle from '~/src/components/Triangle';
import Dropdown from '~/src/components/Dropdown';

import dropdownStyles from '~/src/components/Dropdown/styles';
import styles from './styles';
import useUser from '~/src/hooks/useUser';
import { useObserver } from 'mobx-react';
import useDocumentSetsActions from '~/src/hooks/useDocumentSetsActions';

const DocumentSetActions = () => {
  return <div />;
};

const RowAction = ({ actions, documentSet }) => {
  const renderTrigger = ({ toggleDropdown }) => (
    <span analyticsname="Actions">
      <Button onClick={toggleDropdown} cssStyle={styles.rowActionButton}>
        Actions <Triangle primary orientation={'down'} />
      </Button>
    </span>
  );

  let hasDocumentSetBeenGenerated = false;
  let documentSetHasTemplates = false;

  if (documentSet) {
    hasDocumentSetBeenGenerated = documentSet.documents.every(
      (document) => !!document.docx,
    );
    documentSetHasTemplates = documentSet.documents.some(
      (document) => !!document.templateId,
    );
  }

  const handleDelete = () => {
    actions.delete(documentSet);
  };

  const handleEdit = () => {
    actions.edit(documentSet);
  };

  const handleView = () => {
    actions.view(documentSet);
  };

  const handleRename = () => {
    actions.rename(documentSet);
  };

  const handleDownload = () => {
    actions.download(documentSet);
  };

  const handleDraftNew = () => {
    actions.draftNew(documentSet);
  };

  return (
    <Dropdown renderTrigger={renderTrigger}>
      {hasDocumentSetBeenGenerated && (
        <a
          onClick={handleView}
          className={css(dropdownStyles.dropdownActionItem)}
          analyticsname="View"
        >
          View
        </a>
      )}
      <a
        onClick={handleEdit}
        className={css(dropdownStyles.dropdownActionItem)}
        analyticsname="Edit"
      >
        Edit
      </a>
      {hasDocumentSetBeenGenerated && (
        <a
          onClick={handleDownload}
          className={css(dropdownStyles.dropdownActionItem)}
          analyticsname="Download"
        >
          Download
        </a>
      )}
      <a
        onClick={handleRename}
        className={css(dropdownStyles.dropdownActionItem)}
        analyticsname="Rename"
      >
        Rename
      </a>
      {actions.delete && (
        <a
          onClick={handleDelete}
          className={css(dropdownStyles.dropdownActionItem)}
          analyticsname="Delete"
        >
          Delete
        </a>
      )}
      {hasDocumentSetBeenGenerated && documentSetHasTemplates && (
        <a
          onClick={handleDraftNew}
          className={css(dropdownStyles.dropdownActionItem)}
          analyticsname="Draft New"
        >
          Draft New
        </a>
      )}
    </Dropdown>
  );
};

const DocumentSetsTable = ({
  hideHeader,
  title,
  empty,
  columnWidths,
  noBorder,
  visibleItems,
  documentSets,
  onSearchChange,
  onSortByChange,
}) => {
  const user = useUser();
  const { isAdmin, isInternalUser } = useObserver(() => ({
    isAdmin: user.currentOrganization.admin,
    isInternalUser: user.currentOrganization.enableDevTools,
  }));

  const {
    deleteDocumentSet: handleDeleteDocumentSet,
    downloadDocumentSet: handleDownloadDocumentSet,
    editDocumentSet: handleEditDocumentSet,
    viewDocumentSet: handleViewDocumentSet,
    renameDocumentSet: handleRenameDocumentSet,
    newDocumentSet: handleDraftNewDocumentSet,
  } = useDocumentSetsActions();

  const rowActions = {
    ...((isAdmin || isInternalUser) && { delete: handleDeleteDocumentSet }),
    edit: handleEditDocumentSet,
    view: handleViewDocumentSet,
    rename: handleRenameDocumentSet,
    download: handleDownloadDocumentSet,
    draftNew: handleDraftNewDocumentSet,
  };

  const renderRowAction = (documentSet) => (
    <RowAction actions={rowActions} documentSet={documentSet} />
  );

  return (
    <>
      <div analyticsname="Document Set Actions">
        {title && <h1 className={css(styles.documentSetsTitle)}>{title}</h1>}
        <Table
          noBorder={noBorder}
          hideHeader={hideHeader}
          empty={empty}
          emptyTitle={'No document sets match that filter'}
          searchPlaceholder="Search document sets"
          renderActions={() => <DocumentSetActions />}
          data={documentSets}
          visibleItems={visibleItems}
          onSearchChange={onSearchChange}
          onSortByChange={onSortByChange}
          columns={[
            {
              label: 'Name',
              dataKey: 'title',
              style: {
                width: columnWidths[0],
                textAlign: 'left',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              },
            },
            {
              label: 'Documents',
              dataKey: 'numDocs',
              style: { width: columnWidths[1], textAlign: 'left' },
            },
            {
              label: 'Actions',
              hideLabel: true,
              render: renderRowAction,
              style: {
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'flex-end',
              },
            },
          ]}
        />
      </div>
    </>
  );
};

DocumentSetsTable.propTypes = {
  columnWidths: PropTypes.arrayOf(PropTypes.string),
  documentSets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
  ),
  visibleItems: PropTypes.number,
  rowActions: PropTypes.shape({
    delete: PropTypes.func,
  }),
};

DocumentSetsTable.defaultProps = {
  documentSets: [],
  columnWidths: ['60%', '10%'],
  visibleItems: 10,
  rowActions: {
    delete: () => {},
  },
};

export default DocumentSetsTable;
