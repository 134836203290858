/* Services */
import clioService from '~/src/services/api/clioService';

function clio() {
  const searchMatter = async (orgFprint, params) => {
    return clioService.searchMatter(orgFprint, params);
  };

  const authenticate = async (orgFprint, code) => {
    return clioService.authenticate(orgFprint, code);
  };

  const sync = async (orgFprint) => {
    return clioService.sync(orgFprint);
  };

  const matterSync = (orgFprint, matterId) => {
    return clioService.matterSync(orgFprint, matterId);
  };

  const bulkImport = async (orgFprint) => {
    return clioService.bulkImport(orgFprint);
  };

  const getClioIntegrationData = async (orgFprint) => {
    return clioService.clioIntegrationData(orgFprint);
  };

  const deauthenticate = async (orgFprint) => {
    return clioService.deauthenticate(orgFprint);
  };

  return {
    searchMatter,
    sync,
    matterSync,
    bulkImport,
    authenticate,
    deauthenticate,
    getClioIntegrationData,
  };
}

export default clio();
