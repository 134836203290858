/* Libraries */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import theme from '~/src/theme';
import qs from 'qs';
import clio from '~/src/services/clio';

/* Hooks */
import useFetchProject from '~/src/hooks/useFetchProject';
import useMst from '~/src/hooks/useMst';
import { useObserver } from 'mobx-react';

/* Components */
import Button from '~/src/components/Button';
import { Populate } from './Populate';
import PageLayout, { LoadingOverlay } from '~/src/components/PageLayout';
import EmptyView from '~/src/components/EmptyView';

/* Configurations */
import { useLayoutContext } from '~/src/contexts/Layout';
import { LAYOUT_SLIDEIN_TYPES } from '~/src/components/PageLayout/SlideIns';
import { CLIO_ERROR_STATE } from '../../utils/constants';
import ClioSyncErrorBanner from '../../components/Banner/ClioSyncErrorBanner';
import { LAYOUT_MODAL_TYPES } from '../../components/PageLayout/Modals';
import { clioManageMatterDocumentsLink } from '../../utils/clio';
import { useOrgFprint } from '../../hooks/useOrgFprint';
import { history } from '../../utils/history';
import { PopulateSidebar } from './Sidebar';
import { usePublishProject } from '~/src/pages/Populate/usePublishProject';
import { PopulateLoading } from './PopulateLoading';
import useFeatureFlags from '~/src/hooks/useFeatureFlags';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';

export const PopulatePage = (props) => {
  const orgFprint = useOrgFprint();
  const { projectId, initialProject } = props;
  const { isUnifiedPlatform } = useFeatureFlags();

  const { clioErrorState, clioStore } = useMst((store) => ({
    // eslint-disable-next-line react-hooks/rules-of-hooks
    clioErrorState: useObserver(() => store.clio.errorState),
    clioStore: store.clio,
  }));

  const { showSlideIn, showModal, showToast } = useLayoutContext();

  const [isClioMatterSyncing, setIsClioMatterSyncing] = useState(false);
  const { project, loading } = useFetchProject(projectId, initialProject);

  const { clio_matter_id, clio_parent_id } = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });

  const { publish, publishing } = usePublishProject({
    onSuccess: (documentId) => {
      if (isUnifiedPlatform) {
        history.push(`/drafting/${projectId}/`);
      } else {
        history.push(`/populate/${projectId}/document/${documentId}`);
      }
    },
    onError: (error) => {
      if (error.message === 'WORD_DOCUMENT_AUTOMATION') {
        // user not authorized for Word document automation (not subbed).
        showToast(LAYOUT_TOAST_TYPES.error, {
          message:
            'You need to be subscribed to word document automation to use word templates',
        });
        history.push('/settings/subscriptions');
        return;
      }

      showToast(LAYOUT_TOAST_TYPES.error, {
        message: 'Something went wrong, please try again later.',
      });
    },
  });

  const showNoCourtFormsModal = (clioMatterId) => {
    showModal(LAYOUT_MODAL_TYPES.error, {
      onSubmit: () =>
        (window.location.href = clioManageMatterDocumentsLink(clioMatterId)),
      messages: [
        "Your account doesn't have access to court form automation and therefore can't draft or edit court forms in Lawyaw.",
        'To add court form automation to your account, contact Lawyaw support.',
      ],
      submitButtonName: 'Return to Clio',
      title: 'Court forms not found',
    });
  };

  useEffect(() => {
    if (clio_matter_id) {
      setIsClioMatterSyncing(true);
      clio
        .matterSync(orgFprint, clio_matter_id)
        .then(({ matter_id }) => {
          clioStore.setCourtFormParams({
            syncedMatterId: matter_id,
            courtFormClioMatterId: clio_matter_id,
            courtFormClioParentId: clio_parent_id,
          });
        })
        .catch((err) => {
          if (err.statusCode === 403) {
            showNoCourtFormsModal(clio_matter_id);
          } else if (err.statusCode === 417) {
            clioStore.setErrorState(CLIO_ERROR_STATE.SYNC_NOT_ENABLED);
          } else if (err.statusCode === 404) {
            clioStore.setErrorState(CLIO_ERROR_STATE.MATTER_SYNC_NOT_ENABLED);
          } else {
            clioStore.setErrorState(CLIO_ERROR_STATE.GENERIC_SYNC);
          }
        })
        .finally(() => {
          setIsClioMatterSyncing(false);
        });
    } else if (!initialProject) {
      clioStore.clearCourtFormParams();
      clioStore.clearErrorState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && !project) {
      history.goBack();
    }

    if (!loading && project && project.cards.length > 0) {
      history.replace({ location: { state: { initialProject: false } } });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  function handleAttachContact(cardId) {
    showSlideIn(LAYOUT_SLIDEIN_TYPES.attachContact, { id: cardId });
  }

  if (isClioMatterSyncing && !loading) {
    return (
      <PageLayout title={project?.name || 'Loading...'}>
        <LoadingOverlay title="Syncing data from Clio..." relative={false} />
      </PageLayout>
    );
  }

  if (loading) {
    return (
      <PageLayout title={project?.name || 'Loading...'}>
        <LoadingOverlay title="Loading details" subtitle="Please wait" />
      </PageLayout>
    );
  }

  if (publishing) {
    return (
      <PageLayout title={project?.name || 'Loading...'}>
        <PopulateLoading />
      </PageLayout>
    );
  }

  const empty = !loading && (!project.cards || project.cards.length === 0);
  const shouldShowFooterAndSidebar = project && !empty;

  return (
    <PageLayout
      title={project?.name || 'Loading...'}
      sidebar={
        shouldShowFooterAndSidebar ? (
          <PopulateSidebar project={project} />
        ) : null
      }
      footer={
        shouldShowFooterAndSidebar ? (
          <span analyticsname="Document Generated">
            <Button primary onClick={() => publish(project)}>
              {project.cards?.length === 1
                ? 'Generate document'
                : 'Generate documents'}
            </Button>
          </span>
        ) : null
      }
    >
      {clioErrorState && <ClioSyncErrorBanner errorState={clioErrorState} />}
      {empty ? (
        <EmptyView
          title={'There are no fillable fields in this document'}
          paragraphs={[]}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ marginRight: `${theme.unit * 2}px` }}>
              <Link to="/library">
                <Button primary>Back to library</Button>
              </Link>
            </div>
            <Button onClick={() => publish(project)} discrete>
              Generate document
            </Button>
          </div>
        </EmptyView>
      ) : (
        <Populate project={project} onAttachContact={handleAttachContact} />
      )}
    </PageLayout>
  );
};
