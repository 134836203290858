/* Libraries */
import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { history } from '~/src/utils/history';
import Button from '~/src/components/Button';

/* Containers */
import LibraryContainer from '~/src/pages/Library/Library';
import TemplatesContainer from '~/src/containers/Templates';
import TemplateSetsContainer from '~/src/containers/TemplateSets';
import CourtFormsLandingContainer, {
  stateCodeToStateName,
} from '~/src/containers/CourtFormsLandingContainer';
import PdfTableContainer from '~/src/containers/PdfTableContainer';
import StateRegionTableContainer from '~/src/containers/StateRegionTableContainer';

import { denormalizeLocality } from '~/src/components/CourtForms/StateRegionTable';
import TemplateDefaultValuesListContainer from '~/src/containers/TemplateDefaultValuesList';
import Page, { LoadingOverlay } from '~/src/components/PageLayout';
import Tabs from '~/src/components/PageLayout/Tabs';
import { SelectionSidebarHeader } from '~/src/components/SelectionSidebar/SelectionSidebarHeader';
import { useCreateQuestionnaireTemplate } from '~/src/entities/Questionnaires';
import useMst from '~/src/hooks/useMst';
import { useGetQuestionnaireTemplates } from '~/src/entities/Questionnaires/hooks/useGetQuestionnaireTemplates';
import qs from 'qs';
import { useLayoutContext } from '~/src/contexts';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { useOrgFprint } from '~/src/hooks/useOrgFprint';

type Template = {
  defaultValueId: null | number;
  globalTemplateId: null | number;
  id: number;
  sortOrder: number;
  subtitle: string;
  title: string;
};

export const ClioQuestionnaireSelectDocumentsPage = ({
  questionnaireId,
}: {
  questionnaireId: string;
}) => {
  const tabs = (
    <Tabs
      tabsMetadata={[
        {
          headerText: 'Word templates',
          toUrl: `/questionnaires/${questionnaireId}/select-documents/templates`,
        },
      ]}
      active={undefined}
      setActive={undefined}
    />
  );

  const { initialize, addDocumentToSelectionSidebar } = useMst((store) => ({
    initialize: store.sidebarItems.initialize,
    addDocumentToSelectionSidebar:
      store.documents.addDocumentToSelectionSidebar,
  }));

  const orgFprint = useOrgFprint();
  const createQuestionnaireTemplate = useCreateQuestionnaireTemplate();
  const getQuestionnaireTemplates = useGetQuestionnaireTemplates(
    orgFprint,
    questionnaireId,
  );
  const { showToast } = useLayoutContext();

  const handleOnContinueClick = async (templates: Template[]) => {
    if (!getQuestionnaireTemplates.data) return;

    const templateIds = templates.map((doc) => doc.id);

    createQuestionnaireTemplate.mutateAsync(
      {
        orgFprint,
        questionnaireId,
        templateIds,
      },
      {
        onError: () => {
          showToast(LAYOUT_TOAST_TYPES.error, {
            message: 'Something went wrong, please try again later.',
          });
        },
      },
    );

    history.push(
      `/questionnaires/${questionnaireId}/builder?${qs.stringify({
        templateIds: templateIds,
      })}`,
    );
  };

  useEffect(() => {
    if (
      !getQuestionnaireTemplates.isLoading &&
      getQuestionnaireTemplates.data
    ) {
      getQuestionnaireTemplates.data.forEach((template) => {
        addDocumentToSelectionSidebar({
          id: template.template_id,
          title: `${template.template.title}`,
          subtitle: `${template.template.subtitle}`,
        });
      });
    }

    return () => {
      initialize();
    };
  }, [
    addDocumentToSelectionSidebar,
    getQuestionnaireTemplates.data,
    getQuestionnaireTemplates.isLoading,
    initialize,
  ]);

  if (createQuestionnaireTemplate.isError || getQuestionnaireTemplates.isError)
    return <div>Error, Failed to load document selection.</div>;

  if (
    createQuestionnaireTemplate.isLoading ||
    getQuestionnaireTemplates.isLoading
  )
    return (
      <LoadingOverlay title="Loading document selection..." relative={false} />
    );

  return (
    <Page
      showSelectionSidebar
      showAvatar={false}
      showNavigation={false}
      title={'Choose output documents'}
      headerProps={{
        tabs,
        showCloseButton: true,
        onClose: () => {
          history.replace('/questionnaires');
        },
      }}
      selectionSidebarProps={{
        renderHeader: (items: string | any[]) => (
          <SelectionSidebarHeader title={'Selection'} count={items.length} />
        ),
        renderFooter: (items: any[]) => (
          <div className="border-t p-3 flex flex-row-reverse bg-white">
            <Button
              primary
              disabled={items.length === 0}
              onClick={() => handleOnContinueClick(items)}
            >
              Continue →
            </Button>
          </div>
        ),
      }}
    >
      <LibraryContainer>
        <Switch>
          <Redirect
            exact
            from="/questionnaires/:questionnaireId/select-documents"
            to="/questionnaires/:questionnaireId/select-documents/templates"
          />

          <Route
            exact
            path="/questionnaires/:questionnaireId/select-documents/template-sets"
          >
            <TemplateSetsContainer />
          </Route>

          <Route
            exact
            path="/questionnaires/:questionnaireId/select-documents/court-forms"
          >
            <CourtFormsLandingContainer
              routeUrl={`/questionnaires/${questionnaireId}/select-documents/court-forms`}
            />
          </Route>
          <Route
            exact
            path="/questionnaires/:questionnaireId/select-documents/form-templates"
          >
            <TemplateDefaultValuesListContainer />
          </Route>
          <Route
            exact
            path="/questionnaires/:questionnaireId/select-documents/court-forms/federal_USCIS"
          >
            <PdfTableContainer
              routeUrl={`/questionnaires/${questionnaireId}/select-documents/court-forms`}
              filterCategory="FEDERAL"
            />
          </Route>
          <Route
            exact
            path="/questionnaires/:questionnaireId/select-documents/court-forms/:stateCode/form-listing"
            render={({ match: { params } }) => {
              const { stateCode } = params;
              return (
                <PdfTableContainer
                  routeUrl={`/questionnaires/${questionnaireId}/select-documents/court-forms`}
                  filterCategory={
                    stateCode as keyof typeof stateCodeToStateName
                  }
                  locality="None"
                />
              );
            }}
          />
          <Route
            exact
            path="/questionnaires/:questionnaireId/select-documents/court-forms/:stateCode/locality-listing"
            render={({ match: { params } }) => {
              const { stateCode } = params;
              return (
                <StateRegionTableContainer
                  routeUrl={`/questionnaires/${questionnaireId}/select-documents/court-forms`}
                  filterCategory={stateCode}
                />
              );
            }}
          />
          <Route
            exact
            path="/questionnaires/:questionnaireId/select-documents/court-forms/:stateCode/:locality/form-listing"
            render={({ match: { params } }) => {
              const { stateCode, locality: normalizedLocality } = params;
              const denormalizedLocality =
                denormalizeLocality(normalizedLocality);
              return (
                <PdfTableContainer
                  routeUrl={`/questionnaires/${questionnaireId}/select-documents/court-forms`}
                  filterCategory={
                    stateCode as keyof typeof stateCodeToStateName
                  }
                  locality={denormalizedLocality}
                />
              );
            }}
          />

          <Route
            exact
            path="/questionnaires/:questionnaireId/select-documents/templates/"
          >
            <TemplatesContainer onlyPreviewButton={true} />
          </Route>
        </Switch>
      </LibraryContainer>
    </Page>
  );
};
