/* Libraries */
import React from 'react';
import { css } from 'aphrodite';

/* Components */

import SubscriptionCancelationBanner from '~/src/containers/SubscriptionCancelationBanner';
import FreeTrialBannerContainer from '~/src/containers/FreeTrialBannerContainer';

/* right Sidebar Component */
import SelectionSidebar from '~/src/components/SelectionSidebar';

// Slide In Components
import PageSlideIns from '~/src/components/PageLayout/SlideIns';

// Modal Component
import PageModals from '~/src/components/PageLayout/Modals';

// Footer Components
import ActionFooter from '~/src/components/Footers/ActionFooter';
import QuestionnaireResponsePageFooter from '~/src/components/Footers/QuestionnaireResponsePageFooter';
import TemplateDefaultValuesDraftingFooter from '~/src/components/Footers/TemplateDefaultValuesDraftingFooter';

// Toast Components
import PageToasts from '~/src/components/PageLayout/Toasts';

/* Styles */
import { LAYOUT_FOOTER_TYPES } from '~/src/contexts/Layout';
import styles from './styles';

/* Constants */
import Footer from './Footer';
import Header, { HeaderProps } from './Header';
import Navigation from './Navigation';
import SubSidebar from './SubSidebar';
import Sidebar from './Sidebar';
import { useLayoutContext } from '~/src/contexts';
import { SelectionSidebarProps } from '../SelectionSidebar/SelectionSidebar';

export const FOOTERS = {
  [LAYOUT_FOOTER_TYPES.default]: () => <div />,
  [LAYOUT_FOOTER_TYPES.action]: ActionFooter,
  [LAYOUT_FOOTER_TYPES.questionnaireResponsePage]:
    QuestionnaireResponsePageFooter,
  [LAYOUT_FOOTER_TYPES.templateDefaultValuesDraftingFooter]:
    TemplateDefaultValuesDraftingFooter,
};

type PageProps = {
  title?: string;
  showNavigation?: boolean;
  showHeader?: boolean;
  showAvatar?: boolean;
  sidebar?: React.ReactNode;
  headerProps?: Partial<HeaderProps>;
  footer?: React.ReactNode;
  children: React.ReactNode;
  disablePadding?: boolean;
  disableHorizontalPadding?: boolean;
  showSelectionSidebar?: boolean;
  selectionSidebarProps?: Partial<SelectionSidebarProps>;
};

const Page = ({
  title,
  showNavigation = true,
  showHeader = true,
  showAvatar,
  sidebar,
  footer,
  selectionSidebarProps = {},
  showSelectionSidebar = false,
  children,
  headerProps,
  disablePadding,
  disableHorizontalPadding,
}: PageProps) => {
  const {
    title: layoutTitle,
    footerActive,
    footer: layoutFooter,
    footerProps,
  } = useLayoutContext();

  const FooterChild = layoutFooter
    ? FOOTERS[layoutFooter]
    : FOOTERS[LAYOUT_FOOTER_TYPES.default];

  const getMainTagCssClass = () => {
    if (disablePadding) {
      return css(styles.mainColumnBodyWithoutPadding);
    }
    if (disableHorizontalPadding) {
      return css(styles.mainColumnBodyWithoutHorizontalPadding);
    }
    return css(styles.mainColumnBody);
  };

  return (
    <div id="container" className={css(styles.container)}>
      {showNavigation && (
        <Sidebar>
          <Navigation />
        </Sidebar>
      )}
      <div className={css(styles.mainColumn)}>
        <FreeTrialBannerContainer />
        <SubscriptionCancelationBanner />
        {showHeader ? (
          <Header
            showAvatar={showAvatar}
            title={title || layoutTitle}
            {...headerProps}
          />
        ) : null}
        <div className={css(styles.mainColumnBodyContainer)}>
          {sidebar && <SubSidebar>{sidebar}</SubSidebar>}
          <main id="lawyaw-main-body" className={getMainTagCssClass()}>
            {children}
          </main>
          {showSelectionSidebar && (
            <SelectionSidebar {...selectionSidebarProps} />
          )}
        </div>

        {footerActive || footer ? (
          <Footer>{footer || <FooterChild {...footerProps} />}</Footer>
        ) : null}
      </div>
      {/* set sidebar version of this */}

      <PageSlideIns />

      <PageModals />

      <PageToasts />
    </div>
  );
};

export default Page;
