import React from 'react';
import moment from 'moment';
import { Box } from '@mui/material';
import {
  CardListItemWithActions,
  CardListItemMetaText,
} from '~/src/components/CardList';
import { Edit, Trash } from 'react-feather';
import { QuestionnaireStatusBadge } from '../QuestionnaireStatusBadge';
import { type QuestionnaireSubmission } from '@clio/questionnaire-builder';

export const QuestionnaireSubmissionTableCardList: React.FC<{
  submissions: QuestionnaireSubmission[];
  onDelete: (submission: QuestionnaireSubmission) => void;
  onViewDetails: (submission: QuestionnaireSubmission) => void;
}> = ({ submissions, onDelete, onViewDetails }) => {
  return (
    <>
      {submissions.map((submission) => {
        const actions = [
          {
            label: 'Rename',
            icon: <Edit size={16} />,
            onClick: () => {},
          },
          {
            label: 'Delete',
            icon: <Trash size={16} />,
            onClick: () => onDelete(submission),
          },
        ];

        return (
          <Box pb={1} key={submission.id}>
            <CardListItemWithActions
              key={submission.id}
              onClick={() => {}}
              title={{
                label: `${submission.questionnaire.title}`,
                meta: [submission.questionnaire.description],
              }}
              meta={
                <Box display={'flex'} gap={1} alignItems={'center'}>
                  <QuestionnaireStatusBadge status={submission.status} />
                  <CardListItemMetaText>
                    Last updated:{' '}
                    {moment(submission.updated_at).format('MM/DD/YYYY')}
                  </CardListItemMetaText>
                </Box>
              }
              actions={{
                actions,
                primary: {
                  label: 'View details',
                  disabled: false,
                  onClick: () => onViewDetails(submission),
                },
              }}
            />
          </Box>
        );
      })}
    </>
  );
};
