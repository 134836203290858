import { useEffect } from 'react';

import { LOCK_STYLES, STYLE_KEYS } from './constants';
import {
  allowTouchMove,
  isTouchDevice,
  preventInertiaScroll,
  preventTouchMove,
} from './utils';

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

let activeScrollLocks = 0;

const ScrollLock = (props) => {
  const { accountForScrollbars, touchScrollTarget } = props;

  const originalStyles = {};
  const listenerOptions = {
    capture: false,
    passive: false,
  };

  const applyScrollLockStyles = () => {
    if (!canUseDOM) {
      return;
    }

    const target = document.body;
    const targetStyle = target && target.style;

    if (accountForScrollbars) {
      // store any styles already applied to the body
      STYLE_KEYS.forEach((key) => {
        const val = targetStyle && targetStyle[key];
        originalStyles[key] = val;
      });
    }

    // apply the lock styles and padding if this is the first scroll lock
    if (accountForScrollbars && activeScrollLocks < 1) {
      const currentPadding = parseInt(originalStyles.paddingRight, 10) || 0;
      const clientWidth = document.body ? document.body.clientWidth : 0;
      const adjustedPadding =
        window.innerWidth - clientWidth + currentPadding || 0;

      Object.keys(LOCK_STYLES).forEach((key) => {
        const val = LOCK_STYLES[key];
        if (targetStyle) {
          targetStyle[key] = val;
        }
      });

      if (targetStyle) {
        targetStyle.paddingRight = `${adjustedPadding}px`;
      }
    }

    // account for touch devices
    if (target && isTouchDevice()) {
      // Mobile Safari ignores { overflow: hidden } declaration on the body.
      target.addEventListener('touchmove', preventTouchMove, listenerOptions);

      // Allow scroll on provided target
      if (touchScrollTarget) {
        touchScrollTarget.addEventListener(
          'touchstart',
          preventInertiaScroll,
          listenerOptions,
        );
        touchScrollTarget.addEventListener(
          'touchmove',
          allowTouchMove,
          listenerOptions,
        );
      }
    }

    // increment active scroll locks
    activeScrollLocks += 1;
  };

  const removeLockStyles = () => {
    if (!canUseDOM) {
      return;
    }

    const target = document.body;
    const targetStyle = target && target.style;

    // safely decrement active scroll locks
    activeScrollLocks = Math.max(activeScrollLocks - 1, 0);

    // reapply original body styles, if any
    if (accountForScrollbars && activeScrollLocks < 1) {
      STYLE_KEYS.forEach((key) => {
        const val = originalStyles[key];
        if (targetStyle) {
          targetStyle[key] = val;
        }
      });
    }

    // remove touch listeners
    if (target && isTouchDevice()) {
      target.removeEventListener(
        'touchmove',
        preventTouchMove,
        listenerOptions,
      );

      if (touchScrollTarget) {
        touchScrollTarget.removeEventListener(
          'touchstart',
          preventInertiaScroll,
          listenerOptions,
        );
        touchScrollTarget.removeEventListener(
          'touchmove',
          allowTouchMove,
          listenerOptions,
        );
      }
    }
  };

  /* Lifecycles */
  // On Mount
  useEffect(() => {
    applyScrollLockStyles();
    return removeLockStyles;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

ScrollLock.defaultProps = {
  accountForScrollbars: true,
};

export default ScrollLock;
