import React from 'react';

import { ReactComponent as IconError } from '~/src/static/icon-error.svg';
import { CLIO_ERROR_STATE } from '~/src/utils/constants';

const ClioSyncErrorBanner = (props: { errorState: string }) => {
  let title: string;
  let message: React.ReactFragment;

  switch (props.errorState) {
    case CLIO_ERROR_STATE.SYNC_NOT_ENABLED:
      title = 'Sync not detected';
      message = (
        <>
          Complete the setup steps to connect your Clio Manage and Lawyaw
          accounts found{' '}
          <a href="https://support.clio.com/hc/en-us/articles/360002573354-How-To-Connect-Clio-to-Lawyaw">
            here
          </a>
        </>
      );
      break;
    case CLIO_ERROR_STATE.MATTER_SYNC_NOT_ENABLED:
      title = 'Unable to sync matter';
      message =
        'We were unable to sync your latest matter and client details from Clio Manage. Please check your Clio matter permissions and try again.';
      break;
    case CLIO_ERROR_STATE.GENERIC_SYNC:
      title = 'Sync error';
      message =
        'We were unable to sync your Clio Manage data to Lawyaw. Please check that all information is accurate and up to date before proceeding. ';
      break;
    default:
      return null;
  }

  return (
    <div className="px-6 py-4 pr-10 border border-red-300 bg-red-50 mb-5 rounded-lg relative">
      <div>
        <div className="flex">
          <div className="font-semibold flex items-center">
            <div className="mr-2 flex">
              <IconError />
            </div>
            <h2>{title}</h2>
          </div>
        </div>
        <div className="text-sm mt-2">
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};

export default ClioSyncErrorBanner;
