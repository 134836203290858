/* Libraries */
import React from 'react';
import { css } from 'aphrodite';

/* Components */
import Spinner from '~/src/components/Spinner';

/* Styles */
import styles from './styles';

export const PopulateLoading = () => (
  <div className={css(styles.container)}>
    <div>
      {/* TODO: replace with large loader */}
      <Spinner />
      <h1 className={css(styles.title)}>Generating your document</h1>
      <h2 className={css(styles.subtitle)}>This may take a few seconds</h2>
    </div>
  </div>
);
