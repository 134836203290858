/* Libraries */
import React from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { css } from 'aphrodite';

/* Higher Order Components */
import WithLayoutProps from '~/src/hoc/WithLayoutProps';

/* Components */
import Button from '~/src/components/Button';
import { Form, renderFormField } from '~/src/components/Forms';

/* Styles */
import { VALIDATION_TYPES } from '~/src/utils/validation';
import styles from './styles';

/* Utils */
import { LAYOUT_TOAST_TYPES } from '../PageLayout/Toasts';

const defaultState = {
  form: { valid: false },
  submitting: false,
  submissionSuccess: false,
  submissionError: false,
  errors: {},
  emailFields: [
    {
      id: 'email:0',
      hint: 'Email',
      type: 'email',
      className: css(styles.inviteTeamMemberModalEmailLabel),
      noMargin: true,
      validation: VALIDATION_TYPES.email,
    },
  ],
};

class InviteTeamMembers extends React.Component {
  state = defaultState;

  handleCancel = () => {
    this.setState(defaultState);
    this.props.onCancel();
  };

  handleAddEmail = () => {
    const nextEmailFields = this.state.emailFields;

    nextEmailFields.push({
      id: `email:${nextEmailFields.length + 1}`,
      type: 'text',
      removeable: true,
      onRemove: this.handleRemoveEmail,
      className: css(styles.inviteTeamMemberModalEmailLabel),
      noMargin: true,
      validation: VALIDATION_TYPES.email,
    });

    this.setState({ emailFields: nextEmailFields });
  };

  handleRemoveEmail = ({ id }) => {
    const nextEmailFields = _.filter(this.state.emailFields, (field) => {
      return field.id !== id;
    });

    this.setState({ emailFields: nextEmailFields });
  };

  handleSubmit = async () => {
    const { fields } = this.state.form;

    const emails = [];
    const isAdmin = fields.role.value === 'admin';

    Object.keys(fields).forEach((fieldKey) => {
      const field = fields[fieldKey];
      if (
        field.id.indexOf('email') !== -1 &&
        field.valid &&
        field.value.length > 0
      ) {
        emails.push(field.value);
      }
    });

    this.setState({ submitting: true });
    const res = await this.props.store.user.currentOrganization.inviteUsers(
      emails.join(', '),
      isAdmin,
    );

    if (!res) {
      this.props.showToast(LAYOUT_TOAST_TYPES.error);
      this.setState({ submitting: false });
    } else {
      this.setState({ submissionSuccess: true });
      this.props.showToast(LAYOUT_TOAST_TYPES.success, {
        message: 'Invitations sent!',
      });
      this.handleCancel();
      this.setState(defaultState);
    }
  };

  setErrors(fields) {
    const nextEmailFields = [...this.state.emailFields];

    const fieldKeys = Object.keys(fields);
    const fieldValues = fieldKeys.map((key) => `${fields[key].value}`);

    Object.keys(fields).forEach((key) => {
      const field = fields[key];
      const isEmail = field.id.indexOf('email:') !== -1;

      let error = null;

      const valueDuplicates = fieldValues.reduce(function (
        matches,
        value,
        index,
      ) {
        if (value.trim() === field.value.trim()) {
          matches.push(index);
        }
        return matches;
      },
      []);

      if (isEmail && !field.valid && field.touched && field.value.length > 0) {
        error = 'Not a valid email';
      } else if (field.value.length > 0 && valueDuplicates.length >= 2) {
        error = 'Duplicate email';
      }

      const emailField = _.find(nextEmailFields, ['id', field.id]);

      if (emailField) {
        emailField.error = error;
      }
    });

    this.setState({ emailFields: nextEmailFields });
  }

  render() {
    const { emailFields, form, submitting, submissionSuccess } = this.state;
    const { onCancel } = this.props;

    const memberCount =
      this.props.store.user.currentOrganization.members.length;
    const subscriptionSeats =
      this.props.store.user.currentOrganization.getTotalSeats();
    const seatsRemaining = subscriptionSeats - memberCount;

    if (seatsRemaining === 0) {
      emailFields.map((field) => ({
        ...field,
        disabled: true,
      }));
    }

    const fields = [
      ...emailFields,
      {
        id: 'addEmail',
        type: 'staticLink',
        defaultValue: '+ Add Email',
        disabled: seatsRemaining <= 1,
        onClick: this.handleAddEmail,
        validation: () => true,
      },
      {
        id: 'role',
        hint: 'Role',
        type: 'multipleChoice',
        defaultValue: 'member',
        options: [
          { label: 'Member', value: 'member' },
          { label: 'Admin', value: 'admin' },
        ],
        validation: () => true,
      },
    ];

    const renderFields = fields.map((field) => renderFormField(field));

    const handleFormChange = (form) => {
      this.setState({ form });
      this.setErrors(form.fields);
    };

    let buttonText = 'Send Invites';

    if (submitting) {
      buttonText = 'Sending Invites...';
    }

    if (submissionSuccess) {
      buttonText = 'Invites Sent!';
    }

    const seatLabel = seatsRemaining === 1 ? 'seat' : 'seats';

    return (
      <div className={css(styles.modalContainer)}>
        <h1 className={css(styles.modalTitle)}>Invite teammates</h1>
        <h2 className={css(styles.modalSubtitle)}>
          You have {seatsRemaining} {seatLabel} remaining
        </h2>
        <div className={css(styles.modalBody)}>
          <Form
            triggerOnMount
            disabled={submitting}
            fields={renderFields}
            onChange={handleFormChange}
          />
        </div>
        <div className={css(styles.modalActions)}>
          <Button cssStyle={styles.buttonAction} onClick={onCancel}>
            Cancel
          </Button>{' '}
          <Button
            cssStyle={styles.buttonAction}
            onClick={this.handleSubmit}
            disabled={!form.valid || seatsRemaining === 0}
            primary
          >
            {buttonText}
          </Button>
        </div>
      </div>
    );
  }
}

export default inject((store) => store)(
  WithLayoutProps(observer(InviteTeamMembers)),
);
