import { Instance } from 'mobx-state-tree';
import { useState, useEffect } from 'react';

import { useStore } from '~/src/hooks/useMst';
import { Project } from '../stores/projectsStore';

const useFetchProject = (
  projectId: number,
  initialProject = false,
  getPDFElements = false,
) => {
  const [, setLoading] = useState(false);
  const { projects } = useStore();

  const [project, setProject] = useState<
    Instance<typeof Project> | undefined
  >();

  useEffect(() => {
    setLoading(true);
    if (initialProject) {
      setProject(projects.getProject(projectId));
      setLoading(false);
    } else {
      projects
        .fetchProject(projectId, getPDFElements)
        .then((nextProject: Instance<typeof Project>) => {
          setProject(nextProject);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return {
    project,
    loading: !project,
  };
};

export default useFetchProject;
