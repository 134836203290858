import React, { useState } from 'react';
import { composeMutationOpts } from '~/src/entities/utils';
import { useSyncSingleMatter } from '~/src/hooks/useSyncSingleMatter';
import ImportFromClioModal from './ImportFromClioModal';
import { useLayoutContext } from '~/src/contexts/Layout';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { ClioMatter } from '~/src/services/api/clioService';

export const useImportFromClioModal = (
  options: Parameters<typeof useSyncSingleMatter>[0] = {},
  extraProps: { allowSearch?: boolean },
) => {
  const [open, setOpen] = useState(false);

  const { showToast } = useLayoutContext();
  const [selectedMatter, setSelectedMatter] = useState<ClioMatter>();

  const {
    mutate: sync,
    isLoading,
    isError,
    isSuccess,
    reset,
  } = useSyncSingleMatter(
    composeMutationOpts(options, {
      onSuccess: () => {
        setOpen(false);
        showToast(LAYOUT_TOAST_TYPES.success, {
          message: 'Import succeeded',
        });
      },
    }),
  );

  const show = (matter?: ClioMatter) => {
    setSelectedMatter(matter);
    setOpen(true);
  };

  const onAfterClose = () => {
    setSelectedMatter(undefined);
    reset();
  };

  const modal = (
    <ImportFromClioModal
      {...extraProps}
      onSelectMatter={setSelectedMatter}
      selectedMatter={selectedMatter}
      open={open}
      sync={() => sync({ clio_matter_id: `${selectedMatter!.id}` })}
      isLoading={isLoading}
      isError={isError}
      isSuccess={isSuccess}
      onAfterClose={onAfterClose}
      onClose={() => setOpen(false)}
    />
  );

  return [{ show }, modal] as const;
};
