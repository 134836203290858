/* Libraries */
import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

const styles = StyleSheet.create({
  card: {
    display: 'inline-flex',
    textAlign: 'left',
    marginBottom: theme.unit * 2,
    maxWidth: theme.unit * 75,
  },
  modalContent: {
    textAlign: 'left',
  },
});

export default styles;
