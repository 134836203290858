import React from 'react';

import CreateContactSlideIn from '~/src/components/Contacts/CreateContactSlideIn';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';

import { PERSON_TYPE } from '~/src/stores/contactsStore';

class CreateContactSlideInContainer extends React.Component {
  state = {
    formFields: [],
    newContactForm: {},
    type: PERSON_TYPE,
  };

  componentDidMount() {
    this.setFormFields();
  }

  handleCancelContact = () => {
    this.setState({ selectedContactId: null });
    this.props.hideSlideIn();
  };

  handleNewContactChange = (formValues) => {
    const nextState = {
      disabled: true,
    };

    if (
      formValues.fields &&
      formValues.fields.type &&
      formValues.fields.type.value
    ) {
      nextState.type = formValues.fields.type.value;
    }

    const lastNameValue =
      formValues.fields &&
      formValues.fields.lastName &&
      formValues.fields.lastName.value;
    const hasLastNameValue = lastNameValue && lastNameValue.trim().length >= 1;

    const disabled = !hasLastNameValue;

    nextState.disabled = disabled;
    nextState.newContactForm = formValues;

    this.setState(nextState);
    setTimeout(this.setFormFields, 0);
  };

  handleCreateNewContact = async () => {
    const fieldValues = {};

    Object.keys(this.state.newContactForm.fields).forEach((key) => {
      fieldValues[key] = this.state.newContactForm.fields[key].value;
    });

    const newContact = await this.props.store.contacts.createNewContact(
      fieldValues,
    );

    if (this.props.onCreate) {
      this.props.onCreate(newContact);
    }

    this.handleCancelContact();
  };

  setFormFields = async () => {
    const { fieldValues } = this.props;
    const formFields = await this.props.store.contacts.getCreateFormFields(
      this.state.type,
      fieldValues,
    );

    this.setState({ formFields });
  };

  render() {
    const { disabled, formFields } = this.state;
    const { fieldValues } = this.props;

    return (
      <CreateContactSlideIn
        disabled={disabled}
        formFields={formFields}
        fieldValues={fieldValues}
        onChange={this.handleNewContactChange}
        onSubmit={this.handleCreateNewContact}
        onCancel={this.handleCancelContact}
      />
    );
  }
}

CreateContactSlideInContainer.propTypes = {
  store: MobxPropTypes.observableObject,
};

export default inject((store) => store)(
  observer(CreateContactSlideInContainer),
);
