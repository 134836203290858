import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ClioIcon } from 'static/clio-logo-icon.svg';
import { css, StyleSheet } from 'aphrodite';

import { ReactComponent as SuccessCheckCircle } from 'static/success-check-circle.svg';
import { ReactComponent as PlusIcon } from 'static/icon-plus.svg';

const styles = StyleSheet.create({
  field: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // padding: "20px",
    flex: '1 0 auto',
    ':hover': {
      backgroundColor: '#E9F0F7',
      cursor: 'pointer',
    },
  },
  fieldColumn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fieldColumnCommands: {
    flex: '0 0 auto',
    marginLeft: '10px',
  },
  fieldColumnFieldData: {
    flex: '0 1 auto',
  },
  fieldIcon: {},
  fieldIconImage: {
    marginRight: '8px',
    maxWidth: '20px',
    marginBottom: '-3px',
  },
  fieldName: {
    // fontSize: "15px",
    maxWidth: '250px',
    // lineHeight: "1.25em",
  },
  fieldCounter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20px',
    width: '20px',
    backgroundColor: '#1F4160',
    color: 'white',
    borderRadius: '2px',
    marginRight: '10px',
    fontSize: '11px',
    flex: '1 0 auto',
  },
});

const EntityField = (props) => {
  const { selected, tag, type, prompt, source, onSelect } = props;

  // const [entityId, fieldId] = tag.split('.');
  // const fieldName = startCase(fieldId);

  const externalSource = source === 'clio';

  const Icon = selected ? SuccessCheckCircle : PlusIcon;

  const handleClick = () => {
    onSelect({
      tag,
      type,
      source,
      prompt,
    });
  };

  return (
    <div
      className={`p-4 ${css(styles.field, selected && styles.selected)}`}
      onClick={handleClick}
    >
      <div className={css(styles.fieldColumn, styles.fieldColumnFieldData)}>
        {/* <div className={css(styles.fieldCounter)}>
          <span>{0}</span>
        </div> */}
        {externalSource && (
          <i className={css(styles.fieldIcon)}>
            <ClioIcon className={css(styles.fieldIconImage)} />{' '}
          </i>
        )}
        <div className={`text-sm ${css(styles.fieldName)}`}>
          <span>{prompt}</span>
        </div>
      </div>
      <div className={css(styles.fieldColumn, styles.fieldColumnCommands)}>
        <Icon />
      </div>
    </div>
  );
};

EntityField.propTypes = {
  source: PropTypes.string,
  type: PropTypes.string,
  prompt: PropTypes.string,
  tag: PropTypes.string,
};

export default EntityField;
