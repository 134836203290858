import { FormControlLabel, Checkbox, Box, TextField } from '@mui/material';
import * as React from 'react';
import { Edited } from '~/src/components/Inputs/Edit';
import analyticsService from '~/src/services/analytics';
import { RevertButton } from './RevertButton';

type MultipleChoiceIndividualInputProps = {
  label: string;
  isChecked: boolean;
  isEdited: boolean;
  onChange: () => void;
  onRevert: () => void;
  onBlur: () => void;
  textToMergeValue: string;
  onChangeTextToMergeValue: (value: string) => void;
  showTextToMerge?: boolean;
  inputRef?: (element: any) => void;
};

export const MultipleChoiceIndividualInput = ({
  label,
  isChecked,
  isEdited,
  onChange,
  onRevert,
  onBlur,
  textToMergeValue,
  onChangeTextToMergeValue,
  showTextToMerge,
  inputRef,
}: MultipleChoiceIndividualInputProps) => {
  const isInErrorState = !textToMergeValue;

  return (
    <>
      <FormControlLabel
        sx={{ marginLeft: 0 }}
        slotProps={{ typography: { variant: 'body1' } }}
        onChange={onChange}
        checked={isChecked}
        label={<Box pl={1}>{label}</Box>}
        control={<Checkbox inputRef={inputRef} disabled={isInErrorState} />}
      />
      {showTextToMerge ? (
        <>
          <TextField
            error={isInErrorState}
            size={'small'}
            value={textToMergeValue}
            onChange={(e) => {
              analyticsService.track('Populate Page Field Interaction', {
                fieldType: 'multi_select',
                textToMergeValueEdited: true,
              });
              onChangeTextToMergeValue(e.target.value);
            }}
            onBlur={onBlur}
            helperText={
              isInErrorState ? (
                <Box
                  component={'span'}
                  display={'flex'}
                  justifyContent={'space-between'}
                >
                  <Box component={'span'}>
                    Please enter a custom value or select reset changes
                  </Box>
                  <RevertButton
                    data-testid={`${textToMergeValue}-reset-changes`}
                    onClick={onRevert}
                  />
                </Box>
              ) : undefined
            }
            fullWidth
            multiline
          />
          <Box
            py={1}
            visibility={isEdited && !isInErrorState ? 'visible' : 'hidden'}
            display={'flex'}
            justifyContent={'flex-end'}
            alignItems={'center'}
          >
            <Edited />
            <RevertButton
              data-testid={`${textToMergeValue}-reset-changes`}
              onClick={onRevert}
            />
          </Box>
        </>
      ) : null}
    </>
  );
};
