import React from 'react';
import { Divider, Button, Box } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { useMissingFields } from '../../../useMissingFields';
import { ProjectStackFieldGroup } from '~/src/entities/project';

type SaveAsNewContactProps = {
  onClick: (firstName: string, lastName: string) => void;
  group: ProjectStackFieldGroup;
};

export const SaveAsNewContactButton = ({
  onClick,
  group,
}: SaveAsNewContactProps) => {
  const values = useWatch({ name: group.gid }) || {};
  const hasAtLeastOneFieldFilled =
    useMissingFields(group.fields).length < group.fields.length;

  if (!hasAtLeastOneFieldFilled) return null;

  return (
    <>
      <Divider />
      <Box display={'flex'} justifyContent={'flex-end'} px={3} py={2}>
        <Button
          onClick={() => onClick(values['first_name'], values['last_name'])}
          variant={'contained'}
        >
          Save as new contact
        </Button>
      </Box>
    </>
  );
};
