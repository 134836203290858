import React from 'react';
import Button from '~/src/components/Button';
import { css } from 'aphrodite';
import PropTypes from 'prop-types';

import styles from './styles';

const Confirmation = ({ mattersCount, contactsCount, onCancel, onConfirm }) => (
  <div className={css(styles.modalContainer)}>
    <h1 className={css(styles.modalTitle)}>Import data from Clio</h1>
    <p className={css(styles.modalMessage, styles.modalMessageCenterVertical)}>
      You are about to import {contactsCount} contacts and {mattersCount}{' '}
      matters into Lawyaw
    </p>
    <p className={css(styles.modalMessage, styles.modalMessageCenterVertical)}>
      Be sure to remove any unused matters, contacts or custom fields before you
      import your data.
    </p>
    <div className={css(styles.modalActions)}>
      <Button cssStyle={styles.buttonAction} primary onClick={onCancel}>
        Cancel
      </Button>{' '}
      <Button cssStyle={styles.buttonAction} onClick={onConfirm}>
        Import Data
      </Button>
    </div>
  </div>
);

Confirmation.propTypes = {
  mattersCount: PropTypes.number,
  contactsCount: PropTypes.number,
};

Confirmation.defaultProps = {
  mattersCount: 0,
  contactsCount: 0,
};

export default Confirmation;
