import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { history } from '~/src/utils/history';
import qs from 'qs';

import {
  QuestionnaireBuilderPage,
  ClioQuestionnairePage,
  ClioQuestionnaireSelectDocumentsPage,
  QuestionnaireSubmissionPage,
} from '~/src/pages/ClioQuestionnaire';

const ClioQuestionnaires: React.FC = () => {
  const { search } = useLocation();
  const templateIds =
    (qs.parse(search, { ignoreQueryPrefix: true })?.templateIds as string[]) ||
    ([] as string[]);

  return (
    <Switch>
      <Redirect exact from="/questionnaires" to="/questionnaires/all" />
      <Route
        path={'/questionnaires/:questionnaireId/select-documents'}
        render={({ match }) => (
          <ClioQuestionnaireSelectDocumentsPage
            questionnaireId={match.params.questionnaireId}
          />
        )}
      />
      <Route
        path={'/questionnaires/:questionnaireId/builder'}
        render={({ match }) => (
          <QuestionnaireBuilderPage
            questionnaireId={match.params.questionnaireId}
            templateIds={templateIds}
          />
        )}
      />
      <Route
        path={'/questionnaires/:questionnaireId/pending/:submissionId'}
        render={({ match }) => (
          <QuestionnaireSubmissionPage
            onExit={() => {
              history.push('/questionnaires/pending');
            }}
            submissionId={match.params.submissionId}
            questionnaireId={match.params.questionnaireId}
          />
        )}
      />
      <Route
        path={'/questionnaires/:questionnaireId/submitted/:submissionId'}
        render={({ match }) => (
          <QuestionnaireSubmissionPage
            onExit={() => {
              history.push('/questionnaires/submitted');
            }}
            submissionId={match.params.submissionId}
            questionnaireId={match.params.questionnaireId}
          />
        )}
      />
      <ClioQuestionnairePage />
    </Switch>
  );
};

export default ClioQuestionnaires;
