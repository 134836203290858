/* Libraries */
import React from 'react';
import { observer } from 'mobx-react';

import { StyleSheet, css } from 'aphrodite';
import theme from '~/src/theme';
import PopulateSidebarItem from './PopulateSidebarItem';
import { Instance } from 'mobx-state-tree';
import { Project } from '~/src/stores/projectsStore';

const styles = StyleSheet.create({
  populateSidebarContainer: {
    padding: theme.unit * 2,
  },
});

type PopulateSidebarProps = { project: Instance<typeof Project> };

export const PopulateSidebar = observer(({ project }: PopulateSidebarProps) => {
  const handleCardClick = (cardId: string) => {
    project.cards.forEach((card) => {
      if (card.id === cardId) {
        card.setFocus(true, true);
      } else {
        card.setFocus(false);
      }
    });
  };

  return (
    <div className={css(styles.populateSidebarContainer)}>
      {(project.cards || []).map((card) => (
        <PopulateSidebarItem
          key={card.id}
          card={card}
          onCardClick={handleCardClick}
        />
      ))}
    </div>
  );
});
