import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useStore } from '~/src/hooks/useMst';

export const SentryProvider = ({ children }: { children: React.ReactNode }) => {
  const store = useStore();
  useEffect(() => {
    const { user } = store;
    const email = user.currentOrganization?.email;
    const organizationName = user.currentOrganization?.organizationName;
    const orgFprint = user.currentOrganization?.fprint;
    const subscription = user.currentOrganization?.subscription;
    const orgId = user.currentOrganization?.orgId;

    Sentry.configureScope((scope) => {
      scope.setContext('organization', { id: orgId });
      scope.setUser({
        email,
        id: `${user.profile?.id}`,
        orgFprint,
        isPaid: subscription?.status === 'active',
        firstName: user.profile?.firstName,
        lastName: user.profile?.lastName,
        middleName: user.profile?.middleName,
        orgName: organizationName,
        otpEnabled: user.profile?.ssoEnabled,
        otpMethod: user.profile?.ssoMethod,
        phone: user.profile?.phone,
        needsToSubscribe: !subscription,
      });
    });
  }, [store]);

  return <>{children}</>;
};
