import { useQuery, UseQueryOptions } from 'react-query';
import { APIError } from '~/src/utils/error';
import { composeQueryOpts } from '../../utils';
import { getTagMapsUrl } from '../urls';

export const useDocumentMapTags = (
  orgFprint: string,
  selectedIds: { template_ids: string[] },
  options: UseQueryOptions<any, APIError> = {},
) =>
  useQuery(
    composeQueryOpts(options, {
      queryKey: ['documentMapTags', selectedIds],
      queryFn: async () => {
        const response = await fetch(getTagMapsUrl(orgFprint), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(selectedIds),
        });
        if (!response.ok)
          throw new APIError(
            'Failed to retrieve tags for selected documents',
            response,
          );
        return response.json();
      },
    }),
  );
