import { useQuery, UseQueryOptions } from 'react-query';
import { keys } from '../keys';
import { getProjectUrl } from '../urls';
import { Project } from '../types';
import { APIError } from '~/src/utils/error';
import { composeQueryOpts } from '../../utils';
import { useCurrentOrgFprint } from '../../user';

export const useProject = (
  projectId: number,
  options: UseQueryOptions<Project, APIError, Project> = {},
) => {
  const orgFprint = useCurrentOrgFprint();
  return useQuery(
    composeQueryOpts(options, {
      queryKey: keys.detail(projectId),
      queryFn: async () => {
        const response = await fetch(getProjectUrl(orgFprint, projectId));

        if (!response.ok)
          throw new APIError(`Failed to fetch project ${projectId}`, response);
        return response.json() as Promise<Project>;
      },
    }),
  );
};
