import { APIError } from '~/src/utils/error';
import { getQuestionnaireGenerateLink } from '~/src/entities/Questionnaires/urls';
import {
  getClioQuestionnaireAuthToken,
  questionnaireAuthorizationHeaders,
} from '../utils';
import { useMutation } from 'react-query';
import { useInvalidateQuery } from '../../utils';
import { submissionQueryArgs } from '../queryArgs';

type UseClioQuestionnaireShareLink = {
  orgFprint: string;
  questionnaireId: string;
  matterId: string;
};

export const useGetClioQuestionnaireUrl = () =>
  useMutation<string, APIError, UseClioQuestionnaireShareLink>({
    onSuccess: useInvalidateQuery(submissionQueryArgs.all),
    mutationFn: async ({ orgFprint, questionnaireId, matterId }) => {
      const token = await getClioQuestionnaireAuthToken(orgFprint);
      const response = await fetch(
        getQuestionnaireGenerateLink(questionnaireId),
        {
          method: 'POST',
          headers: {
            ...questionnaireAuthorizationHeaders(token),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ target_identifier: matterId }),
        },
      );
      if (!response.ok)
        throw new APIError(
          'Failed to retrieve questionnaire link for selected questionnaire',
          response,
        );

      return response.text();
    },
  });
