export const TOTAL_DOCUMENT_LIMIT = 20;

export const OCCUPATION_LIST = [
  { value: 'Attorney', label: 'Attorney' },
  { value: 'Paralegal', label: 'Paralegal' },
  { value: 'Admin', label: 'Admin' },
  { value: 'Student', label: 'Student' },
  { value: 'Not a Legal Professional', label: 'Not a Legal Professional' },
  { value: 'Other', label: 'Other' },
];

export const PRACTICE_AREAS = [
  { value: 'Administrative', label: 'Administrative' },
  { value: 'Bankruptcy', label: 'Bankruptcy' },
  { value: 'Builders Liens', label: 'Builders Liens' },
  { value: 'Business', label: 'Business' },
  { value: 'Civil Litigation', label: 'Civil Litigation' },
  { value: 'Commercial', label: 'Commercial' },
  { value: 'Conveyance (Purchase)', label: 'Conveyance (Purchase)' },
  { value: 'Conveyance (Sale)', label: 'Conveyance (Sale)' },
  { value: 'Corporate', label: 'Corporate' },
  { value: 'Criminal', label: 'Criminal' },
  { value: 'Employment', label: 'Employment' },
  { value: 'Estates', label: 'Estates' },
  { value: 'Family', label: 'Family' },
  { value: 'Immigration', label: 'Immigration' },
  { value: 'Insurance', label: 'Insurance' },
  { value: 'Personal Injury', label: 'Personal Injury' },
  { value: 'Tax', label: 'Tax' },
  { value: 'Wills', label: 'Wills' },
];

export const US_AND_CANADA_STATES = [
  { label: 'Alabama', value: 'Alabama' },
  { label: 'Alaska', value: 'Alaska' },
  { label: 'Arizona', value: 'Arizona' },
  { label: 'Arkansas', value: 'Arkansas' },
  { label: 'California', value: 'California' },
  { label: 'Colorado', value: 'Colorado' },
  { label: 'Connecticut', value: 'Connecticut' },
  { label: 'Delaware', value: 'Delaware' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Hawaii', value: 'Hawaii' },
  { label: 'Idaho', value: 'Idaho' },
  { label: 'Illinois', value: 'Illinois' },
  { label: 'Indiana', value: 'Indiana' },
  { label: 'Iowa', value: 'Iowa' },
  { label: 'Kansas', value: 'Kansas' },
  { label: 'Kentucky', value: 'Kentucky' },
  { label: 'Louisiana', value: 'Louisiana' },
  { label: 'Maine', value: 'Maine' },
  { label: 'Maryland', value: 'Maryland' },
  { label: 'Massachusetts', value: 'Massachusetts' },
  { label: 'Michigan', value: 'Michigan' },
  { label: 'Minnesota', value: 'Minnesota' },
  { label: 'Mississippi', value: 'Mississippi' },
  { label: 'Missouri', value: 'Missouri' },
  { label: 'Montana', value: 'Montana' },
  { label: 'Nebraska', value: 'Nebraska' },
  { label: 'Nevada', value: 'Nevada' },
  { label: 'New Hampshire', value: 'New Hampshire' },
  { label: 'New Jersey', value: 'New Jersey' },
  { label: 'New Mexico', value: 'New Mexico' },
  { label: 'New York', value: 'New York' },
  { label: 'North Carolina', value: 'North Carolina' },
  { label: 'North Dakota', value: 'North Dakota' },
  { label: 'Ohio', value: 'Ohio' },
  { label: 'Oklahoma', value: 'Oklahoma' },
  { label: 'Oregon', value: 'Oregon' },
  { label: 'Pennsylvania', value: 'Pennsylvania' },
  { label: 'Rhode Island', value: 'Rhode Island' },
  { label: 'South Carolina', value: 'South Carolina' },
  { label: 'South Dakota', value: 'South Dakota' },
  { label: 'Tennessee', value: 'Tennessee' },
  { label: 'Texas', value: 'Texas' },
  { label: 'Utah', value: 'Utah' },
  { label: 'Vermont', value: 'Vermont' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'Washington', value: 'Washington' },
  { label: 'Washington, D.C.', value: 'Washington, D.C.' },
  { label: 'West Virginia', value: 'West Virginia' },
  { label: 'Wisconsin', value: 'Wisconsin' },
  { label: 'Wyoming', value: 'Wyoming' },
  // Canadian provinces
  { label: 'Ontario', value: 'Ontario' },
  { label: 'Quebec', value: 'Quebec' },
  { label: 'Nova Scotia', value: 'Nova Scotia' },
  { label: 'New Brunswick', value: 'New Brunswick' },
  { label: 'Manitoba', value: 'Manitoba' },
  { label: 'British Columbia', value: 'British Columbia' },
  { label: 'Prince Edward Island', value: 'Prince Edward Island' },
  { label: 'Saskatchewan', value: 'Saskatchewan' },
  { label: 'Alberta', value: 'Alberta' },
  {
    label: 'Newfoundland and Labrador',
    value: 'Newfoundland and Labrador',
  },
  // Not US or Canada
  { label: 'Other', value: 'Other' },
];

export const NATIONAL_COURT_FORM_NAME_MAP = {
  uscis: 'USCIS FORMS',
  bc: 'BRITISH COLUMBIA FORMS',
};

export const COURT_FORM_ANNUAL_PLANS = [
  {
    name: 'USCIS FORMS',
    id: 'FEDERAL_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'CALIFORNIA FORMS',
    id: 'CA_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'TEXAS FORMS',
    id: 'TX_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'ILLINOIS FORMS',
    id: 'IL_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'NEW YORK FORMS',
    id: 'NY_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'FLORIDA FORMS',
    id: 'FL_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'GEORGIA FORMS',
    id: 'GA_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'OHIO FORMS',
    id: 'OH_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'PENNSYLVANIA FORMS',
    id: 'PA_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'MARYLAND FORMS',
    id: 'MD_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'ARIZONA FORMS',
    id: 'AZ_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'MICHIGAN FORMS',
    id: 'MI_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'WASHINGTON FORMS',
    id: 'WA_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'WISCONSIN FORMS',
    id: 'WI_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'MASSACHUSETTS FORMS',
    id: 'MA_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'NEW JERSEY FORMS',
    id: 'NJ_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'NORTH CAROLINA FORMS',
    id: 'NC_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'COLORADO FORMS',
    id: 'CO_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'MINNESOTA FORMS',
    id: 'MN_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'CONNECTICUT FORMS',
    id: 'CT_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'NEVADA FORMS',
    id: 'NV_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'IOWA FORMS',
    id: 'IA_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'UTAH FORMS',
    id: 'UT_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'RHODE ISLAND FORMS',
    id: 'RI_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'DELAWARE FORMS',
    id: 'DE_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'KENTUCKY FORMS',
    id: 'KY_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'NEW MEXICO FORMS',
    id: 'NM_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'INDIANA FORMS',
    id: 'IN_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'MISSOURI FORMS',
    id: 'MO_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'OKLAHOMA FORMS',
    id: 'OK_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'MAINE FORMS',
    id: 'ME_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'VIRGINIA FORMS',
    id: 'VA_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'HAWAII FORMS',
    id: 'HI_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'OREGON FORMS',
    id: 'OR_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'IDAHO FORMS',
    id: 'ID_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'ALABAMA FORMS',
    id: 'AL_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'NEBRASKA FORMS',
    id: 'NE_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'SOUTH CAROLINA FORMS',
    id: 'SC_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'ARKANSAS FORMS',
    id: 'AR_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'LOUISIANA FORMS',
    id: 'LA_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'WEST VIRGINIA FORMS',
    id: 'WV_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'NORTH DAKOTA FORMS',
    id: 'ND_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'SOUTH DAKOTA FORMS',
    id: 'SD_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'TENNESSEE FORMS',
    id: 'TN_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'ALASKA FORMS',
    id: 'AK_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'VERMONT FORMS',
    id: 'VT_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'WYOMING FORMS',
    id: 'WY_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'NEW HAMPSHIRE FORMS',
    id: 'NH_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'MISSISSIPPI FORMS',
    id: 'MS_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'KANSAS FORMS',
    id: 'KS_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'MONTANA FORMS',
    id: 'MT_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
  {
    name: 'DISTRICT OF COLUMBIA FORMS',
    id: 'DC_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },

  {
    name: 'BRITISH COLUMBIA FORMS',
    id: 'CA_BC_JC_FORMS_YEARLY',
    interval: 'year',
    recurring: true,
    price: 400,
    discount: 80,
  },
].sort((plan1, plan2) => {
  // Sort order is -> USCIS then BC then states alphabetically
  // placeholder until we get a better plan in place for subscription/signup

  // Comparing upper case for consistency and since localeCompare is case sensitive
  const plan1Upper = plan1.name.toString().toUpperCase();
  const plan2Upper = plan2.name.toString().toUpperCase();
  // First handle national forms with USCIS first then BC
  if (plan1Upper === NATIONAL_COURT_FORM_NAME_MAP.uscis) {
    return -1;
  }
  if (plan2Upper === NATIONAL_COURT_FORM_NAME_MAP.uscis) {
    return 1;
  }
  if (plan1Upper === NATIONAL_COURT_FORM_NAME_MAP.bc) {
    return -1;
  }
  if (plan2Upper === NATIONAL_COURT_FORM_NAME_MAP.bc) {
    return 1;
  }
  // Finally localeCompare handles default alphabetic sorting for us
  // (i.e. it handles sorting Arizona before of California)
  return plan1Upper.localeCompare(plan2Upper);
});

export const COURT_FORM_MONTHLY_PLANS = [
  {
    name: 'USCIS FORMS',
    id: 'FEDERAL_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'CALIFORNIA FORMS',
    id: 'CA_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'TEXAS FORMS',
    id: 'TX_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'ILLINOIS FORMS',
    id: 'IL_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'NEW YORK FORMS',
    id: 'NY_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'FLORIDA FORMS',
    id: 'FL_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'GEORGIA FORMS',
    id: 'GA_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'OHIO FORMS',
    id: 'OH_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'PENNSYLVANIA FORMS',
    id: 'PA_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'MARYLAND FORMS',
    id: 'MD_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'ARIZONA FORMS',
    id: 'AZ_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'MICHIGAN FORMS',
    id: 'MI_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'WASHINGTON FORMS',
    id: 'WA_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'WISCONSIN FORMS',
    id: 'WI_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'MASSACHUSETTS FORMS',
    id: 'MA_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'NEW JERSEY FORMS',
    id: 'NJ_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'NORTH CAROLINA FORMS',
    id: 'NC_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'COLORADO FORMS',
    id: 'CO_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'MINNESOTA FORMS',
    id: 'MN_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'CONNECTICUT FORMS',
    id: 'CT_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'NEVADA FORMS',
    id: 'NV_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'IOWA FORMS',
    id: 'IA_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'UTAH FORMS',
    id: 'UT_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'RHODE ISLAND FORMS',
    id: 'RI_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'DELAWARE FORMS',
    id: 'DE_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'KENTUCKY FORMS',
    id: 'KY_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'NEW MEXICO FORMS',
    id: 'NM_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'INDIANA FORMS',
    id: 'IN_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'MISSOURI FORMS',
    id: 'MO_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'OKLAHOMA FORMS',
    id: 'OK_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'MAINE FORMS',
    id: 'ME_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'VIRGINIA FORMS',
    id: 'VA_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'HAWAII FORMS',
    id: 'HI_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'OREGON FORMS',
    id: 'OR_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'IDAHO FORMS',
    id: 'ID_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'ALABAMA FORMS',
    id: 'AL_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'NEBRASKA FORMS',
    id: 'NE_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'SOUTH CAROLINA FORMS',
    id: 'SC_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'ARKANSAS FORMS',
    id: 'AR_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'LOUISIANA FORMS',
    id: 'LA_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'WEST VIRGINIA FORMS',
    id: 'WV_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'NORTH DAKOTA FORMS',
    id: 'ND_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'SOUTH DAKOTA FORMS',
    id: 'SD_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'TENNESSEE FORMS',
    id: 'TN_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'ALASKA FORMS',
    id: 'AK_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'VERMONT FORMS',
    id: 'VT_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'WYOMING FORMS',
    id: 'WY_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'NEW HAMPSHIRE FORMS',
    id: 'NH_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'MISSISSIPPI FORMS',
    id: 'MS_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'KANSAS FORMS',
    id: 'KS_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'MONTANA FORMS',
    id: 'MT_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
  {
    name: 'DISTRICT OF COLUMBIA FORMS',
    id: 'DC_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },

  {
    name: 'BRITISH COLUMBIA FORMS',
    id: 'CA_BC_JC_FORMS_MONTHLY',
    interval: 'mo',
    recurring: true,
    price: 40,
  },
].sort((plan1, plan2) => {
  // Sort order is -> USCIS then BC then states alphabetically
  // placeholder until we get a better plan in place for subscription/signup

  // Comparing upper case for consistency and since localeCompare is case sensitive
  const plan1Upper = plan1.name.toString().toUpperCase();
  const plan2Upper = plan2.name.toString().toUpperCase();
  // First handle national forms with USCIS first then BC
  if (plan1Upper === NATIONAL_COURT_FORM_NAME_MAP.uscis) {
    return -1;
  }
  if (plan2Upper === NATIONAL_COURT_FORM_NAME_MAP.uscis) {
    return 1;
  }
  if (plan1Upper === NATIONAL_COURT_FORM_NAME_MAP.bc) {
    return -1;
  }
  if (plan2Upper === NATIONAL_COURT_FORM_NAME_MAP.bc) {
    return 1;
  }
  // Finally localeCompare handles default alphabetic sorting for us
  // (i.e. it handles sorting Arizona before of California)
  return plan1Upper.localeCompare(plan2Upper);
});

export const COURT_FORM_PLANS = {
  monthly: COURT_FORM_MONTHLY_PLANS,
  annual: COURT_FORM_ANNUAL_PLANS,
};

export const DOCUMENT_AUTOMATION_PLANS = {
  monthly: [
    {
      name: 'WORD DOCUMENT AUTOMATION',
      id: 'WORD_DOCUMENT_AUTOMATION_MONTHLY',
      interval: 'mo',
      recurring: true,
      price: 80,
    },
    {
      name: 'VIP ONBOARDING SERVICE',
      id: 'VIP_MONTHLY',
      interval: 'one-time',
      recurring: false,
      price: 799,
    },
  ],
  annual: [
    {
      name: 'WORD DOCUMENT AUTOMATION',
      id: 'WORD_DOCUMENT_AUTOMATION_YEARLY',
      interval: 'year',
      recurring: true,
      price: 800,
      discount: 160,
    },
    {
      name: 'VIP ONBOARDING SERVICE',
      id: 'VIP_YEARLY',
      interval: 'one-time',
      recurring: false,
      price: 750,
      discount: 49,
    },
  ],
};

export const ONE_TIME_PLANS = {
  monthly: 'VIP_MONTHLY',
  annual: 'VIP_YEARLY',
};

export const SEATS_MONTHLY_PRICE = 30;
export const SEATS_YEARLY_PRICE = 300;

export const COUPON = {
  CLIO_SSO_FREE_TRIAL: 'mxTpcrjN', // 10% coupon code for Clio SSO
};

export const CLIO_ERROR_STATE = {
  GENERIC_SYNC: 'something went wrong with your sync',
  MATTER_SYNC_NOT_ENABLED: 'matter sync not enabled',
  SYNC_NOT_ENABLED: 'sync not enabled',
};
