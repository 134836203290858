import React from 'react';
import PropTypes from 'prop-types';

import { document, getWindowDimensions } from '~/src/utils/window';

import { css } from 'aphrodite';

import _ from 'lodash';
import styles from './styles';

const defaultState = {
  open: false,
  position: 'bottom',
};

class Dropdown extends React.Component {
  state = defaultState;

  dropdownRef = React.createRef();

  containerRef = React.createRef();

  handleDocumentClickEvent = (event) => {
    if (
      event &&
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(event.target) &&
      !event.defaultPrevented
    ) {
      this.hideDropdown();
      document.removeEventListener('click', this.handleDocumentClickEvent);
    }
  };

  hideDropdown = () => {
    this.setState({ open: false });
  };

  showDropdown = () => {
    this.setState({ open: true });
    document.addEventListener('click', this.handleDocumentClickEvent);
    this.checkBoundingRec();
  };

  checkBoundingRec = () => {
    const ref = this.containerRef;
    if (ref.current) {
      const boundingRec = ref.current.getBoundingClientRect();
      const windowDimensions = getWindowDimensions();
      const pixelsFromBottomViewport = windowDimensions.y - boundingRec.bottom;

      if (pixelsFromBottomViewport <= 150) {
        this.setState({ position: 'top' });
      } else {
        this.setState({ position: defaultState.position });
      }
    }
  };

  toggleDropdown = () => {
    if (this.state.open) {
      this.hideDropdown();
    } else {
      this.showDropdown();
    }
  };

  render() {
    const { renderTrigger, children, width, horizontalPosition, className } =
      this.props;

    const { open, position } = this.state;

    const renderTriggerProps = {
      showDropdown: this.showDropdown,
      hideDropdown: this.hideDropdown,
      toggleDropdown: this.toggleDropdown,
      open,
    };

    const dropdownPositionStyle = styles[_.camelCase(`dropdown ${position}`)];
    const dropdownHorizontalPositionStyle =
      styles[_.camelCase(`dropdown ${horizontalPosition}`)];

    return (
      <div
        style={{ position: 'relative' }}
        className={className}
        ref={this.containerRef}
      >
        {renderTrigger(renderTriggerProps)}
        <div
          style={{ width }}
          className={css(
            styles.dropdown,
            dropdownPositionStyle,
            dropdownHorizontalPositionStyle,
            !open && styles.dropdownClose,
          )}
          ref={this.dropdownRef}
        >
          {children}
        </div>
      </div>
    );
  }
}

Dropdown.propTypes = {
  renderTrigger: PropTypes.func,
  horizontalPosition: PropTypes.string,
};

Dropdown.defaultProps = {
  renderTrigger: () => {},
  horizontalPosition: 'right',
};

export default Dropdown;
