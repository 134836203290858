/* Libraries */
import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type ContactDeleteWarningModalProps = {
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
};

const ContactDeleteWarningModal = ({
  open,
  onCancel,
  onConfirm,
}: ContactDeleteWarningModalProps) => (
  <Dialog open={open} onClose={onCancel}>
    <DialogTitle>Delete Contact?</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to permanently delete this contact? This action
        cannot be undone. If this is a contact synced from Clio, you will not be
        able to resync it.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>Cancel</Button>
      <Button onClick={onConfirm} variant="contained" autoFocus>
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);

export default ContactDeleteWarningModal;
