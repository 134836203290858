/* Libraries */
import React, { FC, useEffect } from 'react';
import { snakeCase, uniqBy } from 'lodash';
import { Instance } from 'mobx-state-tree';

/* Hooks */
import useMst from '~/src/hooks/useMst';
import usePageScroll from '~/src/hooks/usePageScroll';
import useTemplatesPagination from '~/src/hooks/useTemplatesPagination';
import useFeatureFlags from '~/src/hooks/useFeatureFlags';

/* Components */
import Templates from './Templates';
import TemplatesEmptyView from './TemplatesEmptyView';

import LoadMoreDisplayText from '~/src/components/LoadMoreDisplayText';

/* Types */
import { SortByOrder } from '~/src/hooks/sortByOrder';
import { TemplateStore } from '~/src/stores/templateStore';

interface Template extends Instance<typeof TemplateStore> {}

interface Actions {
  rename?: (templateSet: Template) => void;
  delete?: (templateSet: Template) => void;
  preview?: (templateSet: Template) => void;
}

interface Props {
  addTemplateToSeletionSidebar: (t: Template) => void;
  onRowClick?: (templateIds: number[]) => void;
  rowActions?: Actions;
  hideDraftButton?: boolean;
  onlyPreviewButton?: boolean;
}

const TemplatesWrapper: FC<Props> = ({
  addTemplateToSeletionSidebar,
  onRowClick,
  rowActions,
  hideDraftButton = false,
  onlyPreviewButton = false,
}) => {
  const { getSelectedTemplates } = useMst((store) => ({
    getSelectedTemplates: store.templates.getSelectedTemplates,
    subscription: store.user.currentOrganization?.subscription,
  }));

  const { isAddinLongDocumentsEnabled } = useFeatureFlags();

  const { next, loading, empty, done, data, setSearch, setSortBy, setOrder } =
    useTemplatesPagination();

  const pageScroll = usePageScroll();

  const loadMore = next;

  const handleOnPageScroll = () => {
    if ((pageScroll === 0 || pageScroll >= 85) && !loading) {
      loadMore();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleOnPageScroll, [pageScroll]);

  const tableData = data.map((template: Template) => ({
    id: template.id,
    title: template.title,
    documentCategoryId: template.documentType,
    updatedAt: template.formattedUpdatedAt(),
    selectedState: { selected: template.selected, id: template.id },
    isBaseTemplate: template.isBaseTemplate,
    children: isAddinLongDocumentsEnabled
      ? uniqBy(
          template.includes.map((include) => ({
            id: include.templateId,
            title: include.templateName,
          })),
          'id',
        )
      : [],
  }));

  const handleSearchChange = (searchValue: string) => {
    setSearch(searchValue);
  };

  const handleSortByChange = (property: string, order: number) => {
    if (!property) {
      return;
    }

    const orderLabel = order > 0 ? SortByOrder.desc : SortByOrder.asc;
    let sortBy = snakeCase(property);

    if (property === 'updatedAt') {
      sortBy = 'last_modified';
    }

    setSortBy(sortBy);
    setOrder(orderLabel);
  };

  if (empty) {
    return (
      <>
        <TemplatesEmptyView />
      </>
    );
  }

  const selectedTemplateIds = getSelectedTemplates().map(
    (template: Template) => template.id,
  );

  return (
    <>
      <Templates
        templates={tableData}
        visibleItems={tableData.length}
        empty={!loading && done && tableData.length === 0}
        rowActions={rowActions}
        onRowClick={onRowClick}
        onAddTemplateToSeletionSidebar={addTemplateToSeletionSidebar}
        selectedTemplateIds={selectedTemplateIds}
        onSearchChange={handleSearchChange}
        onSortByChange={handleSortByChange}
        hideDraftButton={hideDraftButton}
        onlyPreviewButton={onlyPreviewButton}
      />
      <LoadMoreDisplayText
        loading={loading}
        done={done}
        onLoadMore={loadMore}
      />
    </>
  );
};

export default TemplatesWrapper;
