import qs from 'querystring';
import { http } from '~/src/services/requests';

function tagsService() {
  return {
    fetchTags: async (orgFprint, { templateIds, tagIds }) => {
      const parse = true;
      const queryParams = {};

      if (templateIds) queryParams.template_ids = templateIds;
      if (tagIds) queryParams.normalized_tag_ids = tagIds;

      const query = qs.stringify(queryParams);

      return http.innerSendAuthenticated(
        {
          relativePath: `/intake_form/org/${orgFprint}/tag_v2/?${query}`,
          method: 'GET',
        },
        parse,
      );
    },
  };
}

export default tagsService();
