import { useQuery, UseQueryOptions } from 'react-query';
import { APIError } from '~/src/utils/error';
import clioService, {
  ClioMatter,
  MatterQueryParameters,
} from '~/src/services/api/clioService';
import { composeQueryOpts, useInvalidateQuery } from '../utils';
import { keys } from './keys';
import analyticsService from '~/src/services/analytics';

export const useInvalidateClioMatters = () => useInvalidateQuery(keys.all);

export const useClioMatters = (
  params: MatterQueryParameters,
  options: UseQueryOptions<
    ClioMatter[],
    APIError,
    ClioMatter[],
    ReturnType<typeof keys.list>
  > = {},
) =>
  useQuery(
    composeQueryOpts(options, {
      keepPreviousData: true,
      queryKey: keys.list(params),
      queryFn: async ({ queryKey }) => {
        const { state } = queryKey[1];
        const { orgFprint, ...rest } = state;
        const start = Date.now();
        const result = await clioService.searchMatter(orgFprint, rest);
        analyticsService.track('Clio Matter Search Time', {
          time: Date.now() - start,
        });
        return result;
      },
    }),
  );
