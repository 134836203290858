import * as React from 'react';
import { Box, Button, ButtonProps } from '@mui/material';
import {
  CardListItemIconAction,
  CardListItemIconActions,
} from './CardListItemIconActions';

export type CardListItemActionsProps = {
  primary?: {
    label: string;
    onClick: () => void;
  } & ButtonProps;
  actions?: CardListItemIconAction[];
};

export const CardListItemActions = ({
  primary,
  actions = [],
}: CardListItemActionsProps) => {
  const { onClick, label, ...rest } = primary || {};
  return (
    <Box display={'flex'} gap={1} alignItems={'center'}>
      {onClick ? (
        <Button
          {...rest}
          variant={'contained'}
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
        >
          {label}
        </Button>
      ) : null}
      <CardListItemIconActions actions={actions} />
    </Box>
  );
};
