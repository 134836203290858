import React from 'react';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';

/* Higher Order Components */
import WithLayoutProps from '~/src/hoc/WithLayoutProps';

/* Components */
import CreditCardForm from '~/src/components/CreditCardForm';
import Button from '~/src/components/Button';

/* Styles */
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import styles from './styles';

/* Constants */

const PaymentMethodForm = ({ loading, onCancel, onChange, onSubmit }) => {
  return (
    <div className={css(styles.modalContainer)}>
      <h1 className={css(styles.modalTitle)}>Update payment method</h1>
      <div className={css(styles.modalBody)}>
        <CreditCardForm onChange={onChange} />
      </div>
      <div className={css(styles.modalActions)}>
        <Button cssStyle={styles.buttonAction} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          cssStyle={styles.buttonAction}
          disabled={loading}
          primary
        >
          {loading ? 'Saving...' : 'Submit'}
        </Button>
      </div>
    </div>
  );
};

class CreateSubscriptionModal extends React.Component {
  state = {
    paymentFormValue: {},
  };

  handlePaymentMethodChange = (form) => {
    this.setState({
      paymentFormValue: form,
    });
  };

  handlePaymentMethodSubmit = async () => {
    const formFields = this.state.paymentFormValue.fields;

    const ccExpMonth = formFields.exp.value.split('/')[0];
    const ccExpYear = formFields.exp.value.split('/')[1];
    const addressZip = formFields.address_zip.value;
    const addressCity = formFields.address_city.value;
    const addressState = formFields.address_state.value;
    const cvc = formFields.cvc.value;
    const number = formFields.number.value;

    const card = {
      number,
      cvc,
      exp_month: ccExpMonth,
      exp_year: ccExpYear,
      address_zip: addressZip,
      address_state: addressState,
      address_city: addressCity,
    };

    const res =
      await this.props.store.user.currentOrganization.updatePaymentMethod({
        card,
      });

    if (res) {
      this.props.showToast(LAYOUT_TOAST_TYPES.success, {
        message: 'Card updated!',
      });
      this.props.onCancel();
    } else {
      this.props.showToast(LAYOUT_TOAST_TYPES.error, {
        message: 'Something went wrong, please try again later.',
      });
    }
  };

  handleCancel = () => {
    if (this.state.showPaymentMethod) {
      this.setState({ showPaymentMethod: false });
    } else {
      this.props.onCancel();
    }
  };

  render() {
    const loading = this.props.store.user.currentOrganization.isLoading();
    return (
      <PaymentMethodForm
        loading={loading}
        onCancel={this.handleCancel}
        onChange={this.handlePaymentMethodChange}
        onSubmit={this.handlePaymentMethodSubmit}
      />
    );
  }
}

export default inject((store) => store)(
  WithLayoutProps(observer(CreateSubscriptionModal)),
);
