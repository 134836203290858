import React, { useEffect, useState } from 'react';
import { css } from 'aphrodite';
import { camelCase } from 'lodash';
import { Box, Card, Stack } from '@mui/material';
import Label from '../Label';
import styles from '../styles';
import { MultipleChoiceIndividualInput } from './MultipleChoiceIndividualInput';
import { Edited, EditIconButton } from '../Edit';

type MultipleChoiceCheckboxProps = {
  label: string;
  value: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  onFocus: () => void;
  hint?: string;
  count?: string;
  options: Array<{ label: string; value: string }>;
};

const tryToParseJson = (value: string) => {
  try {
    return JSON.parse(value);
  } catch {
    return '';
  }
};

export const MultipleChoiceCheckbox = React.forwardRef<
  unknown,
  MultipleChoiceCheckboxProps
>(({ label, value, onChange, onBlur, onFocus, hint, count, options }, _) => {
  const id = camelCase(label);
  const [isEditing, setIsEditing] = useState(false);

  let stackSavedValue: string[] =
    tryToParseJson(value) || Array.from({ length: options.length });

  const isStackDataCompatible = stackSavedValue.length === options.length;
  if (!isStackDataCompatible) {
    stackSavedValue = Array.from({ length: options.length });
  }
  useEffect(() => {
    if (isStackDataCompatible) return;
    onChange(JSON.stringify(Array.from({ length: options.length })));
  }, [isStackDataCompatible, onChange, options.length]);

  const [textToMergeValues, setTextToMergeValues] = useState<string[]>(() => {
    return options.map((opt, idx) => stackSavedValue[idx] || opt.value);
  });

  const isOptionChecked = (idx: number) => !!stackSavedValue[idx];

  const handleChange = (idx: number, nextValue: string) => {
    const nextValues = stackSavedValue.map((original, i) =>
      i === idx ? nextValue : original,
    );
    onChange(JSON.stringify(nextValues));
  };

  const canClose = textToMergeValues.every((v) => !!v);
  const isEdited = textToMergeValues.some(
    (v, idx) => v && v !== options[idx]?.value,
  );

  return (
    <Card
      sx={(theme) => ({
        border: `1px solid ${isEditing ? theme.palette.border : 'transparent'}`,
        marginBottom: theme.spacing(3),
      })}
      elevation={isEditing ? 3 : 0}
    >
      <Box p={isEditing ? 2 : 0}>
        <Label
          label={
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <span>{label}</span>
              <Box display={'flex'}>
                {isEdited ? (
                  <Box pr={1}>
                    <Edited />
                  </Box>
                ) : null}
                <EditIconButton
                  disabled={!canClose}
                  isEditing={isEditing}
                  onClick={() => setIsEditing(!isEditing)}
                />
              </Box>
            </Box>
          }
          hint={hint}
          count={count}
        >
          <fieldset
            className={css(styles.multipleChoiceFieldset)}
            id={id}
            onFocus={() => setTimeout(onFocus, 1)}
            onBlur={onBlur}
          >
            <Box>
              <Stack direction={'column'} spacing={1}>
                {options.map((field, idx) => {
                  const textValue = textToMergeValues[idx] || '';
                  const isChecked = isOptionChecked(idx);
                  const defaultValue = field.value;
                  return (
                    <MultipleChoiceIndividualInput
                      key={field.label}
                      label={field.label}
                      isChecked={isChecked}
                      showTextToMerge={isEditing}
                      onChange={() =>
                        handleChange(idx, isChecked ? '' : textValue)
                      }
                      isEdited={options[idx]?.value !== textValue}
                      textToMergeValue={textValue}
                      onChangeTextToMergeValue={(value) => {
                        setTextToMergeValues((values) =>
                          values.map((text, i) => (i === idx ? value : text)),
                        );
                      }}
                      onRevert={() => {
                        setTextToMergeValues((values) =>
                          values.map((text, i) =>
                            i === idx ? defaultValue : text,
                          ),
                        );
                        if (!isChecked) return;
                        handleChange(idx, defaultValue);
                      }}
                      onBlur={() => {
                        if (!isChecked) return;
                        handleChange(idx, textValue);
                      }}
                    />
                  );
                })}
              </Stack>
            </Box>
          </fieldset>
        </Label>
      </Box>
    </Card>
  );
});

export default MultipleChoiceCheckbox;
