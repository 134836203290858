import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  Project,
  ProjectStackDocument,
  ProjectStackFieldGroup,
} from '~/src/entities/project';
import { SectionsList } from './SectionsList';
import { SidebarHeader } from './SidebarHeader';
import { getDocumentsForFields } from './utils';

export type SidebarProps = {
  project: Project;
  matterName: string;
  onClickDocument: (document: ProjectStackDocument) => void;
  expandedSection?: string;
  onExpandSection: (group: ProjectStackFieldGroup) => void;
};

export const Sidebar = ({
  project,
  matterName,
  onClickDocument,
  expandedSection = '',
  onExpandSection,
}: SidebarProps) => {
  return (
    <Box p={1} bgcolor={'white'}>
      <Box p={2}>
        <SidebarHeader matterName={matterName} />
      </Box>
      <Box px={2}>
        <Divider
          sx={{
            borderColor: '#E3E3E3',
          }}
        />
      </Box>
      <Box pb={2} pt={1.25}>
        <Accordion TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary
            sx={(theme) => ({
              '&:hover': {
                background: theme.palette.blue[50],
              },
            })}
            expandIcon={
              <ExpandMore
                sx={(theme) => ({
                  color: theme.palette.grey[900],
                })}
              />
            }
          >
            <Typography variant="label">
              Documents ({project.stack_data.documents.length})
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingBottom: 0 }}>
            {project.stack_data.documents.map((document, i) => (
              <Box
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
                key={document.docid}
                pb={i !== project.stack_data.documents.length - 1 ? 1 : 0}
                onClick={() => onClickDocument(document)}
              >
                <Typography variant={'caption'}>
                  {document.title}
                  {document.subtitle
                    ? ', ' + document.subtitle
                    : document.subtitle}
                </Typography>
                {i !== project.stack_data.documents.length - 1 ? (
                  <Divider
                    sx={(theme) => ({
                      marginTop: theme.spacing(1),
                      borderColor: '#E3E3E3',
                    })}
                  />
                ) : null}
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
        <Box px={2} pb={1}>
          <Divider
            sx={(theme) => ({
              marginTop: theme.spacing(1),
              borderColor: '#E3E3E3',
            })}
          />
        </Box>
        <Accordion defaultExpanded TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary
            sx={(theme) => ({
              '&:hover': {
                background: theme.palette.blue[50],
              },
            })}
            expandIcon={
              <ExpandMore
                sx={(theme) => ({
                  color: theme.palette.grey[900],
                })}
              />
            }
          >
            <Typography variant="label">
              Cards ({project.stack_data.groups.length})
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box mx={-2}>
              <SectionsList
                expanded={expandedSection}
                onClickSection={onExpandSection}
                onClickDocument={onClickDocument}
                getDocumentsForFields={(fields) =>
                  getDocumentsForFields(fields, project.stack_data.documents)
                }
                sections={project.stack_data.groups}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};
