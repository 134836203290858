/* Constants */
import useMst from '~/src/hooks/useMst';

/* Constants */
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { VALIDATION_TYPES } from '~/src/utils/validation';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { useLayoutContext } from '../../contexts/Layout';
import { history } from '../../utils/history';

const useMattersActions = () => {
  const { matterStore } = useMst((store) => ({
    matterStore: store.matter,
  }));

  const { showToast, showModal, hideModal } = useLayoutContext();

  const handleViewMatter = (matter) => {
    matterStore.viewMatter(matter.id);
    history.push({
      pathname: `/matter/${matter.id}`,
      state: { title: matterStore.getMatter(matter.id)?.fullTitle() },
    });
  };

  const handleRenameMatter = async (matter) => {
    const handleRenameConfirm = async (form) => {
      const { value } = form.fields.name;
      await matterStore.updateMatter(matter.id, { title: value });
      showToast(LAYOUT_TOAST_TYPES.success, {
        message: `Matter ${value} successfully renamed.`,
      });
      hideModal();
    };

    showModal(LAYOUT_MODAL_TYPES.formField, {
      title: 'Rename Matter',
      primaryActionTitle: 'Save',
      fields: [
        {
          label: 'Name',
          id: 'name',
          type: 'text',
          defaultValue: matter.name,
          validation: VALIDATION_TYPES.present,
        },
      ],
      onConfirm: handleRenameConfirm,
    });
  };

  const handleDeleteMatter = (matter) => {
    const handleDeleteConfirm = async () => {
      matterStore.deleteMatter(matter.id);
      hideModal();
    };

    showModal(LAYOUT_MODAL_TYPES.confirm, {
      title: `Delete Matter ${matter.title}?`,
      message: `Are you sure you want to permanently delete this matter? This action cannot be undone.`,
      onConfirm: handleDeleteConfirm,
      primaryActionTitle: 'Delete',
    });
  };

  return {
    onViewMatter: handleViewMatter,
    onRenameMatter: handleRenameMatter,
    onDeleteMatter: handleDeleteMatter,
  };
};

export default useMattersActions;
