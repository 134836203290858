import { useMutation } from 'react-query';
import env from '~/src/utils/env';
import {
  APIError,
  APIErrorFailedToParse,
  APIErrorNotOk,
} from '~/src/utils/error';
import { useInvalidateQuery } from '../../utils';
import { keys } from '../keys';

export type WelcomeFormQuestionAnswer = {
  question: string;
  answer: string;
};

export type WelcomeFormData = WelcomeFormQuestionAnswer[];

export const getWelcomeFormSubmitUrl = (orgFprint: string) =>
  `${env.apiUrl}/clients/api/v1/org/${orgFprint}/questionnaire/`;

export type SubmitWelcomeFormVariables = {
  orgFprint: string;
  payload: WelcomeFormData;
};

export const useSubmitWelcomeForm = () =>
  useMutation<WelcomeFormData, APIError, SubmitWelcomeFormVariables>({
    onSuccess: useInvalidateQuery(keys.all),
    mutationFn: async ({ orgFprint, payload }) => {
      const response = await fetch(getWelcomeFormSubmitUrl(orgFprint), {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (!response.ok) throw new APIErrorNotOk(response);
      try {
        return response.json();
      } catch (err) {
        throw new APIErrorFailedToParse(response);
      }
    },
  });
