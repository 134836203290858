/* Libraries */
import React from 'react';

/* Hooks */
import useTemplatesActions from '~/src/hooks/useTemplatesActions';

/* Components */
import { TemplatesWrapper } from '~/src/components/Templates';

const TemplatesContainer = ({ onlyPreviewButton = false }) => {
  const {
    previewTemplate: handlePreviewTemplate,
    renameTemplate: handleRenameTemplate,
    deleteTemplate: handleDeleteTemplate,
    addTemplateToSeletionSidebar: handleAddTemplateToSeletionSidebar,
    fetchChildrenTemplates: handleFetchChildrenTemplates,
  } = useTemplatesActions();

  const rowActions = {
    rename: handleRenameTemplate,
    preview: handlePreviewTemplate,
    delete: handleDeleteTemplate,
  };

  const onRowClick = (templateIds: number[]) => {
    handleFetchChildrenTemplates(templateIds);
  };

  return (
    <TemplatesWrapper
      addTemplateToSeletionSidebar={handleAddTemplateToSeletionSidebar}
      onRowClick={onRowClick}
      rowActions={rowActions}
      onlyPreviewButton={onlyPreviewButton}
    />
  );
};

export default TemplatesContainer;
