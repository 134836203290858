import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useEmulateUser } from '~/src/entities/user';
import { useOrgFprint } from '~/src/hooks/useOrgFprint';
import { validations, VALIDATION_TYPES } from '~/src/utils/validation';
import { useLayoutContext } from '~/src/contexts';

type EmulateUserDialogProps = {
  open?: boolean;
  onClose: () => void;
};

export const EmulateUserDialog = ({
  open = false,
  onClose,
}: EmulateUserDialogProps) => {
  const orgFprint = useOrgFprint();
  const { showToast } = useLayoutContext();
  const [email, setEmail] = useState('');
  const { mutate, isLoading, reset } = useEmulateUser();
  const [submitted, setSubmitted] = useState(false);

  const valid = validations[VALIDATION_TYPES.email]?.({
    value: email,
    touched: true,
  });

  const showError = submitted && !valid;

  const onAfterClose = () => {
    reset();
    setSubmitted(false);
    setEmail('');
  };

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      TransitionProps={{ onExited: onAfterClose }}
      onClose={onClose}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitted(true);
          if (!valid || isLoading) return;
          mutate(
            { orgFprint, email },
            {
              onError: async (err) => {
                const message = await err.response.json();
                showToast('ERROR', {
                  message: message ? message.message : err.message,
                });
              },
            },
          );
        }}
      >
        <DialogTitle>Emulate User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            error={showError}
            helperText={
              showError
                ? 'Invalid Email Address'
                : 'Enter the email address of the user you want to emulate'
            }
            onChange={({ target }) => setEmail(target.value)}
            value={email}
            fullWidth
            size="small"
            placeholder="Email Address"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit" disabled={isLoading}>
            Emulate User
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
