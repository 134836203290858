import React from 'react';
import { TextField, Box } from '@mui/material';
import { ProjectStackField, Project } from '~/src/entities/project';
import { FieldRenderProps } from './PopulateCardField';
import FieldHeader from './FieldHeader';

export type LongTextFieldProps = {
  field: FieldRenderProps;
  projectField: ProjectStackField;
  project: Project;
};

export const LongTextField = ({
  field,
  projectField,
  project,
}: LongTextFieldProps) => {
  const { ref, ...rest } = field;
  return (
    <Box>
      <Box>
        <FieldHeader projectField={projectField} project={project} />
      </Box>
      <Box>
        <TextField
          multiline
          size="small"
          rows={4}
          fullWidth
          {...rest}
          inputRef={ref}
        />
      </Box>
    </Box>
  );
};
