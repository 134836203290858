import { Typography, Box } from '@mui/material';
import React from 'react';
import {
  ExpandableCard,
  ExpandableCardProps,
} from '~/src/components/ExpandableCard/ExpandableCard';
import { PopulateCardFields } from '../PopulateCardFields';
import { getFieldsForGroup } from '../utils';
import { PopulatePageCardProps } from './types';
import { useCardChip } from './useCardChip';

type PopulatePageCardBodyProps = React.PropsWithChildren<
  PopulatePageCardProps & {
    expanded: boolean;
    onChangeExpanded: () => void;
    extra?: ExpandableCardProps['extra'];
    footer?: ExpandableCardProps['footer'];
  }
>;

export const PopulatePageCardBody = ({
  expanded,
  onChangeExpanded,
  group,
  project,
  children,
  extra,
  footer,
  ...props
}: PopulatePageCardBodyProps) => {
  return (
    <ExpandableCard
      uncollapsibleProps={{
        sx: {
          position: 'sticky',
          top: 0,
          zIndex: 10,
          background: 'white',
        },
      }}
      expanded={expanded}
      onChangeExpanded={onChangeExpanded}
      cardProps={{
        id: group.gid,
        sx: { marginBottom: 3, overflow: 'inherit' },
      }}
      header={
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ height: '32px' }}
        >
          <Typography variant="subheading">{group.label}</Typography>
          {useCardChip(group.fields)}
        </Box>
      }
      extra={extra}
      footer={footer}
    >
      <PopulateCardFields
        fields={getFieldsForGroup(project, group)}
        project={project}
        {...props}
      />
      {children}
    </ExpandableCard>
  );
};
