import { Chip, Theme } from '@mui/material';
import * as React from 'react';

export type QuestionnaireStatus =
  | 'approved'
  | 'not_started'
  | 'needs_review'
  | 'pending';

const styleForStatus = (
  status: QuestionnaireStatus,
  theme: Theme,
): React.CSSProperties => {
  switch (status) {
    case 'approved':
      return {
        background: theme.palette.green['50'],
        color: theme.palette.green['700'],
      };
    case 'needs_review':
      return {
        background: theme.palette.yellow['50'],
        color: theme.palette.yellow['700'],
      };
    case 'pending':
      return {
        background: theme.palette.blue['50'],
        color: theme.palette.blue['600'],
      };
    case 'not_started':
      return {
        background: theme.palette.yellow['50'],
        color: theme.palette.yellow['700'],
      };

    default:
      return {};
  }
};

type QuestionnaireStatusBadgeProps = {
  status: QuestionnaireStatus;
};

const getLabel = (status: QuestionnaireStatus) => {
  switch (status) {
    case 'approved':
      return 'Approved';
    case 'needs_review':
      return 'Needs review';
    case 'pending':
      return 'In progress';
    case 'not_started':
      return 'Not started';
    default:
      return status;
  }
};

export const QuestionnaireStatusBadge = ({
  status,
}: QuestionnaireStatusBadgeProps) => (
  <Chip
    sx={(theme) => styleForStatus(status, theme)}
    label={getLabel(status)}
  />
);
