import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { Project } from '~/src/entities/project';
import { useUpdateProject } from '~/src/entities/project/hooks';
import { useDebouncedValue } from '~/src/hooks/useDebouncedValue';
import { flattenFn } from './utils';

type ProjectUpdaterProps = {
  project: Project;
};

type StackSavedData = {
  [key: string]: string;
};

const ProjectUpdater = ({ project }: ProjectUpdaterProps) => {
  const { mutate } = useUpdateProject();

  const watchedValues = useWatch();
  const debouncedWatchedValues = useDebouncedValue(watchedValues, 1000);

  useEffect(() => {
    const rawStackSavedData: StackSavedData = flattenFn(debouncedWatchedValues);

    // properties whose value is undefined should be removed
    const stackSavedData: StackSavedData = Object.entries(
      rawStackSavedData,
    ).reduce((acc, [key, value]) => {
      if (value !== undefined) {
        return { ...acc, [key]: value };
      }
      return acc;
    }, {});

    let haveDifferentValues = false;
    for (const key in stackSavedData) {
      if (stackSavedData[key] != project.stack_saved_data[key]) {
        haveDifferentValues = true;
        break;
      }
    }
    if (haveDifferentValues) {
      const payload = {
        projectId: project.id,
        stack_saved_data: stackSavedData,
      };
      mutate(payload);
    }
  }, [debouncedWatchedValues, mutate, project.id, project.stack_saved_data]);

  return null;
};

export default ProjectUpdater;
