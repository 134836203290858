import React, { useState } from 'react';

import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { useDebouncedValue } from '~/src/hooks/useDebouncedValue';
import { ListMatters } from '../ListMatters';
import { useClioMatters } from '../../hooks';
import { SpinnerWithTitle } from '~/src/components/Spinner/SpinnerWithTitle';
import { ClioMatter } from '~/src/services/api/clioService';
import { SearchInput } from './SearchInput';

type MatterSearchProps = {
  orgFprint: string;
  onSelect: (matter: ClioMatter) => void;
};

export const MatterSearch = ({ orgFprint, onSelect }: MatterSearchProps) => {
  const [search, setSearch] = useState('');
  const query = useDebouncedValue(search, 300);
  const results = useClioMatters({
    query,
    orgFprint,
    fields: [
      'description',
      'display_number',
      'open_date',
      'id',
      'responsible_attorney{name}',
    ],
    order: 'last_activity_date(desc)',
    limit: 20,
  });

  return (
    <>
      <DialogTitle>
        <SearchInput
          searching={query !== search}
          value={search}
          onChange={setSearch}
        />
      </DialogTitle>

      <DialogContent
        analyticsname="Single Matter Sync - Matter Search List"
        dividers
        sx={{ minHeight: '30vh', p: 0 }}
      >
        {results.data ? (
          <ListMatters matters={results.data} onSelect={onSelect} />
        ) : (
          <SpinnerWithTitle
            title={'Loading Matters'}
            subtitle={'Please wait...'}
          />
        )}
      </DialogContent>
    </>
  );
};
