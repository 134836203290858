import React, { useState } from 'react';
import { useContacts } from '../hooks';
import { Contact } from '../types';
import Autocomplete from '@mui/material/Autocomplete';
import { Paper, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { SingleSelectGroup } from './SingleSelectGroup';
import { SingleSelectAction } from './SingleSelectAction';
import { isRelated } from '../utils';
import { QueryParams } from '../urls';

export type ContactSelectProps = {
  value: Contact | null;
  onChange: (contact: Contact | null) => void;
  parameters?: Omit<Partial<QueryParams>, 'search'>;
  onAddContact: () => void;
  matterId: string | number;
};

export const ContactSelect = ({
  parameters = {},
  value,
  matterId,
  onChange,
  onAddContact,
}: ContactSelectProps) => {
  const [search, setSearch] = useState('');

  const getSearchText = () => {
    const searchTokens = search.trim().split(' ');
    if (searchTokens.length > 1) {
      return {
        search_text: search.trim(),
      };
    }
    return {
      search: search.trim(),
    };
  };

  const labelForContact = ({ first_name = '', last_name = '' }: Contact) =>
    `${first_name} ${last_name}`;
  const contacts = useContacts(matterId, {
    ...parameters,
    ...getSearchText(),
  });

  return (
    <Autocomplete
      PaperComponent={({ children, ...rest }) => (
        <Paper
          {...rest}
          onMouseDown={(e) => e.preventDefault()}
          sx={(theme) => ({
            marginTop: '4px',
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: '4px',
            boxShadow: '0px 4px 8px -2px #1018281A',
          })}
        >
          {children}
          <SingleSelectAction onClick={onAddContact} label={'New Contact'} />
        </Paper>
      )}
      inputValue={search}
      groupBy={(option) =>
        isRelated(option) ? 'RELATED CONTACTS' : 'OTHER CONTACTS'
      }
      renderGroup={(props) => (
        <SingleSelectGroup
          {...props}
          withDivider={props.group === 'RELATED CONTACTS'}
          key={props.key}
        />
      )}
      getOptionLabel={labelForContact}
      value={value}
      isOptionEqualToValue={(c1, c2) => c1.id === c2.id}
      renderOption={(props, o) => (
        <li {...props} style={{ padding: 0 }}>
          <Typography
            variant="button"
            sx={(theme) => ({ padding: theme.spacing(2) })}
          >
            {labelForContact(o)}
          </Typography>
        </li>
      )}
      onChange={(_, c) => onChange(c)}
      options={contacts.data?.pages?.[0]?.contacts || []}
      loading={contacts.isLoading}
      onInputChange={(_, v) => setSearch(v)}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Attach contact"
          size={'small'}
          InputProps={{
            ...params.InputProps,
            startAdornment: <SearchIcon color="action" fontSize="small" />,
            endAdornment: null,
          }}
        />
      )}
    />
  );
};
