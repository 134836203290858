import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, Prompt } from 'react-router-dom';
import { Location } from 'history';
import { isEqual } from 'lodash';

import useMst from '~/src/hooks/useMst';
import useFetchTemplateDefaultValues from '~/src/hooks/useFetchTemplateDefaultValues';

import { useWebViewerContext } from '~/src/components/Webviewer';
import { Text } from '~/src/components/Typography';

import { LAYOUT_FOOTER_TYPES, useLayoutContext } from '~/src/contexts/Layout';
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { LoadingOverlay } from '~/src/components/PageLayout';
import { NAVIGATION_WIDTH } from '~/src/components/PageLayout/styles';

import { ReactComponent as CloseIcon } from '~/src/static/close-icon.svg';

import HancockWrapper from '~/src/components/TemplateDefaultValues/hancockWrapperForTemplateDefaultValues';
import mapTemplateDefaultValuesToHancockDocument from '~/src/utils/mapTemplateDefaultValuesToHancockDocument';

const FormTemplateDraftingContainer = ({
  templateDefaultValuesID,
  duplicate,
}: {
  templateDefaultValuesID: number;
  duplicate: boolean;
}) => {
  const templateDefaultValuesResponse = useFetchTemplateDefaultValues(
    templateDefaultValuesID,
  );

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false); // Todo, set false at init after finish save stacksaveddata finished

  const { onSubmit, clearError, isLoading } = useMst((store) => ({
    onSubmit: store.templateDefaultValues.submitTemplateDefaultValues,
    clearError: store.templateDefaultValues.clearError,
    isLoading: store.templateDefaultValues.isLoading,
  }));

  const history = useHistory();
  const location = useLocation();
  const [lastLocation, setLastLocation] = useState(location);
  const [shouldUnload, setShouldUnload] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  // customStringPrefix is used to differentiate custom annotations keys vs standard fields
  const customStringPrefix: string = 'customField_';

  const hancockWrapper = useRef<HancockWrapper | null>(null);
  const destroyTabIndexListener = useRef<(() => void) | null>(null);

  const { showFooter, hideFooter, showModal, hideModal, showToast } =
    useLayoutContext();

  const closeModal = () => {
    clearError();
    hideModal();
  };

  const {
    instance,
    makeWebViewerVisible,
    setWebViewerPosition,
    hideWebViewer,
  } = useWebViewerContext();

  const handleClose = () => {
    if (hasUnsavedChanges || !templateDefaultValuesResponse?.title) {
      showModal(LAYOUT_MODAL_TYPES.templateDefaultValuesModal, {
        title: 'Save or discard changes',
        inputValue: templateDefaultValuesResponse?.title,
        enableTextInput: true,
        onCancel: () => {
          closeModal();
        },
        onDiscard: async () => {
          if (!templateDefaultValuesResponse?.title) {
            await onSubmit('delete', {
              id: templateDefaultValuesResponse?.id,
            });
          }
          hideModal();
          hideFooter();
          history.replace(`/library/form-templates`);
          setConfirmedNavigation(true);
        },
        onSave: async (title: string) => {
          const valuesToWrite =
            await hancockWrapper.current!.getFieldsFromDocument(
              customStringPrefix,
            );
          const success = await onSubmit('update', {
            id: templateDefaultValuesResponse?.id,
            title:
              title === templateDefaultValuesResponse?.title ? null : title,
            values: valuesToWrite,
          });
          if (success) {
            templateDefaultValuesResponse!.title = title;
            hideModal();
            hideFooter();
            history.replace(`/library/form-templates`);
            setConfirmedNavigation(true);
            showToast(LAYOUT_TOAST_TYPES.success, {
              message: 'Saved form template successfully',
            });
          } else {
            showToast(LAYOUT_TOAST_TYPES.error, {
              message: 'Something went wrong, please try again later.',
            });
          }
        },
        confirmButtonName: 'Save and close',
      });
    } else {
      setConfirmedNavigation(true);
      hideModal();
      hideFooter();
      history.replace(`/library/form-templates`);
    }
  };

  const handleSave = async () => {
    const valuesToWrite = await hancockWrapper.current!.getFieldsFromDocument(
      customStringPrefix,
    );
    if (templateDefaultValuesResponse?.title) {
      // This case handles when we have a title defined for a Template Default Value, we don't need to
      // generate a modal for users to set the name then save
      const success = await onSubmit('update', {
        id: templateDefaultValuesResponse?.id,
        values: valuesToWrite,
      });
      if (success) {
        hideModal();
        setHasUnsavedChanges(false);
        showToast(LAYOUT_TOAST_TYPES.success, {
          message: 'Saved form template successfully',
        });
      } else {
        showToast(LAYOUT_TOAST_TYPES.error, {
          message: 'Something went wrong, please try again later.',
        });
      }
    } else {
      // This case handles when the title is not defined
      // (usually when first creating a Template Default Value)
      // We will generate a modal for the users to set the name of the Template Default Value
      showModal(LAYOUT_MODAL_TYPES.templateDefaultValuesModal, {
        title: 'Save form template',
        inputValue: templateDefaultValuesResponse?.title,
        enableTextInput: true,
        onCancel: () => {
          closeModal();
        },
        onSave: async (title: string) => {
          const success = await onSubmit('update', {
            id: templateDefaultValuesResponse?.id,
            title:
              title === templateDefaultValuesResponse?.title ? null : title,
            values: valuesToWrite,
          });
          if (success) {
            templateDefaultValuesResponse!.title = title;
            hideModal();
            setHasUnsavedChanges(false);
            showToast(LAYOUT_TOAST_TYPES.success, {
              message: 'Saved form template successfully',
            });
          } else {
            showToast(LAYOUT_TOAST_TYPES.error, {
              message: 'Something went wrong, please try again later.',
            });
          }
        },
        confirmButtonName: 'Save',
      });
    }
  };

  const handleDuplicate = () => {
    showModal(LAYOUT_MODAL_TYPES.templateDefaultValuesModal, {
      title: 'Duplicate form template',
      inputValue: templateDefaultValuesResponse?.title,
      enableTextInput: true,
      onCancel: () => {
        closeModal();
      },
      onSave: async (title: string) => {
        const { success, newId } = await onSubmit('duplicate', {
          id: templateDefaultValuesResponse?.id,
          title,
        });
        if (success) {
          hideModal();
          hideFooter();
          history.push(`/form-template/${newId}`, { duplicate: true });
          setConfirmedNavigation(true);
        } else {
          showToast(LAYOUT_TOAST_TYPES.error, {
            message: 'Something went wrong, please try again later.',
          });
        }
      },
      confirmButtonName: 'Duplicate',
    });
  };

  const handleRename = () => {
    showModal(LAYOUT_MODAL_TYPES.templateDefaultValuesModal, {
      title: 'Rename form template',
      inputValue: templateDefaultValuesResponse?.title,
      enableTextInput: true,
      onCancel: () => {
        closeModal();
      },
      onSave: async (title: string) => {
        const success = await onSubmit('update', {
          id: templateDefaultValuesResponse?.id,
          title,
        });
        if (success) {
          hideModal();
          templateDefaultValuesResponse!.title = title;
          showToast(LAYOUT_TOAST_TYPES.success, {
            message: 'Renamed form template successfully',
          });
        } else {
          showToast(LAYOUT_TOAST_TYPES.error, {
            message: 'Something went wrong, please try again later.',
          });
        }
      },
      confirmButtonName: 'Rename',
    });
  };

  const handleDelete = () => {
    showModal(LAYOUT_MODAL_TYPES.templateDefaultValuesModal, {
      title: 'Delete form template',
      onCancel: () => {
        closeModal();
      },
      onDelete: async () => {
        const success = await onSubmit('delete', {
          id: templateDefaultValuesResponse?.id,
        });
        if (success) {
          hideModal();
          hideFooter();
          history.replace(`/library/form-templates`);
          setConfirmedNavigation(true);
          showToast(LAYOUT_TOAST_TYPES.success, {
            message: 'Deleted form template successfully',
          });
        } else {
          showToast(LAYOUT_TOAST_TYPES.error, {
            message: 'Something went wrong, please try again later.',
          });
        }
      },
    });
  };

  const handleBlockedRoute = (nextLocation: Location) => {
    setLastLocation(nextLocation);
    if (!confirmedNavigation && hasUnsavedChanges) {
      handleClose();
      return false;
    }

    hideModal();
    hideFooter();
    return true;
  };

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation) {
      setShouldUnload(true);
      // Navigate to the previous blocked location with your navigate function
      history.push(lastLocation.pathname, lastLocation.state);
      setConfirmedNavigation(false);
      hideFooter();
    }
  }, [confirmedNavigation, lastLocation, history, shouldUnload, hideFooter]);

  // Block refresh/close tab
  useEffect(() => {
    const unload = (event: any) => {
      const e = event || window.event;
      e.preventDefault();
      if (hasUnsavedChanges && !shouldUnload) {
        e.returnValue = 'Are your sure to leave?';
      }
      if (shouldUnload) {
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', unload);

    return () => window.removeEventListener('beforeunload', unload);
  }, [hasUnsavedChanges, shouldUnload]);

  const [hancockState, setHancockState] = useState<HancockWrapper | null>(null);

  const checkUnSavedChanges = async () => {
    const currentValues = await hancockWrapper.current!.getFieldsFromDocument(
      customStringPrefix,
    );
    let previousValues = templateDefaultValuesResponse?.values;
    // default values in templateDefaultValuesResponse is null, but hancock getFieldsFromDocument default return is {}
    if (!previousValues) previousValues = {};
    if (!isEqual(currentValues, previousValues)) {
      setHasUnsavedChanges(true);
    } else {
      setHasUnsavedChanges(false);
    }
  };

  useEffect(() => {
    if (!templateDefaultValuesResponse || !hancockState || isLoading) {
      hideFooter();
      return;
    }

    const document = mapTemplateDefaultValuesToHancockDocument(
      templateDefaultValuesResponse,
      customStringPrefix,
    );
    hancockWrapper.current?.initializeZoomEventOnDocumentLoad(1.5);
    hancockWrapper.current!.loadDocuments(document).then(() => {
      hancockWrapper.current!.hancock.on(
        'field-changed',
        async (annotationId, type) => {
          checkUnSavedChanges();
          const { documentViewer } = instance!.Core;
          // deselect Add Custom Field button when modify custom field
          if (type === 'custom') {
            const xfdf =
              await hancockWrapper.current!.hancock.getAnnotationXfdf(
                annotationId,
              );
            if (xfdf) {
              documentViewer.setToolMode(
                documentViewer.getTool('AnnotationEdit'),
              );
            }
          }
        },
      );
      hancockWrapper.current!.hancock.on('input-edited', async () => {
        checkUnSavedChanges();
      });
      // We only should call this once, on initialization
      // If we call this multiple times it will mess with the global WebViewer's createInnerElement(s) calls
      if (!destroyTabIndexListener || !destroyTabIndexListener.current) {
        destroyTabIndexListener.current =
          hancockWrapper.current!.setTabIndexOfPDFElementsAndFieldResetHandler();
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    templateDefaultValuesResponse,
    hancockState,
    isLoading,
    templateDefaultValuesID,
  ]);

  // Destroy event listeners when the component unmounts
  useEffect(() => {
    return () => {
      // Remove the event listeners for showing the "reset" button on each field:
      destroyTabIndexListener.current?.();
      // Remove any of hancock's event listeners:
      hancockWrapper.current?.hancock.destroy();
    };
  }, []);

  useEffect(() => {
    if (isLoading) {
      hideFooter();
      return;
    }
    showFooter(LAYOUT_FOOTER_TYPES.templateDefaultValuesDraftingFooter, {
      onClose: handleClose,
      onSave: handleSave,
      onDuplicate: handleDuplicate,
      onRename: handleRename,
      onDelete: handleDelete,
      hasUnsavedChanges,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, hasUnsavedChanges]);

  useEffect(() => {
    if (!instance || isLoading) return;

    if (hancockWrapper.current) return;
    hancockWrapper.current = new HancockWrapper(instance);
    setHancockState(hancockWrapper.current);
  }, [instance, isLoading, templateDefaultValuesResponse]);

  useEffect(() => {
    setWebViewerPosition({
      left: NAVIGATION_WIDTH,
      right: 0,
      top: 134,
      bottom: 72,
    });
    if (!isLoading) {
      makeWebViewerVisible();
    }
    return () => {
      hideWebViewer();
    };
  }, [
    setWebViewerPosition,
    makeWebViewerVisible,
    hideWebViewer,
    isLoading,
    templateDefaultValuesID,
  ]);

  if (isLoading) {
    return (
      <LoadingOverlay
        title={
          duplicate ? 'Duplicating template...' : 'Loading form template...'
        }
        subtitle="Please wait"
        relative={false}
      />
    );
  }

  return (
    <>
      <div>
        <header className="flex p-4 bg-white items-center border-gray-200">
          <div>
            <button
              onClick={handleClose}
              className="flex justify-center items-center w-8 h-8 hover:bg-gray-100 rounded-md"
            >
              <CloseIcon />
            </button>
          </div>
          <div className="ml-2">
            <h1 className="font-semibold">
              {templateDefaultValuesResponse?.title
                ? `${templateDefaultValuesResponse?.title}`
                : 'Untitled'}
            </h1>
            <div className="whitespace-nowrap">
              <Text subdued>
                {templateDefaultValuesResponse?.template.title}{' '}
                {templateDefaultValuesResponse?.template.subtitle}
              </Text>
            </div>
          </div>
        </header>
        <div className="bg-blue-50 p-4 border-y border-blue-200">
          <div className="flex justify-center text-gray-900">
            <Text>
              You&apos;re editing a form template.{' '}
              <a
                href="https://help.lawyaw.com/en/articles/6483780-get-started-with-form-templates"
                target="_blank"
                style={{
                  textDecoration: 'underline',
                }}
                rel="noreferrer"
              >
                Learn more
              </a>
            </Text>
          </div>
        </div>
        <div className="Generate"></div>
      </div>
      <Prompt when message={handleBlockedRoute} />
    </>
  );
};

export default FormTemplateDraftingContainer;
