import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { css } from 'aphrodite';

import Button from '~/src/components/Button';
import Card from '~/src/components/Card';
import { Form, renderFormField } from '~/src/components/Forms';
import { VALIDATION_TYPES } from '~/src/utils/validation';

import { default as cardStyles } from '~/src/components/Card/styles';
import { PRACTICE_AREAS, US_AND_CANADA_STATES } from '~/src/utils/constants';
import styles from './styles';

const getFormFields = (fieldsValid) => [
  {
    label: 'Primary practice area',
    id: 'Primary practice area',
    type: 'select',
    validation: VALIDATION_TYPES.present,
    required: true,
    options: PRACTICE_AREAS,
  },
  {
    label: 'Secondary practice area',
    id: 'Secondary practice area',
    type: 'select',
    validation: VALIDATION_TYPES.default,
    options: PRACTICE_AREAS,
  },
  {
    label: 'Number of people in your organization',
    id: 'Number of people in your organization',
    type: 'text',
    validation: VALIDATION_TYPES.number_of_people_in_organization,
    error: fieldsValid['Number of people in your organization']
      ? null
      : 'Not a valid number of people',
  },
  {
    label: 'Phone number',
    id: 'Phone number',
    type: 'text',
    validation: VALIDATION_TYPES.default,
    error: fieldsValid['Phone number'] ? null : 'Not a valid phone number',
  },
  {
    label: 'What state do you practice in primarily?',
    id: 'State',
    type: 'select',
    validation: VALIDATION_TYPES.present,
    options: US_AND_CANADA_STATES,
    required: true,
  },
  {
    label: 'How did you hear about us?',
    id: 'How did you hear about us?',
    type: 'select',
    validation: () => true,
    options: [
      { label: 'Colleague', value: 'Colleague' },
      { label: 'Google', value: 'Google' },
      { label: 'Facebook Ad', value: 'Facebook Ad' },
      { label: 'Facebook Post', value: 'Facebook Post' },
      { label: 'Email', value: 'Email' },
      { label: 'Mail', value: 'Mail' },
      { label: 'Clio', value: 'Clio' },
      { label: 'Podcast', value: 'Podcast' },
      { label: 'Other', value: 'Other' },
    ],
  },
];

const WelcomeForm = ({ onSubmit, onChange, submitting }) => {
  const [fieldsValid, setFieldsValid] = useState({});
  const [formValid, setFormValid] = useState(false);

  const handleChange = (form) => {
    onChange(form);
    const nextFieldsValid = { ...fieldsValid };

    Object.keys(form.fields).forEach((fieldKey) => {
      const field = form.fields[fieldKey];
      nextFieldsValid[fieldKey] = !!field.value === false ? true : field.valid;
    });

    setFieldsValid(nextFieldsValid);
    setFormValid(form.valid);
  };

  const fields = getFormFields(fieldsValid);

  const fieldsProp = fields.map((field) =>
    renderFormField({ ...field, editable: true }),
  );

  const renderHeader = () => 'Help us customize your experience with Lawyaw';
  const renderActions = () => (
    <>
      <Button
        cssStyle={cardStyles.cardAction}
        primary
        disabled={!formValid || submitting}
        onClick={onSubmit}
      >
        {submitting ? 'Loading...' : 'Next'}
      </Button>
    </>
  );

  return (
    <div className={css(styles.container)}>
      <Card renderHeader={renderHeader} renderActions={renderActions}>
        <Form fields={fieldsProp} onChange={handleChange} />
      </Card>
    </div>
  );
};

WelcomeForm.propTypes = {
  submitting: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

WelcomeForm.defaultProps = {
  onChange: () => {},
  onSubmit: () => {},
};

export default WelcomeForm;
