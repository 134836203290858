import React from 'react';
import * as clipboard from 'clipboardy';

import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { useLayoutContext } from '~/src/contexts/Layout';
import { Form, renderFormField } from '~/src/components/Forms';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

export const ShareLink: React.FC<{
  questionnaireLink: string;
  onClose: () => void;
}> = ({ questionnaireLink, onClose }) => {
  const { showToast } = useLayoutContext();

  const handleCopyLink = () => {
    clipboard.write(questionnaireLink).then(() => {
      showToast(LAYOUT_TOAST_TYPES.success, {
        message: 'Link copied to clipboard!',
      });
    });
  };

  const fields = [
    {
      label: 'Questionnaire link',
      id: 'link',
      type: 'copyLink',
      target: '_blank',
      defaultValue: questionnaireLink,
      onClick: handleCopyLink,
    },
  ];

  const fieldsProp = fields.map((field) => {
    return renderFormField(field);
  });

  return (
    <>
      <DialogTitle>Link generated</DialogTitle>
      <DialogContent>
        Share this link with the recipient to gather their responses.
        <br />
        If you need to access the link later, you&apos;ll find it in the Pending
        section.
        <br />
        <br />
        <Form
          fields={fieldsProp}
          className={undefined}
          focusFirstInput={undefined}
          focusTimeout={undefined}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </>
  );
};
