/* Libs */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

/* Hooks */
import { useQuestionnaireActions } from '~/src/hooks/questionnaires';
import useSuspendLoading from '~/src/hooks/useSuspendLoading';

/* Components */
import { QuestionnaireForm } from '~/src/components/QuestionnaireForm';
import { Column, Row } from '~/src/components/PageLayout/Layout';

/* Animations */
import { FadeIn } from '~/src/components/Animations';
import { history } from '~/src/utils/history';

// Note intake is an instance of stores/intakes.js MobX model
const QuestionnaireFormContainer = (props) => {
  const { createConfig = {}, intakeId } = props;

  const {
    intake,

    onMapQuestion,
    onChange,
    onInitalizeIntakeForm,
    // onPublishIntake,
  } = useQuestionnaireActions(intakeId);

  const handleCancel = () => {
    history.replace('/questionnaire/all');
  };

  const handleQuestionMove = (currentIndex, dropIndex) => {
    return intake.moveQuestion(currentIndex, dropIndex);
  };

  const isLoading = !intake;

  useEffect(() => {
    if (!isLoading) {
      onInitalizeIntakeForm({
        templateIds: createConfig.templateIds || [],
        templateSetIds: createConfig.templateSetIds || [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const suspend = useSuspendLoading(isLoading);

  if (!intake) {
    return null;
  }

  return (
    <>
      <Row>
        <Column maxWidth="50%" margin="0 auto">
          <FadeIn show={!suspend}>
            <QuestionnaireForm
              isLoading={isLoading}
              intake={intake}
              id={intakeId}
              onChange={onChange}
              onCancel={handleCancel}
              onQuestionMove={handleQuestionMove}
              onMapQuestion={onMapQuestion}
            />
          </FadeIn>
        </Column>
      </Row>
    </>
  );
};

QuestionnaireFormContainer.propTypes = {
  createConfig: PropTypes.object,
};

QuestionnaireFormContainer.defaultProps = {
  createConfig: {},
};

export default QuestionnaireFormContainer;
