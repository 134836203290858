import { flatten } from 'flat';
import { Contact } from '~/src/entities/contact';
import {
  ProjectStackFieldGroup,
  ProjectStackField,
  Project,
} from '~/src/entities/project';

export const getDocumentsForField = (
  project: Project,
  field: ProjectStackField,
) =>
  project.stack_data.documents.filter((doc) => {
    if (field.fid in doc.document_fields) {
      return true;
    }
    return false;
  });

export const getFieldsForGroup = (
  project: Project,
  group: ProjectStackFieldGroup,
) =>
  group.fields
    .map((fieldId) => {
      const field = project.stack_data.fields.find(
        ({ fid }) => fid === fieldId,
      );
      if (!field)
        console.error(
          `Missing field ${fieldId} in group ${group.label} in project ${project.id}`,
        );
      return field;
    })
    .filter((f): f is ProjectStackField => !!f);

export const getFieldLabelsForGroup = (
  project: Project,
  group: ProjectStackFieldGroup,
) =>
  group.fields.map((fieldId) => {
    const field = project.stack_data.fields.find(({ fid }) => fid === fieldId);
    if (!field) {
      console.error(
        `Missing field ${fieldId} in group ${group.label} in project ${project.id}`,
      );
    } else {
      return { fid: field.fid, full_label: `${group.label}, ${field.label}` };
    }
  });

type StackSavedData = {
  [key: string]: string;
};

export const flattenFn = (value: object): StackSavedData => {
  return flatten.flatten(value);
};
export const getAttributeNamesFromGroup = (group: ProjectStackFieldGroup) =>
  group.fields.map((fieldName) =>
    stripEntityFromFieldName(group.gid, fieldName),
  );

export const stripEntityFromFieldName = (entity: string, fieldName: string) => {
  const regexp = new RegExp('^' + entity + '.');
  return fieldName.replace(regexp, '');
};

export const getUpdatedFormFieldsForContact = (
  contact: Contact,
  keyMap: Record<string, string>,
  attributes: string[],
): string[] => {
  const resolveFrom: Record<string, any> = { ...contact, ...contact.data };
  return attributes.map((attr) => {
    const resolvedAttr = keyMap[attr]!;
    const resolvedValue = resolveFrom[resolvedAttr];
    return resolvedValue ? `${resolvedValue}` : '';
  });
};
