import React from 'react';
import { TextField, Box } from '@mui/material';
import { ProjectStackField, Project } from '~/src/entities/project';
import { FieldRenderProps } from './PopulateCardField';
import FieldHeader from './FieldHeader';

export type ShortTextFieldProps = {
  field: FieldRenderProps;
  projectField: ProjectStackField;
  project: Project;
};

export const ShortTextField = ({
  field,
  projectField,
  project,
}: ShortTextFieldProps) => {
  const { ref, ...rest } = field;
  return (
    <Box>
      <Box>
        <FieldHeader projectField={projectField} project={project} />
      </Box>
      <Box>
        <TextField
          size={'small'}
          fullWidth
          {...rest}
          inputRef={ref}
          inputProps={{
            'data-1p-ignore': true,
          }}
        />
      </Box>
    </Box>
  );
};
