import React, { useEffect, useState } from 'react';
import useMst from '~/src/hooks/useMst';
import qs from 'qs';

import { LoadingOverlay } from '~/src/components/PageLayout';
import { useLayoutContext } from '~/src/contexts/Layout';
import clio from '~/src/services/clio';
import { useObserver } from 'mobx-react';
import { CLIO_ERROR_STATE } from '../../utils/constants';
import ClioSyncErrorBanner from '../../components/Banner/ClioSyncErrorBanner';
import { LAYOUT_MODAL_TYPES } from '../../components/PageLayout/Modals';
import { clioManageMatterDocumentsLink } from '../../utils/clio';
import { useOrgFprint } from '../../hooks/useOrgFprint';
import { history } from '../../utils/history';

const LibraryContainer = (props) => {
  const [isClioMatterSyncing, setIsClioMatterSyncing] = useState(false);
  const orgFprint = useOrgFprint();

  const { clioStore, clioErrorState } = useMst((store) => ({
    clioStore: store.clio,
    // eslint-disable-next-line react-hooks/rules-of-hooks
    clioErrorState: useObserver(() => store.clio.errorState),
  }));

  const { showModal } = useLayoutContext();

  const showNoCourtFormsModal = (clioMatterId) => {
    showModal(LAYOUT_MODAL_TYPES.error, {
      onSubmit: () =>
        (window.location.href = clioManageMatterDocumentsLink(clioMatterId)),
      messages: [
        "Your account doesn't have access to court form automation and therefore can't draft or edit court forms in Lawyaw.",
        'To add court form automation to your account, contact Lawyaw support.',
      ],
      submitButtonName: 'Return to Clio',
      title: 'Court forms not found',
    });
  };

  // fetch and store query params
  useEffect(() => {
    const { clio_matter_id } = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    const singleMatterSync = async (clioMatterId) => {
      const { matter_id } = await clio.matterSync(orgFprint, clioMatterId);
      return matter_id;
    };

    if (clio_matter_id) {
      setIsClioMatterSyncing(true);
      singleMatterSync(clio_matter_id)
        .then((matterId) => {
          clioStore.setCourtFormParams({
            syncedMatterId: matterId,
            courtFormClioMatterId: clio_matter_id,
          });
        })
        .catch((err) => {
          if (err.statusCode === 403) {
            showNoCourtFormsModal(clio_matter_id);
          } else if (err.statusCode === 417) {
            clioStore.setErrorState(CLIO_ERROR_STATE.SYNC_NOT_ENABLED);
          } else if (err.statusCode === 404) {
            clioStore.setErrorState(CLIO_ERROR_STATE.MATTER_SYNC_NOT_ENABLED);
          } else {
            clioStore.setErrorState(CLIO_ERROR_STATE.GENERIC_SYNC);
          }
        })
        .finally(() => {
          setIsClioMatterSyncing(false);
        });
    } else {
      // when user navigates to library route/ with no valid query params, reset store variables
      clioStore.clearCourtFormParams();
      clioStore.clearErrorState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isClioMatterSyncing ? (
    <LoadingOverlay title="Syncing data from Clio..." relative={false} />
  ) : (
    <>
      {clioErrorState && <ClioSyncErrorBanner errorState={clioErrorState} />}
      {props.children}
    </>
  );
};

export default LibraryContainer;
