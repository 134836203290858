import React from 'react';
import { Chip, ChipProps, Typography } from '@mui/material';

type MissingFieldsChipProps = {
  number: number;
} & ChipProps;

const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
  // do not expand/collapse card when this component is clicked
  event.stopPropagation();
};

export const MissingFieldsChip = ({
  number,
  ...rest
}: MissingFieldsChipProps) => (
  <Chip
    {...rest}
    onClick={handleClick}
    label={
      <Typography
        variant="smallLabel"
        sx={(theme) => ({
          color: theme.palette.yellow[700],
        })}
      >
        {number} field{number === 1 ? '' : 's'} remaining
      </Typography>
    }
    sx={(theme) => ({
      background: theme.palette.yellow[50],
      cursor: 'default',
      ':hover': {
        background: theme.palette.yellow[50],
      },
    })}
  />
);
