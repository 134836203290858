import React from 'react';
import { Box } from '@mui/material';
import { ControllerRenderProps } from 'react-hook-form';
import { ProjectStackField, Project } from '~/src/entities/project';
import { FieldType } from '~/src/entities/project/types';
import { ShortTextField } from './ShortTextField';
import { LongTextField } from './LongTextField';
import { DatePickerField } from './DatePickerField/DatePickerField';
import { SingleSelectField } from './SingleSelectField';
import { MultipleChoiceField } from './MultipleChoiceField';

export type FieldRenderProps = ControllerRenderProps & {
  onBlur: () => void;
  onFocus: () => void;
};

export type PopulateCardFieldProps = {
  field: FieldRenderProps;
  projectField: ProjectStackField;
  project: Project;
};

export const PopulateCardField = ({
  field,
  projectField,
  project,
}: PopulateCardFieldProps) => {
  let FieldComponent = ShortTextField;

  switch (projectField.variable_type) {
    case FieldType.LONG_TEXT:
      FieldComponent = LongTextField;
      break;
    case FieldType.DATE:
      FieldComponent = DatePickerField;
      break;
    case FieldType.MULTIPLE_CHOICE:
      FieldComponent = SingleSelectField;
      break;
    case FieldType.BATCH_SELECT:
      FieldComponent = MultipleChoiceField;
      break;
  }

  return (
    <Box pt={1}>
      <FieldComponent
        field={field}
        projectField={projectField}
        project={project}
      />
    </Box>
  );
};
