import { Box, Drawer } from '@mui/material';
import React, { useState, useRef } from 'react';
import {
  Project,
  ProjectStackDocument,
  useTemplatePreview,
} from '~/src/entities/project';
import { Sidebar } from '../Sidebar';
import { FormProvider, useForm } from 'react-hook-form';
import { PopulatePageCard } from './PopulatePageCard/PopulatePageCard';
import { DOCUMENT_CATEGORY_ID } from '~/src/stores/sidebarItemsStore';
import { getFieldLabelsForGroup, getFieldsForGroup } from './utils';
import { ProgressTrackerWidget } from '~/src/components/ProgressTrackerWidget';
import DocumentPreviewSlideIn from '~/src/components/Documents/DocumentPreviewSlideIn';
import ProjectUpdater from './ProjectUpdater';

type PopulatePageContentProps = {
  project: Project;
  isProgressTrackerEnabled: boolean;
};

type DrawerContainerProps = {
  document: ProjectStackDocument;
  open: boolean;
  onClose: () => void;
};

const DrawerContainer = ({ document, open, onClose }: DrawerContainerProps) => {
  const docId =
    document.document_type === DOCUMENT_CATEGORY_ID.MS_WORD
      ? document.template_docid
      : document.global_template_docid;

  const { data: globalTemplate, ...response } = useTemplatePreview(
    docId,
    document.document_type === DOCUMENT_CATEGORY_ID.MS_WORD,
  );

  const getPagesArray = () => {
    let pagesArray = [''];
    if (response.isSuccess && globalTemplate) {
      if ('document' in globalTemplate) {
        pagesArray = globalTemplate.document.pages.map(
          (page) => page.background_url,
        );
      } else {
        pagesArray = globalTemplate.preview_pages.map(
          (page) => page.background_url,
        );
      }
    }
    return pagesArray;
  };
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        width: '800px',
        '& .MuiDrawer-paper': {
          width: '800px',
          boxSizing: 'border-box',
        },
      }}
    >
      <DocumentPreviewSlideIn
        title={document.title}
        images={getPagesArray()}
        message={
          'The preview pages for this document are generating. Please try again later.'
        }
        onCancel={onClose}
        hideAddToSelection={true}
        hideDraftDocument={true}
        onDraftNewDocument={true}
        onAddToSelection={() => {}}
      />
    </Drawer>
  );
};

export const PopulatePageContent = ({
  project,
  isProgressTrackerEnabled,
}: PopulatePageContentProps) => {
  const [expandedSection, setSectionExpanded] = useState('');
  const [previewDocument, setPreviewDocument] =
    useState<ProjectStackDocument>();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [currentField, setCurrentField] = useState<string | undefined>(
    undefined,
  );
  const initialValues = project.stack_saved_data;
  const methods = useForm({ values: initialValues });

  const allFields = project.stack_data.groups
    .map((group) => getFieldsForGroup(project, group))
    .flat();
  const allFieldsLabels = project.stack_data.groups
    .map((group) => getFieldLabelsForGroup(project, group))
    .flat();
  const totalFieldsCount = allFields.length;
  const fidToFieldLabelMapRef = useRef(
    new Map(allFieldsLabels.map((field) => [field?.fid, field?.full_label])),
  );
  const fieldsReviewedMapRef = useRef(
    new Map(allFields.map((field) => [field.fid, !!field.value])),
  );

  const getCurrentFieldLabel = () => {
    if (currentField) {
      const fidToFieldLabelMap = fidToFieldLabelMapRef.current;
      return fidToFieldLabelMap.get(currentField);
    }
  };

  const getFieldReviewedCount = () => {
    const fieldsReviewedMap = fieldsReviewedMapRef.current;
    return Array.from(fieldsReviewedMap.values()).filter((value) => value)
      .length;
  };

  const onNextClick = () => {
    if (getFieldReviewedCount() === totalFieldsCount) {
      fieldsReviewedMapRef.current = new Map(
        new Map(allFields.map((field) => [field.fid, !!field.value])),
      );
      setCurrentField(undefined);
    } else {
      if (currentField) {
        fieldsReviewedMapRef.current.set(currentField, true);
      }
      const nextField = allFields.find(
        (field) => !fieldsReviewedMapRef.current.get(field.fid),
      );
      if (nextField) {
        setCurrentField(nextField.fid);
        methods.setFocus(nextField.fid);
      }
    }
  };

  const onFieldFocus = (fieldId: string) => {
    setCurrentField(fieldId);
  };

  const onFieldBlur = (fieldId: string) => {
    fieldsReviewedMapRef.current.set(fieldId, true);
    if (getFieldReviewedCount() === totalFieldsCount) {
      setCurrentField(undefined);
    }
  };

  const onFieldNameClick = () => {
    if (currentField) {
      methods.setFocus(currentField);
    }
  };

  return (
    <FormProvider {...methods}>
      <ProjectUpdater project={project} />
      <Box display={'flex'} overflow={'auto'} flex={1}>
        <Box
          flex={'0 0 259px'}
          overflow={'auto'}
          sx={{ scrollbarGutter: 'stable' }}
          bgcolor={'white'}
          mr={2}
        >
          <Sidebar
            onClickDocument={(document) => {
              setPreviewDocument(document);
              setDrawerOpen(true);
            }}
            onExpandSection={(section) => {
              const isCurrentlyExpanded = expandedSection === section.gid;
              if (!isCurrentlyExpanded)
                document
                  .getElementById(section.gid)
                  ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
              setSectionExpanded((s) => (s === section.gid ? '' : section.gid));
            }}
            expandedSection={expandedSection}
            matterName={project.stack_data.project_metadata.matter_title}
            project={project}
          />
          {previewDocument && (
            <DrawerContainer
              document={previewDocument}
              open={isDrawerOpen}
              onClose={() => setDrawerOpen(false)}
            />
          )}
        </Box>
        <Box
          flex={1}
          overflow={'auto'}
          sx={(theme) => ({ scrollPadding: theme.spacing(3, 0, 0) })}
        >
          <Box
            maxWidth={464}
            width={'100%'}
            sx={(theme) => ({ margin: theme.spacing(2, 'auto', 5) })}
          >
            {project.stack_data.groups.map((group) => (
              <PopulatePageCard
                key={group.gid}
                group={group}
                project={project}
                onBlur={onFieldBlur}
                onFocus={onFieldFocus}
              />
            ))}
          </Box>
          {isProgressTrackerEnabled && (
            <ProgressTrackerWidget
              fieldName={getCurrentFieldLabel()}
              totalFieldsCount={totalFieldsCount}
              fieldsReviewedCount={getFieldReviewedCount()}
              onNextClick={onNextClick}
              onMarkAsNotApplicableClick={onNextClick}
              onFieldNameClick={onFieldNameClick}
            />
          )}
        </Box>
      </Box>
    </FormProvider>
  );
};
