import React, { useState } from 'react';

import { Form, renderFormField } from '~/src/components/Forms';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { PreviouslyCreatedLink } from './PreviouslyCreatedLink';

export const SelectMatter: React.FC<{
  questionnaireId: string;
  onClose: () => void;
  onGenerateLink: (matterId: string) => void;
}> = ({ questionnaireId, onClose, onGenerateLink }) => {
  const [matterId, setMatterId] = useState<null | string>(null);
  const [formValid, setFormValid] = useState(false);

  const handleFormChange = (form: any) => {
    setMatterId(form.fields?.matter.value);
    setFormValid(form.valid);
  };

  const fields = [
    {
      label: 'Select a matter',
      id: 'matter',
      type: 'matterSelect',
      validation: ({ value }: { value: string }) =>
        !!value && value.trim().length > 0,
    },
  ];

  const fieldsProp = fields.map((field) => {
    return renderFormField(field);
  });

  return (
    <>
      <DialogTitle>Generate a link to share</DialogTitle>
      <DialogContent>
        <Form
          fields={fieldsProp}
          onChange={handleFormChange}
          className={undefined}
          focusFirstInput={undefined}
          focusTimeout={undefined}
        />
        {matterId && (
          <PreviouslyCreatedLink
            questionnaireId={questionnaireId}
            matterId={matterId}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button
          variant="contained"
          disabled={!formValid}
          onClick={() => onGenerateLink(matterId!)}
        >
          Generate Link
        </Button>
      </DialogActions>
    </>
  );
};
