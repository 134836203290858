/* Libraries */
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import qs from 'qs';
import _ from 'lodash';

/* Higher Order Components */
import WithLayoutProps from '~/src/hoc/WithLayoutProps';

/* Components */
import TemplateSetDraft from '~/src/components/Templates/TemplateSetDraft';

/* Constants */
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { history } from '../utils/history';

class TemplateSetDraftContainer extends React.Component {
  componentDidMount = () => {
    this.props.templateIds.forEach(this.props.store.templates.fetchTemplate);
  };

  updatePathWithQueryParams = (templateIDs, title) => {
    const queryParams = qs.stringify(
      { ids: templateIDs, title: title },
      { encode: false },
    );
    const path = `/templates/docset/new?${queryParams}`;

    history.replace(path);
  };

  handleCreateDraft = async (name, matterId) => {
    try {
      const draft = await this.props.store.projects.createDocSet(
        this.props.templateIds,
        name,
        matterId,
      );

      if (!draft || !draft.id) throw new Error('Missing response object.');

      if (draft.documents.length > 1) {
        this.props.store.documentSets.addDocumentSetToStore(draft);
      } else {
        this.props.store.documents.addDocumentToStore(draft);
      }

      history.replace(`/populate/${draft.id}`, {
        initialProject: true,
      });
    } catch (error) {
      if (error.message === 'WORD_DOCUMENT_AUTOMATION') {
        // user not authorized for Word document automation (not subbed).
        this.props.showToast(LAYOUT_TOAST_TYPES.error, {
          message:
            'You need to be subscribed to word document automation to use word templates',
        });
        history.push('/settings/subscriptions');
        return;
      }

      this.props.showToast(LAYOUT_TOAST_TYPES.error, {
        message: 'Something went wrong, please try again later.',
      });
    }
  };

  handleChangeTemplateIDs = (field) => {
    const nextTemplateIds = field.value || [];
    const changed = !_.isEqual(
      _.sortBy(nextTemplateIds),
      _.sortBy(this.templateIds),
    );

    if (changed) {
      this.templateIds = nextTemplateIds || [];

      this.updatePathWithQueryParams(this.templateIds, this.props.title);
    }
  };

  render() {
    const { title } = this.props;

    const templateIds = this.props.templateIds || [];

    const matter = this.props.store.matter.selectOptions();
    const msTemplates = this.props.store.templates.selectOptions();
    const pdfTemplates = this.props.store.pdfCourtForms.selectOptions();
    const templateDefaultValues =
      this.props.store.templateDefaultValues.selectOptions();
    const templates = [
      ...msTemplates,
      ...pdfTemplates,
      ...templateDefaultValues,
    ];

    const loading = templates.length < templateIds.length;
    // to keep order of templateIds (which is ordered by sortOrder property of sidebarItem)
    const sortedSelectedTemplates = [];
    for (let i = 0; i < templateIds.length; i++) {
      const found = templates.find((t) => t.value === templateIds[i]);
      if (found) {
        sortedSelectedTemplates.push(found);
      }
    }

    return loading ? null : (
      <TemplateSetDraft
        matter={matter}
        templates={templates}
        defaults={{ name: title }}
        sortedSelectedTemplates={sortedSelectedTemplates}
        onChangeTemplate={this.handleChangeTemplateIDs}
        onCreateDraft={this.handleCreateDraft}
      />
    );
  }
}

TemplateSetDraftContainer.propTypes = {
  templateIds: PropTypes.array,
};

export default inject((store) => store)(
  WithLayoutProps(observer(TemplateSetDraftContainer)),
);
