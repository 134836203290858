import React, { useEffect, useState } from 'react';
import analyticsService from '~/src/services/analytics';
import WelcomeFreeTrialForm from '~/src/components/WelcomeFreeTrialForm';
import { useSubmitWelcomeForm } from '../entities/welcomeForm';
import { useOrgFprint } from '../hooks/useOrgFprint';
import { useHistory } from 'react-router-dom';
import { useUpdateAccount } from '../entities/user';

const WelcomeFreeTrialFormContainer = () => {
  const orgFprint = useOrgFprint();
  const history = useHistory();

  const [formState, setFormState] = useState({});

  const { mutateAsync: updateAccount, isLoading: isUpdatingAccount } =
    useUpdateAccount();
  const { mutateAsync: submitWelcomeForm, isLoading: isSubmittingWelcomeForm } =
    useSubmitWelcomeForm();

  useEffect(() => {
    analyticsService.track('Welcome Clio-SSO-free-trial Form Viewed');
  }, []);

  const handleChange = (nextFields) => {
    setFormState(nextFields);
  };

  const handleSubmit = async () => {
    const { fields } = formState;
    const questionnairePayload = [];
    const fieldKeys = Object.keys(fields);

    fieldKeys.forEach((key) => {
      questionnairePayload.push({ question: key, answer: fields[key].value });
    });

    await submitWelcomeForm({ orgFprint, payload: questionnairePayload });
    await updateAccount({ contact: { state: fields.State.value } });

    history.replace('/library');
  };

  return (
    <WelcomeFreeTrialForm
      isLoading={isUpdatingAccount || isSubmittingWelcomeForm}
      onChange={handleChange}
      onSubmit={handleSubmit}
    />
  );
};

export default WelcomeFreeTrialFormContainer;
