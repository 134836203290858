/* Libraries */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* Components */
import Card from '~/src/components/Card';
import Form from '~/src/components/Forms';
import ContactSearchBar from '~/src/containers/ContactsSearchBar';
import { css } from 'aphrodite';

import styles from './styles';

const CardHeader = ({
  title,
  defaultValue,
  showContactsSearchBar,
  onSelectContact,
  onClearContact,
}) => {
  const [showClearContact, setShowClearContact] = useState(false);
  const [contactToClear, setContactToClear] = useState();

  const handleOnClearContact = (contact) => {
    setContactToClear(contact);
    setShowClearContact(true);
  };

  const handleCancelClearContact = () => {
    setContactToClear();
    setShowClearContact(false);
  };

  const handleClearContactConfirm = () => {
    onClearContact(contactToClear);
    setShowClearContact(false);
  };

  const ContactSearchBarInput = () => (
    <div className={css(styles.contactSearchBarContainer)}>
      <ContactSearchBar
        value={defaultValue}
        onSelectContact={onSelectContact}
        onClearContact={handleOnClearContact}
      />
    </div>
  );

  const ClearContactConfirm = () => (
    <div className={css(styles.clearContactContainer)}>
      Clear all fields in this card
      <span>
        <span
          onClick={handleClearContactConfirm}
          className={css(styles.clearContactConfirm)}
        >
          Clear all fields
        </span>
        <span
          onClick={handleCancelClearContact}
          className={css(styles.clearContactCancel)}
        >
          Cancel
        </span>
      </span>
    </div>
  );

  return (
    <div className={css(styles.populateCardHeader)}>
      {title}
      {showContactsSearchBar && !showClearContact && <ContactSearchBarInput />}
      {showContactsSearchBar && showClearContact && <ClearContactConfirm />}
    </div>
  );
};

const Footer = ({ onUpdateContact }) => {
  return (
    <div className={css(styles.populateCardFooter)}>
      <a role="button" onClick={onUpdateContact}>
        Update contact
      </a>
    </div>
  );
};

const CreateNewContactFooter = ({ onCreateNewContact }) => {
  return (
    <div className={css(styles.populateCardFooter)}>
      <a role="button" onClick={onCreateNewContact}>
        Create new contact
      </a>
    </div>
  );
};

const PopulateCard = ({
  title,
  formFields,
  defaultValue,
  showContactsSearchBar,
  onUpdateContact,
  attachedContact,
  onCreateNewContact,
  onChange,
  onBlur,
  onAttachContact,
  onClearContact,
  onSearchContact,
  contacts,
  nextContact,
  contactLoading,
  contactDone,
  dynamic,
}) => {
  const handleSelectContact = (contact) => {
    onAttachContact(contact);
  };

  const handleClearContact = (contact) => {
    onClearContact(contact);
  };

  const handleUpdateContact = () => {
    onUpdateContact(formFields);
  };

  const handleCreateNewContact = () => {
    onCreateNewContact(formFields);
  };

  const renderHeader = () => (
    <CardHeader
      title={title}
      showContactsSearchBar={showContactsSearchBar}
      defaultValue={defaultValue}
      onSelectContact={handleSelectContact}
      onClearContact={handleClearContact}
      onSearchContact={onSearchContact}
      contacts={contacts}
      nextContact={nextContact}
      contactLoading={contactLoading}
      contactDone={contactDone}
    />
  );

  const showCreateNewContact = !attachedContact && showContactsSearchBar;
  const showUpdateContact = !showCreateNewContact && showContactsSearchBar;

  let renderFooter = null;

  if (showContactsSearchBar) {
    renderFooter = showUpdateContact
      ? () => (
          <Footer
            onUpdateContact={handleUpdateContact}
            onCreateNewContact={handleCreateNewContact}
          />
        )
      : () => (
          <CreateNewContactFooter onCreateNewContact={handleCreateNewContact} />
        );
  }

  return (
    <Card
      dynamic={dynamic}
      elevate
      renderHeader={renderHeader}
      renderFooter={renderFooter}
    >
      <Form fields={formFields} onChange={onChange} onBlur={onBlur} />
    </Card>
  );
};

PopulateCard.propTypes = {
  title: PropTypes.string,
  formFields: PropTypes.array,
  onChange: PropTypes.func,
  onAttachContact: PropTypes.func,
  onBlur: PropTypes.func,
};

PopulateCard.defaultProps = {
  title: '',
  formFields: [],
  dynamic: false,
  onChange: () => {},
  onAttachContact: () => {},
  onBlur: () => {},
};

export default PopulateCard;
