/* Libraries */
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

/* Utilities */
import { VALIDATION_TYPES } from '~/src/utils/validation';

/* Components */
import Form, { renderFormField } from '~/src/components/Forms';

import Card from '~/src/components/Card';
import Button from '~/src/components/Button';
import { Text, Heading } from '~/src/components/Typography';

/* Styles */
import { observer } from 'mobx-react';

const SettingsAccount = observer(
  ({
    avatar,
    name,
    onSubmit,
    onImageUpload,
    onImageRemove,
    onImageUploadError,
  }) => {
    const handleImageUpload = (files) => {
      if (files.length > 0) {
        onImageUpload(files);
      }
    };

    const dropzoneProps = {
      disabled: false,
      accept: ['image/png', 'image/jpeg', 'image/jpg'],
      onDropAccepted: handleImageUpload,
      onDropRejected: onImageUploadError,
      minSize: 0,
      maxSize: 10000000,
      maxFiles: 1,
      multiple: false,
    };

    const [formValue, setFormValue] = useState();
    const { getRootProps, getInputProps } = useDropzone(dropzoneProps);

    const handleSubmit = (event) => {
      event.preventDefault();
      onSubmit(formValue);
    };

    const handleChange = (form) => {
      setFormValue(form);
    };

    const fields = [
      {
        label: 'Full name',
        id: 'name',
        type: 'text',
        defaultValue: name,
        validation: VALIDATION_TYPES.present,
      },
    ];

    const fieldsProp = fields.map((field) => renderFormField(field));
    const formValid = formValue && formValue.valid;

    return (
      <Card>
        <Heading>Profile</Heading>
        <div className="mt-8">
          <Text strong>Photo</Text>
          <div className="mt-2">
            {avatar && (
              <img
                src={avatar}
                className="w-32 h-32 rounded-full border-gray-300 border"
              />
            )}
          </div>
          <div className="mt-4 flex gap-4">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Button>Upload photo</Button>
            </div>
            {avatar && <Button onClick={onImageRemove}>Remove photo</Button>}
          </div>
        </div>
        <div className="mt-8">
          <Form
            fields={fieldsProp}
            onChange={handleChange}
            triggerOnMount={false}
          />
        </div>
        <div className="mt-8 flex justify-end">
          <Button disabled={!formValid} primary onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </Card>
    );
  },
);

export default SettingsAccount;
