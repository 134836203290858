import { fetchMemberships } from '~/src/entities/user';
import { http } from '~/src/services/requests';

export const fetchSeatInvoice = async (fprint, seats) => {
  const parse = true;
  return http.innerSendAuthenticated(
    {
      relativePath: `/subscriptions/api/v1/org/${fprint}/upcoming_invoices/?seat=${seats}`,
      method: 'GET',
    },
    parse,
  );
};
export const fetchUpcomingInvoice = async (
  fprint,
  add_plans,
  remove_plans,
  one_time_charges,
  duration,
  seat,
  coupon,
  freeTrialActive,
) => {
  const parse = true;
  const params = new URLSearchParams({
    duration,
    seat,
    coupon,
    trial_period_days: '',
    interval_change: false,
  });
  add_plans.forEach((plan) => {
    params.append('add_plans', plan);
  });
  remove_plans.forEach((plan) => {
    params.append('remove_plans', plan);
  });
  one_time_charges.forEach((charge) => {
    params.append('one_time_charges', charge);
  });

  if (freeTrialActive) {
    params.append('free_trial', true);
  }

  return http.innerSendAuthenticated(
    {
      relativePath: `/subscriptions/api/v1/org/${fprint}/v2/upcoming_invoices/?${params.toString()}`,
      method: 'GET',
    },
    parse,
  );
};
export const applyCoupon = async (fprint, coupon) => {
  const parse = true;
  return http.innerSendAuthenticated(
    {
      relativePath: `/subscriptions/api/v1/org/${fprint}/coupons/?coupon=${coupon}`,
      method: 'GET',
    },
    parse,
  );
};
export const renewSubscription = async (fprint) => {
  const parse = true;
  return http.innerSendAuthenticated(
    {
      relativePath: `/subscriptions/api/v1/org/${fprint}/subscriptions/renew/`,
      method: 'POST',
      body: {
        duration: 'monthly',
        plan: 'Pro',
      },
    },
    parse,
  );
};
export const cancelSubscriptionReason = async (fprint, reasons) => {
  const parse = false;
  return http.innerSendAuthenticated(
    {
      relativePath: `/subscriptions/api/v1/org/${fprint}/cancel_reasons/`,
      method: 'POST',
      body: reasons,
    },
    parse,
  );
};
export const cancelSubscription = async (fprint) => {
  const parse = false;
  return http.innerSendAuthenticated(
    {
      relativePath: `/subscriptions/api/v1/org/${fprint}/subscriptions/`,
      method: 'DELETE',
    },
    parse,
  );
};
export const updatePaymentMethod = async (fprint, stripeToken) => {
  const parse = true;
  return http.innerSendAuthenticated(
    {
      relativePath: `/subscriptions/api/v1/org/${fprint}/stripe_customers/`,
      method: 'PATCH',
      body: {
        'card-token': stripeToken,
      },
    },
    parse,
  );
};

export const updateSubscription = async (fprint, properties) => {
  const parse = true;
  return http.innerSendAuthenticated(
    {
      relativePath: `/subscriptions/api/v1/org/${fprint}/subscriptions/`,
      method: 'PUT',
      body: properties,
    },
    parse,
  );
};

export const createSubscription = async (
  fprint,
  stripeToken,
  plan,
  seats,
  coupon,
) => {
  const parse = true;
  return http.innerSendAuthenticated(
    {
      relativePath: `/subscriptions/api/v1/org/${fprint}/subscriptions/`,
      method: 'POST',
      body: {
        'card-token': stripeToken,
        duration: 'monthly',
        plan: 'pro',
        seat: seats,
        coupon,
      },
    },
    parse,
  );
};
export const createSubscription_V2 = async (
  fprint,
  stripeToken,
  add_plans,
  remove_plans,
  one_time_charges,
  duration,
  seats,
  coupon,
  freeTrialActive,
  interval_change,
) => {
  let method = 'POST';
  let body = {
    'card-token': stripeToken,
    duration,
    add_plans,
    remove_plans,
    one_time_charges,
    seat: seats,
    coupon,
    trial_period_days: '',
    interval_change,
  };

  if (freeTrialActive) {
    method = 'PUT';
    body = {
      ...body,
      free_trial: true,
    };
  }

  const parse = true;
  return http.innerSendAuthenticated(
    {
      relativePath: `/subscriptions/api/v1/org/${fprint}/subscriptions/`,
      method,
      body,
    },
    parse,
  );
};
export const fetchCoupons = async (fprint) => {
  const parse = true;
  return http.innerSendAuthenticated(
    {
      relativePath: `/subscriptions/api/v1/org/${fprint}/coupons/`,
      method: 'GET',
    },
    parse,
  );
};
export const fetchSubscription = async (fprint) => {
  const parse = true;
  return http.innerSendAuthenticated(
    {
      relativePath: `/subscriptions/api/v1/org/${fprint}/subscriptions/`,
      method: 'GET',
    },
    parse,
  );
};
export const fetchInvoices = async (fprint) => {
  const parse = true;
  return http.innerSendAuthenticated(
    {
      relativePath: `/subscriptions/api/v1/org/${fprint}/invoices/`,
      method: 'GET',
    },
    parse,
  );
};
export const fetchReceipts = async (fprint) => {
  const parse = true;
  return http.innerSendAuthenticated(
    {
      relativePath: `/subscriptions/api/v1/org/${fprint}/v2/receipts/`,
      method: 'GET',
    },
    parse,
  );
};
export const updateUserProfile = async (properties) => {
  const parse = false;
  return http.innerSendAuthenticated(
    {
      relativePath: '/clients/api/v1/account/',
      method: 'PUT',
      body: properties,
    },
    parse,
  );
};
export const removeProfilePhoto = async () => {
  const parse = false;
  return http.innerSendAuthenticated(
    {
      relativePath: '/clients/api/v1/account/picture/',
      method: 'DELETE',
    },
    parse,
  );
};
export const uploadProfilePhoto = async (formData) => {
  return http.formUpload({
    relativePath: '/clients/api/v1/account/picture/',
    data: formData,
  });
};
export const uploadSignatureXfdf = async (formData) => {
  return http.formUpload({
    relativePath: '/clients/api/v1/account/sign-picture/',
    data: formData,
  });
};
export const toggleTwoFactorAuthMethod = async (type) => {
  const method = type === 'phone' ? 1 : 2;
  return http.innerSendAuthenticated({
    relativePath: '/clients/api/v1/account/otp-method/',
    method: 'POST',
    body: {
      method,
    },
  });
};
export const verifyTwoFactorAuthSmsCode = async (code, phone) => {
  return http.innerSendAuthenticated({
    relativePath: '/api/v2/account/update-otp-phone-number/',
    method: 'PUT',
    body: {
      otp: code,
      phone,
    },
  });
};
export const sendTwoFactorAuthSms = async (phone) => {
  const parse = true;
  return http.innerSendAuthenticated(
    {
      relativePath: '/api/v2/account/sms-temp-verification/',
      method: 'PUT',
      body: {
        phone,
      },
    },
    parse,
  );
};
export const disableTwoFactorAuth = async () => {
  const parse = false;
  return http.innerSendAuthenticated(
    {
      relativePath: `/clients/api/v1/account/otp-toggle/`,
      method: 'DELETE',
    },
    parse,
  );
};
export const enableTwoFactorAuth = async () => {
  const parse = true;
  return http.innerSendAuthenticated(
    {
      relativePath: `/clients/api/v1/account/otp-toggle/`,
      method: 'POST',
    },
    parse,
  );
};
export const changePassword = async (password, newPassword) => {
  const parse = false;
  return http.innerSendAuthenticated(
    {
      relativePath: `/api/v2/account/update-password-authenticated/`,
      method: 'PUT',
      body: {
        'new-password': newPassword,
        'confirm-password': newPassword,
        'current-password': password,
      },
    },
    parse,
  );
};
export const revokeMemberInvite = async (orgFprint, memberId) => {
  const parse = false;
  return http.innerSendAuthenticated(
    {
      relativePath: `/clients/api/v1/org/${orgFprint}/membership/${memberId}/`,
      method: 'DELETE',
    },
    parse,
  );
};
export const resendMemberInvite = async (orgFprint, memberId) => {
  const parse = false;
  return http.innerSendAuthenticated(
    {
      relativePath: `/clients/api/v1/org/${orgFprint}/membership/${memberId}/action/resend-invite/`,
      method: 'POST',
    },
    parse,
  );
};
export const removeMember = async (orgFprint, memberId) => {
  const parse = false;
  return http.innerSendAuthenticated(
    {
      relativePath: `/clients/api/v1/org/${orgFprint}/membership/${memberId}/`,
      method: 'DELETE',
    },
    parse,
  );
};
export const grantAdmin = async (orgFprint, memberId) => {
  const parse = false;
  return http.innerSendAuthenticated(
    {
      relativePath: `/clients/api/v1/org/${orgFprint}/membership/${memberId}/action/add-admin/`,
      method: 'POST',
    },
    parse,
  );
};
export const removeAdmin = async (orgFprint, memberId) => {
  const parse = false;
  return http.innerSendAuthenticated(
    {
      relativePath: `/clients/api/v1/org/${orgFprint}/membership/${memberId}/action/remove-admin/`,
      method: 'POST',
    },
    parse,
  );
};
export const fetchMembers = async (orgFprint) => {
  const parse = true;
  return http.innerSendAuthenticated(
    {
      relativePath: `/clients/api/v1/org/${orgFprint}/membership/`,
      method: 'GET',
    },
    parse,
  );
};
export const inviteUsers = async (orgFprint, emails, isAdmin) => {
  const parse = true;
  return http.innerSendAuthenticated(
    {
      relativePath: `/clients/api/v1/org/${orgFprint}/membership/`,
      method: 'POST',
      body: {
        invite_emails: emails,
        is_admin: isAdmin,
      },
    },
    parse,
  );
};
export const updateOrganization = async (orgFprint, propertiesToUpdate) => {
  const parse = true;
  return http.innerSendAuthenticated(
    {
      relativePath: `/clients/api/v1/org/${orgFprint}/`,
      method: 'POST',
      body: {
        ...propertiesToUpdate,
      },
    },
    parse,
  );
};
export const setAsDefault = async (orgFprint) => {
  const parse = false;
  return http.innerSendAuthenticated(
    {
      relativePath: `/clients/api/v1/org/${orgFprint}/action/default/`,
      method: 'POST',
    },
    parse,
  );
};
export const getMemberships = fetchMemberships;
