import React, { useState } from 'react';
import moment from 'moment';
import { css } from 'aphrodite';
import { Error } from '~/src/components/Type';

/* Hooks */
import useUser from '~/src/hooks/useUser';

/* Components */
import Banner from '~/src/components/Banner';
import Button from '~/src/components/Button';
import { Label } from '~/src/components/Inputs';
import { Form, renderFormField } from '~/src/components/Forms';

/* Styles */
import styles from './styles';

/* Constants */

const MIN_NUMBER_OF_SEATS = 1;

const SubscriptionUpdateModalV2 = () => {
  const [discount] = useState(0);
  const [loading] = useState(false);
  const [showSeatsFilledNotification] = useState(false);
  const [seats] = useState(1);

  const userStore = useUser();

  const getError = () => {
    if (seats < MIN_NUMBER_OF_SEATS) {
      return '*Must have at least 1 seat';
    }

    if (showSeatsFilledNotification) {
      return '*Occupied seats cannot be removed. You need to remove users from your organization first';
    }

    return null;
  };

  const isDisabled = () => {
    const memberCount = userStore.currentOrganization.members.length;
    const { quantity } = userStore.currentOrganization.subscription;

    const additionalSeats = seats - quantity;

    return (
      seats < MIN_NUMBER_OF_SEATS ||
      seats < memberCount ||
      additionalSeats === 0 ||
      loading
    );
  };

  const getAdditionalSeatsAvailable = () => {
    const { subscription } = userStore.currentOrganization;
    const { quantity } = subscription;

    const additionalSeats = seats - quantity;

    return additionalSeats;
  };

  const getButtonLabel = () => {
    const additionalSeats = getAdditionalSeatsAvailable();

    const numberOfSeatsLabel = `${Math.abs(additionalSeats)} ${
      additionalSeats === 1 ? 'seat' : 'seats'
    }`;
    const addRemoveLabel = additionalSeats >= 0 ? 'Add' : 'Remove';

    const buttonLabel = `${addRemoveLabel} ${numberOfSeatsLabel}`;

    return loading ? 'Saving...' : buttonLabel;
  };

  const memberCount = userStore.currentOrganization.members.length;
  const { subscription } = userStore.currentOrganization;
  const { quantity } = subscription;

  const seatLabel = seats === 1 ? 'seat' : 'seats';

  const fields = [
    {
      id: 'seats',
      label: 'Number of seats',
      type: 'incrementer',
      minValue: memberCount,
      maxValue: 1000,
      defaultValue: quantity,
      validation: () => true,
    },
  ];

  const planPrice = userStore.currentOrganization.subscription.plan.amount;
  const discountedPlanPrice = planPrice - planPrice * (discount / 100);
  const discountedMonthlyPrice = discountedPlanPrice * (seats - quantity);

  const buttonLabel = getButtonLabel();

  const dayInMonth = moment().date();
  const daysInMonth = moment().daysInMonth();
  const pricePerDay = Math.round(discountedMonthlyPrice / daysInMonth);
  const proRatedPrice =
    dayInMonth * pricePerDay < 0 ? 0 : dayInMonth * pricePerDay;

  const totalPrice = ((planPrice * seats) / 100).toFixed(2);
  const totalDiscountedPrice = ((discountedPlanPrice * seats) / 100).toFixed(2);
  const dueToday = (proRatedPrice / 100).toFixed(2);

  const renderFields = fields.map((field) => renderFormField(field));

  const error = getError();
  const disabled = isDisabled();

  return (
    <div className={css(styles.modalContainer)}>
      <h1 className={css(styles.modalTitle)}>Update seats</h1>
      <div className={css(styles.modalBody)}>
        <Form
          triggerOnMount
          fields={renderFields}
          onChange={this.handleFormChange}
        />
        <Error cssStyle={styles.modalRowSmall}>{error}</Error>
        <div className={css(styles.modalRow)}>
          <Label label={'New plan total'}>
            ${totalPrice} per month for {seats} {seatLabel}
          </Label>
          {discount > 0 && (
            <Label label={'Discounted plan total'}>
              ${totalDiscountedPrice} per month for {seats} {seatLabel}
            </Label>
          )}
        </div>
        <div className={css(styles.modalRow)}>
          <Label label={'Due today'}>${dueToday}</Label>
        </div>
        <Banner small>
          <div>
            You will be charged a prorated amount of <b>${dueToday}</b> for the
            new seats for the remainder of the current billing period.
          </div>
        </Banner>
      </div>
      <div className={css(styles.modalActions, styles.modalActionsNoMargin)}>
        <Button cssStyle={styles.buttonAction} onClick={this.handleCancel}>
          Cancel
        </Button>{' '}
        <Button
          onClick={this.handleUpdateSeats}
          disabled={disabled}
          loading={loading}
          cssStyle={styles.buttonAction}
          primary
        >
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionUpdateModalV2;
