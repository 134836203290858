import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { QuestionnaireTableCardList } from './QuestionnaireTableCardList';
import { SearchCreateHeader } from './SearchCreateHeader';
import { type Questionnaire } from '@clio/questionnaire-builder';
import { ShareQuestionnaireLinkModal } from '~/src/entities/Questionnaires/components/ShareQuestionnaireLinkModal';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { VALIDATION_TYPES } from '~/src/utils/validation';
import { history } from '~/src/utils/history';
import { useOrgFprint } from '~/src/hooks/useOrgFprint';
import {
  QuestionnaireTemplate,
  getQuestionnaireTemplateUrlById,
  useClioQuestionnaires,
  useCreateClioQuestionnaire,
  useCreateQuestionnaireTemplate,
  useDeleteClioQuestionnaire,
  useDuplicateQuestionnaire,
  useUpdateClioQuestionnaire,
} from '~/src/entities/Questionnaires';
import { useLayoutContext } from '~/src/contexts/Layout';
import LoadMoreDisplayText from '~/src/components/LoadMoreDisplayText';
import usePageScroll from '~/src/hooks/usePageScroll';
import EmptyView from '~/src/components/EmptyView';

export const QuestionnaireTable: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [questionnaireId, setQuestionnaireId] = React.useState('');

  const orgFprint = useOrgFprint();

  const {
    data,
    isError,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useClioQuestionnaires(orgFprint);

  const createClioQuestionnaire = useCreateClioQuestionnaire(orgFprint);
  const createQuestionnaireTemplate = useCreateQuestionnaireTemplate();
  const updateClioQuestionnaire = useUpdateClioQuestionnaire(orgFprint);
  const deleteClioQuestionnaire = useDeleteClioQuestionnaire(orgFprint);
  const duplicateClioQuestionnaire = useDuplicateQuestionnaire(orgFprint);

  const { showToast, showModal, hideModal } = useLayoutContext();

  const pageScroll = usePageScroll();

  useEffect(() => {
    if ((pageScroll === 0 || pageScroll >= 85) && !isLoading) {
      fetchNextPage();
    }
  }, [fetchNextPage, isLoading, pageScroll]);

  const onCreate = () => {
    const handleCreateQuestionnaireTemplate = async (form: {
      fields: { name: { value: string } };
    }) => {
      const { value: title } = form.fields.name;

      createClioQuestionnaire.mutate(
        {
          clioQuestionnaire: { title: title, description: '' },
        },
        {
          onError: () => {
            showToast(LAYOUT_TOAST_TYPES.error, {
              message: 'Something went wrong, please try again later.',
            });
          },
          onSuccess: (res) => {
            history.push(`/questionnaires/${res.id}/select-documents`);
            hideModal();
          },
        },
      );
    };

    showModal(LAYOUT_MODAL_TYPES.formField, {
      title: 'Create new questionnaire',
      primaryActionTitle: 'Continue →',
      fields: [
        {
          label: 'Name',
          id: 'name',
          type: 'text',
          defaultValue: '',
          validation: VALIDATION_TYPES.present,
        },
      ],
      onConfirm: handleCreateQuestionnaireTemplate,
    });
  };

  const onSearch = () => {
    // TODO: once api has search capabilities
  };

  const handleShare = (questionnaireId: string) => {
    setIsModalOpen(true);
    setQuestionnaireId(questionnaireId);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleRename = (questionnaire: Questionnaire) => {
    const updateQuestionnaireTitle = async (form: {
      fields: { name: { value: string } };
    }) => {
      const { value: title } = form.fields.name;
      updateClioQuestionnaire.mutate(
        {
          questionnaireId: `${questionnaire.id}`,
          clioQuestionnaire: { title },
        },
        {
          onError: () => {
            showToast(LAYOUT_TOAST_TYPES.error, {
              message: 'Something went wrong, please try again later.',
            });
          },
          onSuccess: () => {
            showToast(LAYOUT_TOAST_TYPES.success, {
              message: 'Questionnaire title updated',
            });
            hideModal();
          },
        },
      );
    };

    showModal(LAYOUT_MODAL_TYPES.formField, {
      title: 'Rename questionnaire',
      primaryActionTitle: 'Save',
      fields: [
        {
          label: 'Name',
          id: 'name',
          type: 'text',
          defaultValue: questionnaire.title,
          validation: VALIDATION_TYPES.present,
        },
      ],
      onConfirm: updateQuestionnaireTitle,
    });
  };

  const handleDuplicate = async (originalQuestionnaire: Questionnaire) => {
    const duplicateQuestionnaire = async (form: {
      fields: { title: { value: string } };
    }) => {
      const { value: title } = form.fields.title;

      try {
        const response = await fetch(
          getQuestionnaireTemplateUrlById(
            orgFprint,
            `${originalQuestionnaire.id}`,
          ),
        );

        const templates: QuestionnaireTemplate[] = await response.json();

        const dupQuestionnaire = await duplicateClioQuestionnaire.mutateAsync({
          questionnaireId: `${originalQuestionnaire.id}`,
          title,
        });

        await createQuestionnaireTemplate.mutateAsync({
          orgFprint,
          questionnaireId: `${dupQuestionnaire.id}`,
          templateIds: templates.map((template) => template.template_id),
        });

        showToast(LAYOUT_TOAST_TYPES.success, {
          message: 'Questionnaire duplicated',
        });

        hideModal();
      } catch (e) {
        showToast(LAYOUT_TOAST_TYPES.error, {
          message: 'Something went wrong, please try again later.',
        });
      }
    };

    showModal(LAYOUT_MODAL_TYPES.formField, {
      title: 'Duplicate questionnaire',
      primaryActionTitle: 'Duplicate',
      fields: [
        {
          label: 'Name',
          id: 'name',
          type: 'text',
          defaultValue: originalQuestionnaire.title + ' (Copy)',
          validation: VALIDATION_TYPES.default,
        },
      ],
      onConfirm: duplicateQuestionnaire,
    });
  };

  const handleDelete = (questionnaire: Questionnaire) => {
    const deleteQuestionnaire = () =>
      deleteClioQuestionnaire.mutate(
        { questionnaireId: `${questionnaire.id}` },
        {
          onError: () => {
            showToast(LAYOUT_TOAST_TYPES.error, {
              message: 'Something went wrong, please try again later.',
            });
          },
          onSuccess: () => {
            showToast(LAYOUT_TOAST_TYPES.success, {
              message: 'Questionnaire deleted',
            });
            hideModal();
          },
        },
      );

    showModal(LAYOUT_MODAL_TYPES.confirm, {
      title: 'Delete questionnaire',
      message: `This will delete ${questionnaire.title}. This is a permanent action and cannot be undone.`,
      primaryActionTitle: 'Delete',
      onConfirm: deleteQuestionnaire,
    });
  };

  if (isError || (!isLoading && !data)) {
    return (
      <EmptyView
        title={'Your questionnaires will appear here'}
        paragraphs={[
          <>
            Questionnaire feature is unavaliable at the moment. Please try again
            later.
          </>,
        ]}
      >
        <></>
      </EmptyView>
    );
  }

  const questionnaires: Questionnaire[] = [];

  data?.pages.forEach((page) =>
    page.questionnaires.forEach((q: Questionnaire) => questionnaires.push(q)),
  );

  const isEmpty = !isLoading && !questionnaires.length;

  return (
    <>
      <ShareQuestionnaireLinkModal
        questionnaireId={questionnaireId}
        open={isModalOpen}
        onClose={handleCloseModal}
      />
      <SearchCreateHeader handleSearch={onSearch} handleCreate={onCreate} />
      {isEmpty ? (
        <Typography textAlign={'center'} variant="subtitle1">
          No questionnaires match that filter
        </Typography>
      ) : (
        <>
          <QuestionnaireTableCardList
            questionnaires={questionnaires}
            handleShare={handleShare}
            handleDuplicate={handleDuplicate}
            handleRename={handleRename}
            handleDelete={handleDelete}
          />
          <LoadMoreDisplayText
            loading={isLoading || isFetchingNextPage}
            done={!hasNextPage}
            onLoadMore={fetchNextPage}
          />
        </>
      )}
    </>
  );
};
