import cookieUtil from '~/src/utils/cookies';

import { MemebershipAPIResponse, useMemberships } from './useMemberships';

const getOrgFromMembership = ({ memberships }: MemebershipAPIResponse) => {
  const orgFprint = cookieUtil.getOrgFprint();
  const org = memberships.find(({ fprint, is_default }) =>
    orgFprint ? fprint === orgFprint : is_default,
  );

  return org || memberships[0];
};

const useCurrentOrg = () => {
  const result = useMemberships({
    staleTime: Number.POSITIVE_INFINITY,
  });
  return {
    ...result,
    org: result.data ? getOrgFromMembership(result.data) : undefined,
  };
};

export const useCurrentOrgFprint = () => useCurrentOrg().org?.fprint!;
