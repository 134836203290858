import { types, flow, getRoot, getParent, destroy } from 'mobx-state-tree';
import qs from 'qs';

/* Services */
import templateSetService from '~/src/services/templateSets';

/* Configurations */
import {
  DEFAULT_SEARCH,
  DEFAULT_SORT_BY,
  DEFAULT_ORDER,
  DEFAULT_LIMIT,
  DEFAULT_DONE,
} from '~/src/hooks/useResourcePagination';

/* Utils */
import { APIError } from '~/src/utils/error';
import { getTemplateSetIdentifier } from '~/src/utils/dataTransformers';
import { TemplateStore } from './templateStore';
import { TemplateDefaultValuesStore } from './templateDefaultValuesStore';
import { getOrgFprintFromStoreNode } from './utils';
import { history } from '../utils/history';

const MaybeNullTemplateReference = types.maybeNull(
  types.reference(TemplateStore),
);

const MaybeNullTemplateDefaultValuesReference = types.maybeNull(
  types.reference(TemplateDefaultValuesStore),
);

const MaybeNullTemplateWithValueReference = types.model({
  template: MaybeNullTemplateReference,
  templateDefaultValues: MaybeNullTemplateDefaultValuesReference,
});

const TemplateSetStore = types
  .model('TemplateSet', {
    id: types.integer,
    identifier: types.identifier,
    title: types.string,
    lastModified: types.string,
    createdAt: types.string,
    templates: types.optional(types.array(MaybeNullTemplateReference), []),
    templatesWithValues: types.optional(
      types.array(MaybeNullTemplateWithValueReference),
      [],
    ),
  })
  .actions((self) => {
    const updateTemplates = (newTemplates) => {
      self.templates = newTemplates;
    };
    const updateTemplatesWithValues = (newTemplatesWithValues) => {
      self.templatesWithValues = newTemplatesWithValues;
    };
    const update = flow(function* update(payload) {
      try {
        const res = yield templateSetService.update(
          getOrgFprintFromStoreNode(self),
          self.id,
          {
            title: payload.title,
            templateIds: payload.templateIds,
            templatesWithValues: payload.templatesWithValues,
          },
        );

        Object.keys(res).forEach((key) => {
          if (typeof self[key] !== 'undefined') {
            self[key] = res[key];
          }
        });

        return res;
      } catch (error) {
        console.error('Failed to update template set', error);
        return false;
      }
    });

    const removeTemplate = flow(function* removeTemplate(
      templateId,
      templateDefaultValuesId,
    ) {
      const nextTemplatesWithValues = [];
      const nextTemplateIds = [];
      let templateIndex = null;

      for (let i = 0; i < self.templatesWithValues.length; i += 1) {
        const { template, templateDefaultValues } = self.templatesWithValues[i];
        if (
          parseInt(template.id) === parseInt(templateId) &&
          (templateDefaultValuesId == templateDefaultValues ||
            templateDefaultValuesId == templateDefaultValues.id)
        ) {
          templateIndex = i;
        } else {
          nextTemplateIds.push(parseInt(template.id));
          nextTemplatesWithValues.push({
            templateId: parseInt(template.id),
            templateDefaultValuesId: templateDefaultValues?.id,
          });
        }
      }

      if (templateIndex) {
        self.templates.splice(templateIndex, 1);
        self.templatesWithValues.splice(templateIndex, 1);
      }

      const updateResult = yield self.update({
        id: self.id,
        templateIds: nextTemplateIds,
        templatesWithValues: nextTemplatesWithValues,
      });

      return updateResult;
    });

    const fetchTemplates = flow(function* fetchTemplates() {
      const rootStore = getRoot(self);
      for (let i = 0; i < self.templates.length; i += 1) {
        const templateId = self.templates[i];
        yield rootStore.templates.fetchTemplate(templateId);
      }
    });

    const fetchTags = flow(function* fetchTags() {
      for (let i = 0; i < self.templates.length; i += 1) {
        const template = self.templates[i];
        if (template && template.fetchTags) {
          yield template.fetchTags();
        }
      }
    });

    return {
      fetchTemplates,
      fetchTags,
      update,
      removeTemplate,
      updateTemplates,
      updateTemplatesWithValues,
    };
  })
  .views((self) => {
    const currentTemplates = () => {
      return self.templates.filter((template) => !!template);
    };

    const currentTemplatesWithValues = () => {
      return self.templatesWithValues.filter((twv) => !!twv);
    };

    const getTags = () => {
      let allTags = [];

      self.templates.forEach((template) => {
        allTags = allTags.concat(template.getTags());
      });

      return allTags;
    };

    return {
      getTags,
      currentTemplates,
      currentTemplatesWithValues,
    };
  });

const TemplateSetsStore = types
  .model('TemplateSetsStore', {
    isLoading: false,
    maxTemplateLimit: 10,
    error: '',
    dictionary: types.optional(types.map(TemplateSetStore), {}),
    list: types.optional(
      types.array(types.array(types.reference(TemplateSetStore))),
      [],
    ),
    done: types.optional(types.boolean, DEFAULT_DONE),
    search: types.optional(types.string, DEFAULT_SEARCH),
    sortBy: types.optional(types.string, DEFAULT_SORT_BY),
    order: types.optional(types.string, DEFAULT_ORDER),
    limit: types.optional(types.integer, DEFAULT_LIMIT),
    empty: types.optional(types.boolean, false),
  })
  .actions((self) => {
    const fetchById = flow(function* fetchById(id) {
      const rootStore = getRoot(self);
      const templateSet = self.dictionary.get(getTemplateSetIdentifier(id));

      if (templateSet) {
        return templateSet;
      }

      const nextTemplateSet = yield templateSetService.fetchById(
        getOrgFprintFromStoreNode(self),
        parseInt(id),
      );

      for (let i = 0; i < nextTemplateSet.templates.length; i += 1) {
        const templateId = nextTemplateSet.templates[i];
        const id = templateId.split('template:')[1];
        yield rootStore.templates.fetchTemplate(id);
      }

      return self.add(nextTemplateSet);
    });

    const add = (templateSet) => {
      self.dictionary.set(templateSet.identifier, templateSet);
      return self.dictionary.get(templateSet.identifier);
    };

    const fetchRelatedTemplateDefaultValues = async (templateSet, index) => {
      const rootStore = getParent(self);
      if (
        !templateSet.templatesWithValues ||
        !templateSet.templatesWithValues[index].templateDefaultValues
      ) {
        return;
      }
      const templateDefaultValuesIdentifier =
        templateSet.templatesWithValues[index].templateDefaultValues;
      const templateDefaultValuesId =
        templateDefaultValuesIdentifier.match(/\d+/g)[0];
      if (
        !rootStore.templateDefaultValues.getTemplateDefaultValuesFromStore(
          templateDefaultValuesId,
        )
      ) {
        const res =
          await rootStore.templateDefaultValues.getTemplateDefaultValues(
            templateDefaultValuesId,
          );
        rootStore.templateDefaultValues.addItem(
          templateDefaultValuesIdentifier,
          res,
        );
      }
    };

    const removeRelatedTemplateDefaultValuesFromSet = (
      templateDefaultValuesId,
    ) => {
      self.dictionary.forEach((templateSet) => {
        // find related template set
        const templateIdToBeRemoved = [];
        const newTemplatesWithValues = [];
        templateSet.templatesWithValues.forEach((twv) => {
          if (twv.templateDefaultValues?.id !== templateDefaultValuesId) {
            newTemplatesWithValues.push(twv);
          } else {
            templateIdToBeRemoved.push(twv.template);
          }
        });
        const newTemplates = templateSet.templates.filter(
          (template) => !templateIdToBeRemoved.includes(template),
        );
        templateSet.updateTemplatesWithValues(newTemplatesWithValues);
        templateSet.updateTemplates(newTemplates);
        if (
          templateSet.templates.length == 0 &&
          templateSet.templatesWithValues.length == 0
        ) {
          // if template set doesn't have templates any more, delete it
          self.remove(templateSet);
        }
      });
    };

    const fetch = flow(function* fetch({ page, search, sortBy, order, limit }) {
      const pageFetched =
        !!self.list.length >= page && self.list[page - 1].length === limit;
      const needsRefetch =
        search !== self.search ||
        self.sortBy !== sortBy ||
        self.order !== order ||
        self.limit !== limit;

      if (self.done && !needsRefetch) {
        return [];
      }

      if (pageFetched && !needsRefetch) {
        return self.list[page - 1];
      }

      if (needsRefetch) {
        self.list = [];
      }

      try {
        const rootStore = getParent(self);

        self.done = false;
        self.isLoading = true;

        const res = yield templateSetService.fetch({
          page,
          search,
          sortBy,
          order,
          limit,
          orgFprint: getOrgFprintFromStoreNode(self),
        });

        self.limit = limit;
        self.search = search;
        self.sortBy = sortBy;
        self.order = order;
        self.limit = limit;

        const pageItems = [];

        const fetchIds = [];
        const relatedFetches = [];

        for (let i = 0; i < res.length; i += 1) {
          const set = res[i];
          const templateSet = self.add(set);
          pageItems.push(templateSet.identifier);

          for (let j = 0; j < set.templates.length; j += 1) {
            const templateIdentifier = set.templates[j];
            const templateId = templateIdentifier.match(/\d+/g)[0];
            if (!rootStore.templates.getTemplate(templateId)) {
              fetchIds.push(templateId);
            }
            relatedFetches.push(fetchRelatedTemplateDefaultValues(set, j));
          }
        }

        yield Promise.all(
          Array.from(new Set(fetchIds)).map((id) =>
            rootStore.templates.fetchTemplate(id),
          ),
        );
        yield Promise.all(relatedFetches);

        if (self.list.length < page) {
          self.list.push(pageItems);
        } else {
          self.list[page - 1] = pageItems;
        }

        self.isLoading = false;
        self.empty = search.length === 0 && page === 1 && res.length == 0;
        self.error = '';

        self.done = pageItems.length === 0 || pageItems.length < self.limit;

        return pageItems;
      } catch (error) {
        self.isLoading = false;
        console.error('Error fetching template sets: ', error);
        self.error = 'Something went wrong, please try again later';
        return [];
      }
    });

    const fetchAll = flow(function* () {
      self.isLoading = true;

      const rootStore = getParent(self);

      try {
        const res = yield templateSetService.fetchAll(
          getOrgFprintFromStoreNode(self),
        );

        for (let i = 0; i < res.length; i += 1) {
          const set = res[i];
          for (let j = 0; j < set.templates.length; j += 1) {
            const templateId = set.templates[j];
            yield rootStore.templates.fetchTemplate(templateId);
          }
        }

        const templateSetIdentifiers = res.map((set) => {
          const templateSet = self.add(set);
          return templateSet.identifier;
        });

        self.list[0] = templateSetIdentifiers;

        self.isLoading = false;
        self.error = '';
        return res;
      } catch (error) {
        self.error = typeof error === 'string' ? error : 'Something went wrong';
        self.isLoading = false;
        return false;
      }
    });

    const create = flow(function* (payload) {
      self.isLoading = true;
      self.done = false;

      try {
        const newTemplateSet = yield templateSetService.create(
          getOrgFprintFromStoreNode(self),
          {
            title: payload.title,
            templateIds: payload.templateIds,
            templatesWithValues: payload.templatesWithValues,
          },
        );

        // first, fetch each template and default values under newTemplateSet
        const rootStore = getParent(self);
        for (let j = 0; j < newTemplateSet.templates.length; j += 1) {
          const templateIdentifier = newTemplateSet.templates[j];
          const templateId = templateIdentifier.match(/\d+/g)[0];
          if (!rootStore.templates.getTemplate(templateId)) {
            yield rootStore.templates.fetchTemplate(templateId);
          }
          fetchRelatedTemplateDefaultValues(newTemplateSet, j);
        }

        // second, add newTemplateSet to TemplateSets dictionary
        const templateSet = self.add(newTemplateSet);

        if (!self.list[0]) {
          self.list[0] = [];
        }

        self.list[0].unshift(templateSet.identifier);

        self.isLoading = false;
        self.error = '';
        return templateSet;
      } catch (error) {
        console.error('Failed to create template set', error);
        if (error instanceof APIError) {
          self.error = error.message;
        } else if (typeof error === 'string') {
          self.error = error;
        } else {
          self.error = 'Something went wrong!';
        }
        self.isLoading = false;
        throw error;
      }
    });

    const remove = flow(function* remove(templateSetToRemove) {
      try {
        yield templateSetService.delete(
          getOrgFprintFromStoreNode(self),
          templateSetToRemove.id,
        );
        const identifier = getTemplateSetIdentifier(templateSetToRemove.id);

        for (let page = 0; page < self.list.length; page += 1) {
          const pageList = self.list[page] || [];
          for (let itemIndex = 0; itemIndex < pageList.length; itemIndex += 1) {
            const item = pageList[itemIndex];
            if (item && item.identifier === identifier) {
              pageList.splice(itemIndex, 1);
            }
          }

          self.list[page] = pageList;
        }

        const item = self.getById(templateSetToRemove.id);
        self.dictionary.delete(templateSetToRemove.identifier);

        if (item) {
          destroy(item);
        }

        return true;
      } catch (error) {
        console.error(error);
        self.error = error;
        return false;
      }
    });

    const generate = (id) => {
      const templateSet = self.getById(id);
      const templateIds = templateSet.templatesWithValues.map(
        (templatesWithValue) =>
          templatesWithValue.templateDefaultValues
            ? `${
                templatesWithValue.templateDefaultValues.template.toJSON().docId
              }+${templatesWithValue.templateDefaultValues.id}`
            : `${templatesWithValue.template.id}`,
      );
      const queryParams = qs.stringify(
        { ids: templateIds, title: templateSet.title },
        { encode: false },
      );

      history.push(`/templates/docset/new?${queryParams}`);
    };

    const removeTemplate = (templateId) => {
      self.list.flat().forEach((set) => set.removeTemplate(templateId));
    };

    const addTemplatesToSeletionSidebar = (templateSet) => {
      const rootStore = getRoot(self);

      templateSet.children.forEach((templateWithValues) => {
        rootStore.sidebarItems.add({
          id: templateWithValues.defaultValuesId
            ? `${templateWithValues.id}+${templateWithValues.defaultValuesId}`
            : `${templateWithValues.id}`,
          defaultValueId: templateWithValues.defaultValuesId,
          title: templateWithValues.title,
          value: templateWithValues.defaultValuesId
            ? `${templateWithValues.id} ${templateWithValues.defaultValuesId}`
            : `${templateWithValues.id}`,
        });
      });
    };

    return {
      add,
      fetch,
      generate,
      fetchAll,
      fetchById,
      remove,
      create,
      removeTemplate,
      addTemplatesToSeletionSidebar,
      removeRelatedTemplateDefaultValuesFromSet,
    };
  })
  .views((self) => {
    const getById = (id) => {
      return self.dictionary.get(getTemplateSetIdentifier(id));
    };

    const get = (templateSet) => {
      return self.dictionary.get(templateSet.identifier);
    };

    return {
      getById,
      get,
    };
  });

export { TemplateSetsStore as default, TemplateSetStore };
