import env from '~/src/utils/env';

export const getProjectUrl = (orgFprint: string, projectId: number | string) =>
  `${env.apiUrl}/clients/api/v2/org/${orgFprint}/project/${projectId}/`;

export const getV1ProjectUrl = (
  orgFprint: string,
  projectId: number | string,
) => `${env.apiUrl}/clients/api/v1/org/${orgFprint}/project/${projectId}/`;

export const getProjectContactUrl = (
  orgFrpint: string,
  projectId: number | string,
  contactId: number | string,
  role?: string,
) =>
  `${getV1ProjectUrl(orgFrpint, projectId)}contact/${contactId}/${
    role ? `?role=${role}` : ''
  }`;

export const getProjectPopulateUrl = (orgFprint: string, projectId: number) =>
  `${env.apiUrl}/panels/api/v2/org/${orgFprint}/project/${projectId}/populate-ms-words/`;

export const getV1ContactUrl = (
  orgFprint: string,
  contactId: number | string,
) => `${env.apiUrl}/clients/api/v1/org/${orgFprint}/contact/${contactId}/`;
