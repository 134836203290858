import { StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

export default StyleSheet.create({
  container: {
    padding: theme.unit * 3,
  },
  section: {
    marginBottom: theme.unit * 5,
  },
  header: {
    fontSize: theme.fonts.size.small,
    marginBottom: theme.unit,
  },
  cardBody: {
    fontSize: theme.fonts.size.small,
    lineHeight: '1.25em',
  },
  buttonLink: {
    display: 'inline-block',
    padding: `${theme.unit}px 0`,
    fontSize: theme.fonts.size.small,
    ':active': { outline: 'none' },
    ':focus': { outline: 'none' },
  },
  link: {
    display: 'block',
    padding: `${theme.unit}px ${theme.unit}px`,
    fontSize: theme.fonts.size.small,
    color: theme.colors.charcoal,
  },
  linkActive: {
    borderRadius: theme.borderRadius,
    background: theme.colors.paleEggplant,
    color: theme.colors.eggplant,
  },
  settingsFlexRow: {
    display: 'flex',
    marginBottom: theme.unit * 4,
  },
  settingsRow: {
    marginBottom: theme.unit * 4,
    ':last-of-type': {
      marginBottom: theme.unit,
    },
  },
  settingsLabel: {
    fontSize: '14px',
    color: '#777E93',
    marginBottom: theme.unit * 1.5,
  },
  settingsCardContainer: {
    margin: '0 auto',
    maxWidth: theme.unit * 70,
  },
  settingsOuterButtonContainer: {
    marginTop: theme.unit * 4,
  },
  settingsAccountPhoto: {
    display: 'flex',
  },
  settingsAccountButton: {
    marginLeft: theme.unit * 2,
  },
  settingsAccountButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.unit * 3,
  },
  settingsSecurityButton: {
    marginRight: theme.unit * 2,
  },
  settingsSecurityTwoFactor: {
    marginBottom: theme.unit * 2,
  },
  settingsTeamContainer: {
    margin: '0 auto',
    maxWidth: theme.unit * 110,
  },
  settingsIntegrationRow: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: theme.border,
    fontSize: theme.fonts.size.small,
    color: theme.colors.charcoal,
    ':last-of-type': {
      borderBottom: '0',
    },
  },
  settingsIntegrationRowItem: {
    width: '20%',
    padding: theme.unit * 3,
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    ':last-of-type': {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'flex-end',
      textAlign: 'center,',
    },
  },
  settingsIntegrationRowItemLastSynced: {
    display: 'inline-block',
    flexGrow: 1,
    fontSize: '11px',
    paddingLeft: 0,
    paddingRight: 0,
  },
  settingsIntegrationRowItemIcon: {
    marginRight: theme.unit * 2,
  },
  settingsIntegrationCard: {
    width: theme.unit * 90,
  },

  settingsIntegrationInformation: {
    borderColor: '#2B88FF',
    borderRadius: '4px',
    display: 'inline-block',
    margin: '15px auto',
    width: theme.unit * 90,
    background: '#EFF6FF',
  },

  settingsIntegrationInformationTitle: {
    marginBottom: '15px',
    fontWeight: 'bold',
  },

  /* Settings Organization */
  settingsOrganizationSetAsDefault: {
    fontSize: '12px',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
    userSelect: 'none',
  },
  settingsOrganizationDefault: {
    background: theme.colors.white,
    fontSize: '11px',
    position: 'absolute',
    right: 0,
    zIndex: 1,
    userSelect: 'none',
    letterSpacing: ' 0.025em',
    fontWeight: '500',
    top: '0px',
    color: theme.colors.chrome,
  },
  settingsOrganizationNameRow: {
    position: 'relative',
  },

  /* Settings Team */
  pendingInvitationLabel: {
    background: theme.colors.pattensBlue,
    padding: '5px 10px',
    borderRadius: '13px',
    fontSize: '14px',
    display: 'inline-block',
  },

  rowActionButton: {
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.unit * 1}px ${theme.unit * 2}px`,
    paddingRight: theme.unit,
  },

  /* Subscriptions */
  settingsSubscriptionLabelButton: {
    marginTop: theme.unit * 2,
  },
});
