import React from 'react';
import { observer } from 'mobx-react';

import RadioToggle from '~/src/components/RadioToggle';
import { css, StyleSheet } from 'aphrodite';
import theme from '~/src/theme';

const styles = StyleSheet.create({
  populateSidebarItem: {
    display: 'flex',
    borderRadius: theme.borderRadius,
    fontSize: theme.fonts.size.small,
    padding: `${theme.unit}px ${theme.unit * 3}px`,
    marginBottom: theme.unit,
    ':hover': {
      cursor: 'pointer',
    },
  },
  populateSidebarItemSelected: {
    color: theme.colors.eggplant,
    background: theme.colors.paleEggplant,
  },
  populateSidebarItemIcon: {
    marginRight: theme.unit,
  },
});

const PopulateSidebarItemContainer = (props) => {
  const { card, onCardClick } = props;

  return (
    <div
      className={css(
        styles.populateSidebarItem,
        card.focus && styles.populateSidebarItemSelected,
      )}
      onClick={() => onCardClick(card.id)}
    >
      <i className={css(styles.populateSidebarItemIcon)}>
        <RadioToggle
          size={1.5}
          state={card.isComplete()}
          color={card.focus ? theme.colors.eggplant : null}
        />
      </i>
      {card.name}
    </div>
  );
};

export default observer(PopulateSidebarItemContainer);
