import { useMutation, UseMutationOptions } from 'react-query';
import { APIError } from '~/src/utils/error';
import { Contact } from '../types';
import {
  composeMutationOpts,
  fetchAndParse,
  useInvalidateQuery,
} from '../../utils';
import { keys } from '../keys';
import { getContactsUrl } from '../urls';
import { useCurrentOrgFprint } from '../../user';

type CreateContact = Omit<Partial<Contact>, 'id'>;

export const useCreateContact = (
  options: UseMutationOptions<Contact, APIError, CreateContact> = {},
) => {
  const orgFprint = useCurrentOrgFprint();
  return useMutation(
    composeMutationOpts(options, {
      onSuccess: useInvalidateQuery(keys.all),
      mutationFn: (body) =>
        fetchAndParse(getContactsUrl(orgFprint), {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body),
        }),
    }),
  );
};
