import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* Hooks */
import useContact from '~/src/hooks/useContact';

/* Components */
import ContactSlideIn from '~/src/components/Contacts/ContactSlideIn';

const ContactSlideInContainer = (props) => {
  const { id, fieldValues, hideSlideIn, onSave } = props;

  const { contact, updateContact } = useContact(id, props.matterId);

  const [edit, setEdit] = useState(props.edit);
  const [formValue, setFormValue] = useState({});

  const handleEdit = () => setEdit(true);
  const handleCancelEdit = () => setEdit(false);
  const getFormFieldValues = () => {
    const values = {};
    Object.keys(formValue.fields).forEach((key) => {
      values[key] = formValue.fields[key].value;
    });

    return values;
  };

  const handleFormChange = (value) => {
    setFormValue(value);
  };

  const handleSave = async () => {
    const newContact = getFormFieldValues();

    const updatedContact = await updateContact(id, newContact);

    if (hideSlideIn) {
      hideSlideIn();
    }

    if (onSave) {
      onSave(updatedContact);
    }
  };

  return contact && contact.id ? (
    <ContactSlideIn
      fieldValues={fieldValues}
      contact={contact}
      edit={edit}
      onFormChange={handleFormChange}
      onCancelEdit={handleCancelEdit}
      onEdit={handleEdit}
      onSave={handleSave}
    />
  ) : null;
};

ContactSlideInContainer.defaultProps = {
  hideSlideIn: () => {},
  edit: false,
};

ContactSlideInContainer.propTypes = {
  hideSlideIn: PropTypes.func,
  id: PropTypes.number,
  edit: PropTypes.bool,
};

export default ContactSlideInContainer;
