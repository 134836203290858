/* Libraries */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Button from '~/src/components/Button';
import qs from 'qs';

/* Containers */
import LibraryContainer from './Library';
import TemplatesContainer from '~/src/containers/Templates';
import TemplateSetsContainer from '~/src/containers/TemplateSets';
import CourtFormsLandingContainer from '~/src/containers/CourtFormsLandingContainer';
import PdfTableContainer from '~/src/containers/PdfTableContainer';
import StateRegionTableContainer from '~/src/containers/StateRegionTableContainer';

import { denormalizeLocality } from '~/src/components/CourtForms/StateRegionTable';
import TemplateDefaultValuesListContainer from '~/src/containers/TemplateDefaultValuesList';
import Page from '~/src/components/PageLayout';
import Tabs from '~/src/components/PageLayout/Tabs';
import { history } from '~/src/utils/history';
import { SelectionSidebarHeader } from '~/src/components/SelectionSidebar/SelectionSidebarHeader';
import { SaveAsSetButton } from './SaveAsSetButton';

const tabs = (
  <Tabs
    tabsMetadata={[
      {
        headerText: 'Sets',
        toUrl: '/library/template-sets',
      },
      {
        headerText: 'Court forms',
        toUrl: '/library/court-forms',
        requiredFeatureAccess: 'isUnifiedPlatform',
      },
      {
        headerText: 'Form templates',
        toUrl: '/library/form-templates',
        requiredFeatureAccess: 'isCourtFormsEnabled',
      },
      {
        headerText: 'Word templates',
        toUrl: '/library/templates',
      },
    ]}
  />
);

export const LibraryPage = () => {
  return (
    <Page
      showSelectionSidebar
      selectionSidebarProps={{
        title: 'Selection',
        renderHeader: (items) => (
          <SelectionSidebarHeader
            title={'Selection'}
            count={items.length}
            extra={<SaveAsSetButton items={items} />}
          />
        ),
        renderFooter: (items) => (
          <div className="border-t p-3 flex flex-row-reverse bg-white">
            <Button
              primary
              disabled={items.length === 0}
              onClick={() => {
                const templateIds = items.map((item) => item.id);
                const queryParams = qs.stringify(
                  { ids: templateIds },
                  { encode: false },
                );
                history.push(`/templates/docset/new?${queryParams}`);
              }}
            >
              Draft new {items.length < 2 ? 'document' : 'documents'} →
            </Button>
          </div>
        ),
      }}
      title={'Library'}
      headerProps={{ tabs }}
    >
      <LibraryContainer>
        <Switch>
          <Redirect exact from="/library" to="/library/template-sets" />

          <Route exact path="/library/template-sets">
            <TemplateSetsContainer />
          </Route>

          <Route exact path="/library/court-forms">
            <CourtFormsLandingContainer />
          </Route>
          <Route exact path="/library/form-templates">
            <TemplateDefaultValuesListContainer />
          </Route>
          <Route exact path="/library/court-forms/federal_USCIS">
            <PdfTableContainer filterCategory="FEDERAL" />
          </Route>
          <Route
            exact
            path="/library/court-forms/:stateCode/form-listing"
            render={({ match: { params } }) => {
              const { stateCode } = params;
              return (
                <PdfTableContainer filterCategory={stateCode} locality="None" />
              );
            }}
          />
          <Route
            exact
            path="/library/court-forms/:stateCode/locality-listing"
            render={({ match: { params } }) => {
              const { stateCode } = params;
              return <StateRegionTableContainer filterCategory={stateCode} />;
            }}
          />
          <Route
            exact
            path="/library/court-forms/:stateCode/:locality/form-listing"
            render={({ match: { params } }) => {
              const { stateCode, locality: normalizedLocality } = params;
              const denormalizedLocality =
                denormalizeLocality(normalizedLocality);
              return (
                <PdfTableContainer
                  filterCategory={stateCode}
                  locality={denormalizedLocality}
                />
              );
            }}
          />

          <Route exact path="/library/templates">
            <TemplatesContainer />
          </Route>
        </Switch>
      </LibraryContainer>
    </Page>
  );
};
