/* Utilities */
import { delegateMSTEvent } from '~/src/utils/analytics';
import analyticsService from '~/src/services/analytics';

/* Events */
const actionEvents = {
  update: (actionArgs, rootStore) => {
    const [properties] = actionArgs;

    const { displayName, firstName, lastName } = properties;

    const currentProfile = rootStore.user.profile;

    const oldProperties = {
      oldDisplayName: currentProfile.displayName,
      oldFirstName: currentProfile.firstName,
      oldLastName: currentProfile.lastName,
    };

    const newProperties = {
      newDisplayName: displayName,
      newFirstName: firstName,
      newLastName: lastName,
    };

    analyticsService.track('Profile Updated', {
      ...oldProperties,
      ...newProperties,
    });
  },
  uploadPhoto: () => {
    analyticsService.track('Profile Avatar Uploaded');
  },
  removePhoto: () => {
    analyticsService.track('Profile Avatar Removed');
  },
  disableTwoFactorAuth: () => {
    analyticsService.track('Account 2FA Disabled');
  },
  enableTwoFactorAuth: (actionArgs) => {
    const [type] = actionArgs;
    analyticsService.track('Account 2FA Enabled', {
      type,
    });
  },
  changePassword: () => {
    analyticsService.track('Account Password Changed');
  },
  revokeMemberInvitation: (actionArgs) => {
    const [memberId] = actionArgs;

    analyticsService.track('Account Member Invitation Revoked', {
      memberId,
    });
  },
  resendMemberInvitation: (actionArgs) => {
    const [memberId] = actionArgs;

    analyticsService.track('Account Member Invitation Resent', {
      memberId,
    });
  },
  removeMember: (actionArgs) => {
    const [memberId] = actionArgs;
    analyticsService.track('Account Member Removed', {
      memberId,
    });
  },

  inviteUsers: (actionArgs) => {
    const [emails, isAdmin] = actionArgs;

    emails.split(',').forEach((email) => {
      analyticsService.track('Account Member Invited', {
        email: email.trim(),
        isAdmin,
      });
    });
  },
};

const flowReturnEvents = {
  createSubscription: (actionArgs) => {
    const [subscription] = actionArgs;
    if (!subscription) {
      return;
    }

    let properties = {
      subscriptionCoupon: subscription.coupon,
      subscriptionNumberOfSeats: subscription.seats,
      subscriptionPlanName: subscription.name,
    };

    if (subscription && subscription.plan && subscription.plan.id) {
      properties = {
        planId: subscription.plan.id,
        subscriptionCoupon: subscription.coupon,
        subscriptionStatus: subscription.status,
        subscriptionRenewalDate: subscription.current_period_end,
        subscriptionCancelledData: subscription.canceledAt,
        subscriptionNumberOfSeats: subscription.quantity,
        subscriptionPlanName: subscription.plan.name,
        subscriptionPlanAmount: subscription.plan.amount,
        subscriptionPlanInterval: subscription.plan.interval,
      };
    }

    analyticsService.track('Pro Account Subscribed', properties);
  },
  updateSubscription: (actionArgs) => {
    const [{ nextSubscription, previousSubscription }] = actionArgs;

    if (previousSubscription && nextSubscription) {
      analyticsService.track('Account Subscription Updated', {
        previousPlanId: previousSubscription.plan.id,
        previousSubscriptionStatus: previousSubscription.status,
        previousSubscriptionRenewalDate:
          previousSubscription.current_period_end,
        previousSubscriptionCancelledData: previousSubscription.canceledAt,
        previousSubscriptionNumberOfSeats: previousSubscription.quantity,
        previousSubscriptionPlanName: previousSubscription.plan.name,
        previousSubscriptionPlanAmount: previousSubscription.plan.amount,
        previousSubscriptionPlanInterval: previousSubscription.plan.interval,
        nextPlanId: nextSubscription.plan.id,
        nextSubscriptionStatus: nextSubscription.status,
        nextSubscriptionRenewalDate: nextSubscription.current_period_end,
        nextSubscriptionCancelledData: nextSubscription.canceledAt,
        nextSubscriptionNumberOfSeats: nextSubscription.quantity,
        nextSubscriptionPlanName: nextSubscription.plan.name,
        nextSubscriptionPlanAmount: nextSubscription.plan.amount,
        nextSubscriptionPlanInterval: nextSubscription.plan.interval,
      });
    }
  },
};

export default (call, rootStore) => {
  return delegateMSTEvent(call, rootStore, {
    events: actionEvents,
    flowReturnEvents,
  });
};
