import { css } from 'aphrodite';
import React, { useState } from 'react';
import { useUserPreferences } from '~/src/entities/user';
import { useSyncSingleMatter } from '~/src/hooks/useSyncSingleMatter';
import Overlay from '../Modal/Overlay';
import styles from './styles';
import SyncSingleMatterModalContent from './SyncSingleMatterModalContent';

export const useSyncSingleMatterModal = (
  options: Parameters<typeof useSyncSingleMatter>[0] = {},
) => {
  const [clioMatterId, setClioMatterId] = useState<string>('');

  const hide = () => setClioMatterId('');

  const { mutate, isLoading, isError, isSuccess, reset } =
    useSyncSingleMatter(options);

  const performSync = (clio_matter_id: string) =>
    mutate({ clio_matter_id }, { onSuccess: hide });

  const { data: userPreferences } = useUserPreferences();
  const requiresConfirm =
    !userPreferences || userPreferences.warn_sync_overwrite;

  const shouldShow = !!clioMatterId;
  const isConfirmed = isLoading || isError || isSuccess;

  const modal = (
    <Overlay
      onCancel={hide}
      show={shouldShow}
      disableClickCatcher={isLoading}
      onAfterClose={reset}
    >
      <div className={css(styles.modalContent)}>
        <SyncSingleMatterModalContent
          onCancel={hide}
          onConfirm={() => performSync(clioMatterId)}
          requiresConfirm={requiresConfirm && !isConfirmed}
          error={isError}
        />
      </div>
    </Overlay>
  );

  return [
    {
      sync: (clio_matter_id: string) => {
        setClioMatterId(clio_matter_id);
        if (!requiresConfirm) performSync(clio_matter_id);
      },
    },
    modal,
  ] as const;
};
