import * as React from 'react';
import {
  Dialog,
  Slide,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useGetClioQuestionnaireUrl } from '~/src/entities/Questionnaires/hooks';
import { useOrgFprint } from '~/src/hooks/useOrgFprint';
import Spinner from '~/src/components/Spinner';
import { ShareLink } from './ShareLink';
import { SelectMatter } from './SelectMatter';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogContainer: React.FC<{
  open: boolean;
  onClose: () => void;
  children: any;
}> = ({ open, onClose, children }) => {
  return (
    <Dialog
      //@ts-ignore: Need to disable tabIndex for 'Create new matter' modal to work
      TransitionProps={{ tabIndex: '' }}
      sx={{
        zIndex: 1,
      }}
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '100%',
          maxHeight: '560px',
          minWidth: '560px',
          padding: '1em',
        },
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      {children}
    </Dialog>
  );
};

const ShareQuestionnaireLink: React.FC<{
  questionnaireId: string;
  onClose: () => void;
}> = ({ questionnaireId, onClose }) => {
  const orgFprint = useOrgFprint();
  const {
    data: questionnaireLink,
    mutate: shareLink,
    isError,
    isLoading,
  } = useGetClioQuestionnaireUrl();

  const handleGenerateLink = (matterId: string) => {
    shareLink({ orgFprint, questionnaireId, matterId });
  };

  if (isError) {
    return (
      <>
        <DialogTitle>An error occurred.</DialogTitle>
        <DialogContent>Please try again later.</DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </>
    );
  }

  if (isLoading) {
    return (
      <>
        <DialogTitle>Generating link...</DialogTitle>
        <DialogContent>
          <Spinner />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </>
    );
  }

  if (questionnaireLink) {
    return (
      <ShareLink questionnaireLink={questionnaireLink} onClose={onClose} />
    );
  }

  return (
    <SelectMatter
      onClose={onClose}
      onGenerateLink={handleGenerateLink}
      questionnaireId={questionnaireId}
    />
  );
};

export const ShareQuestionnaireLinkModal: React.FC<{
  questionnaireId: string;
  open: boolean;
  onClose: () => void;
}> = ({ questionnaireId, open, onClose }) => (
  <DialogContainer open={open} onClose={onClose}>
    <ShareQuestionnaireLink
      questionnaireId={questionnaireId}
      onClose={onClose}
    />
  </DialogContainer>
);
