import React, { useState, useEffect } from 'react';
import { css } from 'aphrodite';
import { isEqual, cloneDeep } from 'lodash';
import { inject, observer } from 'mobx-react';

/* Higher Order Components */
import WithLayoutProps from '~/src/hoc/WithLayoutProps';

/* Hooks */
import useUser from '~/src/hooks/useUser';

/* Components */
import { Error } from '~/src/components/Type';
import Card from '~/src/components/Card';
import CreditCardForm from '~/src/components/CreditCardForm';
import Button from '~/src/components/Button';
import { Form, renderFormField } from '~/src/components/Forms';
import { TextInput } from '~/src/components/Inputs';
import { Heading, Text } from '~/src/components/Typography';

/* Styles */
import modalStyles from '~/src/components/Modal/styles';
import { ReactComponent as MiniSpinner } from '~/src/static/mini-spinner.svg';
import { ReactComponent as BackArrowIcon } from '~/src/static/back-arrow-icon.svg';

/* Constants */
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';

import {
  COURT_FORM_PLANS,
  DOCUMENT_AUTOMATION_PLANS,
  COUPON,
} from '~/src/utils/constants';
import styles from './styles';
import { history } from '~/src/utils/history';

const ChoosePlanForm = ({
  onChange,
  cycle,
  courtFormPlans,
  documentAutomationPlans,
  oneTimeCharges,
}) => {
  const nextCourtFormPlans = courtFormPlans;
  const nextDocumentAutomationPlans = documentAutomationPlans;

  const user = useUser();
  let quantity = 1;
  // let cycleValue = "monthly";

  const subscription =
    user.currentOrganization && user.currentOrganization.subscription;
  if (subscription && subscription.freeTrialIsActive()) {
    // quantity = subscription.quantity;
    quantity = user.currentOrganization.members.length; // the number of occupied seats
  }

  const fields = [
    {
      id: 'seats',
      label: 'Number of seats',
      type: 'incrementer',
      minValue: 1,
      maxValue: 250,
      defaultValue: quantity,
      validation: () => true,
    },
    {
      id: 'plan',
      label: 'Plan',
      type: 'planChoice',
      options: [
        {
          label: 'Fillable court forms',
          plans: COURT_FORM_PLANS[cycle],
          defaultValue: nextCourtFormPlans,
        },
        {
          label: 'Word document automation',
          plans: ([automationSelected]) => {
            return DOCUMENT_AUTOMATION_PLANS[cycle].map((plan) => {
              if (plan.name !== 'VIP ONBOARDING SERVICE') return plan;
              return { ...plan, disabled: !automationSelected };
            });
          },
          handleUpdate: ([selected1, selected2]) => {
            return selected1 ? [selected1, selected2] : [selected1, false];
          },
          defaultValue: [nextDocumentAutomationPlans, oneTimeCharges],
        },
        {
          label: 'e-signatures',
          subLabel: 'Included for free',
          plans: [],
        },
      ],
      validation: () => true,
    },
    {
      id: 'cycle',
      label: 'Billing cycle',
      type: 'multipleChoice',
      defaultValue: 'monthly', // defaultValue: cycleValue,

      options: [
        { label: 'Monthly', value: 'monthly' },
        { label: 'Annual', value: 'annual' },
      ],
      validation: () => true,
    },
  ];

  const renderFields = fields.map((field) => renderFormField(field));

  return (
    <div>
      <div className={css(modalStyles.modalBody)}>
        <Form fields={renderFields} onChange={onChange} />
      </div>
    </div>
  );
};

const renderDueToday = (totalPrice) => {
  if (!totalPrice) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const user = useUser();

  const subscription =
    user.currentOrganization && user.currentOrganization.subscription;

  if (subscription && subscription.freeTrialIsActive()) {
    return (
      <div>
        <div className="text-base font-medium mb-2 mt-6">Due</div>
        <div className="flex flex-row justify-between text-xs">
          <span>Total</span>
          <span className="font-semibold">
            ${totalPrice.total / 100 + totalPrice.discount / 100}
          </span>
        </div>

        {totalPrice.discount > 0 && (
          <div className="flex flex-row justify-between text-xs">
            <span>Discount ({totalPrice.discount_percent}%)</span>
            <span className="font-semibold text-emerald-600">
              -${totalPrice.discount / 100}
            </span>
          </div>
        )}

        <div className="flex flex-row justify-between text-xs mb-4">
          <span>Due today</span>
          <span className="font-semibold">${totalPrice.total / 100}</span>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="text-base font-medium mb-2 mt-6">Due</div>
      <div className="flex flex-row justify-between text-xs">
        <span>Total</span>
        <span className="font-semibold">
          ${totalPrice.total / 100 + totalPrice.discount / 100}
        </span>
      </div>

      {totalPrice.discount > 0 && (
        <div className="flex flex-row justify-between text-xs mb-4">
          <span>Discount ({totalPrice.discount_percent}%)</span>
          <span className="font-semibold text-emerald-600">
            -${totalPrice.discount / 100}
          </span>
        </div>
      )}

      <div className="flex flex-row justify-between text-xs">
        <span>Due today</span>
        <span className="font-semibold">${totalPrice.total / 100}</span>
      </div>
    </div>
  );
};

const SummaryForm = ({
  onPaymentMethod,
  totalPrice,
  couponSubmitting,
  onCouponChange,
  onCouponSubmit,
  onConfirm,
  courtFormPlans,
  documentAutomationPlans,
  oneTimeCharges,
  seats,
  cycle,
  couponError,
  coupon,
}) => {
  return (
    <div>
      <h1 className={css(modalStyles.modalTitle)}>Summary</h1>

      <div className="text-base font-medium mb-2">Recurring charges</div>

      <div className="flex flex-row justify-between text-xs">
        <span>
          {seats} seat{seats === 1 ? '' : 's'}
        </span>
        <span>
          {' '}
          ${cycle === 'monthly' ? 30 * seats : 300 * seats}{' '}
          {cycle === 'monthly' ? ' / month' : ' / year'}
        </span>
      </div>

      {courtFormPlans.map((planId) => {
        const plan = COURT_FORM_PLANS[cycle].find((item) => item.id === planId);
        return plan ? (
          <div className="flex flex-row justify-between text-xs" key={planId}>
            <span>{plan.name}</span>
            <span>
              ${plan.price} / {cycle === 'monthly' ? 'month' : 'year'}
            </span>
          </div>
        ) : null;
      })}

      {documentAutomationPlans.map((planId) => {
        const plan = DOCUMENT_AUTOMATION_PLANS[cycle].find(
          (item) => item.id === planId,
        );
        return plan ? (
          <div className="flex flex-row justify-between text-xs" key={planId}>
            <span>{plan.name}</span>
            <span>
              ${plan.price} / {cycle === 'monthly' ? 'month' : 'year'}
            </span>
          </div>
        ) : null;
      })}

      {oneTimeCharges.length > 0 && (
        <div className="text-base font-medium mt-4 mb-2">One-time charges</div>
      )}
      {oneTimeCharges.map((planId) => {
        const plan = DOCUMENT_AUTOMATION_PLANS[cycle].find(
          (item) => item.id === planId,
        );
        return plan ? (
          <div className="flex flex-row justify-between text-xs" key={planId}>
            <span>{plan.name}</span>
            <span>${plan.price}</span>
          </div>
        ) : null;
      })}

      <div className="flex flex-row mt-10">
        <TextInput
          id="coupon"
          placeholder="Add coupon"
          value={coupon}
          noMargin={true}
          type="text"
          editable={true}
          error={couponError}
          onChange={(value) => onCouponChange(value)}
        />
        <Button
          className={`${css(styles.applyCouponButton)} ml-3`}
          disabled={!coupon || couponSubmitting}
          onClick={() => onCouponSubmit(coupon)}
          discrete
        >
          {couponSubmitting ? <MiniSpinner height={20} width={20} /> : 'Apply'}
        </Button>
      </div>

      {renderDueToday(totalPrice)}

      {!onPaymentMethod && (
        <div className={css(modalStyles.modalActions)}>
          <Button
            cssStyle={modalStyles.buttonAction}
            disabled={
              documentAutomationPlans.length === 0 &&
              courtFormPlans.length === 0
            }
            style={{ width: '100%' }}
            onClick={onConfirm}
            primary
          >
            {onPaymentMethod ? 'Pay now' : 'Next'}
          </Button>
        </div>
      )}
    </div>
  );
};

const PaymentMethodForm = ({
  coupon,
  couponError,
  couponApplied,
  onCancel,
  onChange,
  onSubmit,
  error,
  loading,
}) => {
  const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const couponAppliedSuccessfully = coupon && couponApplied && !couponError;
  const noCouponApplied = !coupon;

  const canSubmit =
    isValid &&
    !loading &&
    !isSubmitting &&
    (couponAppliedSuccessfully || noCouponApplied);

  useEffect(() => {
    if (!loading && isSubmitting) {
      setIsSubmitting(false);
    }
  }, [loading, isSubmitting]);

  const handleSubmit = () => {
    if (canSubmit) {
      setIsSubmitting(true);
      onSubmit();
    }
  };

  const handleChange = (form) => {
    setIsValid(form.valid);
    onChange(form);
  };

  return (
    <div className="flex flex-col grow md:min-w-[400px]">
      <h1 className={css(modalStyles.modalTitle)}>Payment method</h1>
      <div className={css(modalStyles.modalBody)}>
        <CreditCardForm onChange={handleChange} />
      </div>
      {error && (
        <div className={css(modalStyles.modalActions)}>
          <Error>{error}</Error>
        </div>
      )}
      <div className={css(modalStyles.modalActions)}>
        <Button cssStyle={modalStyles.buttonAction} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={!canSubmit}
          onClick={handleSubmit}
          cssStyle={modalStyles.buttonAction}
          primary
        >
          {loading || isSubmitting ? 'Loading...' : 'Confirm & pay'}
        </Button>
      </div>
    </div>
  );
};

class CreateSubscriptionModal extends React.Component {
  constructor(props) {
    super(props);

    let coupon = '';
    const subscription =
      this.props.store.user.currentOrganization &&
      this.props.store.user.currentOrganization.subscription;

    if (subscription && subscription.freeTrialIsActive()) {
      coupon = COUPON.CLIO_SSO_FREE_TRIAL;
    }

    this.state = {
      selectedPlan: 'pro',
      cycle: 'monthly',
      seats: 0,
      coupon,
      couponDiscount: null,
      paymentPending: false,
      showPaymentMethod: false,
      paymentFormValue: {},
      createSubscriptionError: false,
      courtFormPlans: Array(COURT_FORM_PLANS.monthly.length).fill(false),
      documentAutomationPlans: false,
      oneTimeCharges: false,
      totalPrice: null,
      currentState: {
        courtFormPlans: Array(COURT_FORM_PLANS.monthly.length).fill(false),
        documentAutomationPlans: false,
        oneTimeCharges: false,
      },
    };
  }

  handlePlanChange = async (form) => {
    const selectedPlan = form.fields.plan.value;
    const newState = cloneDeep(this.state);

    const currentState = {
      courtFormPlans: this.state.courtFormPlans,
      documentAutomationPlans: this.state.documentAutomationPlans,
      oneTimeCharges: this.state.oneTimeCharges,
    };
    if (selectedPlan) {
      const { index, selected } = selectedPlan;
      if (index) {
        // Add to document automation plans
        newState.documentAutomationPlans = selected[0];
        newState.oneTimeCharges = selected[1];
      } else {
        // Add to court form plans
        newState.courtFormPlans = selected;
      }
    }
    const seats = form.fields.seats.value;
    const cycle = form.fields.cycle.value;
    const { coupon } = this.state;
    const { courtFormPlans, documentAutomationPlans, oneTimeCharges } =
      newState;

    const courtFormPlanIds = COURT_FORM_PLANS[cycle]
      .filter((plan, id) => courtFormPlans[id])
      .map((plan) => plan.id);
    const documentAutomationPlanIds = documentAutomationPlans
      ? [DOCUMENT_AUTOMATION_PLANS[cycle][0].id]
      : [];
    const oneTimeChargeIds = oneTimeCharges
      ? [DOCUMENT_AUTOMATION_PLANS[cycle][1].id]
      : [];

    newState.cycle = cycle;

    if (newState.cycle !== this.state.cycle) {
      this.setState({ cycle });
    }

    newState.seats = seats;

    if (!isEqual(newState, this.state)) {
      const res =
        await this.props.store.user.currentOrganization.fetchUpcomingInvoice(
          [...(courtFormPlanIds ?? []), ...(documentAutomationPlanIds ?? [])],
          [],
          oneTimeChargeIds ?? [],
          cycle,
          seats,
          coupon,
        );
      if (res) {
        const { subtotal, total, discount, discount_percent } = res;
        newState.totalPrice = { subtotal, total, discount, discount_percent };
      }
      this.setState({ ...newState, cycle, selectedPlan, seats, currentState });
    }
  };

  handleCouponChange = (value) => {
    this.setState({ coupon: value, couponError: '' });
  };

  handleCouponApply = async (coupon) => {
    this.setState({
      couponSubmitting: true,
      coupon,
    });

    const {
      courtFormPlans,
      documentAutomationPlans,
      oneTimeCharges,
      cycle,
      seats,
    } = this.state;

    const courtFormPlanIds = COURT_FORM_PLANS[cycle]
      .filter((plan, id) => courtFormPlans[id])
      .map((plan) => plan.id);
    const documentAutomationPlanIds = documentAutomationPlans
      ? [DOCUMENT_AUTOMATION_PLANS[cycle][0].id]
      : [];
    const oneTimeChargeIds = oneTimeCharges
      ? [DOCUMENT_AUTOMATION_PLANS[cycle][1].id]
      : [];

    const res =
      await this.props.store.user.currentOrganization.fetchUpcomingInvoice(
        [...(courtFormPlanIds ?? []), ...(documentAutomationPlanIds ?? [])],
        [],
        oneTimeChargeIds ?? [],
        cycle,
        seats,
        coupon,
      );

    const newState = {};

    if (res) {
      const { subtotal, total, discount, discount_percent } = res;
      newState.totalPrice = { subtotal, total, discount, discount_percent };
      this.setState({
        ...newState,
        couponSubmitting: false,
        couponError: '',
        couponApplied: true,
      });
    } else {
      this.setState({
        couponError: 'This code is invalid',
        couponSubmitting: false,
      });
    }
  };

  handlePaymentMethodChange = (form) => {
    this.setState({
      paymentFormValue: form,
    });
  };

  handlePaymentMethodSubmit = async () => {
    const {
      couponError,
      courtFormPlans,
      documentAutomationPlans,
      oneTimeCharges,
      cycle,
      seats,
    } = this.state;

    const formFields = this.state.paymentFormValue.fields;

    const ccExpMonth = formFields.exp.value.split('/')[0];
    const ccExpYear = formFields.exp.value.split('/')[1];
    const addressZip = formFields.address_zip.value;
    const addressCity = formFields.address_city.value;
    const addressState = formFields.address_state.value;
    const cvc = formFields.cvc.value;
    const number = formFields.number.value;

    const card = {
      number,
      cvc,
      exp_month: ccExpMonth,
      exp_year: ccExpYear,
      address_zip: addressZip,
      address_state: addressState,
      address_city: addressCity,
    };

    const coupon = !couponError ? this.state.coupon : '';

    this.setState({
      createSubscriptionError: false,
      paymentPending: true,
    });

    const courtFormPlanIds = COURT_FORM_PLANS[cycle]
      .filter((plan, id) => courtFormPlans[id])
      .map((plan) => plan.id);
    const documentAutomationPlanIds = documentAutomationPlans
      ? [DOCUMENT_AUTOMATION_PLANS[cycle][0].id]
      : [];
    const oneTimeChargeIds = oneTimeCharges
      ? [DOCUMENT_AUTOMATION_PLANS[cycle][1].id]
      : [];

    if (
      courtFormPlanIds.length === 0 &&
      documentAutomationPlanIds.length === 0 &&
      oneTimeChargeIds.length === 0
    ) {
      this.props.showToast(LAYOUT_TOAST_TYPES.error, {
        message: 'You should select at least 1 plan!',
      });
      this.setState({
        paymentPending: false,
      });
      return;
    }

    const res =
      await this.props.store.user.currentOrganization.createSubscription_V2({
        card,
        add_plans: [
          ...(courtFormPlanIds ?? []),
          ...(documentAutomationPlanIds ?? []),
        ],
        // remove_plans: [...(currentCourtFormPlanIds ?? []), ...(currentDocumentAutomationPlanIds ?? [])],
        remove_plans: [],
        one_time_charges: oneTimeChargeIds ?? [],
        duration: cycle,
        seats,
        coupon,
      });

    this.setState({ paymentPending: false });

    if (res) {
      this.props.showToast(LAYOUT_TOAST_TYPES.success, {
        message: 'Subscription created!',
      });

      const subscription =
        this.props.store.user.currentOrganization &&
        this.props.store.user.currentOrganization.subscription;

      if (subscription) {
        // this.props.store.user.currentOrganization.subscription = mapOrganizationSubscription(res);
        this.props.store.user.currentOrganization.fetchSubscription();
      } else {
        this.props.store.initialize();
      }

      history.replace('/library');
    } else {
      this.setState({
        createSubscriptionError:
          'Something went wrong, please try again later.',
      });
      this.props.showToast(LAYOUT_TOAST_TYPES.error, {
        message: 'Something went wrong, please try again later.',
      });
    }
  };

  handleCancel = () => {
    if (this.state.showPaymentMethod) {
      this.setState({ showPaymentMethod: false });
    } else {
      this.props.onCancel();
    }
  };

  handlePlanConfirm = () => {
    this.setState({ showPaymentMethod: true });
  };

  render() {
    const {
      selectedPlan,
      seats,
      cycle,
      showPaymentMethod,
      createSubscriptionError,
      paymentPending,
      coupon,
      couponError,
      couponApplied,
      couponSubmitting,
      couponDiscount,
      courtFormPlans,
      documentAutomationPlans,
      oneTimeCharges,
      totalPrice,
    } = this.state;

    const planPricing = {
      basic: 59,
      pro: 99,
    };

    const planPrice = planPricing[selectedPlan];

    const courtFormPlanIds = COURT_FORM_PLANS[cycle]
      .filter((plan, id) => courtFormPlans[id])
      .map((plan) => plan.id);
    const documentAutomationPlanIds = documentAutomationPlans
      ? [DOCUMENT_AUTOMATION_PLANS[cycle][0].id]
      : [];
    const oneTimeChargeIds = oneTimeCharges
      ? [DOCUMENT_AUTOMATION_PLANS[cycle][1].id]
      : [];

    const handleScheduleCall = () => {
      window.open('https://lawyaw.chilipiper.com/book/free-trial', '_blank');
    };

    const renderScheduleCall = () => {
      const subscription =
        this.props.store.user.currentOrganization &&
        this.props.store.user.currentOrganization.subscription;

      if (subscription && subscription.freeTrialIsActive()) {
        return (
          <div className="md:shrink-0 md:w-7/12 mt-4">
            <Card dynamic>
              <div className="flex flex-col gap-4">
                <Heading variant="subheading">
                  Do you have additional questions?
                </Heading>

                <Text>
                  Schedule a call with one of our team members to get answers.
                </Text>

                <Button
                  discrete
                  onClick={handleScheduleCall}
                  className="self-start"
                >
                  Schedule a call
                </Button>
              </div>
            </Card>
          </div>
        );
      }

      return null;
    };

    const renderBackArrow = () => {
      if (!showPaymentMethod) return null;

      return (
        <div
          style={{
            position: 'relative',
            top: '-7px',
            backgroundColor: 'white',
            border: 'solid 1px #e5e7eb',
            borderRadius: '4px',
            margin: '0 10px 10px 0',
          }}
        >
          <Button
            Icon={BackArrowIcon}
            onClick={() => this.setState({ showPaymentMethod: false })}
          />
        </div>
      );
    };

    return (
      <div className="max-w-md mx-auto md:max-w-4xl">
        <div style={{ display: 'flex' }}>
          {renderBackArrow()}

          <div className={css(modalStyles.modalTitle)}>Configure your plan</div>
        </div>
        <div className="md:flex">
          <div className="md:shrink-0 md:w-7/12">
            <Card dynamic>
              {showPaymentMethod ? (
                <PaymentMethodForm
                  loading={paymentPending}
                  onCancel={this.handleCancel}
                  onChange={this.handlePaymentMethodChange}
                  onSubmit={this.handlePaymentMethodSubmit}
                  coupon={coupon}
                  couponError={couponError}
                  couponApplied={couponApplied}
                  error={createSubscriptionError}
                />
              ) : (
                <ChoosePlanForm
                  cycle={cycle}
                  onChange={this.handlePlanChange}
                  courtFormPlans={[...courtFormPlans]}
                  documentAutomationPlans={documentAutomationPlans}
                  oneTimeCharges={oneTimeCharges}
                />
              )}
            </Card>
          </div>
          <div className="md:ml-4 md:my-0 my-4 md:w-5/12">
            <Card dynamic>
              <SummaryForm
                selectedPlan={selectedPlan}
                planPrice={planPrice}
                couponDiscount={couponDiscount}
                totalPrice={totalPrice}
                coupon={coupon}
                couponError={couponError}
                couponSubmitting={couponSubmitting}
                onCouponChange={this.handleCouponChange}
                onCouponSubmit={this.handleCouponApply}
                onCancel={this.handleCancel}
                onConfirm={this.handlePlanConfirm}
                seats={seats}
                courtFormPlans={courtFormPlanIds}
                documentAutomationPlans={documentAutomationPlanIds}
                oneTimeCharges={oneTimeChargeIds}
                cycle={cycle}
                onPaymentMethod={showPaymentMethod}
              />
            </Card>
          </div>
        </div>
        {renderScheduleCall()}
      </div>
    );
  }
}

export default inject((store) => store)(
  WithLayoutProps(observer(CreateSubscriptionModal)),
);
