import React from 'react';
import {
  Box,
  BoxProps,
  Card,
  CardProps,
  Collapse,
  CollapseProps,
  Divider,
} from '@mui/material';
import { ChevronDown, ChevronRight } from 'react-feather';

export type ExpandableCardProps = React.PropsWithChildren<{
  header: React.ReactNode;
  expanded?: boolean;
  defaultExpanded?: boolean;
  onChangeExpanded?: () => void;
  cardProps?: CardProps;
  collapseProps?: Omit<CollapseProps, 'in'>;
  extra?: React.ReactNode;
  footer?: React.ReactNode;
  uncollapsibleProps?: BoxProps;
}>;

export const ExpandableCard = ({
  header,
  expanded,
  defaultExpanded,
  onChangeExpanded,
  children,
  cardProps,
  collapseProps,
  extra,
  footer,
  uncollapsibleProps,
}: ExpandableCardProps) => {
  const [_expanded, setExpanded] = React.useState(defaultExpanded ?? true);
  const isExpanded = typeof expanded !== 'undefined' ? expanded : _expanded;
  const handleChangeExpanded = () => {
    setExpanded(!_expanded);
    onChangeExpanded?.();
  };
  return (
    <Card {...cardProps}>
      <Box {...uncollapsibleProps}>
        <Box
          px={3}
          py={2}
          sx={{ cursor: 'pointer' }}
          onClick={handleChangeExpanded}
          display={'flex'}
          alignItems={'center'}
        >
          <Box flex={0} mr={2}>
            {isExpanded ? <ChevronDown /> : <ChevronRight />}
          </Box>
          <Box flex={1}>{header}</Box>
        </Box>
        <Divider />
        {extra}
      </Box>
      <Collapse {...collapseProps} in={isExpanded}>
        <Box px={3} py={2}>
          {children}
        </Box>
        {footer}
      </Collapse>
    </Card>
  );
};
