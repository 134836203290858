import { useMutation, UseMutationOptions } from 'react-query';
import { getV1ContactUrl } from '../urls';
import { Contact } from '../../contact';
import { APIError } from '~/src/utils/error';
import { composeMutationOpts, fetchAndParse } from '../../utils';
import { useCurrentOrgFprint } from '../../user';

export type StackSavedData = {
  [key: string]: string;
};

type UpdateContactWithProjectDataParams = {
  contactId: number;
  stack_saved_data: StackSavedData; // stack_saved_data is project data
};

export const useUpdateContactWithProjectData = (
  options: UseMutationOptions<
    Contact,
    APIError,
    UpdateContactWithProjectDataParams
  > = {},
) => {
  const orgFprint = useCurrentOrgFprint();
  return useMutation(
    composeMutationOpts(options, {
      mutationFn: ({ contactId, stack_saved_data }) =>
        fetchAndParse<Contact>(getV1ContactUrl(orgFprint, contactId), {
          method: 'PATCH',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify(stack_saved_data),
        }),
    }),
  );
};
