import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { QuestionnaireSubmissionTableCardList } from './QuestionnaireSubmissionTableCardList';
import {
  useClioQuestionnaireSubmissions,
  useDeleteSubmissionConfirmModal,
} from '~/src/entities/Questionnaires';
import {
  QuestionnaireStatus,
  type QuestionnaireSubmission,
} from '@clio/questionnaire-builder';
import { useOrgFprint } from '~/src/hooks/useOrgFprint';
import usePageScroll from '~/src/hooks/usePageScroll';
import LoadMoreDisplayText from '~/src/components/LoadMoreDisplayText';

export const QuestionnaireSubmissionTable: React.FC<{
  title: string;
  status: QuestionnaireStatus[];
  onViewDetails: (submission: QuestionnaireSubmission) => void;
}> = ({ title, status, onViewDetails }) => {
  const orgFprint = useOrgFprint();
  const pageScroll = usePageScroll();

  const {
    data,
    isError,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useClioQuestionnaireSubmissions(orgFprint, {
    limit: 15,
    status,
  });

  const { deleteSubmissionConfirmModal } = useDeleteSubmissionConfirmModal({
    title: 'Delete questionnaire submission link',
  });

  useEffect(() => {
    if ((pageScroll === 0 || pageScroll >= 85) && !isLoading) {
      fetchNextPage();
    }
  }, [fetchNextPage, isLoading, pageScroll]);

  if (isError || (!isLoading && !data)) {
    return (
      <Box margin="auto">
        <Typography textAlign={'center'} variant="h2">
          {title} questionnaires will show up here
        </Typography>
        <Typography textAlign={'center'} variant="subtitle1">
          Cannot retrieve questionnaire submissions, please try again later.
        </Typography>
      </Box>
    );
  }

  const submissions: QuestionnaireSubmission[] =
    data?.pages.flatMap((page) => page.submissions) || [];

  const isEmpty = !isLoading && !submissions.length;

  return (
    <>
      {isEmpty ? (
        <Typography textAlign={'center'} variant="subtitle1">
          No questionnaire submissions match that filter
        </Typography>
      ) : (
        <>
          <QuestionnaireSubmissionTableCardList
            submissions={submissions}
            onDelete={deleteSubmissionConfirmModal}
            onViewDetails={onViewDetails}
          />
          <LoadMoreDisplayText
            loading={isLoading || isFetchingNextPage}
            done={!hasNextPage}
            onLoadMore={fetchNextPage}
          />
        </>
      )}
    </>
  );
};
