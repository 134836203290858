import React from 'react';
import { css } from 'aphrodite';
import { AlertCircle } from 'react-feather';
import { inject, observer } from 'mobx-react';

/* Higher Order Components */
import WithLayoutProps from '~/src/hoc/WithLayoutProps';

/* Components */
import Card, { CardTitle, CardBreak } from '~/src/components/Card';
import Button from '~/src/components/Button';
import { Label } from '~/src/components/Inputs';
import Banner from '~/src/components/Banner';

/* Styles */
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import styles from './styles';

/* Constants */

const CreateNewSubscription = ({ onCreateNewSubscription }) => {
  return (
    <>
      <CardTitle>Create new subscription</CardTitle>
      <div className={css(styles.settingsRow)}>
        <Button onClick={onCreateNewSubscription}>Choose plan</Button>
      </div>
    </>
  );
};

const NoSubscriptionNeeded = () => {
  return (
    <>
      <CardTitle>No subscription</CardTitle>
      <div className={css(styles.settingsRow)}>
        This account does not require a subscription.
      </div>
    </>
  );
};

const SubscriptionDetails = ({
  loading,
  subscription,
  onUpdateSeats,
  onUpdatePaymentMethod,
  onRenewSubscription,
  nextInvoice,
}) => {
  const { quantity } = subscription;
  const seatLabel = quantity > 1 ? 'seats' : 'seat';
  const endDate = subscription.nextInvoiceEndFormatted();

  const { ccId } = subscription;

  const canceled = subscription.isCanceled();

  const handleUpdateSeats = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    if (onUpdateSeats) {
      onUpdateSeats();
    }
  };

  const handleRenewSubscription = () => {
    onRenewSubscription();
  };

  const renewLabel = loading ? 'Renewing...' : 'Renew subscription';

  return (
    <>
      <CardTitle bold>Subscription</CardTitle>
      <div className={css(!canceled && styles.settingsRow)}>
        <Label className={css(styles.settingsLabel)} label={'Plan'}>
          {quantity} {seatLabel}
        </Label>
      </div>
      {!canceled && (
        <div className={css(styles.settingsRow)}>
          <Button primary onClick={handleUpdateSeats}>
            Update seats
          </Button>
        </div>
      )}
      {canceled && (
        <>
          <div className={css(styles.settingsRow)}>
            <Banner small alert Icon={AlertCircle}>
              Your subscription is set to cancel on {endDate}
            </Banner>
            <Button
              onClick={handleRenewSubscription}
              disabled={loading}
              primary
            >
              {renewLabel}
            </Button>
          </div>
        </>
      )}
      <div className={css(styles.settingsRow)}>
        <CardBreak />
      </div>
      {!canceled && (
        <div className={css(styles.settingsRow)}>
          <Label className={css(styles.settingsLabel)} label={'Next invoice'}>
            ${nextInvoice} on {endDate}
          </Label>
        </div>
      )}
      <div className={css(styles.settingsRow)}>
        <Label className={css(styles.settingsLabel)} label={'Payment method'}>
          Card ending in {ccId}
        </Label>
        <Button
          secondary
          cssStyle={styles.settingsSubscriptionLabelButton}
          onClick={onUpdatePaymentMethod}
        >
          Update payment method
        </Button>
      </div>
    </>
  );
};

const CancelCard = ({ onCancelSubscription }) => {
  const handleCancelSubscription = (event) => {
    if (event.preventDefault) {
      event.preventDefault();
    }
    if (onCancelSubscription) {
      onCancelSubscription();
    }
  };

  return (
    <Card elevate>
      <CardTitle bold>Cancel subscription</CardTitle>
      <p className={css(styles.settingsRow, styles.cardBody)}>
        Upon canceling, you and your team will lose access to your templates,
        drafted documents, contacts and matter information stored in Lawyaw
        after the current billing period.
      </p>
      <a
        role="button"
        tabIndex={0}
        href={'#'}
        onKeyPress={handleCancelSubscription}
        onClick={handleCancelSubscription}
        className={css(styles.buttonLink)}
      >
        Cancel subscription
      </a>
    </Card>
  );
};

class SettingsSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nextInvoice: 'Calculating...',
    };
  }

  async componentDidMount() {
    const { subscription, store } = this.props;
    const cycle =
      subscription && subscription.plan.interval === 'month'
        ? 'monthly'
        : 'annual';
    const nextInvoice =
      await store.user.currentOrganization.fetchUpcomingInvoice(
        [],
        [],
        [],
        cycle,
        subscription.quantity,
        '',
      );

    if (nextInvoice) {
      const { total } = nextInvoice;
      this.setState({ nextInvoice: total / 100 });
    }
  }

  handleUpdateSeats = () => {
    this.props.showModal(LAYOUT_MODAL_TYPES.subscriptionUpdate);
  };

  handleUpdatePaymentMethod = () => {
    this.props.showModal(LAYOUT_MODAL_TYPES.updatePaymentMethod);
  };

  handleCancelSubscription = () => {
    this.props.showModal(LAYOUT_MODAL_TYPES.cancelSubscriptionConfirm);
  };

  handleRenewSubscription = () => {
    this.props.onRenewSubscription();
  };

  render() {
    const {
      onCreateNewSubscription,
      subscription,
      isNonProfit,
      isClioFree,
      loading,
    } = this.props;

    const { nextInvoice } = this.state;
    let cardContent = null;

    cardContent = (
      <CreateNewSubscription
        onCreateNewSubscription={onCreateNewSubscription}
      />
    );

    if (subscription) {
      cardContent = (
        <SubscriptionDetails
          loading={loading}
          subscription={subscription}
          onUpdateSeats={this.handleUpdateSeats}
          onUpdatePaymentMethod={this.handleUpdatePaymentMethod}
          onRenewSubscription={this.handleRenewSubscription}
          nextInvoice={nextInvoice}
        />
      );
    }

    if (isNonProfit || !subscription) {
      cardContent = <NoSubscriptionNeeded />;
    }

    if (isClioFree && !subscription) {
      cardContent = (
        <CreateNewSubscription
          onCreateNewSubscription={onCreateNewSubscription}
        />
      );
    }

    const cardActions =
      !isNonProfit && subscription && !subscription.canceledAt ? (
        <CancelCard onCancelSubscription={this.handleCancelSubscription} />
      ) : null;

    return (
      <div className={css(styles.settingsCardContainer)}>
        <Card elevate>{cardContent}</Card>
        <div className={css(styles.settingsOuterButtonContainer)}>
          {cardActions}
        </div>
      </div>
    );
  }
}

export default inject((store) => store)(
  WithLayoutProps(observer(SettingsSubscription)),
);
