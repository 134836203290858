import React from 'react';
import { css } from 'aphrodite';

import Table from '~/src/containers/Table';
import Button from '~/src/components/Button';
import Triangle from '~/src/components/Triangle';
import Dropdown from '~/src/components/Dropdown';
import dropdownStyles from '~/src/components/Dropdown/styles';
import { Typography, Box } from '@mui/material';
import { SearchField } from '~/src/components/SearchField';
import useMattersActions from './useMattersActions';
import { useCanSyncSingleMatter } from '~/src/hooks/useClio';
import analyticsService from '~/src/services/analytics';
import { useLayoutContext } from '~/src/contexts/Layout';
import { useSyncSingleMatterModal } from '~/src/components/SingleMatterSync/useSyncSingleMatterModal';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';

import styles from './styles';
import { useImportFromClioModal } from '~/src/entities/clioMatters';
import { useUIFlagEnabled } from '~/src/entities/uiFlags';
import { MattersCardListTable } from './MattersCardListTable';

const MattersActions = () => {
  const [{ show }, modal] = useImportFromClioModal({
    onMutate: ({ clio_matter_id }) => {
      analyticsService.track(
        'Single Matter Import From Matter List - Initiated',
        { clioMatterId: clio_matter_id },
      );
    },
    onError: (_, { clio_matter_id }) => {
      analyticsService.track('Single Matter Import From Matter List - Failed', {
        clioMatterId: clio_matter_id,
      });
    },
    onSuccess: (_, { clio_matter_id }) => {
      analyticsService.track(
        'Single Matter Import From Matter List - Success',
        { clioMatterId: clio_matter_id },
      );
    },
  });
  if (!useCanSyncSingleMatter()) {
    return null;
  }

  return (
    <>
      <div className={css(styles.actions)}>
        <Button
          primary
          onClick={() => {
            analyticsService.track(
              'Single Matter Import From Matter List - Clicked',
            );
            show();
          }}
        >
          Import from Clio Manage
        </Button>
      </div>
      {modal}
    </>
  );
};

const RowAction = ({ actions, matter }) => {
  const canSyncSingleMatter = useCanSyncSingleMatter();

  const renderTrigger = ({ toggleDropdown }) => (
    <span analyticsname="Actions">
      <Button onClick={toggleDropdown} cssStyle={styles.rowActionButton}>
        Actions <Triangle primary orientation={'down'} />
      </Button>
    </span>
  );

  return (
    <Dropdown renderTrigger={renderTrigger}>
      <a
        onClick={() => actions.view(matter)}
        className={css(dropdownStyles.dropdownActionItem)}
        analyticsname="View"
      >
        View
      </a>

      {matter.clioMatterId && canSyncSingleMatter && (
        <a
          onClick={() => actions.sync(matter.clioMatterId)}
          className={css(dropdownStyles.dropdownActionItem)}
          analyticsname="SyncSingleMatterFromClio"
        >
          Get updates from Clio
        </a>
      )}

      <a
        onClick={() => actions.rename(matter)}
        className={css(dropdownStyles.dropdownActionItem)}
        analyticsname="Rename"
      >
        Rename
      </a>
      <a
        onClick={() => actions.delete(matter)}
        className={css(
          dropdownStyles.dropdownActionItem,
          dropdownStyles.dropdownActionItemRed,
        )}
        analyticsname="Delete"
      >
        Delete
      </a>
    </Dropdown>
  );
};

export const MattersTable = ({
  visibleItems,
  matters,
  isLoading,
  empty,
  onSearchChange,
  onSortByChange,
}) => {
  const {
    onViewMatter: handleViewMatter,
    onRenameMatter: handleRenameMatter,
    onDeleteMatter: handleDeleteMatter,
  } = useMattersActions();
  const { showToast } = useLayoutContext();

  const [{ sync }, syncSingleMatterModal] = useSyncSingleMatterModal({
    onMutate: ({ clio_matter_id }) => {
      analyticsService.track('Single Matter Sync from List View - Initiated', {
        clioMatterId: clio_matter_id,
      });
    },
    onError: (_, { clio_matter_id }) => {
      analyticsService.track('Single Matter Sync from List View - Failed', {
        clioMatterId: clio_matter_id,
      });
    },
    onSuccess: (_, { clio_matter_id }) => {
      analyticsService.track('Single Matter Sync from List View - Success', {
        clioMatterId: clio_matter_id,
      });
      showToast(LAYOUT_TOAST_TYPES.success, {
        message: 'Matter synced successfully',
      });
    },
  });

  const renderRowAction = (matter) => (
    <RowAction
      actions={{
        view: handleViewMatter,
        rename: handleRenameMatter,
        delete: handleDeleteMatter,
        sync,
      }}
      matter={matter}
    />
  );

  const useCardLists = useUIFlagEnabled('cardLists');

  if (useCardLists) {
    return (
      <Box mx={3}>
        <Box maxWidth={1280} width={'100%'} mx={'auto'}>
          <Box pb={3}>
            <SearchField
              onChange={onSearchChange}
              size="small"
              placeholder="Search Matters"
            />
          </Box>
          {empty ? (
            <Typography textAlign={'center'} variant="subtitle1">
              No matters for that filter
            </Typography>
          ) : (
            <MattersCardListTable
              onView={handleViewMatter}
              onRename={handleRenameMatter}
              onDelete={handleDeleteMatter}
              onSync={sync}
              matters={matters}
            />
          )}
          {syncSingleMatterModal}
        </Box>
      </Box>
    );
  }

  return (
    <div analyticsname="Matter Actions">
      <Table
        visibleItems={visibleItems}
        isLoading={isLoading}
        empty={empty}
        emptyTitle={'No matters for that filter'}
        searchPlaceholder="Search matters"
        renderActions={() => <MattersActions />}
        data={matters}
        onSearchChange={onSearchChange}
        onSortByChange={onSortByChange}
        columns={[
          {
            label: 'Name',
            render: (row) => {
              if (row.clioMatterId && row.number) {
                return `${row.number} - ${row.title}`;
              }
              return row.title;
            },
            style: {
              width: '30%',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            },
          },
          {
            label: 'Documents',
            dataKey: 'numberOfDocuments',
            style: { width: '10%', textAlign: 'left' },
          },
          {
            label: 'Actions',
            hideLabel: true,
            render: renderRowAction,
            style: { flexGrow: 1, display: 'flex', justifyContent: 'flex-end' },
          },
        ]}
      />

      {syncSingleMatterModal}
    </div>
  );
};
