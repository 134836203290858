import { useMutation, UseMutationOptions } from 'react-query';
import { getProjectPopulateUrl } from '../urls';
import { Project } from '../types';
import { APIError } from '~/src/utils/error';
import { composeMutationOpts, fetchAndParse } from '../../utils';
import { useCurrentOrgFprint } from '../../user';

type Variables = {
  projectId: number;
};

export const usePopulateProject = (
  options: UseMutationOptions<Project, APIError, Variables> = {},
) => {
  const orgFprint = useCurrentOrgFprint();
  return useMutation(
    composeMutationOpts(options, {
      mutationFn: ({ projectId }) =>
        fetchAndParse(getProjectPopulateUrl(orgFprint, projectId), {
          method: 'POST',
          headers: { 'content-type': 'application/json' },
        }),
    }),
  );
};
