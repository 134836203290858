import {
  Box,
  Card,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import { css } from 'aphrodite';
import { camelCase } from 'lodash';
import React, { useState } from 'react';
import { Edited, EditIconButton } from '../Edit';
import Label from '../Label';
import styles from '../styles';
type SingleSelectProps = {
  renderAsDropdown: boolean;
  label: string;
  value: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  onFocus: () => void;
  hint?: string;
  isStandaloneQuestion?: boolean;
  count?: string;
  options: Array<{ label: string; value: string }>;
};

const SingleSelect = React.forwardRef(
  (
    {
      renderAsDropdown,
      label,
      value,
      onChange,
      onBlur,
      onFocus,
      hint,
      isStandaloneQuestion,
      count,
      options,
    }: SingleSelectProps,
    _,
  ) => {
    const id = camelCase(label);
    const selectedOption = options.find((o) => o.value === value);
    const isEdited = value !== undefined && value.length > 0 && !selectedOption;

    const [isEditing, setIsEditing] = useState(false);
    const editIndicator = isStandaloneQuestion ? null : (
      <Stack direction={'row'} spacing={1}>
        {isEdited ? <Edited /> : null}
        <EditIconButton
          isEditing={isEditing}
          onClick={() => setIsEditing(!isEditing)}
        />
      </Stack>
    );

    return (
      <Card
        sx={(theme) => ({
          border: `1px solid ${
            isEditing ? theme.palette.border : 'transparent'
          }`,
          marginBottom: theme.spacing(3),
        })}
        elevation={isEditing ? 3 : 0}
      >
        <Box p={isEditing ? 2 : 0}>
          <Label
            label={
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <span>{label}</span>
                {editIndicator}
              </Box>
            }
            hint={hint}
            count={!isStandaloneQuestion ? count : undefined}
          >
            <fieldset
              className={css(styles.multipleChoiceFieldset)}
              id={id}
              onFocus={() => setTimeout(onFocus, 1)}
              onBlur={onBlur}
            >
              {renderAsDropdown ? (
                <Select
                  value={value}
                  sx={{ display: 'block' }}
                  size={'small'}
                  onChange={(e: SelectChangeEvent<string>) =>
                    onChange(e.target.value)
                  }
                >
                  {options.map((field, _) => (
                    <MenuItem key={field.value} value={field.value}>
                      {field.label}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <RadioGroup>
                  {options.map((field, _) => (
                    <Box pb={1} key={field.value}>
                      <FormControlLabel
                        style={{ display: 'flex' }}
                        slotProps={{ typography: { variant: 'body1' } }}
                        checked={value === field.value}
                        control={
                          <Radio
                            onChange={(_, checked) => {
                              if (checked) onChange(field.value);
                            }}
                            sx={{ paddingTop: 0, paddingBottom: 0 }}
                          />
                        }
                        label={field.label}
                        value={field.value}
                      />
                    </Box>
                  ))}
                </RadioGroup>
              )}

              {isEditing ? (
                <Box pt={5}>
                  <Label
                    label={
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                      >
                        <span>Text to Merge</span>
                      </Box>
                    }
                  >
                    <>
                      <TextField
                        size={'small'}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        fullWidth
                        multiline
                      />
                      <Box
                        py={1}
                        visibility={isEdited ? 'visible' : 'hidden'}
                        display={'flex'}
                        justifyContent={'flex-end'}
                        alignItems={'center'}
                      >
                        <Edited />
                      </Box>
                    </>
                  </Label>
                </Box>
              ) : null}
            </fieldset>
          </Label>
        </Box>
      </Card>
    );
  },
);

export default SingleSelect;
