import { useMutation, UseMutationOptions } from 'react-query';
import { getProjectUrl } from '../urls';
import { Project } from '../types';
import { APIError } from '~/src/utils/error';
import { composeMutationOpts, fetchAndParse } from '../../utils';
import { useCurrentOrgFprint } from '../../user';

type Variables = {
  projectId: number;
  stack_saved_data: Project['stack_saved_data'];
};

export const useUpdateProject = (
  options: UseMutationOptions<Project, APIError, Variables> = {},
) => {
  const orgFprint = useCurrentOrgFprint();
  return useMutation(
    composeMutationOpts(options, {
      mutationFn: ({ projectId, stack_saved_data }) =>
        fetchAndParse<Project>(getProjectUrl(orgFprint, projectId), {
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({ stack_saved_data }),
        }),
    }),
  );
};
