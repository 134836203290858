/* Libraries */
import { find } from 'lodash';

/* Services */
import * as membershipService from '~/src/services/api/membershipService';

/* Utilities */
import global from '~/src/utils/window';
import {
  mapMember,
  mapMembership,
  mapMembershipProfile,
  mapOrganizationSubscription,
  mapOrganizationInvoice,
  mapOrganizationReceipt,
  mapOrganizationCoupons,
} from '~/src/utils/dataTransformers';

function membership() {
  const fetchSeatInvoice = async (fprint, seats) => {
    return membershipService.fetchSeatInvoice(fprint, seats);
  };

  const fetchUpcomingInvoice = async (
    fprint,
    add_plans,
    remove_plans,
    one_time_charges,
    duration,
    seat,
    coupon,
    freeTrialActive,
  ) => {
    return membershipService.fetchUpcomingInvoice(
      fprint,
      add_plans,
      remove_plans,
      one_time_charges,
      duration,
      seat,
      coupon,
      freeTrialActive,
    );
  };

  const applyCoupon = async (fprint, coupon) => {
    return membershipService.applyCoupon(fprint, coupon);
  };

  const renewSubscription = async (fprint) => {
    return membershipService.renewSubscription(fprint);
  };

  const cancelSubscription = async (fprint, reason) => {
    await membershipService.cancelSubscriptionReason(fprint, reason);
    return membershipService.cancelSubscription(fprint);
  };

  const updateSubscription = async (fprint, properties) => {
    return membershipService.updateSubscription(fprint, properties);
  };

  const updatePaymentMethod = async (fprint, card) => {
    return new Promise((resolve, reject) => {
      try {
        global.Stripe.card.createToken(card, (status, res) => {
          const stripeToken = res.id;
          resolve(membershipService.updatePaymentMethod(fprint, stripeToken));
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  const createSubscription = async (fprint, { card, plan, seats, coupon }) => {
    return new Promise((resolve, reject) => {
      try {
        const stripe = window && window.Stripe;

        stripe.card.createToken(card, (status, res) => {
          const stripeToken = res.id;
          resolve(
            membershipService.createSubscription(
              fprint,
              stripeToken,
              plan,
              seats,
              coupon,
            ),
          );
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  const createSubscription_V2 = async (
    fprint,
    {
      card,
      add_plans,
      remove_plans,
      one_time_charges,
      duration,
      seats,
      coupon,
      freeTrialActive,
      interval_change,
    },
  ) => {
    return new Promise((resolve, reject) => {
      try {
        const stripe = window && window.Stripe;

        stripe.card.createToken(card, (status, res) => {
          const stripeToken = res.id;
          resolve(
            membershipService.createSubscription_V2(
              fprint,
              stripeToken,
              add_plans,
              remove_plans,
              one_time_charges,
              duration,
              seats,
              coupon,
              freeTrialActive,
              interval_change,
            ),
          );
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  const fetchCoupons = async (fprint) => {
    const res = await membershipService.fetchCoupons(fprint);
    return mapOrganizationCoupons(res || {});
  };

  const fetchSubscription = async (fprint) => {
    const res = await membershipService.fetchSubscription(fprint);
    return res ? mapOrganizationSubscription(res) : {};
  };

  const fetchInvoices = async (fprint) => {
    const res = await membershipService.fetchInvoices(fprint);
    if (res.invoices) {
      return res.invoices.map((invoice) => mapOrganizationInvoice(invoice));
    }
    return [];
  };

  const fetchReceipts = async (fprint) => {
    const res = await membershipService.fetchReceipts(fprint);
    if (res.receipts) {
      return res.receipts.map((receipt) => mapOrganizationReceipt(receipt));
    }
    return [];
  };

  const updateUserProfile = async ({
    id,
    phone,
    displayName,
    firstName,
    lastName,
  }) => {
    return membershipService.updateUserProfile({
      member: {
        id,
        auth_user_phone_number: phone,
        first_name: firstName,
        last_name: lastName,
        display_name: displayName,
      },
    });
  };

  const removeProfilePhoto = async () => {
    return membershipService.removeProfilePhoto();
  };

  const uploadProfilePhoto = async (formData) => {
    return membershipService.uploadProfilePhoto(formData);
  };

  /**
   * Uploads an XFDF string to save for the current member. This is
   * used for future signatures on signature packages.
   */
  const uploadSignatureXfdf = async (formData) => {
    return membershipService.uploadSignatureXfdf(formData);
  };

  const toggleTwoFactorAuthMethod = async (type) => {
    return membershipService.toggleTwoFactorAuthMethod(type);
  };

  const verifyTwoFactorAuthSmsCode = async (code, phone) => {
    return membershipService.verifyTwoFactorAuthSmsCode(code, phone);
  };

  const sendTwoFactorAuthSmsCode = async (phone) => {
    return membershipService.sendTwoFactorAuthSms(phone);
  };

  const disableTwoFactorAuth = async () => {
    return membershipService.disableTwoFactorAuth();
  };

  const enableTwoFactorAuth = async () => {
    return membershipService.enableTwoFactorAuth();
  };

  const changePassword = async (password, newPassword) => {
    return membershipService.changePassword(password, newPassword);
  };

  const revokeMemberInvite = async (fprint, memberId) => {
    return membershipService.revokeMemberInvite(fprint, memberId);
  };

  const resendMemberInvite = async (fprint, memberId) => {
    return membershipService.resendMemberInvite(fprint, memberId);
  };

  const removeMember = async (fprint, memberId) => {
    return membershipService.removeMember(fprint, memberId);
  };

  const updateMemberRole = async (fprint, memberId, role) => {
    if (role === 'admin') {
      return membershipService.grantAdmin(fprint, memberId);
    }
    return membershipService.removeAdmin(fprint, memberId);
  };

  const fetchMembers = async (orgFprint) => {
    const { memberships } = await membershipService.fetchMembers(orgFprint);
    return memberships.map((member) => mapMember(member));
  };

  const inviteUsers = async (orgFprint, emails, isAdmin) => {
    return membershipService.inviteUsers(orgFprint, emails, isAdmin);
  };

  const updateOrganization = async (orgFprint, properties) => {
    return membershipService.updateOrganization(orgFprint, properties);
  };

  const setAsDefault = async (orgFprint) => {
    return membershipService.setAsDefault(orgFprint);
  };

  const getMemberships = async (orgFprint) => {
    const membershipsData = await membershipService.getMemberships();
    const profile = mapMembershipProfile(membershipsData.member_info);
    const organizations = membershipsData.memberships.map((org) =>
      mapMembership(org),
    );

    let findBy = ['isDefault', true];

    if (orgFprint) {
      findBy = ['fprint', orgFprint];
    }

    const defaultOrganization = find(organizations, findBy);
    const currentOrganization = defaultOrganization
      ? defaultOrganization.uid
      : organizations[0].uid;

    return {
      profile,
      organizations,
      currentOrganization,
    };
  };

  return {
    changePassword,
    fetchMembers,
    getMemberships,
    updateMemberRole,
    setAsDefault,
    updateOrganization,
    inviteUsers,
    removeMember,
    revokeMemberInvite,
    resendMemberInvite,
    toggleTwoFactorAuthMethod,
    verifyTwoFactorAuthSmsCode,
    sendTwoFactorAuthSmsCode,
    disableTwoFactorAuth,
    enableTwoFactorAuth,
    uploadProfilePhoto,
    uploadSignatureXfdf,
    removeProfilePhoto,
    updateUserProfile,
    fetchInvoices,
    fetchReceipts,
    fetchSubscription,
    fetchCoupons,
    createSubscription,
    createSubscription_V2,
    cancelSubscription,
    updateSubscription,
    updatePaymentMethod,
    renewSubscription,
    applyCoupon,
    fetchSeatInvoice,
    fetchUpcomingInvoice,
  };
}

export default membership();
