import React from 'react';
import { AlertCircle } from 'react-feather';
import { inject, observer } from 'mobx-react';

/* Components */
import Banner from '~/src/components/Banner';
import Button from '~/src/components/Button';
import { history } from '../utils/history';

class FreeTrialBannerContainer extends React.Component {
  handleUpgrade = () => {
    history.replace('/subscribe');
  };

  render() {
    const subscription =
      this.props.store.user.currentOrganization &&
      this.props.store.user.currentOrganization.subscription;

    if (!subscription || !subscription.freeTrialIsActive()) {
      return null;
    }

    const path =
      (history.location &&
        history.location.pathname &&
        history.location.pathname.split('/').join('')) ||
      '';
    if (path === 'subscribe') {
      return null;
    }

    const remaingDays = subscription.remainingDaysOfFreeTrial();

    if (remaingDays < 0) {
      return (
        <Banner small alert fullWidth Icon={AlertCircle}>
          Your free trial period has expired. Configure your plan to continue.
        </Banner>
      );
    }

    return (
      <Banner small alert fullWidth Icon={AlertCircle}>
        <span className="mr-3">
          {remaingDays} days left. Get 10% discount by upgrading before the end
          of your free trial.
        </span>

        <Button primary onClick={this.handleUpgrade}>
          Upgrade
        </Button>
      </Banner>
    );
  }
}

export default inject((store) => store)(observer(FreeTrialBannerContainer));
