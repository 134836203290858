import React from 'react';
import { Link } from 'react-router-dom';
import { AlertCircle } from 'react-feather';
import { inject, observer } from 'mobx-react';

/* Components */
import Banner from '~/src/components/Banner';

class SubscriptionCancelationBannerContainer extends React.Component {
  render() {
    let endDate = null;

    const org = this.props.store.user.currentOrganization;
    const subscription = (org && org.subscription) || false;

    if (org && subscription) {
      endDate = org.subscription.currentPeriodEndFormatted();
    }

    if (!subscription || !subscription.isCanceled()) {
      return null;
    }

    let description = `Your subscription is set to cancel on ${endDate}`;
    if (
      org.subscription.isCanceled() &&
      org.subscription.remainingDaysFromCanceledDate() < 0
    ) {
      description = `Your subscription was canceled on ${endDate}`;
    }

    return (
      <Banner small alert fullWidth Icon={AlertCircle}>
        {description}. Click{' '}
        <Link to="/settings/subscriptions">&nbsp;here&nbsp;</Link> to renew your
        subscription.
      </Banner>
    );
  }
}

export default inject((store) => store)(
  observer(SubscriptionCancelationBannerContainer),
);
