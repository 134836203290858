/* Libraries */
import React, { FC, useEffect } from 'react';
import { Instance } from 'mobx-state-tree';
import { snakeCase } from 'lodash';
import ReactTooltip from 'react-tooltip';

/* Hooks */
import usePageScroll from '~/src/hooks/usePageScroll';
import usePdfCourtFormsPagination from '~/src/hooks/usePdfCourtFormsPagination';

/* Components */
import Breadcrumb from '~/src/components/Breadcrumb';
import PdfTable from '~/src/components/CourtForms/PdfTable';
import LoadMoreDisplayText from '~/src/components/LoadMoreDisplayText';
import Button from '~/src/components/Button';
import RowActions from '~/src/components/RowActions/';
import { ReactComponent as ItemSelectedIcon } from '~/src/static/item-selected.svg';

import { Actions } from '~/src/components/RowActions/RowActions';
import { CourtFormSortByOption } from '~/src/hooks/courtFormSortByOption';
import { SortByOrder } from '~/src/hooks/sortByOrder';
import { PdfCourtFormStore } from '~/src/stores/pdfCourtFormsStore';

interface PdfCourtForm extends Instance<typeof PdfCourtFormStore> {}

interface Props {
  stateCode: string;
  locality?: string;
  disableAddButton: boolean;
  breadcrumbData: Record<string, any>[];
  rowActions?: Actions<PdfCourtForm>;
  handleAddTemplateToSeletionSidebar: (t: PdfCourtForm) => void;
  handlePreviewTemplate: (t: PdfCourtForm) => void;
  containedInSidebar: (t: PdfCourtForm) => boolean;
}

const PdfTableWrapper: FC<Props> = ({
  stateCode,
  locality,
  disableAddButton,
  breadcrumbData,
  rowActions,
  handleAddTemplateToSeletionSidebar,
  handlePreviewTemplate,
  containedInSidebar,
}) => {
  const requestPayload = {
    facets: {
      filter_category: stateCode,
    },
  };

  if (locality) {
    Object.assign(requestPayload.facets, { locality });
  }

  const { next, loading, done, data, setSearch, setSortBy, setOrder } =
    usePdfCourtFormsPagination(requestPayload);

  const pageScroll = usePageScroll();

  const loadMore = next;

  const handleOnPageScroll = () => {
    if ((pageScroll === 0 || pageScroll >= 85) && !loading) {
      loadMore();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleOnPageScroll, [pageScroll]);

  const tableData = data.map((pdfCourtForm: PdfCourtForm) => ({
    id: pdfCourtForm.id,
    filePath: pdfCourtForm.filePath,
    fprint: pdfCourtForm.fprint,
    filterCategory: pdfCourtForm.filterCategory,
    locality: pdfCourtForm.locality,
    title: pdfCourtForm.title,
    subtitle: pdfCourtForm.subtitle,
    documentCategoryId: pdfCourtForm.documentCategoryId,
    legalDocumentType: pdfCourtForm.legalDocumentType,
    legalPracticeArea: pdfCourtForm.legalPracticeArea,
    isDeleted: pdfCourtForm.isDeleted,
  }));

  const handleSearchChange = (searchValue: string) => {
    setSearch(searchValue);

    if (searchValue) {
      setSortBy(CourtFormSortByOption.title);
      setOrder(SortByOrder.asc);
    } else {
      setSortBy(CourtFormSortByOption.lastModified);
      setOrder(SortByOrder.desc);
    }
  };

  const handleSortByChange = (property: string, order: number) => {
    if (!property) {
      return;
    }

    const orderLabel = order > 0 ? SortByOrder.desc : SortByOrder.asc;
    let sortBy = snakeCase(property);

    if (property === 'updatedAt') {
      sortBy = CourtFormSortByOption.lastModified;
    }

    setSortBy(sortBy);
    setOrder(orderLabel);
  };

  const renderAddButtonOrAddedIcon = (template: PdfCourtForm) => {
    if (containedInSidebar(template)) {
      return (
        <div className="flex">
          <div className="mr-2 flex items-center">
            <ItemSelectedIcon />
          </div>
          <div className="leading-none">Added</div>
        </div>
      );
    }

    return (
      <div>
        <Button
          data-tip
          data-for={`add-button-${template.id}`}
          onClick={() => {
            handleAddTemplateToSeletionSidebar(template);
          }}
          disabled={disableAddButton}
        >
          <span>Add</span>

          <ReactTooltip
            id={`add-button-${template.id}`}
            place="bottom"
            effect="solid"
            getContent={() => {
              return disableAddButton
                ? 'Cannot add more than 20 documents to selection.'
                : null;
            }}
          ></ReactTooltip>
        </Button>
      </div>
    );
  };

  const renderRowAction = (template: PdfCourtForm) => (
    <div className="flex">
      <div className="mr-6 flex items-center">
        {renderAddButtonOrAddedIcon(template)}
      </div>
      {rowActions ? (
        <RowActions actions={rowActions} template={template} />
      ) : (
        <div>
          <Button
            data-tip
            data-for={`add-button-${template.id}`}
            onClick={() => {
              handlePreviewTemplate(template);
            }}
          >
            <span>Preview</span>
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <PdfTable
        pdfCourtForms={tableData}
        visibleItems={tableData.length}
        empty={!loading && done && tableData.length === 0}
        renderRowAction={renderRowAction}
        onSearchChange={handleSearchChange}
        onSortByChange={handleSortByChange}
      />
      <LoadMoreDisplayText
        loading={loading}
        done={done}
        onLoadMore={loadMore}
      />
    </>
  );
};

export default PdfTableWrapper;
