/* Services */
import templatesService from '~/src/services/api/templatesService';

/* Utils */
import { mapTemplate } from '~/src/utils/dataTransformers';

function templates() {
  const getTemplateTags = async (orgFprint, templateId) => {
    return templatesService.fetchTags(orgFprint, { templateId });
  };

  const getTemplatePreview = async (orgFprint, docId) => {
    return templatesService.getTemplatePreview(orgFprint, docId);
  };

  const getTemplate = async (orgFprint, templateId) => {
    const res = await templatesService.getTemplate(orgFprint, templateId);
    return mapTemplate(res);
  };

  const getTemplates = async ({
    page,
    search,
    sortBy,
    order,
    limit,
    orgFprint,
  }) => {
    const templates = await templatesService.getTemplates(orgFprint, {
      page,
      search,
      sortBy,
      order,
      limit,
    });

    // Temp change to show pdfs
    const filteredTemplates = templates;

    return filteredTemplates.map((template) => mapTemplate(template));
  };

  const updateTemplate = async (orgFprint, templateId, templateUpdate) => {
    const updatedTemplate = await templatesService.updateTemplate(
      orgFprint,
      templateId,
      templateUpdate,
    );

    return mapTemplate(updatedTemplate);
  };

  const deleteTemplate = async (orgFprint, templateId) => {
    return templatesService.deleteTemplate(orgFprint, templateId);
  };

  return {
    getTemplateTags,
    getTemplate,
    deleteTemplate,
    updateTemplate,
    getTemplates,
    getTemplatePreview,
  };
}

export default templates();
