import React from 'react';
import ConfigurePlan from './ConfigurePlan';
import Page from '~/src/components/PageLayout';

export const Subscribe = () => {
  return (
    <Page showNavigation={false} title={'Subscribe'}>
      <ConfigurePlan />
    </Page>
  );
};
