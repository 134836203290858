import React from 'react';
import { useOrgFprint } from '~/src/hooks/useOrgFprint';
import { useClioQuestionnaireSubmissions } from '../../hooks';
import { Box, CircularProgress, Typography } from '@mui/material';

export const PreviouslyCreatedLink: React.FC<{
  questionnaireId: string;
  matterId: string;
}> = ({ questionnaireId, matterId }) => {
  const orgFprint = useOrgFprint();

  const {
    data: questionnaireSubmissionData,
    isError,
    isLoading,
  } = useClioQuestionnaireSubmissions(orgFprint, {
    questionnaire_id: questionnaireId,
    target_identifier: matterId,
  });

  const submissionUrl: string =
    questionnaireSubmissionData?.pages[0]?.submissions[0]?.submission_link ||
    null;

  if (isLoading) {
    return (
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: theme.spacing(1),
        })}
      >
        <CircularProgress size={20} />
      </Box>
    );
  }

  if (isError) {
    return (
      <Typography
        sx={(theme) => ({
          color: theme.palette.text.primary,
          paddingTop: theme.spacing(1),
        })}
      >
        An error occured, please try again later...
      </Typography>
    );
  }

  return submissionUrl ? (
    <Typography
      sx={(theme) => ({
        color: theme.palette.text.primary,
        paddingTop: theme.spacing(1),
      })}
    >
      This will overwrite the previously created link.
    </Typography>
  ) : (
    <></>
  );
};
