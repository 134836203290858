import { useEffect, useState } from 'react';
import { type QuestionnaireSubmission } from '@clio/questionnaire-builder';
import { Matter } from '~/src/stores/matterStore';
import { Project } from '~/src/stores/projectsStore';
import { Instance } from 'mobx-state-tree';

import matterService from '~/src/services/matter';
import { useStore } from '~/src/hooks/useMst';

export const useDraftDocumentsUsingQuestionnaire = (
  orgFprint: string,
  submissionData: QuestionnaireSubmission | undefined,
) => {
  const store = useStore();
  const [matterData, setMatterData] =
    useState<Instance<typeof Matter | null>>(null);
  const [isMatterError, setIsMatterError] = useState(false);

  useEffect(() => {
    const getMatterData = async () => {
      if (!submissionData?.target_identifier) return;

      try {
        setIsMatterError(false);
        const { matter } = await matterService.fetchMatter(
          orgFprint,
          submissionData.target_identifier,
        );

        setMatterData(matter as Instance<typeof Matter>);
      } catch (e) {
        setIsMatterError(true);
      }
    };

    getMatterData();
  }, [orgFprint, submissionData]);

  const updateMatterAndCreateDocSet = async (
    title: string,
    entityAttributeIdResponseDict: Record<string, string>,
    templateIds: string[],
  ): Promise<Instance<typeof Project>> => {
    setIsMatterError(!matterData);

    if (!matterData) {
      throw new Error('Matter data not found.');
    }

    await store.matter.updateMatter(matterData.id, {
      data: entityAttributeIdResponseDict,
    });
    return store.projects.createDocSet(templateIds, title, matterData.id);
  };

  return { matterData, isMatterError, updateMatterAndCreateDocSet };
};
