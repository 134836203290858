import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/* Components */
import PopulateCardContainer from './PopulateCard';

import { css } from 'aphrodite';
import styles from './styles';

export const Populate = (props) => {
  const { project, onAttachContact } = props;

  const [cardElements, setCardElements] = useState([]);

  const renderCard = (card) => {
    const key = `populate-card-${card.id}`;
    return (
      <div id={key} key={key} className={css(styles.templatesPopulate)}>
        <PopulateCardContainer
          project={project}
          card={card}
          cards={project.cards}
          onAttachContact={onAttachContact}
        />
      </div>
    );
  };

  const preLoadDocuments = () => {
    project.documents.forEach((document) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', document.pdf || document.originalFileUrl, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        const doc = this.response;

        if (this.status === 200) {
          document.updatePdfBlob(doc);
        }
      };
      xhr.send();
    });
  };

  useEffect(() => {
    const { cards } = project;
    const nextCardElements = cards.map(renderCard);
    setCardElements(nextCardElements);
    preLoadDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>{cardElements}</div>;
};

Populate.propTypes = {
  cards: PropTypes.array,
  onAttachContact: PropTypes.func,
};

Populate.defaultProps = {
  cards: [],
  onAttachContact: () => {},
};
