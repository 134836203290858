import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { X } from 'react-feather';
import {
  Controller,
  FieldErrors,
  FormProvider,
  Resolver,
  useForm,
} from 'react-hook-form';
import { useCreateContact } from '../../hooks';
import { Contact } from '../../types';

export type CreateContactModalProps = {
  firstName?: string;
  lastName?: string;
  existingFields?: { [key: string]: string };
  onContactCreated: (contact: Contact) => void;
  onClose: () => void;
} & Omit<DialogProps, 'onClose'>;

type ContactFormValues = {
  firstName: string;
  lastName: string;
};

const contactFormResolver: Resolver<ContactFormValues> = (values) => {
  let hasError = false;
  const errors: FieldErrors<ContactFormValues> = {};
  if (!values.firstName) {
    hasError = true;
    errors.firstName = {
      type: 'required',
      message: 'First Name is required',
    };
  }

  if (!values.lastName) {
    hasError = true;
    errors.lastName = {
      type: 'required',
      message: 'Last Name is required',
    };
  }

  return { values: hasError ? {} : values, errors: hasError ? errors : {} };
};

export const CreateContactModal = ({
  firstName = '',
  lastName = '',
  onContactCreated,
  onClose,
  existingFields,
  ...rest
}: CreateContactModalProps) => {
  const methods = useForm<ContactFormValues>({
    values: { firstName, lastName },
    resolver: contactFormResolver,
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const { mutate, isLoading } = useCreateContact({
    onSuccess: onContactCreated,
    onError: () => methods.setError('root', { message: 'An error occurred' }),
  });

  const rootErrorMessage = methods.formState.errors.root?.message;

  return (
    <FormProvider {...methods}>
      <Dialog
        TransitionProps={{ onExited: () => methods.reset() }}
        fullWidth
        maxWidth="sm"
        onClose={onClose}
        {...rest}
      >
        <form
          noValidate
          onSubmit={methods.handleSubmit(({ firstName, lastName }) =>
            mutate({
              first_name: firstName,
              last_name: lastName,
              ...existingFields,
              contact_type: 'person',
            }),
          )}
        >
          <DialogTitle>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <span>Create new Contact</span>
              <IconButton onClick={onClose}>
                <X />
              </IconButton>
            </Box>
            {rootErrorMessage ? (
              <Alert
                variant="filled"
                sx={(theme) => ({
                  padding: theme.spacing(0.5, 2),
                  margin: theme.spacing(1, 0),
                })}
                severity="error"
              >
                {rootErrorMessage}
              </Alert>
            ) : null}
          </DialogTitle>
          <DialogContent>
            <Box pb={1}>
              <Stack direction={'column'} gap={1}>
                <Typography variant="label">
                  First Name <span>*</span>
                </Typography>
                <Controller
                  name={'firstName'}
                  render={({ field, fieldState, formState }) => {
                    const shouldShowError =
                      (formState.isSubmitted || fieldState.isTouched) &&
                      !!fieldState.error;
                    return (
                      <TextField
                        autoFocus
                        required
                        {...field}
                        inputProps={{
                          'data-1p-ignore': true,
                        }}
                        error={shouldShowError}
                        helperText={
                          shouldShowError ? fieldState.error?.message : ' '
                        }
                        fullWidth
                        size={'small'}
                      />
                    );
                  }}
                />
              </Stack>
            </Box>
            <Box>
              <Stack direction={'column'} gap={1}>
                <Typography variant="label">
                  Last Name <span>*</span>
                </Typography>
                <Controller
                  name={'lastName'}
                  render={({ field, fieldState, formState }) => {
                    const shouldShowError =
                      (formState.isSubmitted || fieldState.isTouched) &&
                      !!fieldState.error;
                    return (
                      <TextField
                        required
                        {...field}
                        inputProps={{
                          'data-1p-ignore': true,
                        }}
                        error={shouldShowError}
                        helperText={
                          shouldShowError ? fieldState.error?.message : ' '
                        }
                        fullWidth
                        size={'small'}
                      />
                    );
                  }}
                />
              </Stack>
            </Box>
          </DialogContent>
          <DialogActions sx={(theme) => ({ padding: theme.spacing(0, 3, 2) })}>
            <Button onClick={onClose}>Cancel</Button>
            <Button type={'submit'} disabled={isLoading} variant={'contained'}>
              {isLoading ? 'Creating Contact...' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </FormProvider>
  );
};
