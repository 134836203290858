import React from 'react';
import { useUIFlagEnabled } from '~/src/entities/uiFlags';
import { PopulatePage as OriginalPopulatePage } from './PopulatePage';
import { PopulatePageV2 } from './PopulatePageV2';

type PopulatePageProps = {
  projectId: number;
  initialProject?: boolean;
};

export const PopulatePage = ({
  projectId,
  initialProject,
}: PopulatePageProps) => {
  const isPopulateExperienceMilestone4Enabled = useUIFlagEnabled(
    'populateExperienceMilestone4',
  );

  if (!isPopulateExperienceMilestone4Enabled)
    return (
      <OriginalPopulatePage
        projectId={projectId}
        initialProject={initialProject}
      />
    );

  return <PopulatePageV2 projectId={projectId} />;
};
