/* Libraries */
import React from 'react';
import { inject, observer } from 'mobx-react';

/* Higher Order Components */
import WithLayoutProps from '~/src/hoc/WithLayoutProps';

/* Components */
import SettingsAccount from '~/src/components/Settings/SettingsAccount';

/* Constants */
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';

class SettingsAccountContainer extends React.Component {
  onUpdateUserProfile = async (form) => {
    const names = form.fields.name.value.split(' ');
    const res = await this.props.store.user.profile.update({
      displayName: form.fields.name.value,
      firstName: names[0],
      lastName: names[names.length - 1],
    });

    if (res) {
      this.props.showToast(LAYOUT_TOAST_TYPES.success, {
        message: 'Profile updated',
      });
    } else {
      this.props.showToast(LAYOUT_TOAST_TYPES.error, {
        message: 'Failed to update profile, please try again later.',
      });
    }
  };

  onImageRemove = async () => {
    const res = await this.props.store.user.profile.removePhoto();

    if (res) {
      this.props.showToast(LAYOUT_TOAST_TYPES.success, {
        message: 'Photo removed',
      });
    } else {
      this.props.showToast(LAYOUT_TOAST_TYPES.error, {
        message: 'Failed to remove photo, please try again later.',
      });
    }

    return res;
  };

  onImageUpload = async (files) => {
    const file = files[0];

    if (file) {
      const res = await this.props.store.user.profile.uploadPhoto(file);

      if (res) {
        this.props.showToast(LAYOUT_TOAST_TYPES.onImageRemove, {
          message: 'Photo upload succesfully!',
        });
      } else {
        this.props.showToast(LAYOUT_TOAST_TYPES.error, {
          message: 'Failed to upload photo, please try again later.',
        });
      }

      return res;
    }
  };

  handleImageUploadError = (error) => {
    if (error.message) {
      this.props.showToast(LAYOUT_TOAST_TYPES.error, {
        message: error.message,
      });
    }
  };

  render() {
    const { avatar, phone, initials } = this.props.store.user.profile;
    const name = this.props.store.user.profile.displayName;
    return (
      <SettingsAccount
        avatar={avatar}
        phone={phone}
        name={name}
        initials={initials}
        onSubmit={this.onUpdateUserProfile}
        onImageUpload={this.onImageUpload}
        onImageRemove={this.onImageRemove}
        onImageUploadError={this.handleImageUploadError}
      />
    );
  }
}

export default inject((store) => store)(
  WithLayoutProps(observer(SettingsAccountContainer)),
);
