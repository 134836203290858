import React from 'react';
import { Box, Typography } from '@mui/material';
import { ProjectStackField, Project } from '~/src/entities/project';
import { FieldHeaderCount } from './FieldHeaderCount';
import { FieldHeaderHint } from './FieldHeaderHint';
import { Edited, EditIconButton } from '~/src/components/Inputs/Edit';

export type FieldHeaderWithEditProps = {
  projectField: ProjectStackField;
  project: Project;
  isEdited: boolean;
  isEditing: boolean;
  setIsEditing: (arg1: boolean) => void;
  canClose?: boolean | undefined;
};

export const FieldHeaderWithEdit = ({
  projectField,
  project,
  isEdited,
  isEditing,
  setIsEditing,
  canClose,
}: FieldHeaderWithEditProps) => {
  return (
    <Box pb={2}>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box flex={1}>
          <Typography variant="label">{projectField.label}</Typography>
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box
            sx={() => ({ visibility: isEdited ? 'visible' : 'hidden' })}
            mr={1}
          >
            <Edited data-testid="edited-chip" />
          </Box>
          <Box>
            <EditIconButton
              disabled={canClose === undefined ? false : !canClose}
              data-testid="edit-text-to-merge"
              isEditing={isEditing}
              sx={() => ({
                visibility: projectField.is_standalone_question
                  ? 'hidden'
                  : 'visible',
              })}
              onClick={() => setIsEditing(!isEditing)}
            />
          </Box>
          <Box pl={1}>
            <FieldHeaderCount projectField={projectField} project={project} />
          </Box>
        </Box>
      </Box>
      <Box>
        <FieldHeaderHint projectField={projectField} />
      </Box>
    </Box>
  );
};
