import useMst from '~/src/hooks/useMst';

const useInternalTools = () => {
  const { isInternalUser } = useMst((store) => ({
    isInternalUser: store.user.currentOrganization?.enableDevTools,
  }));

  return {
    isInternalUser,
  };
};

export default useInternalTools;
