import { Instance } from 'mobx-state-tree';
import { useState } from 'react';
import { Project } from '~/src/stores/projectsStore';

type UsePublishProjectCallbacks = {
  onSuccess: (documentId: string) => void;
  onError: (err: any) => void;
};

export const usePublishProject = ({
  onSuccess,
  onError,
}: UsePublishProjectCallbacks) => {
  const [publishing, setPublishing] = useState(false);
  const publish = async (project: Instance<typeof Project>) => {
    try {
      setPublishing(true);
      const { documents } = await project.publish();
      const document = documents[0];
      project.setDocument(document);
      onSuccess(document.id);
    } catch (error: any) {
      onError(error);
    }

    setPublishing(false);
  };

  return { publish, publishing };
};
