/* Libraries */
import React, { useState } from 'react';
import { css } from 'aphrodite';
import PropTypes from 'prop-types';
import moment from 'moment';

/* Components */
import Button from '~/src/components/Button';
import { TextArea } from '~/src/components/Inputs';

/* Hooks */
import useUser from '~/src/hooks/useUser';

/* Styles */
import styles from './styles';

const ReasonModalBody = ({
  isLoading,
  primaryActionTitle,
  onCancel,
  onNext,
}) => {
  const [technicalIssues, setTechnicalIssues] = useState(false);
  const [trainingMaterial, setTrainingMaterial] = useState(false);
  const [noBuyIn, setNoBuyIn] = useState(false);
  const [shuttingDown, setShuttingDown] = useState(false);
  const [missingFeatures, setMissingFeatures] = useState(false);
  const [tooComplicated, setTooComplicated] = useState(false);
  const [switchedProducts, setSwitchedProducts] = useState(false);
  const [timeConsuming, setTimeConsuming] = useState(false);
  const [customerService, setCustomerService] = useState(false);
  const [other, setOther] = useState('');

  const handleTechnicalIssuesClick = (event) => {
    event.preventDefault();
    setTechnicalIssues(!technicalIssues);
  };

  const handleTrainingMaterialClick = (event) => {
    event.preventDefault();
    setTrainingMaterial(!trainingMaterial);
  };

  const handleNoBuyInClick = (event) => {
    event.preventDefault();
    setNoBuyIn(!noBuyIn);
  };

  const handleShuttingDownClick = (event) => {
    event.preventDefault();
    setShuttingDown(!shuttingDown);
  };

  const handleMissingFeaturesClick = (event) => {
    event.preventDefault();
    setMissingFeatures(!missingFeatures);
  };

  const handleTooComplicatedClick = (event) => {
    event.preventDefault();
    setTooComplicated(!tooComplicated);
  };

  const handleSwitchedProductsClick = (event) => {
    event.preventDefault();
    setSwitchedProducts(!switchedProducts);
  };

  const handleTimeConsumingClick = (event) => {
    event.preventDefault();
    setTimeConsuming(!timeConsuming);
  };

  const handleCustomerServiceClick = (event) => {
    event.preventDefault();
    setCustomerService(!customerService);
  };

  const handleOtherChange = (nextValue) => {
    setOther(nextValue);
  };

  const handleNext = () => {
    onNext({
      technicalIssues,
      trainingMaterial,
      noBuyIn,
      shuttingDown,
      missingFeatures,
      tooComplicated,
      switchedProducts,
      timeConsuming,
      customerService,
      other,
    });
  };

  const hasReason =
    technicalIssues ||
    trainingMaterial ||
    noBuyIn ||
    shuttingDown ||
    missingFeatures ||
    tooComplicated ||
    switchedProducts ||
    timeConsuming ||
    customerService ||
    other.length > 1;

  return (
    <div>
      <p className="text-base font-light mb-4">
        Before you cancel please let us know the reason you are leaving
      </p>
      <p className="text-base font-medium mb-4">Select all that apply</p>

      <div className="mb-2">
        <label className="flex items-center" htmlFor="technicalIssues">
          <input
            type="checkbox"
            id="technicalIssues"
            checked={technicalIssues}
            onMouseDown={handleTechnicalIssuesClick}
          />
          <span className="ml-2 text-sm font-medium">Technical issues</span>
        </label>
      </div>

      <div className="mb-2">
        <label className="flex items-center" htmlFor="trainingMaterial">
          <input
            type="checkbox"
            id="trainingMaterial"
            checked={trainingMaterial}
            onMouseDown={handleTrainingMaterialClick}
          />
          <span className="ml-2 text-sm font-medium">
            Not enough training material
          </span>
        </label>
      </div>

      <div className="mb-2">
        <label className="flex items-center" htmlFor="noBuyIn">
          <input
            type="checkbox"
            id="noBuyIn"
            checked={noBuyIn}
            onMouseDown={handleNoBuyInClick}
          />
          <span className="ml-2 text-sm font-medium">
            Did not get buy-in from colleagues
          </span>
        </label>
      </div>

      <div className="mb-2">
        <label className="flex items-center" htmlFor="shuttingDown">
          <input
            type="checkbox"
            id="shuttingDown"
            checked={shuttingDown}
            onMouseDown={handleShuttingDownClick}
          />
          <span className="ml-2 text-sm font-medium">
            Shutting down or switching practice
          </span>
        </label>
      </div>

      <div className="mb-2">
        <label className="flex items-center" htmlFor="missingFeatures">
          <input
            type="checkbox"
            id="missingFeatures"
            checked={missingFeatures}
            onMouseDown={handleMissingFeaturesClick}
          />
          <span className="ml-2 text-sm font-medium">
            Missing features I need
          </span>
        </label>
      </div>

      <div className="mb-2">
        <label className="flex items-center" htmlFor="tooComplicated">
          <input
            type="checkbox"
            id="tooComplicated"
            checked={tooComplicated}
            onMouseDown={handleTooComplicatedClick}
          />
          <span className="ml-2 text-sm font-medium">
            Too complicated to learn
          </span>
        </label>
      </div>

      <div className="mb-2">
        <label className="flex items-center" htmlFor="switchedProducts">
          <input
            type="checkbox"
            id="switchedProducts"
            checked={switchedProducts}
            onMouseDown={handleSwitchedProductsClick}
          />
          <span className="ml-2 text-sm font-medium">
            Switching to another product
          </span>
        </label>
      </div>

      <div className="mb-2">
        <label className="flex items-center" htmlFor="timeConsuming">
          <input
            type="checkbox"
            id="timeConsuming"
            checked={timeConsuming}
            onMouseDown={handleTimeConsumingClick}
          />
          <span className="ml-2 text-sm font-medium">
            Initial setup seems too time consuming
          </span>
        </label>
      </div>

      <div className="mb-2">
        <label className="flex items-center" htmlFor="customerService">
          <input
            type="checkbox"
            id="customerService"
            checked={customerService}
            onMouseDown={handleCustomerServiceClick}
          />
          <span className="ml-2 text-sm font-medium">
            Didn&apos;t get enough support from customer service
          </span>
        </label>
      </div>

      <div className="my-4">
        <label htmlFor="other">
          <span className="ml-2 mb-2 inline-block text-sm font-medium">
            Other (please explain)
          </span>
          <TextArea id="other" onChange={handleOtherChange} />
        </label>
      </div>

      <div className={css(styles.modalActions)}>
        <Button cssStyle={styles.buttonAction} onClick={onCancel} primary>
          Go Back
        </Button>
        <Button
          cssStyle={styles.buttonAction}
          onClick={handleNext}
          disabled={!hasReason || isLoading}
        >
          {primaryActionTitle}
        </Button>
      </div>
    </div>
  );
};

const ConfirmModalBody = ({
  members,
  subscriptionEndDate,
  primaryActionTitle,
  isLoading,
  onNext,
  onCancel,
}) => {
  const [isChecked, setChecked] = useState(false);

  const handleCheck = (event) => {
    event.preventDefault();
    setChecked(!isChecked);
  };

  return (
    <div>
      <p className="text-sm mb-4">
        {' '}
        By canceling your subscription you and all {members} members of your
        organization will lose access to Lawyaw at the end of your current
        billing period on {subscriptionEndDate}. You will no longer have access
        to your client information, projects or documents.
      </p>

      <div className={css(styles.cancelSubscriptionModalAcknowledge)}>
        <input
          type="checkbox"
          onMouseDown={handleCheck}
          checked={isChecked}
          className={css(styles.cancelSubscriptionModalAcknowledgeInput)}
        />
        <p>
          I acknowledge that by canceling this account, all members of this
          organization will lose access to all their clients, projects, and
          documents created by Lawyaw.
        </p>
      </div>

      <div className={css(styles.modalActions)}>
        <Button cssStyle={styles.buttonAction} onClick={onCancel} primary>
          Go Back
        </Button>
        <Button
          cssStyle={styles.buttonAction}
          onClick={onNext}
          disabled={!isChecked || isLoading}
        >
          {primaryActionTitle}
        </Button>
      </div>
    </div>
  );
};

const CancelSubscriptionConfirm = (props) => {
  const { title, onCancel } = props;

  const [hasConfirmed, setHasConfirmed] = useState(false);
  const userStore = useUser();

  const handleNext = (reasons) => {
    if (!hasConfirmed) {
      setHasConfirmed(true);
    } else {
      userStore.currentOrganization.cancelSubscription(reasons);
      onCancel();
    }
  };

  const members = userStore.currentOrganization
    ? userStore.currentOrganization.members.length
    : 0;
  const subscriptionEndDate = userStore.currentOrganization
    ? moment(
        userStore.currentOrganization.subscription.currentPeriodEnd,
      ).format('MMMM DD, YYYY')
    : '';

  const isLoading = userStore.currentOrganization.isLoading();

  const primaryActionTitle = isLoading
    ? 'Cancelling...'
    : 'Continue with cancellation';

  const reasonModal = (
    <ReasonModalBody
      primaryActionTitle={primaryActionTitle}
      isLoading={isLoading}
      onNext={handleNext}
      onCancel={onCancel}
    />
  );

  const confirmModal = (
    <ConfirmModalBody
      members={members}
      subscriptionEndDate={subscriptionEndDate}
      primaryActionTitle={primaryActionTitle}
      isLoading={isLoading}
      onNext={handleNext}
      onCancel={onCancel}
    />
  );

  return (
    <div className={`${css(styles.modalContainer)} max-w-md mx-auto`}>
      <h1 className={css(styles.modalTitle)}>{title}</h1>
      <div className={css(styles.modalBody)}>
        {hasConfirmed ? reasonModal : confirmModal}
      </div>
    </div>
  );
};

CancelSubscriptionConfirm.propTypes = {
  title: PropTypes.string,
};

CancelSubscriptionConfirm.defaultProps = {
  title: 'Are you sure you want to cancel?',
  primaryActionTitle: 'Continue with cancellation',
};

export default CancelSubscriptionConfirm;
