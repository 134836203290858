import { types } from 'mobx-state-tree';

export const ProjectCardFieldOption = types.model({
  label: types.string,
  value: types.string,
});

export const ProjectCardField = types.model({
  id: types.string,
  label: types.string,
  hint: types.string,
  options: types.optional(types.array(ProjectCardFieldOption), []),
  isStandaloneQuestion: types.optional(types.boolean, false),
  type: types.string,
  validation: types.optional(types.string, ''),
  count: types.integer,
  dateFormat: types.maybeNull(types.string),
});
