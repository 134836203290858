import { useMutation } from 'react-query';
import env from '~/src/utils/env';
import { APIError } from '~/src/utils/error';

export const ACCOUNT_URL = `${env.apiUrl}/clients/api/v1/account/`;

export const useUpdateAccount = () =>
  useMutation<unknown, APIError, { phone?: string; state?: string }>({
    mutationFn: (body) =>
      fetch(ACCOUNT_URL, { method: 'PUT', body: JSON.stringify(body) }),
  });
