import React from 'react';
import {
  QuestionnaireBuilderApp,
  type Questionnaire,
} from '@clio/questionnaire-builder';
import { history } from '~/src/utils/history';
import {
  useDocumentMapTags,
  useUpdateClioQuestionnaire,
  useGetClioQuestionnaireById,
} from '~/src/entities/Questionnaires';
import { useOrgFprint } from '~/src/hooks/useOrgFprint';
import Page, { LoadingOverlay } from '~/src/components/PageLayout';
import { useSnackbar } from 'notistack';

export const QuestionnaireBuilderPage = ({
  questionnaireId,
  templateIds,
}: {
  questionnaireId: string;
  templateIds: string[];
}) => {
  const snackbar = useSnackbar();
  const orgFprint = useOrgFprint();
  const { data: documents, isError: isDocumentMappingError } =
    useDocumentMapTags(orgFprint, {
      template_ids: templateIds as string[],
    });

  const { data: questionnaire, isError: isQuestionnaireError } =
    useGetClioQuestionnaireById(orgFprint, questionnaireId);

  const updateClioQuestionnaire = useUpdateClioQuestionnaire(orgFprint);

  const handleSaveQuestionnaire = async (questionnaire: Questionnaire) =>
    updateClioQuestionnaire.mutateAsync(
      {
        questionnaireId,
        clioQuestionnaire: { ...questionnaire },
      },
      {
        onError: async () => {
          snackbar.enqueueSnackbar(
            'Something went wrong, please try again later.',
            {
              variant: 'error',
            },
          );
        },
        onSuccess: () => {
          snackbar.enqueueSnackbar(
            'Saved questionnaire template successfully',
            {
              variant: 'success',
            },
          );
        },
      },
    );

  const handleClose = () => {
    history.push(`/questionnaires/`);
  };

  if (questionnaire && documents) {
    return (
      <Page disablePadding={true} showNavigation={false} showHeader={false}>
        <QuestionnaireBuilderApp
          onExit={handleClose}
          documents={documents}
          questionnaire={questionnaire}
          onSave={handleSaveQuestionnaire}
        />
      </Page>
    );
  }

  if (isDocumentMappingError || isQuestionnaireError)
    return <div>Error, Failed to load Questionnaire Builder.</div>;

  return (
    <LoadingOverlay title="Loading Questionnaire Builder..." relative={false} />
  );
};
