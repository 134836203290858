import { APIErrorNotOk } from '~/src/utils/error';
import { getAuthTokenUrl } from './urls';

export const getClioQuestionnaireAuthToken = async (orgFprint: string) => {
  const response = await fetch(getAuthTokenUrl(orgFprint), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new APIErrorNotOk(response);
  }

  const json = await response.json();

  return json.questionnaire_auth_token;
};

export const questionnaireAuthorizationHeaders = (token: string) => ({
  questionnaire_auth_token: token,
});
