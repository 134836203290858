/* Libs */
import React, { useEffect, useState } from 'react';
import analyticsService from '~/src/services/analytics';

/* Hooks */
import { useStore } from '~/src/hooks/useMst';

/* Components */
import WelcomeForm from '~/src/components/WelcomeForm';
import { observer } from 'mobx-react';
import { useSubmitWelcomeForm } from '../entities/welcomeForm';
import { useOrgFprint } from '../hooks/useOrgFprint';
import { useUpdateAccount } from '../entities/user';
import { useHistory } from 'react-router-dom';

const WelcomeFormContainer = observer(() => {
  const store = useStore();
  const orgFprint = useOrgFprint();
  const history = useHistory();

  const [formState, setFormState] = useState({});

  const { mutateAsync: updateAccount, isLoading: isUpdatingAccount } =
    useUpdateAccount();
  const { mutateAsync: submitWelcomeForm, isLoading: isSubmittingWelcomeForm } =
    useSubmitWelcomeForm();

  useEffect(() => {
    analyticsService.track('Welcome Form Viewed');
  }, []);

  const handleChange = (nextFields) => {
    setFormState(nextFields);
  };

  const handleSubmit = async () => {
    const { fields } = formState;
    const questionnairePayload = [];
    const fieldKeys = Object.keys(fields);
    fieldKeys.forEach((key) => {
      questionnairePayload.push({ question: key, answer: fields[key].value });
    });

    await submitWelcomeForm({ orgFprint, payload: questionnairePayload });
    await updateAccount({
      contact: {
        phone: fields['Phone number'].value,
        state: fields.State.value,
      },
    });

    if (!store.user.currentOrganization.isNonProfit) {
      history.push('/subscribe');
    } else {
      history.push('/library');
    }
  };

  return (
    <WelcomeForm
      submitting={isSubmittingWelcomeForm || isUpdatingAccount}
      onChange={handleChange}
      onSubmit={handleSubmit}
    />
  );
});

export default WelcomeFormContainer;
