import React, { useState } from 'react';

/* Hooks */
import useMst from '~/src/hooks/useMst';
import useContactsPagination from '~/src/hooks/useContactsPagination';

/* Components */
import { ContactsSearchBar } from '~/src/components/Inputs';

/* Constants */
import { LAYOUT_SLIDEIN_TYPES } from '~/src/components/PageLayout/SlideIns';
import { useLayoutContext } from '../contexts/Layout';

const ContactsSearchBarContainer = (props) => {
  const { onSelectContact, onClearContact } = props;

  const { allData, loading, done, next, setSearch, data, empty } =
    useContactsPagination();

  const contacts = allData.map((contact) => {
    return {
      label: contact.fullName(),
      value: `${contact.id}`,
    };
  });

  const handleSearchContact = (value) => {
    setSearch(value);
  };

  const [value, setValue] = useState(props.value);

  const { getContact } = useMst((store) => ({
    getContact: store.contacts.getContact,
  }));

  const { showSlideIn } = useLayoutContext();

  const handleCreateNewContactSuccess = (newContact) => {
    const contact = getContact(newContact.id);
    onSelectContact(contact);
  };

  const handleCreateNewContact = () => {
    showSlideIn(LAYOUT_SLIDEIN_TYPES.contactCreate, {
      onCreate: handleCreateNewContactSuccess,
    });
  };

  const handleChange = (contactId, meta) => {
    if (!contactId && meta.action === 'clear' && value) {
      const contact = getContact(value);
      onClearContact(contact);
    }

    setValue(contactId);

    if (contactId && meta.action === 'select-option' && onSelectContact) {
      const contact = getContact(contactId);
      onSelectContact(contact);
    }
  };

  return (
    <ContactsSearchBar
      value={value}
      onCreateNewContact={handleCreateNewContact}
      onChange={handleChange}
      onSearchContact={handleSearchContact}
      contacts={contacts}
      loading={loading}
      done={done}
      next={next}
      data={data}
      empty={empty}
    />
  );
};

export default ContactsSearchBarContainer;
