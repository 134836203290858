import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';
import theme from '~/src/theme';

import { Circle, CheckCircle } from 'react-feather';

import styles from './styles';

const RadioToggle = ({ state, color, size }) => (
  <div className={css(styles.radio, state && styles.radioToggled)}>
    {!state && (
      <Circle size={theme.unit * size} color={color || theme.colors.charcoal} />
    )}
    {state && (
      <CheckCircle size={theme.unit * size} color={theme.colors.tennisBall} />
    )}
  </div>
);

RadioToggle.propTypes = {
  size: PropTypes.number,
  state: PropTypes.bool,
  color: PropTypes.string,
};

RadioToggle.defaultProps = {
  size: 1.5,
  state: false,
  color: theme.colors.charcoal,
};

export default RadioToggle;
