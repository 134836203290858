import React from 'react';
import {
  Button,
  Card,
  CardContent,
  LinearProgress,
  Typography,
  styled,
  Tooltip,
} from '@mui/material';
import { Slash, ArrowRight } from 'react-feather';

type ProgressTrackerWidgetProps = {
  fieldName?: string;
  totalFieldsCount: number;
  fieldsReviewedCount: number;
  onNextClick: () => void;
  onMarkAsNotApplicableClick: () => void;
  onFieldNameClick: () => void;
};

const FieldNameTypography = styled(Typography)(`
p {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 176px;
  white-space: nowrap;
  display: inline-block;
  cursor: pointer;
},
svg {
  margin-bottom: 10px;
  cursor: pointer;
}
`);

const ProgressTrackerWidget = ({
  fieldName,
  onNextClick,
  onMarkAsNotApplicableClick,
  totalFieldsCount,
  fieldsReviewedCount,
  onFieldNameClick,
}: ProgressTrackerWidgetProps) => {
  const areAllFieldsCompleted = fieldsReviewedCount === totalFieldsCount;
  const noFieldIsSelected = fieldName === undefined;

  const getFieldNameTypography = () => {
    if (noFieldIsSelected) {
      return (
        <Typography
          variant="button"
          sx={(theme) => ({
            color: theme.palette.grey[400],
          })}
        >
          {areAllFieldsCompleted
            ? 'All fields are populated'
            : 'No field is selected'}
        </Typography>
      );
    }
    return (
      <Tooltip title={fieldName} placement="bottom-start" arrow>
        <FieldNameTypography variant="button" onClick={onFieldNameClick}>
          <p>{fieldName}</p>
          <ArrowRight size={11} />
        </FieldNameTypography>
      </Tooltip>
    );
  };
  return (
    <Card
      raised
      sx={{
        width: '224px',
        position: 'fixed',
        left: 'calc(50% + 380px)',
        top: '15%',
      }}
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="smallCaption" sx={{ marginBottom: '16px' }}>
          REVIEW FIELDS
        </Typography>
        {getFieldNameTypography()}
        <Typography
          variant="smallLabel"
          sx={(theme) => ({
            color: theme.palette.text.subdued,
            marginTop: '16px',
            textAlign: 'end',
          })}
        >
          {totalFieldsCount - fieldsReviewedCount} remaining
        </Typography>
        <LinearProgress
          variant="determinate"
          value={(fieldsReviewedCount / totalFieldsCount) * 100}
          sx={(theme) => ({
            margin: '4px 0px 16px',
            backgroundColor: theme.palette.green[50],
            height: '10px',
            borderRadius: '10px',
            '& > .MuiLinearProgress-bar': {
              backgroundColor: theme.palette.green[400],
            },
          })}
          color="inherit"
        />
        <Button
          onClick={onNextClick}
          sx={(theme) => ({
            backgroundColor: theme.palette.background.page,
            color: theme.palette.grey[900],
            border: 'none',
            marginBottom: '16px',
            '&:hover': {
              border: 'none',
            },
          })}
        >
          {areAllFieldsCompleted ? 'Review again' : 'Next'}
        </Button>
        <Button
          onClick={onMarkAsNotApplicableClick}
          variant="text"
          startIcon={<Slash />}
          disabled={areAllFieldsCompleted || noFieldIsSelected}
          sx={(theme) => ({
            paddingTop: '0px',
            '& > .MuiButton-startIcon': {
              color: 'black',
              width: '13.33px',
            },
            '&:disabled > .MuiButton-startIcon': {
              color: theme.palette.grey[300],
            },
          })}
        >
          <Typography
            variant="smallLabel"
            sx={(theme) => ({
              color:
                theme.palette.grey[
                  noFieldIsSelected || areAllFieldsCompleted ? 300 : 900
                ],
            })}
          >
            Mark as not applicable
          </Typography>
        </Button>
      </CardContent>
    </Card>
  );
};

export default ProgressTrackerWidget;
