import React from 'react';
import { clioManageMatterLink } from '~/src/utils/clio';
import analyticsService from '~/src/services/analytics';
import { Instance } from 'mobx-state-tree';
import { Matter } from '~/src/stores/matterStore';
import SyncSingleMatterCard from './SyncSingleMatterCard';
import { useSyncSingleMatterModal } from './useSyncSingleMatterModal';
import { useLayoutContext } from '~/src/contexts/Layout';
import { LAYOUT_TOAST_TYPES } from '../PageLayout/Toasts';
import { useIsClioAuthenticated } from '~/src/hooks/useClio';
import useFeatureFlags from '~/src/hooks/useFeatureFlags';

type SingleMatterSyncProps = {
  matter: Instance<typeof Matter>;
  onSynced?: () => void;
  analyticsEventName: string;
};

const SingleMatterSync = ({
  matter,
  onSynced,
  analyticsEventName,
}: SingleMatterSyncProps) => {
  const { showToast } = useLayoutContext();

  const isAuthenticatedWithClio = useIsClioAuthenticated();
  const isUnifiedPlatform = useFeatureFlags().isUnifiedPlatform;
  const [{ sync }, modal] = useSyncSingleMatterModal({
    onMutate: ({ clio_matter_id }) => {
      analyticsService.track(
        `Single Matter Sync from ${analyticsEventName} - Initiated`,
        {
          clioMatterId: clio_matter_id,
        },
      );
    },
    onError: (_, { clio_matter_id }) => {
      analyticsService.track(
        `Single Matter Sync from ${analyticsEventName} - Failed`,
        { clioMatterId: clio_matter_id },
      );
    },
    onSuccess: (_, { clio_matter_id }) => {
      analyticsService.track(
        `Single Matter Sync from ${analyticsEventName} - Success`,
        { clioMatterId: clio_matter_id },
      );
      showToast(LAYOUT_TOAST_TYPES.success, {
        message: 'Matter synced successfully',
      });
      onSynced?.();
    },
  });

  const clioMatterId = matter.externalMatterId;
  if (!clioMatterId || !isAuthenticatedWithClio || !isUnifiedPlatform)
    return null;

  return (
    <>
      <SyncSingleMatterCard
        lastUpdated={matter.lastSynced ? matter.lastSynced : undefined}
        handleSync={() => sync(clioMatterId)}
        linkToManage={clioManageMatterLink(+clioMatterId)}
      />
      {modal}
    </>
  );
};

export default SingleMatterSync;
