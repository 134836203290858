import React, { useState } from 'react';
import { PopulatePageCardBody } from './PopulatePageCardBody';
import { PopulatePageCardProps } from './types';

export const PopulatePageOtherCard = ({
  group,
  project,
  ...props
}: PopulatePageCardProps) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <PopulatePageCardBody
      {...props}
      group={group}
      project={project}
      expanded={expanded}
      onChangeExpanded={() => setExpanded(!expanded)}
    />
  );
};
