import { useMutation, UseMutationOptions } from 'react-query';
import clio, { MatterSyncResponse } from '~/src/services/api/clioService';

import { useOrgFprint } from './useOrgFprint';
import { APIError } from '../utils/error';
import { composeMutationOpts } from '../entities/utils';
import matterService from '../services/matter';
import { useStore } from './useMst';
import { MatterStore } from '../stores/matterStore';
import { Instance } from 'mobx-state-tree';
import { useInvalidateClioMatters } from '../entities/clioMatters';

type UseSyncSingleMatterVars = {
  clio_matter_id: string;
};

export const useSyncSingleMatter = (
  options: UseMutationOptions<
    MatterSyncResponse,
    APIError,
    UseSyncSingleMatterVars
  > = {},
) => {
  const orgFprint = useOrgFprint();
  const store = useStore();
  const matterStore: Instance<typeof MatterStore> = store.matter;
  return useMutation(
    composeMutationOpts(options, {
      onSuccess: useInvalidateClioMatters(),
      mutationFn: async ({ clio_matter_id }) => {
        const matter_id = await clio.matterSync(orgFprint, clio_matter_id);

        // as a side effect, so the rest of the application can work as is
        // we're going to update the matter store
        const { matter } = await matterService.fetchMatter(
          orgFprint,
          matter_id.matter_id,
        );
        if (matterStore.getMatter(matter.id)) {
          matterStore.updateMatter(matter.id, { ...matter });
        } else {
          matterStore.setMatter(matter);
        }
        return matter_id;
      },
    }),
  );
};
