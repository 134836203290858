import React from 'react';
import { DialogActions, Button, Divider } from '@mui/material';

import { useOrgFprint } from '~/src/hooks/useOrgFprint';
import { MatterSearch } from '~/src/entities/clioMatters/components/MatterSearch';
import { ClioMatter } from '~/src/services/api/clioService';
import { ImportHeader } from '../ImportHeader';

type MatterSearchContentProps = {
  setSelectedClioMatter: (value: ClioMatter) => void;
  onClose: () => void;
};

const MatterSearchContent = ({
  setSelectedClioMatter,
  onClose,
}: MatterSearchContentProps) => {
  const orgFprint = useOrgFprint();
  return (
    <>
      <ImportHeader onClose={onClose} title="Select a matter to import" />
      <Divider />
      <MatterSearch orgFprint={orgFprint} onSelect={setSelectedClioMatter} />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </>
  );
};

export default MatterSearchContent;
