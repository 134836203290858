import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { ProjectStackField, Project } from '~/src/entities/project';
import { getDocumentsForField } from '../../utils';

export type FieldHeaderCountProps = {
  projectField: ProjectStackField;
  project: Project;
};

export const FieldHeaderCount = ({
  projectField,
  project,
}: FieldHeaderCountProps) => {
  let tooltipTitle1 = `${projectField.num_instances} instances in `;
  if (projectField.num_instances === 1) {
    tooltipTitle1 = `1 instance in `;
  }

  const documents = getDocumentsForField(project, projectField);
  let tooltipTitle2 = `${documents.length} documents`;
  if (documents.length === 1) {
    tooltipTitle2 = `1 document`;
  }

  return (
    <Tooltip
      title={
        <div style={{ whiteSpace: 'pre-wrap' }}>
          {tooltipTitle1 + tooltipTitle2}
        </div>
      }
    >
      <Box
        py={0.25}
        px={0.5}
        sx={(theme) => ({
          borderRadius: '4px',
          backgroundColor: theme.palette.background.page,
        })}
        display="flex"
      >
        <Typography variant="smallCaption">
          {projectField.num_instances}
        </Typography>
      </Box>
    </Tooltip>
  );
};
