/* Libraries */
import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

/* Components */
import Card from '~/src/components/Card';
import Button from '~/src/components/Button';
import ClioSyncStatus from '~/src/components/ClioSyncStatus';
import Overlay from '~/src/components/Overlay';
import { LoadingOverlay } from '~/src/components/PageLayout';

/* Assets */
import { ReactComponent as ClioSvg } from 'static/clio-icon.svg';

/* Styles */
import styles from './styles';

const SettingsIntegrations = ({
  onConnect,
  onSync,
  onDisconnect,
  enabled,
  lastSynced,
  syncing,
  clioSyncStatus,
}) => {
  const renderHeader = () => 'Integrations';

  const connectButton = (
    <Button primary onClick={onConnect}>
      Connect
    </Button>
  );

  const backgroundSyncing = clioSyncStatus === 'syncing';

  const syncButton = (
    <Button primary onClick={onSync} disabled={backgroundSyncing}>
      {syncing ? 'Starting' : 'Sync'}
    </Button>
  );

  const SyncingOverlay = ({ show }) => (
    <Overlay light show={show}>
      <LoadingOverlay title="Starting to sync your Clio data to Lawyaw" />
    </Overlay>
  );

  return (
    <>
      <Card
        large={true}
        renderHeader={renderHeader}
        cssStyle={styles.settingsIntegrationCard}
        noPadding
      >
        <div className={css(styles.settingsIntegrationRow)}>
          <div className={css(styles.settingsIntegrationRowItem)}>
            <i className={css(styles.settingsIntegrationRowItemIcon)}>
              <ClioSvg />
            </i>
          </div>
          <div className={css(styles.settingsIntegrationRowItem)}>
            <ClioSyncStatus status={clioSyncStatus} />
          </div>
          {lastSynced && enabled && (
            <div
              className={css(
                styles.settingsIntegrationRowItem,
                styles.settingsIntegrationRowItemLastSynced,
              )}
            >
              <span>
                {backgroundSyncing ? 'Sync started:' : 'Synced:'} {lastSynced}
              </span>
            </div>
          )}
          <div className={css(styles.settingsIntegrationRowItem)}>
            {enabled ? syncButton : connectButton}
          </div>
          {enabled && (
            <div className={css(styles.settingsIntegrationRowItem)}>
              <Button
                onClick={onDisconnect}
                disabled={backgroundSyncing}
                secondaryAlert
              >
                {'Disconnect'}
              </Button>
            </div>
          )}
        </div>
      </Card>
      {backgroundSyncing && (
        <Card elevate cssStyle={styles.settingsIntegrationInformation}>
          <p className={css(styles.settingsRow, styles.cardBody)}>
            We are importing your data from Clio. This could take up to 10
            minutes. You will receive an email when everything is imported
          </p>
        </Card>
      )}
      <SyncingOverlay show={syncing} />
    </>
  );
};

SettingsIntegrations.propTypes = {
  enabled: PropTypes.bool,
  onConnect: PropTypes.func,
};

SettingsIntegrations.defaultProps = {
  enabled: false,
  onConnect: () => {},
};

export default SettingsIntegrations;
