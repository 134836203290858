import React from 'react';
import { Box } from '@mui/material';
import { Controller } from 'react-hook-form';
import { ProjectStackField, Project } from '~/src/entities/project';
import { PopulateCardField } from './PopulateCardField';

export type PopulateCardFieldsProps = {
  fields: ProjectStackField[];
  project: Project;
  onBlur: (fieldId: string) => void;
  onFocus: (fieldId: string) => void;
};

export const PopulateCardFields = ({
  fields,
  project,
  onBlur,
  onFocus,
}: PopulateCardFieldsProps) => (
  <>
    {fields.map((projectField) => (
      <Box mb={2} key={projectField.fid}>
        <Controller
          name={projectField.fid}
          render={({ field }) => {
            return (
              <PopulateCardField
                field={{
                  ...field,
                  onBlur: () => {
                    field.onBlur();
                    onBlur(projectField.fid);
                  },
                  onFocus: () => onFocus(projectField.fid),
                }}
                projectField={projectField}
                project={project}
              />
            );
          }}
        />
      </Box>
    ))}
  </>
);
