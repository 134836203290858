import React, { useState, useEffect } from 'react';
import { debounce, camelCase } from 'lodash';
import { useObserver } from 'mobx-react';
import { User } from 'react-feather';

/* Higher Order Components */
import useMst from '~/src/hooks/useMst';
import { useLayoutContext } from '~/src/contexts/Layout';

/* Components */
import { PopulateCard } from '~/src/components/Populate';
import { renderFormField } from '~/src/components/Forms';

/* Constants */
import { LAYOUT_SLIDEIN_TYPES } from '~/src/components/PageLayout/SlideIns';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { CARD_TYPE_CONTACT } from '~/src/stores/contactsStore';

const STANDALONE_QUESTIONS_CARD = 'Standalone Questions';

const PopulateCardContainer = (props) => {
  const { card, cards } = props;

  const [inputRef, setInputRef] = useState(null);
  const [contactFieldValues, setContactFieldValues] = useState({});
  const [formFields, setFormFields] = useState([]);
  const [attachedContact, setAttachedContact] = useState();

  const { showToast, showSlideIn } = useLayoutContext();

  const cardFocused = useObserver(() => {
    return props.card.focus;
  });

  const { project, cardRelatedContactId } = useObserver(() => {
    return {
      project: props.project,
      cardRelatedContactId: props.card.relatedContactId,
    };
  });

  const { fetchContact, getContact, mapValuesToKeys } = useMst((store) => ({
    fetchContact: store.contacts.fetchContact,
    getContact: store.contacts.getContact,
    mapValuesToKeys: store.contacts.mapValuesToKeys,
  }));

  // Actions
  const getOrFetchContact = async (contactId) => {
    if (contactId) {
      const contact = getContact(contactId);
      if (contact && contact.id) {
        return contact;
      }
      return fetchContact(contactId);
    }

    return false;
  };

  const getTitle = (cardName) => {
    if (cardName === STANDALONE_QUESTIONS_CARD) {
      return 'Questions';
    }

    return cardName;
  };

  const shouldShowContactsSearchBar = () => {
    const { matterId } = project;
    const hasMatterId = !!matterId;

    const isContactCardType =
      card.type && card.type.toLowerCase() === CARD_TYPE_CONTACT.toLowerCase();
    const unkownCardType = card.type.length === 0;

    const isStandaloneQuestionCard =
      card.name && card.name === STANDALONE_QUESTIONS_CARD;

    // If there is a matter id and the card type is a contact or doesn't have a type, show the contact search bar.
    return (
      hasMatterId &&
      (isContactCardType || unkownCardType) &&
      !isStandaloneQuestionCard
    );
  };

  const mapContactToCardFields = async (contact) => {
    const cardFieldLabelKeys = card.fields.map(({ label }) => camelCase(label));
    const fieldValuesByLabelKey = await mapValuesToKeys(
      cardFieldLabelKeys,
      contact,
    );
    const fieldValuesById = {};

    card.fields.forEach((field) => {
      const fieldLabelKey = camelCase(field.label);
      const value = contact ? fieldValuesByLabelKey[fieldLabelKey] : '';

      if (fieldValuesByLabelKey[fieldLabelKey]) {
        fieldValuesById[field.id] = value;
      }
    });

    return fieldValuesById;
  };

  const handleGetInputRef = (ref) => {
    setInputRef(ref.current);

    if (card.focus && inputRef) {
      ref.current.focus();
    }
  };

  // Used like:
  // const contactValuesByCardLabelKey = await mapContactToCardFieldsByLabelKey(attachedContact);
  // const mapContactToCardFieldsByLabelKey = async (contact) => {
  //   const cardFieldLabelKeys = card.fields.map(({ label }) => camelCase(label));
  //   const fieldValuesByLabelKey = await mapValuesToKeys(cardFieldLabelKeys, contact);

  //   card.fields.forEach(field => {
  //     const labelKey = camelCase(field.label)
  //     if (!fieldValuesByLabelKey[labelKey]) {
  //       fieldValuesByLabelKey[labelKey] = field.defaultValue || "";
  //     }
  //   });

  //   const nextFormFields = getFormFields(card, fieldValues);
  //   setFormFields(nextFormFields);

  //   return fieldValuesByLabelKey;
  // };

  const mapAndSetContactFieldValues = async (contact) => {
    let nextFieldValues = {};
    const contactToMap = contact && contact.id ? contact : attachedContact;

    if (contactToMap) {
      nextFieldValues = await mapContactToCardFields(contactToMap);
    }

    setContactFieldValues(nextFieldValues);
    return nextFieldValues;
  };

  const updateFormFields = (values = {}) => {
    const fieldValues = {
      ...card.getFieldValues(),
      ...contactFieldValues,
      ...values,
    };

    const rawFields = [];

    let focusField = false;

    const mapCardFieldToFormField = (field) => {
      const additionalProps = {
        label: field.label,
      };

      if (Object.prototype.hasOwnProperty.call(fieldValues, field.id)) {
        field.defaultValue = fieldValues[field.id];
      }

      if (shouldShowContactsSearchBar()) {
        additionalProps.LeftIcon = User;
      }

      const focusableFieldTypes = [
        'text',
        'populateMultipleChoice',
        'textarea',
        'populateMultiSelect',
      ];

      if (!focusField && focusableFieldTypes.indexOf(field.type) !== -1) {
        focusField = true;

        return renderFormField(field, {
          getInputRef: handleGetInputRef,
          ...additionalProps,
        });
      }

      rawFields.push({
        field,
        additionalProps,
      });

      return renderFormField(field, additionalProps);
    };

    const nextFormFields = card.fields.map(mapCardFieldToFormField);

    setFormFields(nextFormFields);
  };

  const handleChange = debounce((formValue) => {
    if (formValue.focused && formValue.touched) {
      cards.forEach((c) => c.setFocus(c.id === card.id));
    }

    card.setFormValue(formValue);
    project.syncProjectFields();
  }, 500);

  const getCurrentFormFieldValues = () => {
    const fieldValues = {};

    const cardFieldValuesByLabel = card.getFieldValuesByLabel();

    // Find all the card field values that we can use to update the attached contact.
    Object.keys(cardFieldValuesByLabel).forEach((labelKey) => {
      const fieldValue =
        (cardFieldValuesByLabel[labelKey] &&
          cardFieldValuesByLabel[labelKey].value) ||
        '';
      fieldValues[labelKey] = fieldValue;
    });

    return fieldValues;
  };

  const populateFormValues = async (cardRelatedContactId) => {
    if (!cardRelatedContactId) {
      setContactFieldValues({});
      updateFormFields();
      return;
    }

    return getOrFetchContact(cardRelatedContactId).then(async () => {
      const contact = getContact(cardRelatedContactId);

      if (contact) {
        setAttachedContact(contact);
        const contactFieldValues = await mapAndSetContactFieldValues(contact);
        updateFormFields(contactFieldValues);
      }
    });
  };

  const resetFormFields = () => {
    const cardFieldLabelKeys = card.fields.map(({ id }) => id);
    const resetFieldValues = {};

    cardFieldLabelKeys.forEach((key) => {
      resetFieldValues[key] = '';
    });

    updateFormFields(resetFieldValues);
    return resetFieldValues;
  };

  const attachContactToCard = async (contact) => {
    // TODO: consolidate these two calls, we should only be calling one.
    // Ideally the card should be responsible for attaching contacts not the contact
    const cardName = card.name;
    const projectId = project.id;
    const cardFieldLabelKeys = card.fields.map(({ label }) => camelCase(label));
    card.setRelatedContactId(contact.id, card, project);
    contact.attachToCard(projectId, cardName, cardFieldLabelKeys);
  };

  const detachContactFromCard = async (contact) => {
    await contact.detachFromCard(project.id, card.name);
    card.setRelatedContactId(null);
    setAttachedContact(null);
  };

  const handleClearContact = async (contact) => {
    setAttachedContact(null);
    setContactFieldValues({});

    if (contact) {
      detachContactFromCard(contact);
    }

    resetFormFields();
  };

  const handleAttachContact = async (contact) => {
    // If it's the same contact, don't do anything
    if (attachedContact && attachedContact.id === contact.id) {
      return;
    }

    await handleClearContact();
    await attachContactToCard(contact);
  };

  const handleCreateNewContact = () => {
    const fields = {};
    const cardFieldValuesByLabel = card.getFieldValuesByLabel();

    Object.keys(cardFieldValuesByLabel).forEach((key) => {
      fields[key] = cardFieldValuesByLabel[key].value;
    });

    const handleSuccess = (contact) => {
      const contactId = contact.id;
      const contactProxy = getContact(contactId); // mobx-state-tree proxy

      populateFormValues(contactId);
      mapAndSetContactFieldValues(contactProxy);
      handleAttachContact(contactProxy);

      showToast(LAYOUT_TOAST_TYPES.success, { message: `Contact created` });
    };

    showSlideIn(LAYOUT_SLIDEIN_TYPES.contactCreate, {
      fieldValues: fields,
      onCreate: handleSuccess,
    });
  };

  const handleUpdateContact = () => {
    const handleSave = () => {
      populateFormValues(cardRelatedContactId);
      mapAndSetContactFieldValues(attachedContact);
    };

    const fieldsToUpdate = getCurrentFormFieldValues();

    showSlideIn(LAYOUT_SLIDEIN_TYPES.contact, {
      id: cardRelatedContactId,
      edit: true,
      fieldValues: fieldsToUpdate,
      onSave: handleSave,
    });
  };

  useEffect(() => {
    if (inputRef && cardFocused) {
      inputRef.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardFocused]);

  // When the card related contact changes, update the form values.
  useEffect(() => {
    populateFormValues(cardRelatedContactId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardRelatedContactId]);

  const title = getTitle(card.name);
  const showContactsSearchBar = shouldShowContactsSearchBar();

  return (
    <PopulateCard
      title={title}
      attachedContact={attachedContact && attachedContact.id}
      formFields={formFields}
      defaultValue={cardRelatedContactId}
      showContactsSearchBar={showContactsSearchBar}
      onUpdateContact={handleUpdateContact}
      onChange={handleChange}
      onCreateNewContact={handleCreateNewContact}
      onAttachContact={handleAttachContact}
      onClearContact={handleClearContact}
    />
  );
};

export default PopulateCardContainer;
