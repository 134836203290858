import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
