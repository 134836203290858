/* Hooks */
import useMst from '~/src/hooks/useMst';

const useFeatureFlags = () => {
  const {
    doesFeatureFlagForThisStateCodeExist,
    isQuestionnairesEnabled,
    isClioQuestionnaireEnabled,
    isWordDocumentAutomationEnabled,
    isFederalCourtFormsEnabled,
    isCourtFormsEnabled,
    isUnifiedPlatform,
    isClioFreePlan,
    isAddinLongDocumentsEnabled,
  } = useMst((store) => {
    return {
      doesFeatureFlagForThisStateCodeExist:
        store.user.flags.doesFeatureFlagForThisStateCodeExist,
      isClioFreePlan: store.user.flags.isClioFreePlan(),
      isQuestionnairesEnabled: store.user.flags.isQuestionnairesEnabled(),
      isClioQuestionnaireEnabled: store.user.flags.isClioQuestionnaireEnabled(),
      isWordDocumentAutomationEnabled:
        store.user.flags.isWordDocumentAutomationEnabled(),

      isFederalCourtFormsEnabled: store.user.flags.isFederalCourtFormsEnabled(),
      isCourtFormsEnabled: store.user.flags.isCourtFormsEnabled(),
      isUnifiedPlatform: store.user.flags.isUnifiedPlatform(),
      isAddinLongDocumentsEnabled:
        store.user.flags.isAddinLongDocumentsEnabled(),
    };
  });

  return {
    isClioFreePlan,
    doesFeatureFlagForThisStateCodeExist,
    isQuestionnairesEnabled,
    isClioQuestionnaireEnabled,
    isWordDocumentAutomationEnabled,
    isFederalCourtFormsEnabled,
    isCourtFormsEnabled,
    isUnifiedPlatform,
    isAddinLongDocumentsEnabled,
  };
};

export default useFeatureFlags;
