import React from 'react';
import { Box } from '@mui/material';
import {
  CardListItemWithActions,
  CardListItemMetaText,
} from '~/src/components/CardList';
import { Edit, Copy, Trash } from 'react-feather';
import { type Questionnaire } from '@clio/questionnaire-builder';
import { history } from '~/src/utils/history';
import moment from 'moment';

export const QuestionnaireTableCardList: React.FC<{
  questionnaires: Questionnaire[];
  handleRename: (questionnaire: Questionnaire) => void;
  handleDuplicate: (questionnaire: Questionnaire) => void;
  handleShare: (questionnaireId: string) => void;
  handleDelete: (questionnaire: Questionnaire) => void;
}> = ({
  questionnaires,
  handleRename,
  handleDuplicate,
  handleShare,
  handleDelete,
}) => {
  return (
    <>
      {questionnaires.map((questionnaire) => {
        const actions = [
          {
            label: 'Rename',
            icon: <Edit size={16} />,
            onClick: () => handleRename(questionnaire),
          },
          {
            label: 'Duplicate',
            icon: <Copy size={16} />,
            onClick: () => handleDuplicate(questionnaire),
          },
          {
            label: 'Delete',
            icon: <Trash size={16} />,
            onClick: () => handleDelete(questionnaire),
          },
        ];

        return (
          <Box pb={1} key={questionnaire.id}>
            <CardListItemWithActions
              key={questionnaire.id}
              onClick={() =>
                history.push(
                  `/questionnaires/${questionnaire.id}/select-documents/`,
                )
              }
              title={{
                label: `${questionnaire.title}`,
                meta: [questionnaire.description],
              }}
              meta={
                <CardListItemMetaText>
                  Last updated:{' '}
                  {moment(questionnaire.updated_at).format('MM/DD/YYYY')}
                </CardListItemMetaText>
              }
              actions={{
                actions,
                primary: {
                  label: 'Share',
                  disabled: false,
                  onClick: () => handleShare(`${questionnaire.id}`),
                },
              }}
            />
          </Box>
        );
      })}
    </>
  );
};
