import React from 'react';
import { styled } from '@mui/material';
import Button from '~/src/components/Button';
import MiniSpinner from '~/src/components/Spinner/MiniSpinner';

type PopulatePageFooterProps = {
  onGenerate: () => void;
  onCancel: () => void;
  isGenerating: boolean;
};

const Div = styled('div')({
  display: 'flex',
});

const BackButton = styled(Button)({
  marginRight: '16px',
});

const GenerateButton = styled(Button)({
  width: '228px',
  svg: {
    height: '21px',
  },
});

export const PopulatePageFooter = ({
  onGenerate,
  onCancel,
  isGenerating,
}: PopulatePageFooterProps) => {
  return (
    <Div>
      <BackButton onClick={onCancel}>Cancel</BackButton>
      <GenerateButton primary onClick={onGenerate} disabled={isGenerating}>
        {isGenerating && <MiniSpinner />}
        Generate document(s)
      </GenerateButton>
    </Div>
  );
};
