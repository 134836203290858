import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';

import {
  Contact,
  fetchContactFieldKeyMap,
  useContact,
} from '~/src/entities/contact';
import { PopulatePageCardBody } from '../PopulatePageCardBody';
import { PopulatePageCardProps } from '../types';
import {
  useRemoveContactFromProject,
  useUpdateProjectWithContact,
} from '~/src/entities/project';
import { ContactBar } from './ContactBar';
import { Refresh } from '@mui/icons-material';
import { useCurrentOrgFprint } from '~/src/entities/user';
import {
  getAttributeNamesFromGroup,
  getUpdatedFormFieldsForContact,
} from '../../utils';
import { useFormContext } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useCreateContactModal } from '~/src/entities/contact/components/CreateContactModal';
import ContactUpdaterWithProjectData from './ContactUpdaterWithProjectData';
import { SaveAsNewContactButton } from './SaveAsNewContactButton';

export const PopulatePageRoleCard = ({
  group,
  project,
  ...props
}: PopulatePageCardProps) => {
  const form = useFormContext();
  const snackbar = useSnackbar();
  const [isUpdatingContact, setIsUpdatingContact] = useState(false);
  const orgFprint = useCurrentOrgFprint();
  const [contact, setContact] = useState<Contact | null>(null);
  const [expanded, setExpanded] = useState(!!group.related_contact_id);
  const { isLoading, refetch, isError } = useContact(group.related_contact_id, {
    onSuccess: async (contactObj) => {
      if (contact == null) {
        const attributes = getAttributeNamesFromGroup(group);
        const keyMap = await fetchContactFieldKeyMap(
          orgFprint,
          contactObj.id,
          attributes,
        );
        getUpdatedFormFieldsForContact(contactObj, keyMap, attributes).forEach(
          (value, i) => {
            if (form.getValues(group.fields[i]!) !== value) {
              form.setValue(group.fields[i]!, value);
            }
          },
        );
        setContact(contactObj);
      }
    },
    refetchOnReconnect: false,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const { isLoading: isRemoving, mutateAsync: removeContact } =
    useRemoveContactFromProject();

  const { mutateAsync: addContact } = useUpdateProjectWithContact();

  const onAddContact = async (contact: Contact) => {
    setIsUpdatingContact(true);

    const attributes = getAttributeNamesFromGroup(group);

    try {
      const keyMap = await fetchContactFieldKeyMap(
        orgFprint,
        contact.id,
        attributes,
      );

      const { org_contact } = await addContact({
        projectId: project.id,
        contactId: contact.id,
        role: group.gid,
      });

      setContact(org_contact);
      getUpdatedFormFieldsForContact(org_contact, keyMap, attributes).forEach(
        (value, i) => {
          form.setValue(group.fields[i]!, value);
        },
      );
      setExpanded(true);
    } catch (err) {
      snackbar.enqueueSnackbar('Failed to attach contact', {
        variant: 'error',
      });
    } finally {
      setIsUpdatingContact(false);
    }
  };

  const [toggleCreateContactModal, createContactModal] = useCreateContactModal({
    maxWidth: 'xs',
    fullWidth: true,
    onContactCreated: (contact) => {
      toggleCreateContactModal();
      onAddContact(contact);
    },
  });

  const onRemoveContact = async () => {
    try {
      await removeContact({
        contactId: contact!.id,
        projectId: project.id,
        role: group.gid,
      });
      group.fields.forEach((fieldName) => form.setValue(fieldName, ''));
      setContact(null);
    } catch (err) {
      snackbar.enqueueSnackbar('Failed to remove contact', {
        variant: 'error',
      });
    }
  };

  const onSaveNewContact = (firstName: string, lastName: string) => {
    let fieldsToUpdate: any = {};
    group.fields.forEach((contactField: string) => {
      const partialKey = contactField.split('.')[1];
      const groupKey = contactField.split('.')[0];
      if (groupKey && partialKey) {
        fieldsToUpdate[partialKey] = form.getValues()[groupKey][partialKey];
      }
    });
    toggleCreateContactModal({
      existingFields: fieldsToUpdate,
      firstName,
      lastName,
    });
  };

  return (
    <>
      {createContactModal}
      <PopulatePageCardBody
        {...props}
        group={group}
        project={project}
        expanded={expanded}
        onChangeExpanded={() => setExpanded(!expanded)}
        extra={
          <Box px={3} py={2}>
            {contact ? (
              <ContactUpdaterWithProjectData contact={contact} group={group} />
            ) : null}
            <ContactBar
              contact={contact}
              error={
                isError ? (
                  <>
                    <Typography variant={'smallLabel'}>
                      Failed to retrieve contact {group.related_contact_id}
                    </Typography>
                    <Tooltip title={'Retry'}>
                      <IconButton onClick={() => refetch()}>
                        <Refresh fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : null
              }
              isLoading={isLoading || isRemoving || isUpdatingContact}
              contactSelectParams={{
                limit: 20,
              }}
              matterId={project.stack_data.project_metadata.matter_id}
              onAddContact={() => toggleCreateContactModal()}
              onChangeContact={(contact) =>
                contact ? onAddContact(contact) : onRemoveContact()
              }
            />
          </Box>
        }
        footer={
          contact ? null : (
            <SaveAsNewContactButton group={group} onClick={onSaveNewContact} />
          )
        }
      />
    </>
  );
};
