// Libs
import { types, flow } from 'mobx-state-tree';
import { saveAs } from 'file-saver';

// Services
import projectsService from '~/src/services/projects';

// Child Store
import ProjectCard from './projectCardStore';
import ProjectDocument from './projectDocumentStore';
import { getOrgFprintFromStoreNode } from '../utils';

export const Project = types
  .model('Project', {
    id: types.integer,
    identifier: types.identifier,
    name: types.string,
    matterId: types.maybeNull(types.integer),
    fprint: types.string,
    cards: types.optional(types.array(ProjectCard), []),
    documents: types.optional(types.array(ProjectDocument), []),
    updatedAt: types.maybeNull(types.string),
    isDownloading: types.optional(types.boolean, false),
    stackSavedData: types.map(types.frozen({})),
  })
  .actions((self) => {
    const getFieldValues = () => {
      let fieldValues = {};

      self.cards.forEach((card) => {
        fieldValues = {
          ...fieldValues,
          ...card.getFieldValues(),
        };
      });

      return fieldValues;
    };

    const setStackSavedData = flow(function* setStackSavedData(key, value) {
      if (
        (key.indexOf('\t') >= 0 || key.indexOf('customField_') >= 0) &&
        value === null
      ) {
        // For one way fields, if value is set to null, triggers a deletion of key
        self.stackSavedData.delete(key);
      } else {
        self.stackSavedData.set(key, value);
      }
      try {
        yield projectsService.updateProject(
          getOrgFprintFromStoreNode(self),
          self.id,
          self.stackSavedData,
        );
      } catch (error) {
        console.error('Failed to update project fields: ', error);
        self.error = typeof error === 'string' ? error : 'Something went wrong';
      }
    });

    const updateStackSavedDataWithFieldValues = (fieldValues) => {
      Object.keys(fieldValues).forEach((key) => {
        self.stackSavedData.set(key, fieldValues[key]);
      });
    };

    const publish = flow(function* publish() {
      const fieldValues = getFieldValues();

      try {
        self.error = '';
        self.isLoading = true;

        if (self.hasPdfs()) {
          updateStackSavedDataWithFieldValues(fieldValues);
          yield projectsService.updateProject(
            getOrgFprintFromStoreNode(self),
            self.id,
            fieldValues,
            {},
            self.stackSavedData,
          );
        } else {
          yield projectsService.updateProject(
            getOrgFprintFromStoreNode(self),
            self.id,
            fieldValues,
          );
        }

        yield projectsService.populateProject(
          getOrgFprintFromStoreNode(self),
          self.id,
        );
        const res = yield projectsService.fetchProject(
          getOrgFprintFromStoreNode(self),
          self.id,
        );

        self.documents = res.documents; // triggers change to data model

        return self;
      } catch (error) {
        console.error('Failed to publish draft: ', error);
        self.error = typeof error === 'string' ? error : 'Something went wrong';
        self.isLoading = false;
        throw error;
      }
    });

    const syncProjectFields = flow(function* syncProjectFields() {
      try {
        const fieldValues = getFieldValues();
        if (self.hasPdfs()) {
          updateStackSavedDataWithFieldValues(fieldValues);
          yield projectsService.updateProject(
            getOrgFprintFromStoreNode(self),
            self.id,
            fieldValues,
            {},
            self.stackSavedData,
          );
        } else {
          yield projectsService.updateProject(
            getOrgFprintFromStoreNode(self),
            self.id,
            fieldValues,
          );
        }
      } catch (error) {
        console.error('Failed to update project fields: ', error);
        self.error = typeof error === 'string' ? error : 'Something went wrong';
      }
    });

    function setDocument(document) {
      const id = parseInt(document.id);
      let found = false;

      for (let i = 0; i < self.documents.length; i++) {
        const document = self.documents[i];
        if (document.id === id) {
          found = true;
          self.documents[i] = document;
        }
      }

      if (!found) {
        self.documents.push(document);
      }
    }

    const setCards = (cards) => {
      self.cards = cards;
      return self;
    };

    const exportDoc = (showHighlights = true) => {
      try {
        self.isDownloading = true;
        const document = self.documents && self.documents[0];
        document.downloadDocument(showHighlights);
        self.isDownloading = false;
        return true;
      } catch (error) {
        console.error('Failed to download document: ', error);
        self.isDownloading = false;
        self.error = typeof error === 'string' ? error : 'Something went wrong';
        return false;
      }
    };

    const exportDocSet = flow(function* exportDocumentSet(
      showHighlights = true,
    ) {
      try {
        self.isDownloading = true;
        const zipBlob = yield projectsService.downloadDocSet(
          self.fprint,
          !showHighlights,
        );
        const fileName = self.name.replace(/[^a-z0-9]/gi, '_');

        saveAs(zipBlob, `${fileName}.zip`, { type: 'application/zip' });

        self.isDownloading = false;
        return true;
      } catch (error) {
        console.error('Failed to export doc set: ', error);

        self.isDownloading = false;
        self.error = typeof error === 'string' ? error : 'Something went wrong';

        return false;
      }
    });

    const update = flow(function* updateProject(projectData) {
      try {
        yield projectsService.updateProject(
          getOrgFprintFromStoreNode(self),
          self.id,
          null,
          projectData,
        );

        Object.keys(projectData).forEach((key) => {
          if (key === 'title') {
            self.name = projectData[key];
          } else {
            self[key] = projectData[key];
          }
        });

        return true;
      } catch (error) {
        console.error('Failed to update project title: ', error);
        self.error = typeof error === 'string' ? error : 'Something went wrong';

        return false;
      }
    });

    const deleteSelf = flow(function* deleteSelf() {
      yield projectsService.deleteProject(
        getOrgFprintFromStoreNode(self),
        self.id,
      );
      return true;
    });

    return {
      setCards,
      exportDocSet,
      setStackSavedData,
      exportDoc,
      syncProjectFields,
      getFieldValues,
      setDocument,
      update,
      deleteSelf,
      publish,
    };
  })
  .views((self) => {
    function getDocument(documentId) {
      const id = parseInt(documentId);

      for (let i = 0; i < self.documents.length; i++) {
        const document = self.documents[i];
        if (document.id === id) {
          return document;
        }
      }

      return false;
    }

    function isDocumentSet() {
      return self.documents.length > 1;
    }

    function hasPdfs() {
      // only show drafting page if documents have a pdf
      return self.documents.filter((doc) => !doc.docx).length > 0;
    }

    function getTemplateIds() {
      const templateIds = [];
      for (let i = 0; i < self.documents.length; i++) {
        const document = self.documents[i];
        if (document && document.templateId) {
          templateIds.push(document.templateId);
        }
      }

      return templateIds;
    }

    return {
      getDocument,
      isDocumentSet,
      hasPdfs,
      getTemplateIds,
    };
  });

export default Project;
