import { useLayoutContext } from '~/src/contexts';
import { useOrgFprint } from '~/src/hooks/useOrgFprint';
import { useDeleteClioQuestionnaireSubmission } from './useDeleteClioQuestionnaireSubmission';
import { LAYOUT_TOAST_TYPES } from '~/src/components/PageLayout/Toasts';
import { LAYOUT_MODAL_TYPES } from '~/src/components/PageLayout/Modals';
import { type QuestionnaireSubmission } from '@clio/questionnaire-builder';

export const useDeleteSubmissionConfirmModal = ({
  title,
}: {
  title: string;
}) => {
  const orgFprint = useOrgFprint();
  const { showToast, showModal, hideModal } = useLayoutContext();
  const { mutateAsync: deleteSubmission } =
    useDeleteClioQuestionnaireSubmission(orgFprint);

  const deleteSubmissionConfirmModal = (
    submission: QuestionnaireSubmission,
  ) => {
    showModal(LAYOUT_MODAL_TYPES.confirm, {
      title: title,
      message: `This will delete ${submission.questionnaire.title}. This is a permanent action and cannot be undone.`,
      primaryActionTitle: 'Delete',
      onConfirm: () =>
        deleteSubmission(
          { submissionId: `${submission.id}` },
          {
            onError: () => {
              showToast(LAYOUT_TOAST_TYPES.error, {
                message: 'Something went wrong, please try again later.',
              });
            },
            onSuccess: () => {
              showToast(LAYOUT_TOAST_TYPES.success, {
                message: 'Submission deleted',
              });
              hideModal();
            },
          },
        ),
    });
  };

  return { deleteSubmissionConfirmModal };
};
