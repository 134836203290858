import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  radio: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    content: "' '",
  },
});
