/* Libraries */
import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';

/* Components */
import UserAvatar, {
  getUserAvatarPlaceholder,
} from '~/src/components/UserAvatar';
import Dropdown from '~/src/components/Dropdown';

/* Styles */
import dropdownStyles from '~/src/components/Dropdown/styles';
import styles from './styles';
import useInternalTools from '~/src/hooks/useInternalTools';
import { useEmulateUserDialog } from '../EmulateUserDialog';
import { useLogout } from '~/src/entities/user';
import { useUIFlagsModal } from '~/src/entities/uiFlags';

const UserAvatarDropdownMenu = ({
  email,
  avatar,
  initials,
  organization,
  showSwitchOrganizationsAction,
  showEditProfileAction,
  onEditProfile,
  onSwitchOrganizations,
}) => {
  const { isInternalUser } = useInternalTools();
  const src = avatar || getUserAvatarPlaceholder(initials);
  const { mutate: onLogout } = useLogout();

  const [showEmulateUser, emulateUserDialog] = useEmulateUserDialog();
  const [showUIFlagsManager, uiFlagsManagerDialog] = useUIFlagsModal();

  const renderTrigger = ({ toggleDropdown }) => (
    <UserAvatar
      onClick={toggleDropdown}
      src={src}
      triangleOrientation={'down'}
      showTriangle={true}
    />
  );

  return (
    <Dropdown renderTrigger={renderTrigger} width={200}>
      <div className={css(styles.headerDropdown)}>
        <div className={css(styles.headerDropdownOrganizationLabel)}>
          {organization}
        </div>
        <div className={css(styles.headerDropdownOrganizationEmail)}>
          {email}
        </div>
      </div>
      {showSwitchOrganizationsAction && (
        <a
          className={css(dropdownStyles.dropdownActionItem)}
          onClick={onSwitchOrganizations}
        >
          Switch Organizations
        </a>
      )}
      {showEditProfileAction && (
        <a
          className={css(dropdownStyles.dropdownActionItem)}
          onClick={onEditProfile}
        >
          Edit profile
        </a>
      )}
      {isInternalUser ? (
        <>
          <a
            className={css(dropdownStyles.dropdownActionItem)}
            onClick={showEmulateUser}
          >
            Emulate User
          </a>
          {emulateUserDialog}
        </>
      ) : null}
      {isInternalUser ? (
        <>
          <a
            className={css(dropdownStyles.dropdownActionItem)}
            onClick={showUIFlagsManager}
          >
            Manage UI Flags
          </a>
          {uiFlagsManagerDialog}
        </>
      ) : null}
      <a className={css(dropdownStyles.dropdownActionItem)} onClick={onLogout}>
        Logout
      </a>
    </Dropdown>
  );
};

UserAvatarDropdownMenu.propTypes = {
  showEditProfileAction: PropTypes.bool,
  showSwitchOrganizationsAction: PropTypes.bool,
  organization: PropTypes.string,
  email: PropTypes.string,

  onEditProfile: PropTypes.func,
  onSwitchOrganizations: PropTypes.func,
};

UserAvatarDropdownMenu.defaultProps = {
  showEditProfileAction: false,
  showSwitchOrganizationsAction: false,

  onEditProfile: () => {},
  onSwitchOrganizations: () => {},
};

export default UserAvatarDropdownMenu;
