import React, { useState } from 'react';
import { FlagSetter, UIFlagsContext } from './uiFlags.context';
import { getDefaultFlagsForMembership, setFlagsForMembership } from './utils';

type UIFlagsProviderProps = {
  children: React.ReactNode;
  membershipId?: number;
};

export const InnerUIFlagsProvider = ({
  children,
  membershipId,
}: UIFlagsProviderProps) => {
  const [flags, setFlags] = useState(
    getDefaultFlagsForMembership(membershipId),
  );

  React.useEffect(() => {
    setFlagsForMembership(membershipId, flags);
  }, [flags, membershipId]);

  const setFlag: FlagSetter = (flag, enabled) =>
    setFlags((existing) => ({ ...existing, [flag]: enabled }));

  return (
    <UIFlagsContext.Provider value={{ flags, setFlag }}>
      {children}
    </UIFlagsContext.Provider>
  );
};
